'use client';
import React from 'react'
import { Accordion } from 'flowbite-react';

const AccordionComponent = () => {
    return (
        <>
            <Accordion>
                <Accordion.Panel>
                    <Accordion.Title>
                    What is TheFinMaster.com?
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                        TheFinMaster.com is your one-stop shop for all things personal finance! We provide clear and unbiased information on a wide range of financial products and services, like credit cards, mutual funds, and insurance. Think of us as your friendly neighborhood financial guide, helping you navigate the complexities of the financial world and make informed decisions. </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                    Can I compare financial products side-by-side on TheFinMaster.com?
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                        Absolutely! We understand the importance of comparing different options before making a financial decision. TheFinMaster.com offers user-friendly comparison tools for credit cards, mutual funds, and insurance policies.  These tools allow you to analyze key features, fees, and benefits to choose the product that best aligns with your needs. </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                    Does TheFinMaster.com offer any educational resources?
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                        TheFinMaster.com offers a comprehensive library of educational resources, including informative blog posts. Our engaging blog articles cover a wide range of financial topics, from the basics of budgeting and saving to more complex concepts like investing and retirement planning. </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                    Is TheFinMaster.com affiliated with any financial institutions?
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                        TheFinMaster.com maintains a strict policy of editorial independence. We are not affiliated with any banks, investment companies, or insurance providers. This ensures that the information and recommendations we provide are objective and unbiased.  Our goal is to empower you with the knowledge to make informed financial decisions, not to promote specific products or service.</p>
                    </Accordion.Content>
                </Accordion.Panel>
                {/* <Accordion.Panel>
                    <Accordion.Title>
                        Is using FinancialEase Hub free?
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            Yes, our services are completely free for users. We earn revenue through affiliate partnerships with financial service providers, but this has no impact on the information and comparisons we provide.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                        How frequently are the comparisons updated?
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            We strive to keep our comparisons up-to-date with the latest information. However, the frequency of updates may vary depending on changes in the financial market. We recommend checking back regularly for the most current data.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                        Can I apply for a financial product directly through FinancialEase Hub?
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            Yes, you can! Once you've found the right BFSI service through our comparisons, you can proceed to apply directly on our platform. We provide a seamless application process to make it convenient for you.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                        How can I contact FinancialEase Hub for support or inquiries?
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            Feel free to reach out to us through our "Contact Us" page. We're here to assist you with any questions or concerns you may have. Your feedback is valuable to us in continually improving our services.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                        Is my personal information secure on FinancialEase Hub?
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            Yes, we take the security and privacy of your information seriously. Please refer to our Privacy Policy for detailed information on how we handle and protect your data.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title>
                        Can I trust the information provided on FinancialEase Hub?
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="mb-2 text-gray-500 dark:text-gray-400">
                            Absolutely. We are committed to providing accurate and reliable information. Our comparisons are based on thorough research and analysis, and we strive to maintain the highest standards of integrity and transparency.
                        </p>
                    </Accordion.Content>
                </Accordion.Panel> */}
            </Accordion>
        </>
    )
}

export default AccordionComponent
