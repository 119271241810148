import React, { useState } from 'react';
import AccordionComponent from '../accordion/AccordionComponent'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}
function IdfcFirstClassic() {
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);


    // Function to get current date
    // const getCurrentDate = () => {
    //     const date = new Date();
    //     const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //     return date.toLocaleDateString('en-US', options);
    // };
    const [activeIndex, setActiveIndex] = useState(0);

    const faqs = [
        {
            question: 'How can I apply for the IDFC First Classic Credit Card?',
            answer:
            'IDFC has announced its credit cards but applications are not yet open. You can visit the website and opt to receive notifications upon launch to be informed as soon as the credit card becomes available.',
        },
        {
            question: ' What is the joining fee for the IDFC First Classic Credit Card?',
            answer:
                'The IDFC First Classic Credit Card is a lifetime free card with no joining, membership, or annual fee applicable.',
        },
        {
            question: ' What is the validity of reward points earned on the IDFC First Classic Credit Card?',
            answer:
                'The reward points accumulated on the IDFC First Classic Credit Card do not expire and remain valid indefinitely.',
        },
        {
            question: 'Can I earn reward points on any online or offline transaction through the IDFC First Classic Credit Card?',
            answer:
                'Yes, you can earn 3X reward points on both online and offline transactions. However, certain transactions such as fuel purchases, EMIs, and cash withdrawals are not eligible for reward points.',
        },
        {
            question: 'Which transactions on the IDFC First Classic Credit Card can be converted into EMI?',
            answer:
                'Transactions above Rs. 2,500 on the IDFC First Classic Credit Card can be converted into EMIs post-purchase.',
        },
        {
            question: 'Does the IDFC First Classic Credit Card provide airport lounge access?',
            answer:
                'No, the IDFC First Classic Credit Card does not offer complimentary access to airport lounges. However, cardholders receive 4 complimentary Railway lounge visits per quarter.',
        },
        {
            question: 'What is the foreign exchange markup fee on the IDFC First Classic Credit Card?',
            answer:
                'A foreign exchange markup fee of 3.50% is charged on transactions made in foreign currencies using the IDFC First Classic Credit Card.',
        },
    ];

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };
    return (
        <>
<Helmet>
  <title>IDFC FIRST Classic Credit Card - Benefits &amp; Apply Online</title>
      <meta  name='description' content='IDFC FIRST Classic Credit Card is a lifetime free credit with the best interest rates. Check Features, Benefits, Rewards, Fees, Charges, and Apply Online.'/>
      <meta name='keywords' content='idfc classic credit card, idfc first classic, idfc classic credit card benefits, idfc first classic credit card lounge access' />
      <link rel="canonical" href="https://thefinmaster.com/credit-card/idfcfirst-classic-credit-card" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
            <div className='w-[95%] py-10 px-10'>
                <div className="mx-auto ml-4 border border-gray-300 rounded-md mt-4  mb-6 ">
                    <div className="flex justify-between items-center px-4 py-2 mt-4 text-black">
                        {/* Left Side */}
                        <div className="flex items-center">
                            <h1 className="text-3xl font-bold">IDFC FIRST Classic Credit Card</h1>
                        </div>

                        {/* Right Side */}
                        <div className="flex items-center ">
                            {/* Replace 'your_link_here' with your actual link */}

                            <a href="https://www.idfcfirstbank.com/credit-card/classic" target="_blank"  rel="noopener noreferrer" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>

                        </div>


                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        {/* Right Side - Image */}
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/Idfc1.webp" alt="IDFC Bank" className="w-full" />
                            {/* Rating Component */}
                            <div className="flex items-center mr-4 text-lg ml-4">
                                {/* You can replace the static stars with your actual rating logic */}
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>  
                        </div>

                        {/* Left Side - Headings */}
                        <div className="md:w-1/3 flex flex-col justify-between mb-4 md:mb-0 ">
                            <div className="mb-2 ">
                                <h2 className="text-lg font-semibold text-blue-600">Fees</h2>
                                <p className='text-gray-600'>Joining Fee: Nil</p>
                                <p className='text-gray-600'>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg font-semibold text-blue-600">Welcome Benefits</h2>
                                <p className='text-gray-600'>Receive a Rs. 500 gift voucher when you spend Rs. 15,000 or more in the first 90 days. Plus, get 5% cashback on your first EMI transaction within 90 days of card issuance.</p>
                            </div>
                        </div>



                        {/* Right Side - Content */}
                        <div className="md:w-1/3 md:ml-6">

                            <div>
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p className='text-gray-600'>Travel & Shopping</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p className='text-gray-600'>Earn 3 Reward Points for every Rs. 150 spent offline and 6 Reward Points for every Rs. 150 spent online.</p>
                            </div>
                        </div>
                    </div>
                    {/* New Div with Border Line */}
                    <div className="border-t border-gray-300 mt-4">
                        {/* Content */}
                        <div className="text-xl md:text-2xl lg:text-1xl font-bold  mt-3 ml-4">Rewards and Benefits</div>
                        <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                            {/* Left Side - Headings */}
                            <div className="md:w-1/2 mb-4 md:mb-0">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600"> Rewards Rate</h2>
                                    <p className='text-gray-600'>Earn 1 Reward Point for every Rs. 100 spent on retail using the card, and enjoy accelerated Reward Points at 10X or 5X rates for specific features. Additionally, unlock additional cashbacks for selected features.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Travel</h2>
                                    <p className='text-gray-600'>Enjoy complimentary Railway lounge access 4 times per calendar quarter. Additionally, receive complimentary roadside assistance valued at Rs. 1,399, available 4 times per year.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Movie and Dining</h2>
                                    <p className='text-gray-600'>Enjoy a 25% discount on movies (up to Rs. 100) once a month on the Paytm app, along with up to 20% off dining with your IDFC FIRST Classic Credit Card.</p>
                                </div>
                            </div>
        
                            {/* Right Side - Headings */}
                            <div className="md:w-1/2 md:ml-6">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">International Lounge Access</h2>
                                    <p className='text-gray-600'>Nil</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Zero Liability Protection</h2>
                                    <p className='text-gray-600'>You won't be liable for any fraudulent transactions on your card if you report the loss to the bank promptly.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Reward Redemption</h2>
                                    <p className='text-gray-600'>Reward Points can be redeemed instantly for online transactions and at partner stores, with 1 Reward Point equaling Rs. 0.25. Note that a convenience fee of Rs. 99 plus taxes applies when you redeem your reward points..</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-300 mt-4" />
                    <Accordion open={open === 1} className='px-3' icon={<Icon id={1} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(1)}>Fees & Charges</AccordionHeader>
                        <AccordionBody>
                            <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                                {/* Left Side - Headings */}
                                <div className="md:w-1/2 mb-4 md:mb-0">
                                    
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Interest Rates</h2>
                                        <p className='text-gray-600 text-base'>0.75% to 3.5% per month</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Rewards Redemption Fee</h2>
                                        <p className='text-gray-600 text-base'>Rs. 99 plus taxes</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Fuel Surcharge
                                        </h2>
                                        <p className='text-gray-600 text-base'>1% fuel surcharge waiver (up to Rs. 200 per statement cycle)</p>
                                    </div>
                                </div>

                                {/* Right Side - Headings */}
                                <div className="md:w-1/2 md:ml-6">
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Foreign Currency Markup</h2>
                                        <p className='text-gray-600 text-base'>3.5% on all foreign currency spends.</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Waiver Based on Spending</h2>
                                        <p className='text-gray-600 text-base' >N/A</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Add-on Card Fee</h2>
                                        <p className='text-gray-600 text-base'>Nil</p>
                                    </div>
                                </div>
                                {/* Right Side - Headings */}
                                {/* <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div> */}
                            </div>

                        </AccordionBody>
                    </Accordion>
                    <Accordion className='px-3' open={open === 2} icon={<Icon id={2} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(2)}>
                            Card Details
                        </AccordionHeader>
                        <AccordionBody>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        3x Reward Points for every Rs. 150 spent on offline purchases.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        6x Reward Points for every Rs. 150 spent on online purchases.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        10x Reward Points for every Rs. 150 spent on purchases over Rs. 20,000.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        10x Reward Points for every Rs. 150 spent on your birthday.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        No Joining/Renewal Fee for this credit card.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Welcome Benefit includes a Rs. 500 gift voucher and 5% cashback on your first EMI transaction.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Movie Discount: 25% off on movie tickets through the Paytm app, up to Rs. 100, once per month.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Railway Lounge Access: 4 complimentary visits per quarter.
                    </p>
                         
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Exclusive Partner Offers with over 300 partner merchants.
                    </p>
                         
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Free Roadside Assistance worth Rs. 1,399.
                    </p>
                         
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Insurance Coverage: Personal accident cover worth Rs. 2 lakh and lost card liability cover worth Rs. 25,000.
                    </p>
                         
                                    {/* <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Left Heading 2</h2>
                            <p>Description for left heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font- text-blue-600">Left Heading 3</h2>
                            <p>Description for left heading 3</p>
                        </div> */}
                            

                                {/* Right Side - Headings */}
                                {/* <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div>
                      {/* Right Side - Headings *
                      <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div> */}
                            

                        </AccordionBody>
                    </Accordion>

                </div>
                <div className=" mx-auto ">
                <div className="text-xl md:text-2xl lg:text-4xl font-bold mt-8 mb-4 ml-4">IDFC FIRST Classic Credit Card Detail</div>
                <div className="text-sm md:text-base lg:text-lg text-gray-600 mb-8">
                    {/* <p className="float-left">{getCurrentDate()}</p> */}
                    {/* <p className="float-right font-bold mr-4 ">{getCurrentDate()}</p> */}
                </div>
                <p className='px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg '>

                The IDFC FIRST Classic Credit Card offers exclusive benefits and rewards without any joining or renewal fees. It provides perks across various categories, such as travel, dining, movies, insurance, and fuel surcharge waivers, along with complimentary roadside assistance. 

                </p>
                <p className=' px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg'>

                With its low-interest rates, this card is an excellent choice if you're looking for a credit card that combines value and cost-effectiveness.                </p>
              
            </div>
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of IDFC FIRST Classic Credit Card</h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">The IDFC First credit card offers 16 complimentary Railway Lounge visits each year (4 per quarter) and roadside assistance worth Rs. 1,399 four times a year. Enjoy a 25% movie discount on Paytm (up to Rs. 100 per month) and up to 20% off dining at over 1,500 restaurants.</p>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Insurance benefits include a personal accident cover of Rs. 2 lakh and a lost card cover of Rs. 25,000. The card also provides a 1% fuel surcharge waiver, capped at Rs. 200 per month, and discounts at more than 3,000 Health & Wellness outlets.</p>
                </div>
                {/* <div className="container mx-auto px-4 py-2 ">
                    <h1 className="text-2xl font-bold pl-4 py-2">New UI Heading</h1>
                    <p className="text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> 
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600 ">&#8226;</span>
                        Responsive paragraph text. This text will adjust based on the screen size.
                    </p>
                    <p className="text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> 
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> 
                        Responsive paragraph text. This text will adjust based on the screen size.
                    </p>
                </div> */}
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Cashback & Reward Points</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-4"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Earn 3x Reward Points for every Rs. 150 spent on offline purchases.

                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Earn 6x Reward Points for every Rs. 150 spent on online purchases.

                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Earn 10x Reward Points for every Rs. 150 spent on purchases over Rs. 20,000.

                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Earn 10x Reward Points for every Rs. 150 spent on your birthday.

                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
Transactions such as fuel purchases, EMI transactions, loan payments, and cash withdrawals do not earn Reward Points.

                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        The Reward Points you earn with your IDFC FIRST Classic Credit Card never expire.

                    </p>
                    <h1 className="text-2xl font-bold pl-4 py-2 mt-2">How to redeem rewards points?</h1>
                   
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Redeem your Reward Points easily and instantly for online transactions or at partner stores.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Each Reward Point is worth Rs. 0.25.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        The IDFC FIRST Classic Credit Card has a convenience fee of Rs. 99 plus taxes for redeeming rewards.                    </p>
                    
                    
                    
                    
                </div>
            </div>
            <div className="container mx-auto px-4 ">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How To Apply For The IDFC FIRST Classic Credit Card? </h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
                    Follow these simple steps to apply for an IDFC FIRST Classic Credit Card.

                    </p>
               
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Click the "Apply Now" button on this page.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        You'll be redirected to IDFC credit card application page.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Complete the required form to submit your application.
                    </p>
                    
                    </div>
            <div className="max-w-4xl mx-auto py-6">
            <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                        <button
                            onClick={() => toggleAccordion(index)}
                            className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                        >
                            {faq.question}
                            <svg
                                className={`w-6 h-6 transition-transform transform ${
                                    activeIndex === index ? 'rotate-180' : ''
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d={activeIndex === index ? 'M19 9l-7 7-7-7' : 'M9 5l7 7 7-7'}
                                />
                            </svg>
                        </button>
                        {activeIndex === index && (
                            <div className="p-4 bg-gray-100">
                                <p className="text-gray-600">{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
            {/* <div className='w-[95%] py-10 px-10'>
                <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
                <AccordionComponent />
            </div> */}

        </>



    );
}

export default IdfcFirstClassic;


