import React, { useState } from 'react';
import AccordionComponent from '../accordion/AccordionComponent'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}
function  FlipcartAxixBank() {
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);


    // Function to get current date
    // const getCurrentDate = () => {
    //     const date = new Date();
    //     const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //     return date.toLocaleDateString('en-US', options);
    // };
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: 'Will I get cashback on fuel transactions?',
            answer:
            'No, fuel transactions do not qualify for cashback on the Flipkart Axis Bank Credit Card. Additionally, cashback is not applicable on purchases such as gift cards on Flipkart and Myntra, EMI transactions, transactions converted to EMI post facto, wallet loading transactions, purchase of gold items, cash advances, payment of outstanding balances, and payment of card fees and other charges.',
        },
        {
            question: 'Do I earn reward points on the Flipkart Axis Bank Credit Card?',
            answer:
                'No, instead of reward points, the Flipkart Axis Bank Credit Card offers direct cashback on all transactions. The cashback earned is directly credited to the cardholders statement. Cardholders can also access their account online to check and avail other card services and offers.',
        },
        {
            question: ' Can I use the Flipkart Axis Bank Credit Card offline or at ATMs?',
            answer:
                'Yes, the Flipkart Axis Bank Credit Card can be used for both offline and online purchases. It is accepted at POS terminals, online platforms, and ATMs. However, using the card at ATMs for cash withdrawals incurs additional charges such as a 2.5% cash withdrawal fee (minimum Rs. 500) and finance charges of 3.6% per month from the withdrawal date, making it a costly form of credit.',
        },
        {
            question: 'Where can I avail the fuel surcharge waiver?',
            answer:
                'The fuel surcharge waiver can be availed at any petrol pump across India. The maximum waiver amount is capped at Rs. 400 per month, applicable on fuel transactions between Rs. 400 to Rs. 4,000. Transactions above or below this range are not eligible for the surcharge waiver.',
        },
        {
            question: 'What is the foreign exchange markup fee on the Flipkart Axis Bank Credit Card?',
            answer:
                'A foreign exchange markup fee of 3.50% is charged on transactions made in foreign currency using the Flipkart Axis Bank Credit Card.',
        },
        {
            question: 'How can I check my Flipkart Axis Bank Credit Card application status?',
            answer:
                'You can check the status of your Flipkart Axis Bank Credit Card application by contacting customer care or logging into the bank’s website. You will need your application ID or PAN Card number along with your mobile number to access your application details.',
        },
        {
            question: 'How long will it take for my application to be processed?',
            answer:
                'After submitting all required documents, it may take up to 21 working days for Axis Bank to process and issue the Flipkart Axis Bank Credit Card.',
        },
        {
            question: 'Is the Flipkart Axis Bank Credit Card beneficial?',
            answer:
                'The Flipkart Axis Bank Credit Card is beneficial for frequent online shoppers who prefer Flipkart and its partner platforms. It offers accelerated cashback across various spending categories including shopping, entertainment, travel, and lifestyle-related expenses. Refer to the details above for comprehensive features.',
        },
         {
            question: 'What are the customer care numbers for the Flipkart Axis Bank Credit Card?',
            answer:
                'You can reach Axis Bank Credit Card customer care at 1860-419-5555 and 1860-500-5555.',
        },

    ];

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };
    return (
        <>
<Helmet>
  <title>Flipkart Axis Bank Credit Card - Apply Online for Cashback</title>
  <meta name="description" content="Enjoy a Range of Benefits with the Flipkart Axis Bank Credit Card. Get 5% Cashback on Flipkart, Exclusive Airport Lounge Access, and Fuel Benefits." />
  <meta name='keywords' content='apply flipkart axis bank credit card, axis bank flipkart credit card benefits, flipkart axis credit card, axis flipkart credit card lounge access' />
  <link rel="canonical" href="https://thefinmaster.com/credit-card/axis-bank-flipkart-credit-card" />
  <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
  <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
            <div className='w-[95%] py-10 px-10'>
                <div className="mx-auto ml-4 border border-gray-300 rounded-md mt-4  mb-6 ">
                    <div className="flex justify-between items-center px-4 py-2 mt-4 text-black">
                        {/* Left Side */}
                        <div className="flex items-center">
                            <h1 className="text-xl font-bold">Flipkart Axis Bank Credit Card</h1>
                        </div>

                        {/* Right Side */}
                        <div className="flex items-center ">
                            {/* Replace 'your_link_here' with your actual link */}

                            <a href="https://www.axisbank.com/retail/cards/credit-card/flipkart-axisbank-credit-card" target="_blank"  rel="noopener noreferrer" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>

                        </div>


                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        {/* Right Side - Image */}
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/axis1.webp" alt="IDFC Bank" className="w-full" />
                            {/* Rating Component */}
                            <div className="flex items-center mr-4 text-lg ml-4">
                                {/* You can replace the static stars with your actual rating logic */}
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>  
                        </div>

                        {/* Left Side - Headings */}
                        <div className="md:w-1/3 flex flex-col justify-between mb-4 md:mb-0 ">
                            <div className="mb-2 ">
                                <h2 className="text-lg font-semibold text-blue-600">Fees</h2>
                                <p className='text-gray-600'>Joining Fee: Rs. 500 + GST</p>
                                <p className='text-gray-600'>Renewal Fee: Rs. 500 + GST</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg font-semibold text-blue-600">Welcome Benefits</h2>
                                <p className='text-gray-600'>Introductory Benefits Worth Rs. 600 on Card Issuance</p>
                            </div>
                        </div>



                        {/* Right Side - Content */}
                        <div className="md:w-1/3 md:ml-6">

                            <div>
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p className='text-gray-600'>Shopping</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p className='text-gray-600'>0.25%</p>
                            </div>
                        </div>
                    </div>
                    {/* New Div with Border Line */}
                    <div className="border-t border-gray-300 mt-4">
                        {/* Content */}
                        <div className="text-xl md:text-2xl lg:text-1xl font-bold  mt-3 ml-4">Rewards and Benefits</div>
                        <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                            {/* Left Side - Headings */}
                            <div className="md:w-1/2 mb-4 md:mb-0">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600"> Rewards Rate</h2>
                                    <p className='text-gray-600'>Earn 5% cashback on Flipkart, 4% cashback on select partners like Swiggy, Uber, PVR, and cult.fit, and 1% cashback on all other spending.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Travel</h2>
                                    <p className='text-gray-600'>Accelerated Cashback (4%) on Uber Rides</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Movie and Dining</h2>
                                    <p className='text-gray-600'>Enjoy up to 15% off at partner restaurants with Axis Bank's exclusive Dining Delights program.</p>
                                </div>
                            </div>
        
                            {/* Right Side - Headings */}
                            <div className="md:w-1/2 md:ml-6">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Domestic Lounge Access</h2>
                                    <p className='text-gray-600'>1 Free Domestic Airport Lounge Access Every Quarter (4 Each Year)
</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Zero Liability Protection</h2>
                                    <p className='text-gray-600'>Cardmembers are not responsible for any unauthorized charges if they report a lost card within 3 days.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Reward Redemption</h2>
                                    <p className='text-gray-600'>N/A</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-300 mt-4" />
                    <Accordion open={open === 1} className='px-3' icon={<Icon id={1} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(1)}>Fees & Charges</AccordionHeader>
                        <AccordionBody>
                            <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                                {/* Left Side - Headings */}
                                <div className="md:w-1/2 mb-4 md:mb-0">
                                    
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Interest Rates</h2>
                                        <p className='text-gray-600 text-base'>3.6% per month (52.86% annually)</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Rewards Redemption Fee</h2>
                                        <p className='text-gray-600 text-base'>Nil</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Fuel Surcharge
                                        </h2>
                                        <p className='text-gray-600 text-base'>A 1% surcharge is waived for fuel purchases between Rs. 400 and Rs. 4,000, with a maximum waiver of Rs. 400 per month.</p>
                                    </div>
                                </div>

                                {/* Right Side - Headings */}
                                <div className="md:w-1/2 md:ml-6">
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Foreign Currency Markup</h2>
                                        <p className='text-gray-600 text-base'>3.5% of the total amount</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Waiver Based on Spending</h2>
                                        <p className='text-gray-600 text-base' >Annual fee waived on spends of Rs. 3.5 lakhs</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Add-on Card Fee</h2>
                                        <p className='text-gray-600 text-base'>Nil</p>
                                    </div>
                                </div>
                                {/* Right Side - Headings */}
                                {/* <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div> */}
                            </div>

                        </AccordionBody>
                    </Accordion>
                    <Accordion className='px-3' open={open === 2} icon={<Icon id={2} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(2)}>
                            Card Details
                        </AccordionHeader>
                        <AccordionBody>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Welcome Gifts worth Rs. 600.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        5% Cashback on purchases from Flipkart.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        4% Cashback on select partners such as Swiggy, Uber, PVR, and cult.fit.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        1% Cashback on other retail spends, with a few exceptions.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        4 Complimentary Airport Lounge Visits in India upon spending Rs. 50,000 or more in the previous three months.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        1% Fuel Surcharge Waiver for purchases between Rs. 400 and Rs. 4,000 at petrol stations across India, with a maximum waiver of Rs. 400 per statement cycle.
                    </p>
                  
                                    {/* <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Left Heading 2</h2>
                            <p>Description for left heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font- text-blue-600">Left Heading 3</h2>
                            <p>Description for left heading 3</p>
                        </div> */}
                            

                                {/* Right Side - Headings */}
                                {/* <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div>
                      {/* Right Side - Headings *
                      <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div> */}
                            

                        </AccordionBody>
                    </Accordion>

                </div>
                <div className=" mx-auto ">
                <div className="text-xl md:text-2xl lg:text-4xl font-bold mt-8 mb-4 ml-4">Flipkart Axis Bank Credit Card</div>
                <div className="text-sm md:text-base lg:text-lg text-gray-600 mb-8">
                    {/* <p className="float-left">{getCurrentDate()}</p> */}
                    {/* <p className="float-right font-bold mr-4 ">{getCurrentDate()}</p> */}
                </div>
                <p className=' px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg'>

                If you're a regular Flipkart shopper, the Flipkart Axis Bank Credit Card is a must-have. Its unlimited cashback feature stands out, offering a 5% return on eligible purchases, whether you're buying mobile phones, electronics, home furniture, or anything else.
                </p>
                <p className=' px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg'>

                The card also provides a 4% cashback on popular services like food delivery, movie bookings, and cab rides with select partners, including Swiggy, PVR, and Cleartrip, making it a great option for a variety of online spending needs.                </p>
               
            </div>
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of Flipkart Axis Bank Credit Card</h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">With this credit card, you get welcome benefits worth ₹600, which include ₹500 Flipkart vouchers and 50% off on Swiggy orders (up to ₹100, with a minimum cart value of ₹129). Dining benefits under the Axis Bank Dining Delights program offer 15% off (up to ₹500) at partner restaurants, and you also get 4% cashback on food delivery through Swiggy (excluding Instamart and Genie), 4% on PVR movie tickets, and 4% on Uber rides.
</p>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Travel benefits include four complimentary airport lounge visits per year in India. From May 1, 2024, these are available only if you've spent ₹50,000 or more in the last three calendar months. You also get 4% cashback on flights and hotels with Cleartrip.</p>
                </div>
                {/* <div className="container mx-auto px-4 py-2 ">
                    <h1 className="text-2xl font-bold pl-4 py-2">New UI Heading</h1>
                    <p className="text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> 
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600 ">&#8226;</span>
                        Responsive paragraph text. This text will adjust based on the screen size.
                    </p>
                    <p className="text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> 
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> 
                        Responsive paragraph text. This text will adjust based on the screen size.
                    </p>
                </div> */}
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Cashback & Reward Points</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-4"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        5% Unlimited Cashback on all eligible purchases made on Flipkart.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        4% Cashback with select partners such as Swiggy, Uber, Cleartrip, PVR, and cult.fit.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        1% Cashback on all other online and offline spends, including Myntra.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        The Flipkart Axis Bank Credit Card has no limit on the cashback you can earn.
Spending through Flipkart Smart Store's "Bills and Recharges" section earns 1% cashback, the same rate as for other general transactions.                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Cashback does not apply to certain transaction categories, such as fuel, rental payments, insurance, utilities, and education.                    </p>
                
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Additionally, you won't earn cashback for gift card purchases on Flipkart or Myntra, EMI transactions, wallet reloads, gold purchases, cash advances, card balance payments, card fees, or payments to government services.
                    </p>
                   
                   
                  
                   
                    
                    
                </div>
            </div>
            <div className="container mx-auto px-4 ">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How To Apply For The Flipkart Axis Bank Credit Card?</h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
                    You can apply for the Flipkart Axis Bank Credit Card through both online and offline channels.
                    </p>
                    <h1 className="text-lg font-bold pl-4 py-2 mt-2">For online application:</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Visit  Flipkart Axis official website.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Navigate to the 'Cards' section, then select Credit Cards and choose 'FlipcartAxixBank Credit Card.'
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Click on 'Apply Now' and complete the necessary details.
                    </p>
                  
                    
                    </div>
            <div className="max-w-4xl mx-auto py-6">
            <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                        <button
                            onClick={() => toggleAccordion(index)}
                            className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                        >
                            {faq.question}
                            <svg
                                className={`w-6 h-6 transition-transform transform ${
                                    activeIndex === index ? 'rotate-180' : ''
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d={activeIndex === index ? 'M19 9l-7 7-7-7' : 'M9 5l7 7 7-7'}
                                />
                            </svg>
                        </button>
                        {activeIndex === index && (
                            <div className="p-4 bg-gray-100">
                                <p className="text-gray-600">{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
            {/* <div className='w-[95%] py-10 px-10'>
                <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
                <AccordionComponent />
            </div> */}

        </>



    );
}

export default FlipcartAxixBank;


