import React from 'react';
import { Link } from 'react-router-dom';

const Box = ({ imgSrc, title, description, link }) => {
  return (
    //  <Link to={link} >
    //   <div className="bg-white shadow-md rounded-lg overflow-hidden p-4 border flex flex-col items-center space-y-4">
    //     <img 
    //       src={imgSrc} 
    //       alt="Box image" 
    //       className="w-16 h-16 object-cover rounded-lg animate-pulse" 
    //     />
    //     <div className="text-center">
    //       <h2 className="text-xl font-bold">{title}</h2>
    //       <p className="text-gray-600">{description}</p>
    //     </div>
    //     {/* <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Click Me</button> */}
    //   </div>
    //   </Link>
    <Link to={link}  className='  rounded-tr-[50%]'>
      <div class=" w-full rounded-md">
        <div class="ag-courses_item shadow-md w-full h-[225px]">
          <div class="ag-courses-item_link">
            <div class="ag-courses-item_bg"></div>

            <div class="ag-courses-item_title">
              <img
                src={imgSrc}
                alt="Box image"
                className="w-16 h-16 object-cover rounded-lg "
              />
              <span className='text-xl font-medium'>{title}</span> <br />
              <span className='text-sm font-normal leading-0'>{description}</span>
            </div>

          </div>
        </div>
      </div>
    </Link>
  );
}

export default Box;
