import React, { useState } from 'react';
import AccordionComponent from '../accordion/AccordionComponent'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}
function AuBankLit() {
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);


    // Function to get current date
    // const getCurrentDate = () => {
    //     const date = new Date();
    //     const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //     return date.toLocaleDateString('en-US', options);
    // };
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: 'How can I pay my AU LIT Credit Card bill?',
            answer:
            'You can conveniently pay your AU LIT Credit Card bill by visiting the AU Banks official website [here](https://www.aubank.in/personal-banking/credit-cards/lit-credit-card). Navigate to the Make Payment section, select Credit Card Bill Payment, and follow the prompts to enter your credit card details and complete the payment. Alternatively, you can use the AU 0101 mobile app for bill payments.',
        },
        {
            question: 'Can I add my children as add-on partners for the LIT credit card?',
            answer:
                'Children aged 18 years and above can be added as add-on partners for the LIT credit card, allowing them to enjoy its benefits.',
        },
        {
            question: 'Do I need to be a customer of AU Bank to apply for the LIT credit card?',
            answer:
                'Yes, you must be an existing customer of AU Bank to apply for the LIT credit card.',
        },
        {
            question: 'What should I do if I lose my LIT credit card?',
            answer:
                'In case of a lost LIT credit card, it is crucial to immediately contact AU Banks customer care service to have the card blocked.',
        },
        {
            question: 'Will cashback be adjusted against my outstanding dues on using the AU LIT Credit Card?',
            answer:
                'Yes, any cashback earned using the AU LIT credit card will be adjusted against your outstanding dues.',
        },
    ];

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };
    return (
        <>
 <Helmet>
      <title>AU Bank Credit Card - Features &amp; Apply Online | TheFinmaster</title>
      <meta  name='description' content='AU Bank Credit Card - Check Apply for AU Credit Card Online. Know Features, Benefits, Eligibility, Rewards, Fees &amp; Charges.'/>
      <meta name='keywords' content='au bank credit card, au bank credit card benefits, au zenith credit card, au bank rupay credit card, au vetta credit card, au bank altura credit card, au altura plus credit card' />
      <link rel="canonical" href="https://thefinmaster.com/credit-card/aubank-credit-card" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <FAQSchema faqs={faqs} />
            <div className='w-[95%] py-10 px-10'>
                <div className="mx-auto ml-4 border border-gray-300 rounded-md mt-4  mb-6 ">
                    <div className="flex justify-between items-center px-4 py-2 mt-4 text-black">
                        {/* Left Side */}
                        <div className="flex items-center">
                            <h1 className="text-xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>

                        {/* Right Side */}
                        <div className="flex items-center ">
                            {/* Replace 'your_link_here' with your actual link */}

                            <a href="https://www.aubank.in/personal-banking/credit-cards/lit-credit-card" target="_blank"  rel="noopener noreferrer" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>

                        </div>


                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        {/* Right Side - Image */}
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="IDFC Bank" className="w-full" />
                            {/* Rating Component */}
                            <div className="flex items-center mr-4 text-lg ml-4">
                                {/* You can replace the static stars with your actual rating logic */}
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>  
                        </div>

                        {/* Left Side - Headings */}
                        <div className="md:w-1/3 flex flex-col justify-between mb-4 md:mb-0 ">
                            <div className="mb-2 ">
                                <h2 className="text-lg font-semibold text-blue-600">Fees</h2>
                                <p className='text-gray-600'>Joining Fee: Nil</p>
                                <p className='text-gray-600'>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg font-semibold text-blue-600">Welcome Benefits</h2>
                                <p className='text-gray-600'>Nil</p>
                            </div>
                        </div>



                        {/* Right Side - Content */}
                        <div className="md:w-1/3 md:ml-6">

                            <div>
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p className='text-gray-600'>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p className='text-gray-600'>0.25%</p>
                            </div>
                        </div>
                    </div>
                    {/* New Div with Border Line */}
                    <div className="border-t border-gray-300 mt-4">
                        {/* Content */}
                        <div className="text-xl md:text-2xl lg:text-1xl font-bold  mt-3 ml-4">Rewards and Benefits</div>
                        <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                            {/* Left Side - Headings */}
                            <div className="md:w-1/2 mb-4 md:mb-0">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600"> Rewards Rate</h2>
                                    <p className='text-gray-600'>Earn 1 Reward Point for every Rs. 100 spent on retail using the card, and enjoy accelerated Reward Points at 10X or 5X rates for specific features. Additionally, unlock additional cashbacks for selected features.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Travel</h2>
                                    <p className='text-gray-600'>Enjoy free access to domestic lounges across India.
                                        Receive 2 complimentary airport lounge accesses every quarter, totaling 8 accesses per year.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Movie and Dining</h2>
                                    <p className='text-gray-600'>Get 3 months of free membership for Zee5 and Amazon Prime, along with an extra 5% cashback on dining expenses.</p>
                                </div>
                            </div>
        
                            {/* Right Side - Headings */}
                            <div className="md:w-1/2 md:ml-6">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">International Lounge Access</h2>
                                    <p className='text-gray-600'>Nil</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Zero Liability Protection</h2>
                                    <p className='text-gray-600'>The cardholder bears no liability for fraudulent transactions in case of credit card loss or theft, provided it is reported to the bank's customer care within 7 days.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Reward Redemption</h2>
                                    <p className='text-gray-600'>Exchange your Reward Points for fantastic discounts and offers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-300 mt-4" />
                    <Accordion open={open === 1} className='px-3' icon={<Icon id={1} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(1)}>Fees & Charges</AccordionHeader>
                        <AccordionBody>
                            <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                                {/* Left Side - Headings */}
                                <div className="md:w-1/2 mb-4 md:mb-0">
                                    
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Interest Rates</h2>
                                        <p className='text-gray-600 text-base'>3.49% per month</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Rewards Redemption Fee</h2>
                                        <p className='text-gray-600 text-base'>Nil</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Fuel Surcharge
                                        </h2>
                                        <p className='text-gray-600 text-base'>Enjoy a 1% waiver on fuel surcharge for transactions ranging from Rs. 400 to Rs. 5,000 at fuel stations.</p>
                                    </div>
                                </div>

                                <div className="md:w-1/2 md:ml-6">
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Foreign Currency Markup</h2>
                                        <p className='text-gray-600 text-base'>A fee of 3.49% applies to all foreign currency transactions.</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Waiver Based on Spending</h2>
                                        <p className='text-gray-600 text-base' >N/A</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Add-on Card Fee</h2>
                                        <p className='text-gray-600 text-base'>Nil</p>
                                    </div>
                                </div>
                            
                            </div>

                        </AccordionBody>
                    </Accordion>
                    <Accordion className='px-3' open={open === 2} icon={<Icon id={2} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(2)}>
                            Card Details
                        </AccordionHeader>
                        <AccordionBody>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        India’s premier customizable credit card, empowering you to select your preferred features at your convenience.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Enjoy a complimentary 3-month membership for Zee5, Amazon Prime, and Cult.fit with your card.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Earn 1 Reward Point for every Rs. 100 spent on retail transactions.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Avail accelerated Reward Points at 10X or 5X rates for both online and offline transactions.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Benefit from a 1% surcharge waiver on fuel transactions ranging from Rs. 400 to Rs. 5,000
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Get 5% and 2% cashback on retail spends upon reaching the spend target.
Receive an additional 5% cashback on travel, dining, and grocery expenses.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Enjoy 4 complimentary lounge accesses per quarter.
                    </p>
                                   

                        </AccordionBody>
                    </Accordion>

                </div>
                <div className=" mx-auto ">
                <div className="text-xl md:text-2xl lg:text-4xl font-bold mt-8 mb-4 ml-4">AU Bank LIT Credit Card Detail</div>
                <div className="text-sm md:text-base lg:text-lg text-gray-600 mb-8">
                   
                </div>
                <p className=' px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  '>

                    The AU Small Finance LIT Credit Card stands out as India’s pioneer customizable credit card, allowing users to tailor their card's features to their preferences and pay only for what they choose. This innovation from AU Bank introduces a unique offering in the credit card landscape, unlike the multitude of options available from other providers. With a plethora of credit cards in the market, each boasting different features and benefits, customers often find themselves overwhelmed and end up holding multiple cards, each with its associated fees.

                </p>
                <p className=' px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg '>

                    AU Bank identified this challenge and addressed it by launching the LIT credit card, the first-ever customizable credit card. While the bank offers several other cards with various benefits, the LIT card distinguishes itself with its customizable features.
                </p>
                <p className=' px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg '>

                    The LIT credit card empowers cardholders with the flexibility to select features that suit their needs for the next 90 days through the AU 0101 App, simplifying the process. Users can choose from a range of features, each offering unique benefits, including the opportunity to earn reward points. For every Rs. 100 spent on retail, users earn 1 Reward Point, with the option to enjoy accelerated Reward Points at 10X or 5X rates based on their selected feature. Additionally, the card provides enticing cashback benefits tailored to the chosen feature.
                </p>
                <p className=' px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg '>

                    Moreover, the LIT card extends complimentary memberships to premium brands like Zee5 and Amazon Prime, enhancing the cardholder experience. For frequent travelers, the card offers attractive cashback offers and complimentary access to airport lounges, adding further value.
                </p>
                <p className='px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  '>


                    By selecting their desired feature, users can unlock a world of benefits and privileges tailored to their preferences.
                </p>
            </div>
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of AU Bank LIT Credit Card</h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">The AU Small Finance Bank LIT Credit Card is India's pioneering customizable credit card, offering the unique advantage of selecting and paying for desired features exclusively. With a wide array of benefits spanning lifestyle, groceries, travel, and beyond, this card provides unparalleled flexibility and convenience.</p>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">It provides a comprehensive range of credit card benefits and allows holders to select one or multiple features/categories simultaneously. This empowers customers to enjoy benefits from their chosen category while only paying for the features they use, rather than the entire annual card fees.</p>
                </div>
               
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Cashback & Reward Points</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-4"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        This credit card offers the opportunity to earn both cashback and reward points on transactions, in addition to various other benefits. Here are some of the key features and advantages:
                    </p>
                    <h1 className="text-2xl font-bold pl-4 py-2 mt-2">CashBack :</h1>
                    <h1 className="text-lg font-bold pl-4 py-2">Earn cashback of up to 5%.</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Receive 5% cashback three times within a 90-day period.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Get 5% cashback on retail purchases, alongside earning 1 Reward Point for every Rs. 100 spent.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Minimum retail expenditures of Rs. 7,500 with a maximum cashback limit of Rs. 500 within a 30-day timeframe.
                    </p>
                    
                    <h1 className="text-lg font-bold pl-4 py-2 mt-2">Earn cashback of up to 2%.</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Receive 2% cashback three times within a 90-day period.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Get 2% cashback on retail purchases, alongside earning 1 Reward Point for every Rs. 100 spent.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Minimum retail expenditures of Rs. 10,000 with a maximum cashback limit of Rs. 1000 within a 30-day timeframe.
                    </p>
                    <h1 className="text-lg font-bold pl-4 py-2 mt-2">Category Cashback</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Receive an extra 5% cashback on travel, dining, grocery, electronics, and apparel purchases, along with earning 1 reward point for every Rs. 100 spent.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Maximum cashback limit of Rs. 1,000 within each 30-day period.
                    </p>
                   
                  
                    <h1 className="text-2xl font-bold pl-4 py-2 mt-2">Rewards :</h1>
                    <h1 className="text-lg font-bold pl-4 py-2">For Online Spends</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        10x Reward Points: For Rs. 299 over 90 days, earn 10x reward points on your online spending. Note that rent, cash withdrawals, and fuel spending are not eligible for rewards.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        5x Reward Points: For Rs. 199 over 90 days, earn 5x reward points on your online spending. Similarly, rent, cash withdrawals, and fuel spending are not included in this offer.
                    </p>
                    <h1 className="text-lg font-bold pl-4 py-2">For Offline Spends</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        10x Reward Points: For Rs. 299 over 90 days, earn 10x reward points on your offline spending. Rent, cash withdrawals, and fuel spending are excluded.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        5x Reward Points: For Rs. 199 over 90 days, earn 5x reward points on your offline spending. Again, rent, cash withdrawals, and fuel spending do not count toward this reward.
                    </p>
                    <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How To Apply For The AU Bank LIT Credit Card?</h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
                    You can apply for the AU Bank LIT Credit Card through both online and offline channels.
                    </p>
                     <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Click the "Apply Now" button on this page.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        You'll be redirected to HDFC's credit card application page.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Complete the required form to submit your application.
                    </p>
                    </div>
                    
                </div>
            </div>
            <div className="max-w-4xl mx-auto py-6">
            <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                        <button
                            onClick={() => toggleAccordion(index)}
                            className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                        >
                            {faq.question}
                            <svg
                                className={`w-6 h-6 transition-transform transform ${
                                    activeIndex === index ? 'rotate-180' : ''
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d={activeIndex === index ? 'M19 9l-7 7-7-7' : 'M9 5l7 7 7-7'}
                                />
                            </svg>
                        </button>
                        {activeIndex === index && (
                            <div className="p-4 bg-gray-100">
                                <p className="text-gray-600">{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
           
        </>



    );
}

export default AuBankLit;


