import React from 'react'
import { Link } from 'react-router-dom'
import CardModal from '../cardComponents/CardModal'

const LoanCardComponent = ({ cardName, cardImage, rating, fees, Card_features, category }) => {
    if (category !== 'Loan') {
        return null;
    }
    return (
        <>
            <div className='md:w-[900px] md:h-[200px] border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap items-center justify-between'>
                <div className='flex flex-wrap md:flex-nowrap w-full justify-center md:justify-normal'>
                    <Link to="/card-details">
                        <div className='w-[95%] md:w-[180px] md:h-[180px] rounded-full overflow-hidden border shadow-xl md:ml-2'>
                            <img className='w-[100%] h-[100%] object-fill hover:scale-110 duration-150'  src={cardImage  || '/fake.jpeg'} 
                             onError={({ currentTarget }) => {
                                currentTarget.onerror = null || undefined; // prevents looping
                                currentTarget.src = "/fake.jpeg";
                              }}     
                            alt='no img' />
                        </div>
                    </Link>

                    <div className='h-[180px] w-[330px] flex justify-between md:mt-0 md:ml-3'>
                        <div className='flex flex-col justify-between py-1'>
                            <div>
                                <Link to="/card-details">
                                    <h1 className='font-semibold'>{cardName}</h1>

                                    <div className="flex items-center">
                                        {Array.from({ length: parseInt(rating) }, (_, i) => (
                                            <svg key={i} className="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                            </svg>
                                        ))}
                                        <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">{rating} out of 5</p>
                                    </div>

                                    <p className='text-gray-400 mt-2 text-sm'>1st year fee: <span className='text-black'>{fees}</span></p>

                                </Link>
                                <div className='flex flex-wrap gap-2 mt-2 box-border'>
                                    {Card_features.slice(0, 3).map((feature, index) => (
                                        <div key={index} className='border shadow-md'>
                                            <span className='text-xs text-gray-600 p-2'>{feature.Title}</span>
                                        </div>
                                    ))}
                                    <div className=''>
                                        {(Card_features?.length > 0 && Card_features !== undefined) && <CardModal Card_features={Card_features} />}
                                    </div>
                                </div>
                            </div>

                            <div className='flex gap-2'>
                            
                               <input type='checkbox' />
                                <p className='text-sm font-semibold text-gray-500'>Add to compare</p>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='flex justify-center items-center my-5 md:mr-3 w-full md:w-[220px] '>
                    <Link to="/proceed-to-buy">
                        <button className='bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300'>
                            Apply Now!
                        </button>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default LoanCardComponent
