import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const AdityaHealthLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: ' How do I port my existing health insurance policy to Aditya Birla?',
        answer:
            'Porting your existing health insurance policy to Aditya Birla Health Insurance is a straightforward process. You need to fill out the portability form at least 45 days before your current policy renewal date. Aditya Birla Health Insurance will then contact your current insurer to obtain your medical and claim history. Upon receiving this information, Aditya Birla Health Insurance will review your application and provide a decision within 15 days from the date of receiving the policy-related information from your existing insurer.',
    },
    {
        question: 'Can I track my claim status?',
        answer:
            'Yes, you can track your claim status by visiting the Aditya Birla Health Insurance website, navigating to the Track Claim section, and logging in using your username and password. Once logged in, you can view the status of your claim.',
    },
    {
        question: 'How will the payment be made in case of Reimbursement Claims?',
        answer:
            'For Reimbursement Claims, the payment is directly transferred to the policyholders bank account. Alternatively, policyholders can opt to receive the payment via NEFT by providing their NEFT details on the Reimbursement Claim Form, including account holders name, account number, IFSC code, branch address, and a cancelled cheque. If NEFT details are not provided, the claim settlement amount will be sent via cheque to the policyholders registered address.',
    },
    {
      question: 'Will I be entitled to a claim raised during the policy grace period?',
      answer:
          ' No, any claim raised during the policy grace period will not be entertained by the insurer.',
  },
  {
    question: 'What if hospitalisation expenses exceed the initial cashless authorization request limit?',
    answer:
        ' If hospitalisation expenses exceed the approved cashless claim limit, the treating network hospital can request an enhancement of the authorization limit from the insurer. This request must include details of the treatment and reasons for the increased expenses. The insurer will review the request, verify eligibility for enhancement, and make a decision accordingly.',
},
{
  question: 'What is the maximum age for policy renewal under the Activ Health Plan?',
  answer:
      ' Under the Activ Health Plan, policyholders benefit from lifetime renewability, meaning there is no maximum age limit for policy renewal. Renewal depends on payment of premiums and compliance with policy conditions.',
},
{
  question: 'Who is eligible for the optional maternity expenses cover under the Activ Health Plan?',
  answer:
      ' The optional maternity expenses cover is available if both the policyholder and their spouse are covered under the same individual or floater policy. The insured woman must be between 18 to 45 years of age, and the cover comes with a waiting period of 24 months.',
},
{
  question: 'Is there any premium discount offered for an Activ Secure policy with a term of 2 or 3 years?',
  answer:
      'Policyholders opting for a single premium Activ Secure plan with a policy term of 2 years receive a premium discount of 7.5%, while those with a policy term of 3 years receive a discount of 10%.',
},

    
  ];
    const benefits = [
      {
        title: 'No Claim Bonus Protection',
      },
      {
        title: 'Roadside Assistance',
      },
      {
        title: 'Repair of Glass Fiber and Plastic Parts',
      },
      {
        title: 'Daily Allowance',
      },
      {
        title: 'Emergency Transport and Hotel Expenses',
      },
      {
        title: 'Key Replacement        ',
      },
      {
        title: 'Engine & Tyre Secure',
      },
      {
        title: 'Loss of Personal Belongings',
      },
      {
        title: 'Return to Invoice',
      },
      // {
      //   title: 'Tyre Secure Cover',
      // },
    ]
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
         <title>Aditya Birla Health Insurance Plan: Features, Riders & Support | TheFinmaster</title>
        <meta  name='description' content='Aditya Birla Health Insurance: Check key features & metrics, Top plans, Claim process, Customer support & Grievance redressal. Compare and Buy Plan Now. | TheFinmaster.'/>
        <meta name='keywords' content='birla health insurance, aditya birla medical insurance, aditya birla health insurance plans' />
        <link rel="canonical" href="https://thefinmaster.com/health-insurance/aditya-health-insurance" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <FAQSchema faqs={faqs} />
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/health-insurance" className="text-blue-600 hover:text-blue-800">Health Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Aditya Health Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Invest in your well-being. Get comprehensive health 
                  </span>{" "}
                  coverage with Aditya Birla.
                </h1>
                <a href="https://www.adityabirlacapital.com/healthinsurance/homepage"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Open Account!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/adityahealth1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about Aditya Birla Health Insurance</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> Aditya Birla Health Insurance is renowned for its commitment to providing holistic healthcare solutions through a wide network of hospitals, surpassing 16,600 facilities nationwide, ensuring policyholders have access to quality medical care. Their comprehensive plans go beyond traditional coverage, incorporating innovative features such as wellness coaching and chronic disease management programs, promoting proactive health management. </p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Moreover, Aditya Birla Health Insurance offers extensive coverage for critical illnesses, providing financial security during challenging times. Although their premiums may be slightly higher, customers benefit from the company's high claim settlement ratio, reinforcing their reputation for reliability and customer satisfaction.</p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/hospital.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            11000 + Cashless Hospitals


            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            96 %* Claim Settlement Ratio


            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/onspot.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            4800+ Locations Presence



            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/life.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            2.5Cr+ Lives Insured



            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/policy.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            20.5 Lacs+ Claims Settled


            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/trust.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Get 6X Cover by the 6th Year

            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>

    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Explore Aditya Birla's Health Insurance Plans</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Here are some top insurance plans provided by Aditya Birla. Click the link below to discover all plans offered by the brand.</p>
            </div>
            
            <div class="container mx-auto px-4 sm:px-6 lg:px-8 mt-4">
  <div class="flex flex-wrap justify-between -mx-2">
  
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/aditya1.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Activ One NXT</h2>
          <ul class="list-disc list-inside text-gray-700 mb-4 ">
          <p className='font-bold'>HealthReturns™ + No Capping on Medical Expenses + Super Reload</p>
        <li className='mt-4'>Earn up to 100% premium back as HealthReturns™</li>
        <li>No capping on hospitalization expenses</li>
        <li>100% unlimited refill of sum insured with 2x cover from day 1</li>
      
    
      </ul>
   
      <Link to="https://www.adityabirlacapital.com/healthinsurance/activ-one">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>

    
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/aditya2.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Activ Fit</h2>
          <ul class="list-disc list-inside text-gray-700 mb-4">
          <p className='font-bold'>Health Insurance   +  Health Benefits   +  Rewards</p>
        <li className='mt-4'>10% upfront good health discount at policy purchase and early bird discount up to 10% at renewal</li>
        <li>Earn up to 50% of your premium as HealthReturns™</li>
        <li>100% unlimited sum insured refill    under this plan</li>
       
      </ul>


      <Link to="https://www.adityabirlacapital.com/healthinsurance/activ-fit">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>

   
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/aditya3.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2"> Activ Assure Diamond</h2>
          <ul class="list-disc list-inside text-gray-700 mb-4">
         <p className='font-bold'> Health Insurance + Health Benefit</p>
        <li className='mt-4'>586 daycare procedures covered</li>
        <li> 150% reload of sum insured </li>
<li> Earn up to 30% of your premium as HealthReturns™</li>
<li>Provides coverage for a comprehensive list of 586 daycare procedures.</li>
    
    
      </ul>
      <Link to="https://www.adityabirlacapital.com/healthinsurance/activ-assure-diamond">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
<Link to="https://www.adityabirlacapital.com/healthinsurance/health-insurance-plans">
<h1 className="text-2xl font-semibold text-center mb-8 text-black italic underline flex items-center justify-center">
  View all Plans
  <svg
    className="ml-2 w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5l7 7-7 7"
    ></path>
  </svg>
</h1></Link>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>


        </>
    )
}

export default AdityaHealthLearn
