export const LoanData = [
    {
        "Bank_Name": "Axis Bank",
        "Interest_Range": "15.20%",
        "Loan_Amount": "Up to Rs. 4 Lakhs"
    },
    {
        "Bank_Name": "Axis Bank",
        "Interest_Range": "14.70%",
        "Loan_Amount": "Loans greater than Rs. 4 Lakhs and upto Rs. 7.5 Lakhs"
    },
    {
        "Bank_Name": "Axis Bank",
        "Interest_Range": "13.70%",
        "Loan_Amount": "Loans greater than Rs. 7.5 Lakhs"
    },
    {
        "Bank_Name": "Bank of Baroda",
        "Interest_Range": "8.50% onwards",
        "Loan_Amount": "For all loan types and amounts"
    },
    {
        "Bank_Name": "Canara Bank",
        "Interest_Range": "6.60%",
        "Loan_Amount": "For Vidhya Turant Loan Scheme"
    },
    {
        "Bank_Name": "Canara Bank",
        "Interest_Range": "10.40%",
        "Loan_Amount": "Up to Rs. 4 Lakhs"
    },
    {
        "Bank_Name": "Canara Bank",
        "Interest_Range": "10.40%",
        "Loan_Amount": "Rs. 4 Lakhs to Rs. 7.50 Lakhs"
    },
    {
        "Bank_Name": "Canara Bank",
        "Interest_Range": "10.20%",
        "Loan_Amount": "Loans above Rs. 7.50 lakhs"
    },
    {
        "Bank_Name": "Canara Bank",
        "Interest_Range": "9.90%",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "HDFC Bank",
        "Interest_Range": "9.00%",
        "Loan_Amount": "Minimum APR"
    },
    {
        "Bank_Name": "HDFC Bank",
        "Interest_Range": "13.86%",
        "Loan_Amount": "Maximum APR"
    },
    {
        "Bank_Name": "HDFC Bank",
        "Interest_Range": "11.57%",
        "Loan_Amount": "Average APR"
    },
    {
        "Bank_Name": "Kotak Mahindra Bank",
        "Interest_Range": "11.5% to 24%",
        "Loan_Amount": "For all loan types and amounts"
    },
    {
        "Bank_Name": "Punjab National Bank",
        "Interest_Range": "8.55% onwards",
        "Loan_Amount": "PNB Kaushal"
    },
    {
        "Bank_Name": "Punjab National Bank",
        "Interest_Range": "10.30% onwards",
        "Loan_Amount": "PNB Saraswati/Udaan"
    },
    {
        "Bank_Name": "Punjab National Bank",
        "Interest_Range": "8.45% onwards",
        "Loan_Amount": "PNB Pratibha (for admission to IITs , IIMs and XLRI Jamshedpur)"
    },
    {
        "Bank_Name": "Punjab National Bank",
        "Interest_Range": "8.55% onwards",
        "Loan_Amount": "PNB Pratibha (for admission to other colleges)"
    },
    {
        "Bank_Name": "Punjab National Bank",
        "Interest_Range": "10.45%",
        "Loan_Amount": "PNB Honhaar"
    },
    {
        "Bank_Name": "State Bank of India",
        "Interest_Range": "10.25%",
        "Loan_Amount": "Up to Rs. 7.5 Lakhs"
    },
    {
        "Bank_Name": "State Bank of India",
        "Interest_Range": "10.50%",
        "Loan_Amount": "Above Rs. 7.5 Lakhs"
    },
    {
        "Bank_Name": "Avanse",
        "Interest_Range": "10% to 16.50%",
        "Loan_Amount": "For all loan types and amounts"
    },
    {
        "Bank_Name": "Credila",
        "Interest_Range": "11.85% onwards",
        "Loan_Amount": "For all loan types and amounts"
    },
    {
        "Bank_Name": "Allahabad Bank",
        "Interest_Range": "9.20% onwards",
        "Loan_Amount": "As per applicant's profile"
    },
    {
        "Bank_Name": "Andhra Bank",
        "Interest_Range": "10.80% onwards",
        "Loan_Amount": "As per applicant's profile"
    },
    {
        "Bank_Name": "Axis Bank",
        "Interest_Range": "12% onwards",
        "Loan_Amount": "50,000 to 15 lakh"
    },
    {
        "Bank_Name": "Bajaj Finserv",
        "Interest_Range": "12.99% onwards",
        "Loan_Amount": "Up to 25 lakh"
    },
    {
        "Bank_Name": "Bank of Baroda",
        "Interest_Range": "10.50% onwards",
        "Loan_Amount": "50,000 to 10 lakh"
    },
    {
        "Bank_Name": "Bank of India",
        "Interest_Range": "9.35% onwards",
        "Loan_Amount": "Up to 10 lakh"
    },
    {
        "Bank_Name": "Bank of Maharashtra",
        "Interest_Range": "9.70% onwards",
        "Loan_Amount": "Up to 10 lakh"
    },
    {
        "Bank_Name": "CASHe",
        "Interest_Range": "33% to 36%",
        "Loan_Amount": "9,000 to 3 lakh"
    },
    {
        "Bank_Name": "Central Bank",
        "Interest_Range": "8.45% onwards",
        "Loan_Amount": "Up to 20 lakh"
    },
    {
        "Bank_Name": "Citibank",
        "Interest_Range": "9.99% onwards",
        "Loan_Amount": "10,000 to 30 lakh"
    },
    {
        "Bank_Name": "Early Salary",
        "Interest_Range": "24% onwards",
        "Loan_Amount": "8,000 to 2 lakh"
    },
    {
        "Bank_Name": "Federal Bank",
        "Interest_Range": "11.49% onwards",
        "Loan_Amount": "Up to 25 lakh"
    },
    {
        "Bank_Name": "Fullerton India",
        "Interest_Range": "11.99% onwards",
        "Loan_Amount": "Up to 25 lakhs"
    },
    {
        "Bank_Name": "HDFC Bank",
        "Interest_Range": "10.75% onwards",
        "Loan_Amount": "50,000 to 40 lakh"
    },
    {
        "Bank_Name": "Home Credit",
        "Interest_Range": "24% onwards",
        "Loan_Amount": "Up to 2.4 lakh"
    },
    {
        "Bank_Name": "HSBC Bank",
        "Interest_Range": "10.50% onwards",
        "Loan_Amount": "Up to 30 lakh"
    },
    {
        "Bank_Name": "ICICI Bank",
        "Interest_Range": "11.25% onwards",
        "Loan_Amount": "50,000-20 lakhs"
    },
    {
        "Bank_Name": "IDBI Bank",
        "Interest_Range": "12% onwards",
        "Loan_Amount": "25,000-5 lakhs"
    },
    {
        "Bank_Name": "IDFC First",
        "Interest_Range": "10.75% onwards",
        "Loan_Amount": "1 lakh-40 lakhs"
    },
    {
        "Bank_Name": "Indiabulls",
        "Interest_Range": "13.99% onwards",
        "Loan_Amount": "Rs. 1000 to Rs. 15 lakh"
    },
    {
        "Bank_Name": "Indian Bank",
        "Interest_Range": "9.20% onwards",
        "Loan_Amount": "As per applicant's profile"
    },
    {
        "Bank_Name": "Indian Overseas Bank",
        "Interest_Range": "10.30% onwards",
        "Loan_Amount": "Up to 15 lakh"
    },
    {
        "Bank_Name": "IndusInd Bank",
        "Interest_Range": "11% onwards",
        "Loan_Amount": "50,000-15 lakhs"
    },
    {
        "Bank_Name": "Kotak Mahindra Bank",
        "Interest_Range": "10.75 onwards",
        "Loan_Amount": "50,000-20 lakh"
    },
    {
        "Bank_Name": "Kreditbee",
        "Interest_Range": "1.02% p.m. onwards",
        "Loan_Amount": "1000 to 2 lakh"
    },
    {
        "Bank_Name": "Moneytap",
        "Interest_Range": "1.25% p.m. onwards",
        "Loan_Amount": "Rs. 3000 to Rs. 5 lakh"
    },
    {
        "Bank_Name": "Moneyview",
        "Interest_Range": "1.33% p.m. onwards",
        "Loan_Amount": "Rs. 10,000 to Rs. 5 lakh"
    },
    {
        "Bank_Name": "Muthoot Finance",
        "Interest_Range": "Varies based on applicant profile",
        "Loan_Amount": "Rs. 50,000 onwards"
    },
    {
        "Bank_Name": "Punjab National Bank",
        "Interest_Range": "8.95% onwards",
        "Loan_Amount": "25,000 to 15 lakh"
    },
    {
        "Bank_Name": "RBL Bank",
        "Interest_Range": "14% onwards",
        "Loan_Amount": "1 lakh to 20 lakh"
    },
    {
        "Bank_Name": "Standard Chartered Bank",
        "Interest_Range": "11.00% onwards",
        "Loan_Amount": "1 lakh-50 lakhs"
    },
    {
        "Bank_Name": "State Bank of India",
        "Interest_Range": "9.60% onwards",
        "Loan_Amount": "Up to 20 lakhs"
    },
    {
        "Bank_Name": "TATA Capital",
        "Interest_Range": "10.99% onwards",
        "Loan_Amount": "75,000 to 25 lakh"
    },
    {
        "Bank_Name": "UCO Bank",
        "Interest_Range": "8.45% onwards",
        "Loan_Amount": "Up to 10 lakh"
    },
    {
        "Bank_Name": "Union Bank of India",
        "Interest_Range": "8.90% onwards",
        "Loan_Amount": "Up to 15 lakh"
    },
    {
        "Bank_Name": "Yes Bank",
        "Interest_Range": "10.75% onwards",
        "Loan_Amount": "1 lakh to 40 lakh"
    },
    {
        "Bank_Name": "SBI",
        "Interest_Range": "9.25% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "HDFC Bank",
        "Interest_Range": "9.35% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "ICICI Bank",
        "Interest_Range": "9.55% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Axis Bank",
        "Interest_Range": "9.50% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "IDBI Bank",
        "Interest_Range": "9.95% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Punjab National Bank",
        "Interest_Range": "9.25% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Bank of India",
        "Interest_Range": "9.25% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Syndicate Bank",
        "Interest_Range": "10.1% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "CitiBank",
        "Interest_Range": "8.80% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Syndicate Bank",
        "Interest_Range": "11.50% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "HDFC Ltd.",
        "Interest_Range": "9.50% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "State Bank of India",
        "Interest_Range": "9.60% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Kotak Mahindra Bank",
        "Interest_Range": "9.60% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "ICICI Bank",
        "Interest_Range": "9.80% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "IDBI Bank",
        "Interest_Range": "10.10% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Bajaj Finserv",
        "Interest_Range": "10.10% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "PNB Housing Finance Ltd.",
        "Interest_Range": "10.25% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Tata Capital",
        "Interest_Range": "10.50% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "LIC Housing Finance Ltd.",
        "Interest_Range": "11.30% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Axis Bank",
        "Interest_Range": "11.35% onwards",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "PaySense",
        "Interest_Range": "18% to 36% p.a.",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Rupeelend",
        "Interest_Range": "30% for 30 days",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "CASHe",
        "Interest_Range": "1.75% onwards for 15-day loan",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "CASHe",
        "Interest_Range": "3.25% onwards for 30-day loan",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "CASHe",
        "Interest_Range": "2.75% onwards (monthly) for 90-day loan",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "CASHe",
        "Interest_Range": "2.50% per month for 180-day loan",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Money View",
        "Interest_Range": "1.33% - 2% p.m.",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "Credy",
        "Interest_Range": "1% - 1.5% p.m.",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "EarlySalary",
        "Interest_Range": "2.5% p.m.",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "LazyPay",
        "Interest_Range": "15% - 32% p.a.",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "mPokket",
        "Interest_Range": "Based on lender's terms",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "MoneyinMinutes",
        "Interest_Range": "0.1% - 0.7% per day",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "QuickCredit",
        "Interest_Range": "1% per day",
        "Loan_Amount": ""
    },
    {
        "Bank_Name": "FlexSalary",
        "Interest_Range": "Up to 3% per month",
        "Loan_Amount": ""
    }
]