// src/FDCalculator.js
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const FDCalculator = () => {
  const [totalInvestment, setTotalInvestment] = useState(100000);
  const [rateOfInterest, setRateOfInterest] = useState(5);
  const [timePeriod, setTimePeriod] = useState(5);
  const [investedAmount, setInvestedAmount] = useState(0);
  const [estimatedReturn, setEstimatedReturn] = useState(0);
  const [totalValue, setTotalValue] = useState(0);

  const calculateFD = () => {
    const interestRate = rateOfInterest / 100;
    const estimatedReturn = totalInvestment * Math.pow(1 + interestRate / 1, 1 * timePeriod) - totalInvestment;
    const totalValue = totalInvestment + estimatedReturn;

    setInvestedAmount(totalInvestment);
    setEstimatedReturn(estimatedReturn.toFixed(2));
    setTotalValue(totalValue.toFixed(2));
  };

  const handleTotalInvestmentChange = (e) => {
    const value = e.target.value;
    setTotalInvestment(value === '' ? 0 : parseInt(value));
  };

  const handleRateOfInterestChange = (e) => {
    const value = e.target.value;
    setRateOfInterest(value === '' ? 0 : parseFloat(value));
  };

  const handleTimePeriodChange = (e) => {
    const value = e.target.value;
    setTimePeriod(value === '' ? 0 : parseInt(value));
  };

  const data = {
    labels: ['Invested Amount', 'Estimated Return'],
    datasets: [
      {
        label: 'FD Breakdown',
        data: [investedAmount, estimatedReturn],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <>

<Helmet>
         <title>FD Calculator- Fixed Deposit Intrest calculator Online At TheFinmaster</title>
        <meta  name='description' content='RD Calculator - FD Calculator Online - Use this Fixed Deposit Calculator to calculate maturity value and the amount of interest earned. | TheFinmaster'/>
        <meta name='keywords' content='fd calculator online, online fixed deposit calculator, calculate recurring deposit interest online, fd calculator sbi online, sbi online fd interest calculator' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/fd-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
</Helmet>
<nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">FD Calculator</span>
        </li> 
      
      </ol>
    </nav>
    <div className='py-4'>
    <div className="container mx-auto p-4 shadow-xl rounded-md ">
      <h1 className="text-2xl font-bold mb-4">Fixed Deposit Calculator</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Total Investment</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                value={totalInvestment}
                onChange={handleTotalInvestmentChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">₹ {totalInvestment.toLocaleString()}</span>
            </div>
            <input
              type="range"
              min="0"
              max="10000000"
              value={totalInvestment}
              onChange={(e) => setTotalInvestment(parseInt(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Rate of Interest (p.a.)</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                max="100"
                step="0.1"
                value={rateOfInterest}
                onChange={handleRateOfInterestChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{rateOfInterest} %</span>
            </div>
            <input
              type="range"
              min="0"
              max="100"
              step="0.1"
              value={rateOfInterest}
              onChange={(e) => setRateOfInterest(parseFloat(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Time Period (years)</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                max="50"
                value={timePeriod}
                onChange={handleTimePeriodChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{timePeriod} Yr</span>
            </div>
            <input
              type="range"
              min="0"
              max="50"
              value={timePeriod}
              onChange={(e) => setTimePeriod(parseInt(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <button
            onClick={calculateFD}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Calculate
          </button>
        </div>
        <div>
          <div className="mb-4">
            <h2 className="text-xl font-bold">Results</h2>
            <p>Invested Amount: ₹ {investedAmount.toLocaleString()}</p>
            <p>Estimated Return: ₹ {estimatedReturn.toLocaleString()}</p>
            <p>Total Value: ₹ {totalValue.toLocaleString()}</p>
          </div>
          <div>
            <h2 className="text-xl font-bold">Breakdown</h2>
            <div className="flex justify-center">
              <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="flex flex-wrap px-6 py-6" >
        <div className="w-full md:w-2/3 p-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Fixed Deposits: Earn Guaranteed Interest with an FD Calculator </h1>
          {/* <p className="text-lg font-normal mt-2 text-gray-700 ">What is a Fixed Deposit (FD)?</p> */}

          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">A fixed deposit is a popular savings option offered by Banks and NBFCs. It allows you to invest a LumpSum for a fixed period (usually between 7 days and 10 years) and earn a guaranteed interest rate.</p>

          
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Fixed Deposits:</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Higher Interest Rates:</span>    Compared to regular savings accounts, FDs offer attractive interest rates.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Security:  </span>   Your principal amount is locked-in and guaranteed by the bank/NBFC (up to specific limits set by deposit insurance).
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Flexibility:</span>  Choose a tenure that aligns with your financial goals.
            </p>
           
            </div>
            <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Using an FD Calculator:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">An FD calculator is a free online tool that helps you estimate the maturity amount of your FD. This eliminates the need for complex manual calculations.</p>
</div>       

<div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Here's how an FD calculator benefits you:</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Effortless Calculations:</span>    Get instant results without the hassle of complex formulas.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Compare FD Rates: </span>   Easily compare interest rates offered by different institutions to make informed investment decisions.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Plan your Finances:</span>   Estimate your future returns and plan for upcoming expenses.
            </p>
           
            </div>

            <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">TheFinMaster FD Calculator (Optional):</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">TheFinMaster offers a user-friendly FD calculator that supports both simple and compound interest calculations. Simply enter your investment amount, interest rate, and tenure to receive your estimated maturity amount.</p>
</div>       
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Additional Tips:
</h1>
                 
               <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Explore different FD options offered by Banks and NBFCs to find the best interest rates.            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Consider factors like your investment goals and risk tolerance when choosing an FD tenure.                         </p>
              <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Remember, FD calculators provide estimates. Always refer to the bank/NBFC's terms and conditions for final details.
            </p>
                      </div>

                      
            <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Using an EPF Calculator:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">There are various online EPF calculators available, including TheFinMaster free and user-friendly calculator. Here's a basic guide on using it:</p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2"> 1.<span className='font-bold'> Input your information:</span>  Enter your basic salary, age, and current EPF balance (if any).. </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  ">2. <span className='font-bold'> Get your results:</span>  The calculator will estimate your employer's contributions, total interest earned, and projected maturity amount. </p>

           
            
            </div>

         


          
            <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Invest in Fixed Deposits Today!</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Fixed deposits offer a secure and predictable way to grow your savings. Use an FD calculator to plan your investments and achieve your financial goals with confidence.</p>
</div>            
<div className="container mx-auto  py-2">
            <p className="text-lg font-normal  text-gray-600 "><span className='text-gray-800 text-xl'>Note:</span> This revised content avoids promotional mentions of TheFinMaster and focuses on the general benefits of fixed deposits and FD calculators.</p>
</div>    
                      
           
                      
        </div>

        <div className="w-full md:w-1/3 p-4">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
              <h2 className="text-lg font-semibold ">Popular Categories</h2>
            </div>
            <div className="p-2 ">
            <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                {/* {moreCategories.map(category => (
          <Link key={category.id} to={`/category/${category.id}`}>
            <li className="py-2 border-b border-gray-300">{category.name}</li>
          </Link>
        ))} */}
              </ul>
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default FDCalculator;
