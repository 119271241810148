import React from 'react'
import CardModal from './CardModal'
import { Link } from 'react-router-dom'

const CardComponent = ({ cardName, cardDetails, cardImage, key, rating, fees, Card_features, category, bank_link }) => {
    //console.log("1Card_features", Card_features)

    // if (category !== 'Credit card') {
    //         return null; // Return null if the category is not 'Credit card'
    //     }
    return (
        <>
            <div className='md:w-[900px] md:h-[200px] border shadow-lg hover:shadow-xl rounded-2xl flex flex-wrap md:flex-nowrap items-center justify-between'>
                <div className='flex flex-wrap md:flex-nowrap w-full justify-center md:justify-normal'>
                    <Link to="">
                        <div className='w-[95%] md:w-fit md:h-[180px] mt-2 md:mt-0 md:ml-2'>
                            <img className='w-[100%] h-[100%] object-cover' src={cardImage || '/fake.jpeg'}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null || undefined; // prevents looping
                                    currentTarget.src = "/fake.jpeg";
                                }}
                                alt='No img' />
                        </div>
                    </Link>

                    <div className='h-[180px] w-[330px] flex justify-between mt-2 md:mt-0 md:ml-3'>
                        <div className='flex flex-col justify-between'>
                            <div>
                                <Link to="">
                                    <h1 className='font-bold capitalize'>{cardName}</h1>

                                    <div className="flex items-center">
                                        {Array.from({ length: parseInt(rating) }, (_, i) => (
                                            <svg key={i} className="w-4 h-4 text-yellow-300 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                                            </svg>
                                        ))}
                                        <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">{rating} out of 5</p>
                                    </div>
                                    <p className='text-gray-400 mt-2 text-sm'>1st year fee: <span className='text-black'>{fees}</span> +GST</p>
                                </Link>
                                <div className='flex flex-wrap gap-2 mt-2 box-border'>
                                    {Card_features.slice(0, 3).map((feature, index) => (
                                        <div key={index} className='border shadow-md'>
                                            <span className='text-xs text-gray-600 p-2'>{feature.Title}</span>
                                        </div>
                                    ))}
                                    {/* <div className='border shadow-md'>
                                        <span className='text-xs text-gray-600 p-2'>No Room Rent Limit</span>
                                    </div>
                                    <div className='border shadow-md'>
                                        <span className='text-xs text-gray-600 p-2'>15 Lakh No Claim Bonus</span>
                                    </div>
                                    <div className='border shadow-md'>
                                        <span className='text-xs text-gray-600 p-2'>Unlimited Restoration of Cover</span>
                                    </div> */}
                                    <div className=''>
                                        {/* {console.log({Card_features})} */}
                                        {/* <span className='text-xs font-semibold cursor-pointer text-blue-500 p-2'>View all features</span> */}
                                        {(Card_features?.length > 0 && Card_features !== undefined) && <CardModal Card_features={Card_features} />}
                                    </div>
                                </div>
                            </div>

                            {/* <div className='flex gap-2'>
                            
                                <input type='checkbox' />
                                <p className='text-sm font-semibold text-gray-500'>Add to compare</p>
                            </div> */}

                            {/* Learn More Button */}

                            {/* <Link to="/allcard-detail " state={cardDetails}>

                                <button className='text-sm font-semibold cursor-pointer text-blue-500 p-2'>Learn More</button>
                            </Link>  */}
                             <Link to="/learn-more-page/card" state={cardDetails} >

                                <button className='text-sm font-semibold cursor-pointer text-blue-500 p-2'>Learn More</button>
                            </Link> 
                        </div>
                    </div>
                </div>

                <div className='flex justify-center items-center my-5 md:mr-3 w-full md:w-[220px]'>
                <a 
          href={bank_link}
          target="_blank" 
          rel="noopener noreferrer"
        >
               <button class="buttonapply">
  Apply Now
  <svg fill="currentColor" viewBox="0 0 24 24" class="icon">
    <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
  </svg>
</button>
          {/* <button className='w-full md:hover:scale-105 md:duration-100 border rounded-full bg-blue-500 text-white font-medium shadow-md px-4 py-4 md:py-1.5'>
          Apply Now!
          </button> */}
        </a>
                    {/* // <Link to={bank_link}>
                    //     <button className='w-full md:hover:scale-105 md:duration-100 border rounded-full bg-blue-500 text-white font-medium shadow-md px-4 py-4 md:py-1.5'>
                    //         Apply Now!
                    //     </button>
                    // </Link> */}
                </div>

            </div>
        </>
    )
}

export default CardComponent
