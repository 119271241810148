import OneSignal from 'react-onesignal';

const initializeOneSignal = async () => {
  await OneSignal.init({
    appId: '5013f897-6d98-4c5f-bb14-7f5561ddc195',
    allowLocalhostAsSecureOrigin: true, // If you are testing on localhost
    notifyButton: {
      enable: true,
    },
  });

  OneSignal.showSlidedownPrompt();
};

export default initializeOneSignal;


// import OneSignal from 'react-onesignal';

// const initializeOneSignal = async () => {
//   try {
//     await OneSignal.init({
//       appId: '5013f897-6d98-4c5f-bb14-7f5561ddc195', // Replace with your actual OneSignal app ID
//       allowLocalhostAsSecureOrigin: true, // If you are testing on localhost
//       notifyButton: {
//         enable: true,
//       },
//     });

//     // Ensure OneSignal is initialized before calling showSlidedownPrompt
//     const isPushNotificationsEnabled = await OneSignal.isPushNotificationsEnabled();

//     if (!isPushNotificationsEnabled) {
//       OneSignal.showSlidedownPrompt().catch((error) => {
//         console.error('Error showing slide down prompt:', error);
//       });
//     }

//     OneSignal.on('subscriptionChange', function (isSubscribed) {
//       if (!isSubscribed) {
//         OneSignal.showSlidedownPrompt().catch((error) => {
//           console.error('Error showing slide down prompt:', error);
//         });
//       }
//     });
//   } catch (error) {
//     console.error('OneSignal initialization error:', error);
//   }
// };
// export default initializeOneSignal;


