import React from "react"
import BlogPageNew from "./BlogPageNew"
import { Helmet } from "react-helmet"
const Blogs= () => {
    return(
        <>
        <Helmet>    
      <title>TheFinmaster Blog - Articles on Life Insurance, Term Insurance, Health Insurance, Moter Insurance, Bike Insurance,Tax, Investment, Retirement Planning</title>
      <meta  name='description' content='Check out informative blogs on term insurance, life insurance,Health Insurance, Moter-Car Insurance, Bike Insurance, Study Loan, tax savings, investments, retirement planning, child insurance, cancer insurance &amp; more on Max Life Insurance.'/>
      <meta name='keywords' content=' insurance blogs, travel insurance blog, auto insurance blog, health insurance blog, life insurance blog' />
      <link rel="canonical" href="https://thefinmaster.com/blog_page" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    
        <div className='w-full flex flex-col items-center overflow-hidden'>
            <div className='w-full h-[92vh]'>
                <div className='w-full h-[50vh] bg-black shadow-2xl relative flex justify-center'>
                    <img
                        className='w-[100%] h-[100%] object-cover opacity-30'
                        src='https://www.searchenginejournal.com/wp-content/uploads/2020/08/7-ways-a-blog-can-help-your-business-right-now-5f3c06b9eb24e-1280x720.png'
                        alt='blog'
                    />
                    <div className='absolute flex flex-col justify-center items-center bottom-0 translate-y-[50%] w-[80%] h-[300px] shadow-2xl bg-black z-20'>
                        <h1 className='text-3xl md:text-6xl text-white pb-5 border-b-2 border-blue-600'>Our Blog</h1>
                        <p className='text-lg md:text-3xl text-white pt-5 w-[70%] text-center'>
                            The Power of Compound Interest: How to Make Your Money Work for You
                        </p>
                    </div>
                </div>

            </div>

            <div className='w-full mb-10'>
    <h2 className='text-2xl md:text-5xl font-bold text-center pb-3'>Money Management</h2>
    <p className='md:text-xl text-center'>
        Discover effective budgeting, saving, and debt management strategies to improve your financial well-being.
    </p>
    </div>
            <div className="max-w-7xl mx-auto">
<BlogPageNew />
            </div>
        </div>
        </>
    )
}
export default Blogs