// src/NSCCalculator.js
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(
  ArcElement,
  ChartTooltip,
  Legend
);

const NSCCalculator = () => {
  const [amountInvested, setAmountInvested] = useState(10000);
  const [interestRate, setInterestRate] = useState(6.8); // Example rate for NSC
  const [compoundFrequency, setCompoundFrequency] = useState('yearly');
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const calculateNSC = () => {
    const timePeriod = 5; // Fixed time period of 5 years
    let n;
    switch (compoundFrequency) {
      case 'quarterly':
        n = 4;
        break;
      case 'halfyearly':
        n = 2;
        break;
      case 'yearly':
      default:
        n = 1;
        break;
    }

    const rate = interestRate / 100;
    const amount = amountInvested * Math.pow((1 + rate / n), n * timePeriod);
    const interest = amount - amountInvested;

    setTotalInterest(interest.toFixed(2));
    setTotalAmount(amount.toFixed(2));
  };

  const handleAmountInvestedChange = (e) => {
    const value = e.target.value;
    setAmountInvested(value === '' ? 0 : parseInt(value));
  };

  const handleInterestRateChange = (e) => {
    const value = e.target.value;
    setInterestRate(value === '' ? 0 : parseFloat(value));
  };

  const handleCompoundFrequencyChange = (e) => {
    setCompoundFrequency(e.target.value);
  };

  const data = {
    labels: ['Principal Amount', 'Total Interest'],
    datasets: [
      {
        label: 'NSC Breakdown',
        data: [amountInvested, totalInterest],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <>
    <Helmet>
         <title>NSC Calculator: Calculate National Savings Certificate returns | TheFinmaster</title>
        <meta  name='description' content='Effortlessly calculate NSC maturity values with Pluto Money&apos;s NSC Calculator. User-friendly, precise tool for secure long-term savings. | TheFinmaster'/>
        <meta name='keywords' content='nsc calculator, 5 year nsc interest rate chart, calculate nsc interest, national saving certificate calculator, national savings certificate interest rate calculator, nsc calculator post office, nsc interest rate calculator, nsc savings calculator' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/nsc-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">NSC Calculator</span>
        </li> 
      
      </ol>
    </nav>
    <div className='py-4'>

    
    <div className="container mx-auto p-4 shadow-xl rounded-md ">
      <h1 className="text-2xl font-bold mb-4">NSC Calculator</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Amount Invested</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                value={amountInvested}
                onChange={handleAmountInvestedChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">₹ {amountInvested.toLocaleString()}</span>
            </div>
            <input
              type="range"
              min="0"
              max="10000000"
              value={amountInvested}
              onChange={(e) => setAmountInvested(parseInt(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Rate of Interest (P.A.)</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                max="100"
                step="0.1"
                value={interestRate}
                onChange={handleInterestRateChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{interestRate} %</span>
            </div>
            <input
              type="range"
              min="0"
              max="100"
              step="0.1"
              value={interestRate}
              onChange={(e) => setInterestRate(parseFloat(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Compound Frequency</label>
            <select
              value={compoundFrequency}
              onChange={handleCompoundFrequencyChange}
              className="w-full p-2 border rounded"
            >
              <option value="yearly">Yearly</option>
              <option value="halfyearly">Half-Yearly</option>
              <option value="quarterly">Quarterly</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Time Period</label>
            <div className="flex items-center">
              <span className="text-gray-700">5 Years (Fixed)</span>
            </div>
          </div>
          <button
            onClick={calculateNSC}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Calculate
          </button>
        </div>
        <div>
          <div className="mb-4">
            <h2 className="text-xl font-bold">Results</h2>
            <p>Principal Amount: ₹ {amountInvested.toLocaleString()}</p>
            <p>Total Interest: ₹ {parseFloat(totalInterest).toLocaleString()}</p>
            <p>Total Amount (Principal + Interest): ₹ {parseFloat(totalAmount).toLocaleString()}</p>
          </div>
          <div>
            <h2 className="text-xl font-bold">Breakdown</h2>
            <div className="flex justify-center">
              <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div className="flex flex-wrap px-6 py-6" >
     <div className="w-full md:w-2/3 p-4">
       <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Grow Your Savings with TheFinMaster's National Savings Certificate (NSC) Calculator
 </h1>
 {/* <p className="text-lg font-normal mt-2 text-gray-700 ">Safe and Steady Returns:</p> */}

       <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">The National Savings Certificate (NSC) is a popular Indian government program offering guaranteed returns and tax benefits. TheFinMaster's free NSC calculator helps you estimate your potential earnings and see if this investment aligns with your financial goals.
</p>


       <div className="container mx-auto  py-4">
         <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using a NSC Calculator:

</h1>

         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'> Effortless Calculations:</span>   Skip complex formulas and get instant results for your future returns.
         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'> Informed Decisions:   </span>  See how much your NSC investment can potentially grow over time, considering the interest rate and compounding.

         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>   Save Time:</span>     Get quick estimates without manual calculations.
         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>   Plan Your Finances:</span>    Use the calculator to determine if an NSC investment fits your savings goals and timeframe.

         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>     Always Available: </span>   Access the free calculator anytime, anywhere to explore different investment scenarios.
         </p>
       </div>
       <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How the Calculator Works:
</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Simply enter your desired investment amount. The calculator automatically considers the current NSC interest rate and 5-year lock-in period to estimate your total maturity value, including both principal and earned interest.
            </p>
          </div>
      
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Grow Beyond the Basics:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">TheFinMaster offers a variety of financial tools and resources to help you understand complex financial concepts and make smarter investment decisions.
            </p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Ready to Get Started?</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Use TheFinMaster's free NSC calculator today! Plan your savings for the future with clarity and confidence.


            </p>
          </div>
     

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Additional Notes:</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Removed unnecessary information about the NSC program itself (government program, low-risk, etc.) - most users will be familiar with NSC already.
         </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Streamlined the explanation of how interest is compounded in NSC.
    </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Focused on the user benefits and a clear call to action.

      </p>
             
          </div>

       


     </div>

     <div className="w-full md:w-1/3 p-4">
       <div className="bg-white shadow-md rounded-lg overflow-hidden">
         <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
           <h2 className="text-lg font-semibold ">Popular Categories</h2>
         </div>
         <div className="p-2 ">
         <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
             {/* {moreCategories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
           </ul>
         </div>
       </div>

     </div>

   </div>

    </>
  );
};

export default NSCCalculator;
