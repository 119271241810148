import React, { useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(
  ArcElement,
  ChartTooltip,
  Legend
);

const InflationCalculator = () => {
  const [currentCost, setCurrentCost] = useState(1000);
  const [inflationRate, setInflationRate] = useState(5); // Inflation rate (per annum)
  const [timePeriod, setTimePeriod] = useState(10); // Time period in years
  const [costIncrease, setCostIncrease] = useState(0);
  const [futureCost, setFutureCost] = useState(0);

  const calculateInflation = () => {
    const futureCostValue = currentCost * Math.pow((1 + inflationRate / 100), timePeriod);
    const costIncreaseValue = futureCostValue - currentCost;

    setCostIncrease(costIncreaseValue.toFixed(2));
    setFutureCost(futureCostValue.toFixed(2));
  };

  const handleCurrentCostChange = (e) => {
    setCurrentCost(parseFloat(e.target.value));
  };

  const handleInflationRateChange = (e) => {
    setInflationRate(parseFloat(e.target.value));
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(parseInt(e.target.value));
  };

  const data = {
    labels: ['Current Cost', 'Cost Increase'],
    datasets: [
      {
        label: 'Inflation Breakdown',
        data: [currentCost, costIncrease],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <>
     <Helmet>
         <title>Inflation Calculator - Calculate Future Value Online | Nippon India Mutual Fund</title>
        <meta  name='description' content='Free inflation calculator that runs on U.S. CPI data or a custom inflation rate. Also, find the historical U.S. inflation data and learn more about inflation. | TheFinmaster'/>
        <meta name='keywords' content='inflation rate calculator, swp calculator with inflation, lumpsum calculator with inflation, adjusted inflation rate calculator, calculator to adjust for inflation, time and value of money calculator' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/inflation-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Inflation Calculator</span>
        </li> 
      
      </ol>
    </nav>
    <div className='py-4'>
      <div className="container mx-auto p-4 shadow-xl rounded-md ">
        <h1 className="text-2xl font-bold mb-4">Inflation Calculator</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Current Cost</label>
              <div className="flex items-center">
                <input
                  type="number"
                  min="0"
                  value={currentCost}
                  onChange={handleCurrentCostChange}
                  className="w-1/2 p-2 border rounded mr-2"
                />
                <span className="text-gray-700">₹ {currentCost.toLocaleString()}</span>
              </div>
              <input
                type="range"
                min="0"
                max="100000"
                value={currentCost}
                onChange={(e) => setCurrentCost(parseInt(e.target.value))}
                className="w-full mt-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Rate of Inflation (P.A.)</label>
              <div className="flex items-center">
                <input
                  type="number"
                  min="0"
                  max="100"
                  step="0.1"
                  value={inflationRate}
                  onChange={handleInflationRateChange}
                  className="w-1/2 p-2 border rounded mr-2"
                />
                <span className="text-gray-700">{inflationRate} %</span>
              </div>
              <input
                type="range"
                min="0"
                max="100"
                step="0.1"
                value={inflationRate}
                onChange={(e) => setInflationRate(parseFloat(e.target.value))}
                className="w-full mt-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Time Period (Years)</label>
              <div className="flex items-center">
                <input
                  type="number"
                  min="0"
                  max="30"
                  value={timePeriod}
                  onChange={handleTimePeriodChange}
                  className="w-1/2 p-2 border rounded mr-2"
                />
                <span className="text-gray-700">{timePeriod} Yr</span>
              </div>
              <input
                type="range"
                min="0"
                max="30"
                value={timePeriod}
                onChange={(e) => setTimePeriod(parseInt(e.target.value))}
                className="w-full mt-2"
              />
            </div>
            <button
              onClick={calculateInflation}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Calculate
            </button>
          </div>
          <div>
            <div className="mb-4">
              <h2 className="text-xl font-bold">Results</h2>
              <p>Current Cost: ₹ {parseFloat(currentCost).toLocaleString()}</p>
              <p>Cost Increase: ₹ {parseFloat(costIncrease).toLocaleString()}</p>
              <p>Future Cost: ₹ {parseFloat(futureCost).toLocaleString()}</p>
            </div>
            <div>
              <h2 className="text-xl font-bold">Breakdown</h2>
              <div className="flex justify-center">
                <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div className="flex flex-wrap px-6 py-6" >
     <div className="w-full md:w-2/3 p-4">
       <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">What is Inflation?

</h1>

            {/* <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">What is Inflation?</h1> */}
            <p className="text-lg font-normal mt-2 text-gray-700 ">Ever notice how the same amount of money seems to buy less over time? That's inflation! It's the ongoing increase in prices for everyday goods and services. This means your rupee buys less, reducing your purchasing power. Inflation tells you how much faster prices are going up over time. Imagine a basket of groceries you buy every month. Inflation measures how much more that basket costs compared to last month, or a year ago.
            </p>
            <p className="text-lg font-bold mt-2 text-gray-700 ">To track inflation, economists use two main indexes: </p>
            
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>     Consumer Price Index (CPI): </span> This measures how much the prices of a basket of typical consumer goods have changed. Think of it like tracking the cost of your grocery shopping over time. 

         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>  Wholesale Price Index (WPI):</span>   This focuses on price changes at the wholesale level, before goods reach stores. It reflects how much more it costs businesses to get the things they sell to you.

           </p>

          <div className="container mx-auto  py-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How Does Inflation Affect Your Savings?</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">We all save and invest to grow our money for future needs. But inflation, the rising cost of living, can eat into those savings. It's like buying groceries - the same amount of money buys less over time. 
            </p>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Here's the challenge: interest earned on savings accounts might not keep pace with inflation. This means your money technically grows, but its buying power shrinks. 

            </p>
            <p className="text-lg font-normal mt-2 text-gray-700 ">The good news? Not all investments are created equal. Some, like stocks and real estate, can potentially grow faster than inflation, protecting your purchasing power.
            </p>
          </div>
          
          <div className="container mx-auto  py-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How to Prepare to Overcome Inflation?</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Inflation, the rising cost of living, is a fact of life. While governments try to control it, some inflation is inevitable. So, what can you do?
            </p>
            <p className="text-lg font-normal mt-2 text-gray-700 ">The key is to be proactive with your finances. By investing your money strategically, you can potentially outpace inflation and protect your purchasing power.
            </p>
            <p className="text-lg font-bold mt-2 text-gray-700 ">Here are some options to consider: </p>

            <p className="text-lg font-normal mt-2 text-gray-700 ">Stocks and mutual funds: These can offer returns that historically beat inflation, but they also carry some risk of loss.
            </p>
            <p className="text-lg font-normal mt-2 text-gray-700 ">The key to success? Diversification! Spreading your investments across different asset classes helps manage risk and smooth out market bumps.

            </p>
            <p className="text-lg font-normal mt-2 text-gray-700 ">By planning for inflation and making smart choices, you can weather the storm and keep your finances on track for the future.
            </p>
          </div>
          <div className="container mx-auto  py-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">What is an Inflation Calculator?
</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Ever wonder how much your money will be worth in the future? Inflation calculators can help!
            </p>
            <p className="text-lg font-normal mt-2 text-gray-700 ">These handy tools take inflation, the rise in prices over time, into account. They tell you what a specific amount of money will be able to buy in the future based on historical inflation rates.
            </p>
            <p className="text-lg font-bold mt-2 text-gray-700 ">Here's what they can do: </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>    Show purchasing power: </span> Imagine you have $100 today. An inflation calculator can estimate how much that $100 will be worth in 10 years, considering inflation.

         </p><p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>   Investment comparison: </span> Thinking about investing? Inflation calculators can also factor in potential investment returns. This helps you compare how much your money would grow with inflation alone versus with an investment strategy.
         </p>
     
<p className="text-lg font-normal mt-2 text-gray-700 ">Basically, inflation calculators are like time machines for your money! They help you see how inflation and potential investments might impact your purchasing power down the road.
</p>
            </div>

            <div className="container mx-auto  py-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How is Inflation Calculated?
</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Imagine you buy a basket of groceries every month. The Consumer Price Index (CPI) is like tracking the price of that basket over time. It helps measure inflation, which is the increase in the cost of goods and services.

            </p>
          
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>   CPI tracks a fixed basket: </span>  This basket includes common goods and services people buy, like food, clothing, and rent.

         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>  Prices change, CPI reflects those changes: </span>  The CPI compares the cost of this basket in different years. If the cost goes up, that means inflation has risen.
         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>    A formula calculates CPI: </span> It's CPI = (Cost of basket in current year / Cost of basket in base year) * 100. The base year is a starting point, often set at 100.

         </p>
     
<p className="text-lg font-bold mt-2 text-gray-700 ">By tracking CPI, economists can gauge inflation and its impact on our purchasing power.

</p>
            </div>
            <div className="container mx-auto  py-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Formula for Inflation
</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Inflation can be measured by using Consumer Price Index (CPI)

            </p>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Inflation = ((CPI x+1 – CPIx)/ CPIx))*100

            </p>
            <p className="text-lg font-normal  text-gray-600 mt-3 "><span className='text-gray-800 text-xl'>Note:</span> CPIx is the Initial Consumer Price of the Index


</p>
            </div>






       <div className="container mx-auto  py-4">
         <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Inflation Calculator

</h1>
<p className="text-lg font-normal mt-2 text-gray-700 ">These free and easy-to-use tools can help you understand how inflation, the rise in prices over time, can impact your money. Here's how they can benefit you:
</p>

         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>    See the future value of your money: </span> Imagine you have a certain amount saved for retirement or a down payment on a house. An inflation calculator can estimate what that amount will be worth in the future, taking inflation into account.

         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>  Plan for rising costs:</span>   By understanding how inflation can erode your purchasing power, you can adjust your savings goals and budget accordingly.
           </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>Compare investment options:</span>      Inflation calculators can help you factor in inflation when considering potential investment returns. This allows you to see if an investment is likely to keep pace with inflation or even outpace it.


         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>      Save time and effort:</span>   Forget complex calculations! Inflation calculators deliver quick and accurate results, saving you valuable time and energy.


         </p>
         <p className="text-lg font-bold mt-2 text-gray-700 ">With an inflation calculator in your financial toolbox, you can make informed decisions about your money and plan for a secure future!</p>

       </div>
       

     </div>

     <div className="w-full md:w-1/3 p-4">
       <div className="bg-white shadow-md rounded-lg overflow-hidden">
         <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
           <h2 className="text-lg font-semibold ">Popular Categories</h2>
         </div>
         <div className="p-2 ">
         <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
             {/* {moreCategories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
           </ul>
         </div>
       </div>

     </div>

   </div>
   
    </>
  );
};

export default InflationCalculator;
