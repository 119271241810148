import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const CareHealthTravelLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: '  Can I purchase international travel insurance for my parents who are over 70 years old?',
        answer:
            ' Yes, Care Health Insurance provides senior citizen travel insurance for individuals over 70 years old.',
    },
    {
        question: 'Is a medical test required to obtain international travel insurance from Care Health Insurance Limited?',
        answer:
            'No, a pre-policy health checkup is not required to purchase Care Travel Insurance. The insurer adheres to a no pre-policy medical check policy unless there are pre-existing diseases (PEDs).',
    },
    {
        question: ' Why should I choose the Explore - International Travel Insurance from Care Health Insurance?',
        answer:
            'The Care Health Explore travel insurance plans offer broad protection for international trips, including coverage for medical expenses, personal liability, and loss of passport. They also provide airline insurance with trip interruption and cancellation benefits, and can be customized to fit specific destinations and traveler needs, ensuring comprehensive emergency medical assistance.',
    },
    {
      question: 'Is international travel insurance required for a Schengen visa?',
      answer:
          ' Yes, a travel insurance plan with a minimum coverage of EUR 30,000 is mandatory for obtaining a Schengen visa.',
  },
  {
    question: 'How long can I extend my overseas travel insurance with Care Health Insurance Limited?',
    answer:
        'Care Health Insurance allows policy extensions for up to a maximum of 180 days. Extensions must be requested at least 10 days before the policy’s expiration.',
},
{
    question: 'Are pre-existing conditions covered by Care Travel Insurance?',
    answer:
        'Yes, Care Travel Insurance covers pre-existing conditions under life-threatening situations. However, such conditions must be disclosed at the time of policy purchase.',
},
{
    question: 'Can I cancel my travel insurance policy with Care Health Insurance Limited?',
    answer:
        ' You may cancel your Care Travel Insurance policy and receive a premium refund under certain conditions, including visa denial, trip cancellation, or early return to your home. Refunds are available if you cancel the policy within 7 days of the policy start date. For reasons other than visa denial, a cancellation fee will apply if the policy is canceled before the trip begins. Please note that no refund will be granted if a claim has already been filed.',
},



    
  ];
    const benefits = [
      {
        title: 'No Claim Bonus Protection',
      },
      {
        title: 'Roadside Assistance',
      },
      {
        title: 'Repair of Glass Fiber and Plastic Parts',
      },
      {
        title: 'Daily Allowance',
      },
      {
        title: 'Emergency Transport and Hotel Expenses',
      },
      {
        title: 'Key Replacement        ',
      },
      {
        title: 'Engine & Tyre Secure',
      },
      {
        title: 'Loss of Personal Belongings',
      },
      {
        title: 'Return to Invoice',
      },
      // {
      //   title: 'Tyre Secure Cover',
      // },
    ]
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
         <title>Get Care Travel Insurance | TheFinmaster</title>
        <meta  name='description' content='Get Care travel insurance with TheFinmaster. Secure your travels with comprehensive coverage. Buy online quickly and easily.'/>
        <meta name='keywords' content='care travel insurance, care health travel insurance' />
        <link rel="canonical" href="https://thefinmaster.com/travel-insurance/care-health-travel-insurance" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <FAQSchema faqs={faqs} />
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/travel-insurance" className="text-blue-600 hover:text-blue-800">Care Health Travel Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Care Health Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Safeguard Your Global Adventures with
                  </span>{" "}
                  Care Travel Insurance
                </h1>
                <a href="https://www.careinsurance.com/v1/travel-insurance/tr/international-travel-insurance-nj.html?agentId=20024993&utm_source=google&utm_medium=cpc&utm_campaign=tr_ind_new-101_AD_E_DT&utm_content=rta&utm_keyword=care%20health%20travel%20insurance&s_kwcid=AL!10397!3!705375781674!e!!g!!care%20health%20travel%20insurance&utm_term=19608107419&utm_adgroup=144193952766&gad_source=1&gclid=Cj0KCQjwh7K1BhCZARIsAKOrVqGtfg9bEhqL6ILZ1mfwj3tP5QFpOYu_zyk3sgdFmPtrxvhA_aLzRtMaAlgwEALw_wcB"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Get Plan!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/adityahealth1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about Care  Travel Insurance</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Care travel insurance plans offer comprehensive coverage for both medical and non-medical emergencies. Provided by Care Health Insurance Ltd., a leading insurer in India, these plans are backed by the company's reputation for excellence. Recognized as the 'Smart Insurer of the Year' at The Economic Times Insurance Summit & Awards 2022, Care Health Insurance Ltd. boasts a commendable claim settlement ratio of 87.1% for FY 2021-22, reflecting a strong commitment to efficient claims processing.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Given that emergencies can arise unexpectedly, it is crucial to secure appropriate coverage before traveling abroad. Protect yourself and your loved ones with Care travel insurance, ensuring a smooth journey with prompt processing and reliable support.
                </p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/hospital.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
        
            Best Health Insurance Company of the year

            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            48 Lakh+ Claims Settled 


            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/onspot.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            24,800+ Cashless Healthcare Providers



            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
       
      </motion.div>
    </div>
  </div>
</div>

    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Explore Aditya Birla's Health Insurance Plans</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Here are some top insurance plans provided by Aditya Birla. Click the link below to discover all plans offered by the brand.</p>
            </div>
            <div class="flex justify-center items-center p-4">
            <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[64rem] flex-col sm:flex-row">
    <div class="relative w-full sm:w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-t-xl sm:rounded-r-none bg-clip-border rounded-xl shrink-0">
      <img
        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
        alt="card-image" class="object-cover w-full h-full" />
    </div>
    <div class="p-6">
      <h6 class="block mb-2  text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      Individual Travel Insurance
      </h6>
      {/* <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Lyft launching cross-platform service this week
      </h4> */}
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      International travel insurance offers comprehensive protection against various uncertainties encountered during overseas trips.
      </p>
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      It covers unforeseen medical expenses, including inpatient and outpatient care, as well as non-medical issues such as loss of passport or checked-in baggage, trip delays, and cancellations. At Care, you can find country-specific travel insurance plans tailored to meet your needs for different destinations.      </p>
      <a href="#" class="inline-block"><button
          class="flex items-center gap-2 px-6 py-3  text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none  disabled:shadow-none disabled:pointer-events-none hover:bg-blue-600 bg-blue-500"
          type="button">
          Learn More<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
    </div>
  </div>
  </div>
  <div class="flex justify-center items-center p-4">
            <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[64rem] flex-col sm:flex-row">
    <div class="relative w-full sm:w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-t-xl sm:rounded-r-none bg-clip-border rounded-xl shrink-0">
      <img
        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
        alt="card-image" class="object-cover w-full h-full" />
    </div>
    <div class="p-6">
      <h6 class="block mb-2  text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      Student Travel Insurance
      </h6>
      {/* <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Lyft launching cross-platform service this week
      </h4> */}
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      Student travel insurance is tailored to address the specific needs of students studying abroad. This coverage includes protection against travel-related issues such as trip interruptions, medical emergencies, and liabilities during their educational journey.       </p>
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      With comprehensive travel insurance, students can concentrate on their studies and return home with both success and cherished memories.
      </p>
      <a href="#" class="inline-block"><button
          class="flex items-center gap-2 px-6 py-3  text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none  disabled:shadow-none disabled:pointer-events-none hover:bg-blue-600 bg-blue-500"
          type="button">
          Learn More<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
    </div>
  </div>
  </div>
  <div class="flex justify-center items-center p-4">
            <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[64rem] flex-col sm:flex-row">
    <div class="relative w-full sm:w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-t-xl sm:rounded-r-none bg-clip-border rounded-xl shrink-0">
      <img
        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
        alt="card-image" class="object-cover w-full h-full" />
    </div>
    <div class="p-6">
      <h6 class="block mb-2  text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      Group Travel Insurance
      </h6>
      {/* <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Lyft launching cross-platform service this week
      </h4> */}
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      Group travel insurance is designed to protect those traveling together.
            </p>
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      Our comprehensive and cost-effective plan covers each individual within the group, offering protection against unforeseen events such as emergency hospitalization, trip interruptions, and loss or theft of belongings. With this coverage, every member of the group is ensured a secure and worry-free journey.
 </p>
      <a href="#" class="inline-block"><button
          class="flex items-center gap-2 px-6 py-3  text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none  disabled:shadow-none disabled:pointer-events-none hover:bg-blue-600 bg-blue-500"
          type="button">
          Learn More<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
    </div>
  </div>
  </div>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>


        </>
    )
}

export default CareHealthTravelLearn
