import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const FutureLifeLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: ' What is life insurance?',
        answer:
            'Life insurance is a financial tool that provides a death benefit to your designated beneficiaries (usually loved ones) in case of your passing. This benefit can help them cover expenses like funeral costs, outstanding debts, or maintain their financial well-being.',
    },
    {
        question: 'What factors should I consider when choosing a life insurance policy?',
        answer:
            ' Consider factors like the type of coverage (term vs. whole life), coverage amount, premium cost, beneficiary designation, and any riders (optional add-ons) that may be beneficial, such as disability income riders or waiver of premium riders.',
    },
    {
        question: 'Why is life insurance important?',
        answer:
            'Life insurance is important because it provides financial security to your loved ones in the event of your death. It can help cover funeral expenses, pay off debts, replace lost income, and ensure that your family financial needs are met. Life insurance offers peace of mind, knowing that those who depend on you will be supported even when you are no longer there to provide for them.',
    },
    {
        question: 'Can I buy insurance through TheFinMaster.com?',
        answer:
            'TheFinMaster.com is not an insurance provider.  We offer educational resources, comparison tools, and unbiased information to help you understand different insurance types and choose the right coverage for your needs.  Once you have identified your needs, you will be directed to the issuers website to complete a secure application process.',
    },
  ];
    const benefits = [
      {
        title: 'Fixed sum assured for loved ones in case of an unfortunate incident. ',
      },
      {
        title: ' Covers medical expenses or provides predetermined payout for injuries/illnesses.',
      },
      {
        title: 'Combines investment and insurance in one policy, with premiums divided for investment and securing loved ones.',
      },
      {
        title: 'Build savings securely while safeguarding your family financial future. ',
      },
    //   {
    //     title: 'Provides regular retirement income by investing a lump sum, with payouts from returns generated by the life insurance company.',
    //   },
      
      // {
      //   title: 'Tyre Secure Cover',
      // },
    ]
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Plan for your future. Secure your loved 
                  </span>{" "}
                  complete peace of mind.
                </h1>
                <a href="https://www.iciciprulife.com/"
                  target="_blank" 
                  rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                  ones with ICICI Prudential. 
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/car1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about ICICI Prudential Life Insurance </h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> ICICI Prudential Life Insurance is a stalwart in the industry, renowned for its versatile product range catering to diverse financial needs. Their term plans offer essential life coverage, while investment-linked plans seamlessly merge insurance with wealth accumulation, providing comprehensive financial security.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Whole life plans from ICICI Prudential guarantee benefits, ensuring long-term peace of mind. With an extensive branch network and robust digital platform, they ensure hassle-free policy management for their customers, fostering trust and confidence. </p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/overnight.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            1 Day Claim Settlement


            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/garage.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Doorstep claim assistance


            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Full death benefit under Terminal illness coverage.

            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/bonus.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Tax Benefits on premiums.




            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/add.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            ₹2,900Cr+ volume of claims closed

            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/protect.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Get premiums back with Smart Exit Benefit



            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>

<div className="w-full flex flex-col items-center  mb-10">
      <div className="w-[90%] text-center mb-10">
        <h2 className="font-bold text-4xl mb-4">ICICI Prudential Life Insurance  plan add-on benefits</h2>
        <p className="text-gray-700 mb-4">
          Choose from an assorted range of add-ons, for an additional premium, when buying ICICI Prudential Life Insurance  online.
        </p>
      </div>
      <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            {/* <img src='../assets/${benefit.icon}' alt={benefit.title} className="mb-4" /> */}
            <h3 className=" text-gray-600 text-2xl mb-2">{benefit.title}</h3>
            {/* <p className="text-gray-700">{benefit.description}</p> */}
          </div>
        ))}
      </div>
    </div>

    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of AU Bank LIT Credit Card</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">The AU Small Finance Bank LIT Credit Card is India's pioneering customizable credit card, offering the unique advantage of selecting and paying for desired features exclusively. With a wide array of benefits spanning lifestyle, groceries, travel, and beyond, this card provides unparalleled flexibility and convenience.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">It provides a comprehensive range of credit card benefits and allows holders to select one or multiple features/categories simultaneously. This empowers customers to enjoy benefits from their chosen category while only paying for the features they use, rather than the entire annual card fees.</p>
            </div>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default FutureLifeLearn
