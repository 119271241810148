// src/HRACalculator.js
import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import 'tailwindcss/tailwind.css'; // Make sure you have tailwindcss installed and imported
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

const HRACalculator = () => {
  const [basicSalary, setBasicSalary] = useState(540050);
  const [dearnessAllowance, setDearnessAllowance] = useState(0);
  const [hraReceived, setHraReceived] = useState(100000);
  const [totalRentPaid, setTotalRentPaid] = useState(300000);
  const [isMetroCity, setIsMetroCity] = useState(false);
  const [exemptedHRA, setExemptedHRA] = useState(0);
  const [taxableHRA, setTaxableHRA] = useState(0);

  const calculateHRA = () => {
    const actualHRAReceived = hraReceived;
    const rentMinus10PercentSalary = totalRentPaid - (0.1 * (basicSalary + dearnessAllowance));
    const metroCityHRA = isMetroCity ? 0.5 * basicSalary : 0.4 * basicSalary;

    const hraExempt = Math.min(actualHRAReceived, rentMinus10PercentSalary, metroCityHRA);
    const hraTaxable = actualHRAReceived - hraExempt;

    setExemptedHRA(hraExempt);
    setTaxableHRA(hraTaxable);
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value === '' ? 0 : parseInt(e.target.value));
  };

  const handleMetroCityChange = (e) => {
    setIsMetroCity(e.target.value === 'yes');
  };

  const data = {
    labels: ['Exempted HRA', 'Taxable HRA'],
    datasets: [
      {
        label: 'HRA Breakdown',
        data: [exemptedHRA, taxableHRA],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <>
     <Helmet>
         <title>HRA Exemption Calculator - Calculate House Rent Allowance Online | TheFinmaster</title>
        <meta  name='description' content='HRA exemption calculator allows you to calculate how much of your HRA is taxable and how much is exempted from tax. | TheFinmaster'/>
        <meta name='keywords' content='hra exemption calculator, calculate hra tax exemption, calculate hra deduction, calculation of hra rebate, computation of hra exemption, hra calculation for tax exemption' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/hra-exemption-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">HRA Calculator</span>
        </li> 
      
      </ol>
    </nav>
    <div className='py-4'>
      <div className="container mx-auto p-4 shadow-xl rounded-md">
        <h1 className="text-2xl font-bold mb-4">HRA Calculator</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Basic Salary (p.a)</label>
              <div className="flex items-center">
                <input
                  type="number"
                  value={basicSalary}
                  onChange={handleInputChange(setBasicSalary)}
                  className="w-1/2 p-2 border rounded mr-2"
                />
                <span className="text-gray-700">₹ {basicSalary.toLocaleString()}</span>
              </div>
              <input
                type="range"
                min="0"
                max="10000000"
                value={basicSalary}
                onChange={(e) => setBasicSalary(parseInt(e.target.value))}
                className="w-full mt-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Dearness Allowance (p.a)</label>
              <div className="flex items-center">
                <input
                  type="number"
                  value={dearnessAllowance}
                  onChange={handleInputChange(setDearnessAllowance)}
                  className="w-1/2 p-2 border rounded mr-2"
                />
                <span className="text-gray-700">₹ {dearnessAllowance.toLocaleString()}</span>
              </div>
              <input
                type="range"
                min="0"
                max="1000000"
                value={dearnessAllowance}
                onChange={(e) => setDearnessAllowance(parseInt(e.target.value))}
                className="w-full mt-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">HRA Received (p.a)</label>
              <div className="flex items-center">
                <input
                  type="number"
                  value={hraReceived}
                  onChange={handleInputChange(setHraReceived)}
                  className="w-1/2 p-2 border rounded mr-2"
                />
                <span className="text-gray-700">₹ {hraReceived.toLocaleString()}</span>
              </div>
              <input
                type="range"
                min="0"
                max="500000"
                value={hraReceived}
                onChange={(e) => setHraReceived(parseInt(e.target.value))}
                className="w-full mt-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Total Rent Paid (p.a)</label>
              <div className="flex items-center">
                <input
                  type="number"
                  value={totalRentPaid}
                  onChange={handleInputChange(setTotalRentPaid)}
                  className="w-1/2 p-2 border rounded mr-2"
                />
                <span className="text-gray-700">₹ {totalRentPaid.toLocaleString()}</span>
              </div>
              <input
                type="range"
                min="0"
                max="1000000"
                value={totalRentPaid}
                onChange={(e) => setTotalRentPaid(parseInt(e.target.value))}
                className="w-full mt-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Are you working in a metro city?</label>
              <div className="flex items-center">
                <label className="mr-4">
                  <input
                    type="radio"
                    value="yes"
                    checked={isMetroCity === true}
                    onChange={handleMetroCityChange}
                    className="mr-2"
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    value="no"
                    checked={isMetroCity === false}
                    onChange={handleMetroCityChange}
                    className="mr-2"
                  />
                  No
                </label>
              </div>
            </div>
            <button
              onClick={calculateHRA}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Calculate
            </button>
          </div>
          <div>
            <div className="mb-4">
              <h2 className="text-xl font-bold">Results</h2>
              <p>Exempted HRA: ₹ {exemptedHRA.toLocaleString()}</p>
              <p>Taxable HRA: ₹ {taxableHRA.toLocaleString()}</p>
            </div>
            <div>
              <h2 className="text-xl font-bold">Breakdown</h2>
              <div className="flex justify-center">
                <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div className="flex flex-wrap px-6 py-6" >
        <div className="w-full md:w-2/3 p-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Understanding House Rent Allowance (HRA) and Tax Benefits </h1>
          {/* <p className="text-lg font-normal mt-2 text-gray-700 ">What is House Rent Allowance (HRA)?</p> */}

          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">House Rent Allowance (HRA) is a component of your salary that helps offset the cost of renting a home. It's a common benefit offered by employers in India.</p>


          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Who Can Claim HRA Exemption?</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>    Salaried Employees:  </span> You must be a salaried individual receiving HRA as part of your salary package.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Renting a Home: </span>     You must be residing in a rented accommodation.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  HRA Exceeds Minimum Threshold: </span> The HRA amount should be more than 10% of your basic salary.

            </p>

          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How Can You Save Tax on HRA?</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">The Indian tax system allows you to claim an exemption on a portion of your HRA. The amount exempt from tax depends on three factors: amount, investment tenure, and expected interest rates. An NPS calculator simplifies this process.</p>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Actual Rent Paid:  </span> Minus 10% of your basic salary.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> HRA Limit Based on City:  </span>  This varies depending on the city you live in:
              <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                Metro Cities (X): 50% of your basic salary.                 </p>
              <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                Other Cities (Y): 40% of your basic salary.                 </p>

            </p>


          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How Does an HRA Calculator Help?</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">An HRA calculator simplifies the process of determining your potential HRA exemption. It considers factors like your salary, HRA amount, rent paid, and city of residence to estimate the maximum tax exemption you can claim on your HRA.
            </p>
          </div>


          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using an HRA Calculator:</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>     Effortless Calculations:  </span> Avoids complex manual calculations and provides quick results.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>Informed Decisions:  </span>     Helps you understand how much tax you can save on your HRA.

            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Tax Planning: </span>  Assists in planning your finances and maximizing tax benefits.

            </p>

          </div>

          <div className="container mx-auto  py-2">
            <p className="text-lg font-normal  text-gray-600 "><span className='text-gray-800 text-xl'>Note:</span> This revised content avoids promotional mentions and focuses on explaining HRA, tax benefits, and the advantages of using an HRA calculator.

            </p>
          </div>



        </div>

        <div className="w-full md:w-1/3 p-4">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
              <h2 className="text-lg font-semibold ">Popular Categories</h2>
            </div>
            <div className="p-2 ">
            <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                {/* {moreCategories.map(category => (
          <Link key={category.id} to={`/category/${category.id}`}>
            <li className="py-2 border-b border-gray-300">{category.name}</li>
          </Link>
        ))} */}
              </ul>
            </div>
          </div>

        </div>

      </div>
    </>

  );
};

export default HRACalculator;
