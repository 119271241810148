import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const LumpsumCalculator = ({ isSip, setIsSip }) => {
  const [lumpsumInvestment, setLumpsumInvestment] = useState(100000);
  const [expectedReturnRate, setExpectedReturnRate] = useState(12);
  const [timePeriod, setTimePeriod] = useState(10);
  const [totalReturns, setTotalReturns] = useState(0);
  const [estimatedReturns, setEstimatedReturns] = useState(0);

  useEffect(() => {
    calculateReturns();
  }, [lumpsumInvestment, expectedReturnRate, timePeriod]);

  const calculateReturns = () => {
    if (lumpsumInvestment <= 0 || expectedReturnRate <= 0 || timePeriod <= 0) return;
    
    const rate = expectedReturnRate / 100;
    const total = lumpsumInvestment * Math.pow(1 + rate, timePeriod);
    const estimated = total - lumpsumInvestment;
    setTotalReturns(total.toFixed(2));
    setEstimatedReturns(estimated.toFixed(2));
  };

  const handleInvestmentChange = (e) => {
    const value = e.target.value;
    setLumpsumInvestment(value === '' ? 0 : parseInt(value));
  };

  const handleExpectedReturnRateChange = (e) => {
    const value = e.target.value;
    setExpectedReturnRate(value === '' ? 0 : parseFloat(value));
  };

  const handleTimePeriodChange = (e) => {
    const value = e.target.value;
    setTimePeriod(value === '' ? 0 : parseInt(value));
  };

  const data = {
    labels: ['Total Investment', 'Estimated Returns'],
    datasets: [
      {
        label: 'Lumpsum Returns Breakdown',
        data: [lumpsumInvestment, estimatedReturns],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>Lumpsum Calculator Online- Best SIP and Lumpsum Calculator | TheFinmaster</title>
        <meta name='description' content='Lumpsum Calculator to simply calculate the future value of your lumpsum investment. Very easy to use Lumpsum Calculator Online 2024 | TheFinmaster' />
        <meta name='keywords' content='lump sum sip calculator, mutual fund calculator lumpsum, lumpsum investment calculator' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/lumpsum-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Lumpsum Calculator</span>
        </li> 
      
      </ol>
    </nav>
      <div className='py-6'>
        <div className="container mx-auto p-4 shadow-xl rounded-md ">
          <div className="flex space-x-4 mb-4">
            <button
              className={`py-2 px-4 rounded-md ${isSip ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              onClick={() => setIsSip(true)}
            >
              SIP
            </button>
            <button
              className={`py-2 px-4 rounded-md ${!isSip ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              onClick={() => setIsSip(false)}
            >
              Lumpsum
            </button>
          </div>
          <h1 className="text-2xl font-bold mb-4">Lumpsum Returns Calculator</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Total Investment</label>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="0"
                    max="10000000"
                    value={lumpsumInvestment}
                    onChange={handleInvestmentChange}
                    className="w-1/2 p-2 border rounded mr-2"
                  />
                  <span className="text-gray-700">₹ {lumpsumInvestment.toLocaleString()}</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="10000000"
                  value={lumpsumInvestment}
                  onChange={(e) => setLumpsumInvestment(parseInt(e.target.value))}
                  className="w-full mt-2"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Expected Return Rate (P.A.)</label>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.1"
                    value={expectedReturnRate}
                    onChange={handleExpectedReturnRateChange}
                    className="w-1/2 p-2 border rounded mr-2"
                  />
                  <span className="text-gray-700">{expectedReturnRate} %</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="100"
                  step="0.1"
                  value={expectedReturnRate}
                  onChange={(e) => setExpectedReturnRate(parseFloat(e.target.value))}
                  className="w-full mt-2"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Time Period (Years)</label>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="1"
                    max="50"
                    value={timePeriod}
                    onChange={handleTimePeriodChange}
                    className="w-1/2 p-2 border rounded mr-2"
                  />
                  <span className="text-gray-700">{timePeriod} Yr</span>
                </div>
                <input
                  type="range"
                  min="1"
                  max="50"
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(parseInt(e.target.value))}
                  className="w-full mt-2"
                />
              </div>
              {/* You can remove this button if you prefer automatic calculation */}
              <button
                onClick={calculateReturns}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Calculate
              </button>
            </div>
            <div>
              <div className="mb-4">
                <h2 className="text-xl font-bold">Results</h2>
                <p>Total Investment: ₹ {lumpsumInvestment.toLocaleString()}</p>
                <p>Estimated Returns: ₹ {estimatedReturns.toLocaleString()}</p>
                <p>Total Returns: ₹ {totalReturns.toLocaleString()}</p>
              </div>
              <div>
                <h2 className="text-xl font-bold">Breakdown</h2>
                <div className="flex justify-center">
                  <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="flex flex-wrap px-6 py-6" >
        <div className="w-full md:w-2/3 p-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Unlocking Lump Sum Investing: Grow Your Money with Mutual Funds</h1>
          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">This revised content simplifies the explanation of lump sum investments in mutual funds and the benefits of using a LumpSum calculator.</p>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Investing in Mutual Funds: Lump Sums Explained</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
              Mutual funds offer two main investment approaches: Lump Sums and SIPs (Systematic Investment Plans). Lump sum investing involves investing a significant amount of money at once in a chosen mutual fund scheme.
            </p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Understanding LumpSum Returns</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
              Before diving into calculators, let's explore the different return types for lump sum investments:              </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Absolute Return:</span> Total profit or loss on your investment.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Total Return:</span>  Combines capital appreciation (increase in share price) and dividends received.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Annualized Return: </span> Reflects average annual growth over the investment period.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Point-to-Point Return:</span>  Return between two specific dates.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Rolling Return: </span>  Return for a set period that's repeatedly calculated over a longer timeframe.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Trailing Return:</span>   Return over a specific period ending on the current date.

            </p>
            <h1 className="text-xl font-medium  text-gray-600 pl-4  mt-6">Knowing these return types empowers you to make informed investment decisions.</h1>
          </div>

          <div className="container mx-auto  py-2">

            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 mt-2">Benefits of Using a LumpSum Calculator</h1>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg mt-2">A LumpSum calculator provides a valuable tool for estimating your potential returns:
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Visualize Returns:  </span>Get an estimate of your returns for different timeframes (1 year, 3 years, 5 years).
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> User-Friendly: </span>  Easy to use, even for beginners.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Reasonable Estimates: </span> Provides a good approximation, considering market fluctuations.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Informed Planning: </span>  Helps plan your finances based on expected returns.
            </p>
          </div>



          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Formula Breakdown: Making Sense of the Math</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
              Lumpsum calculators use a compound interest formula to estimate returns. Here's a simplified breakdown (full formula provided below):              </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Estimated Return (A):</span>  Your potential future earnings.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Present Value (P):</span>  Present Value (P): Your initial lump sum investment.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Rate of Return (r): </span> The expected annual return percentage.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Duration of Investment (t):</span> The total investment period in years.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Number of Compounding Interests (n): </span>  How often interest is compounded annually (e.g., monthly, quarterly)
            </p>


          </div>

          <div className="container mx-auto  py-2">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 mt-2">Example: Calculating Your Return</h1>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg mt-2">Imagine investing Rs. 15 lakh in a fund with a 12% return for 5 years, compounded every 6 months (n = 2). The calculator estimates your return to be Rs. 26,43,513.
            </p>
          </div>
          <div className="container mx-auto  py-2">

            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 mt-2">TheFinMaster Lumpsum Calculator: Simple and Efficient</h1>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg mt-2">TheFinMaster online LumpSum calculator simplifies the process
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative  mt-2"> {/* Added pl-6 for left padding */}

              1. Enter your investment amount, expected return, and investment duration.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative  "> {/* Added pl-6 for left padding */}

              2. Use the slider to adjust values and see variations.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative  "> {/* Added pl-6 for left padding */}

              3. Get your estimated return in seconds.
            </p>
          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">TheFinMaster Calculator: Your Investment Ally</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>Estimates Market Fluctuations:</span>  Provides a realistic picture despite market uncertainties
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Financial Planning Tool:</span>   Helps plan future expenses based on potential returns.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Accessibility: </span> Use it online, anytime & anywhere.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>Saves Time and Effort: </span>Avoids complex manual calculations.
            </p>


          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Start Investing Today!</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
              Lump sum investments can be a powerful tool for wealth creation. Consider starting with a smaller amount and gradually increasing as you gain confidence. TheFinMaster offers a user-friendly platform to begin your mutual fund journey.              </p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Open an account with TheFinMaster and explore the world of direct mutual funds! </h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
              (Consider adding a link to TheFinMaster website here)              </p>
          </div>
        </div>

        <div className="w-full md:w-1/3 p-4">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
              <h2 className="text-lg font-semibold ">Popular Categories</h2>
            </div>
            <div className="p-2 ">
              <ul>
                <Link to='/financial-tools-calculators-swp-calculator'>
                  <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/fd-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/rd-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/nps-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/emi-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>

                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                </Link>
                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
              </ul>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
            <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
              <h2 className="text-lg font-semibold">More Categories</h2>
            </div>
            <div className="p-2">
              <ul>
                <Link to='/financial-tools-calculators/gratuity-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/APY-calculator'>
                  <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/cagr-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/gst-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/inflation-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/salary-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/brokerage-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                </Link>
                <Link to='/financial-tools-calculators/tds-calculator'>
                  <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                </Link>
                {/* {moreCategories.map(category => (
          <Link key={category.id} to={`/category/${category.id}`}>
            <li className="py-2 border-b border-gray-300">{category.name}</li>
          </Link>
        ))} */}
              </ul>
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default LumpsumCalculator;
