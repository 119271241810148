import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Http from "../../Http";
import { Contact_API,  Newsletter_API } from "../../Helper";
import { BsFillSendFill } from "react-icons/bs";
import Newsletter from './Newsletter';
import FAQSchema from '../../FAQSchema';


const ContactPage = () => {
  const initialFormData = {
    name: "",
    subject: "",
    email: "",
    message: "",
    phone: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [newsletterEmail, setNewsletterEmail] = useState("");
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [newsletterError, setNewsletterError] = useState(null);
  const [newsletterSuccess, setNewsletterSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name') {
      const pattern = /^[A-Za-z\s]+$/;
      if (!pattern.test(value)) {
        setError("Name should contain only letters and spaces.");
      } else {
        setError(null);
      }
    }
  
    if (name === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        setError("Please enter a valid email address.");
      } else {
        setError(null);
      }
    }
  
     // Validation for phone number (accepts only numeric input with 10 to 12 digits)
  if (name === 'phone') {
    const phonePattern = /^\d{10,12}$/; // Accepts numeric input with 10 to 12 digits
    if (!phonePattern.test(value)) {
      setError("Please enter a valid phone number with 10 to 12 digits.");
    } else {
      setError(null);
    }
  }

  
  

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  //  console.log(formData.name, formData.email.trim() , formData.phone.trim() , formData.message.trim())
    if (!formData.name.trim() || !formData.email.trim() || !formData.phone.trim() || !formData.subject.trim()  || !formData.message.trim()) {
      setError("Please fill out all fields.");
      return;
    }
    else{
      setError(null)
    }

    
    if (error) {
      return;
    }

    setLoading(true);

    try {
      const response = await Http.post(Contact_API, formData);
      console.log("Response:", response);
      setSuccess(true);
      resetForm();
    } catch (error) {
      console.error("Error sending data:", error);
      setError("Failed to send data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };


  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();

    if (!newsletterEmail.trim()) {
      setNewsletterError("Please enter an email address.");
      return;
    }

    setNewsletterLoading(true);

    try {
      const response = await Http.post(Newsletter_API, { email: newsletterEmail });
      console.log("Newsletter Response:", response);
      setNewsletterSuccess(true);
      setNewsletterEmail("");
    } catch (error) {
      console.error("Error subscribing to newsletter:", error);
      setNewsletterError("Failed to subscribe. Please try again later.");
    } finally {
      setNewsletterLoading(false);
    }
  };
  const resetForm = () => {
    setFormData(initialFormData);
  };
  const backgroundImageUrl = "../assets/bgcontact.webp"; // Update this with your actual image URL
  const faqs = [
    {
      question: "What if I have a question about a product?",
      answer: "While we display products from various lenders, we don't have direct control over their application process or terms.  If you have questions about a specific product/offer, we recommend contacting the lender directly using the information provided on the offer page."
    },
    {
      question: "How can I stay updated on financial news and tips?",
      answer: "TheFinMaster.com is committed to keeping you informed! Subscribe to our email newsletter for regular updates on financial news, tips, and exclusive offers."
    },
    {
      question: "Is my information secure on TheFinMaster.com?",
      answer: "The security of your information is a top priority at TheFinMaster.com. We employ industry-standard security measures to safeguard your data, including encryption and secure login protocols. We never share your personal information with third-party vendors without your consent. For more details, you can review our comprehensive privacy policy located on our website."
    },
    {
      question: "Is there a physical location for TheFinMaster.com?",
      answer: "TheFinMaster.com is currently an online platform and does not have a physical branch location. However, our customer service team is available remotely to assist you with any questions you may have."
    },
    {
      question: "What investment tools or calculators are available on TheFinMaster.com?",
      answer: "TheFinMaster.com offers a variety of investment tools and calculators to help you manage your finances. On our Tools page, you can find multiple calculators including SIP (Systematic Investment Plan), Lumpsum, PPF (Public Provident Fund), Mutual Fund, and EMI (Equated Monthly Installment) Calculator. These tools are designed to provide you with accurate and easy-to-understand financial insights."
    },
    
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  // Function to toggle open/close state of FAQ item
  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      // If the clicked question is already expanded, collapse it
      setActiveIndex(null);
  } else {
      // If the clicked question is not expanded, expand it
      setActiveIndex(index);
  };
  };

  return (
    <>
     <Helmet>
      <title>TheFinmaster Customer Care Toll Free Numbers & Contact Details</title>
      <meta  name='description' content='Get TheFinmaster contact details, customer care helpline number & toll free numbers in case you require any assistance. You can call us at TheFinmaster toll free number for any help.'/>
      <link rel="canonical" href="https://thefinmaster.com/contact-us" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 "> Contact US</span>
        </li> 
      
      </ol>
    </nav>
      <div className="mx-auto max-w-screen-xl mt-4 mb-4">
        <div className="relative bg-cover bg-center h-80 md:h-96 flex items-center overflow-hidden">
          <div className="absolute inset-0 bg-black opacity-20"></div>
          <div className="absolute inset-0 z-10 flex flex-col items-center justify-center text-white text-center">
            <h1 className="text-3xl md:text-4xl lg:text-7xl font-bold mb-4">We're Here to Listen!</h1>
            <p className="text-sm md:text-base lg:text-lg mb-6">
            Your feedback shapes the future of TheFinMaster
            </p>
           
          </div>
          <img className="absolute inset-0 object-cover w-full h-full" src={backgroundImageUrl} alt="Background" />
        </div>
      </div>

      <div className="container mx-auto px-4 md:px-8 lg:px-12 xl:px-20 mt-8 mb-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-semibold mb-4">Have Questions? We've Got Answers. </h2>
              <div>
                {faqs.map((faq, index) => (
                  <div key={index} className="mb-4">
                    <button
                      className="flex justify-between w-full bg-gray-100 py-3 px-4 rounded-lg focus:outline-none hover:bg-gray-200 transition duration-300"
                      onClick={() => toggleFAQ(index)}
                    >
                      <span className="text-lg font-medium">{faq.question}</span>
                      <svg
  xmlns="http://www.w3.org/2000/svg"
  className={`h-6 w-6 transition-transform transform ${activeIndex === index ? 'rotate-180' : ''}`}
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
</svg>
                    </button>
                    {activeIndex === index && (
                      <p className="text-gray-700 mt-2 text-center">{faq.answer}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-6">Contact Us</h2>
            <form onSubmit={handleSubmit}
            className = "space-y-4">
              <div className="mb-4">
                <input   
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange} 
                        className="appearance-none border rounded w-full py-3 px-4 text-gray-800 leading-tight focus:outline-none focus:border-blue-500 focus:shadow-outline"
                        required
                        />
              </div>
              <div className="mb-4">
              <input 
              type="text"
                    name="subject"
                    placeholder='Subject'
                    value={formData.subject}
                    onChange={handleChange}
                    className="appearance-none border rounded w-full py-3 px-4 text-gray-800 leading-tight focus:outline-none focus:border-blue-500 focus:shadow-outline"
                    required
                    />
                    </div>
              <div className="mb-4">
                <input 
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="appearance-none border rounded w-full py-3 px-4 text-gray-800 leading-tight focus:outline-none focus:border-blue-500 focus:shadow-outline" 
                required
                />
              </div>
              <div className="mb-4">
                <input 
                type="tel"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (!/\d/.test(e.key) && e.key !== 'Backspace') {
                 e.preventDefault();
                 }
                 }}
                className="appearance-none border rounded w-full py-3 px-4 text-gray-800 leading-tight focus:outline-none focus:border-blue-500 focus:shadow-outline" 
                required
                />
              </div>
              <div className="mb-4">
                <textarea 
                 name="message"
                 value={formData.message}
                 onChange={handleChange}
                 className="appearance-none border rounded w-full py-3 px-4 text-gray-800 leading-tight focus:outline-none focus:border-blue-500 focus:shadow-outline" 
                 placeholder='Write Your Messege'
                 rows="4"
              >

              </textarea>
              </div>
              <button type="submit" 
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-full focus:outline-none focus:shadow-outline w-full"  disabled={loading}>
                 {loading ? "Sending..." : "Submit "}
              </button>
            </form>
            {error && <p>{error}</p>}
                {success && <p>We have received your query. Our team will contact you soon</p>}
          </div>
        </div>
      </div>
      <Newsletter />
     {/* <div className="px-4 sm:px-8 lg:px-10 mb-8">
    <div className="w-full py-8 text-white bg-blue-600 rounded-lg shadow-lg">
        <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row items-center lg:justify-between">
            <div className="flex flex-col lg:flex-row items-center mb-4 lg:mb-0 lg:mr-4">
                <img src="../assets/newsletter.jpeg" alt="icon" class="w-48 h-40 mb-4 lg:mb-0 lg:mr-4" />
                <div className="text-center lg:text-left max-w-full lg:max-w-[60%]">
                    <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 whitespace-normal lg:whitespace-nowrap">
                        Never miss out on exclusive offers and updates!
                    </h1>
                    <p class="text-lg sm:text-xl">Sign up now to receive personalized deals and stay in the loop.</p>
                </div>
            </div>
            <form onSubmit={handleNewsletterSubmit} className="flex flex-col sm:flex-row items-center w-full lg:w-auto mt-4 lg:mt-20 lg:mr-4">
            <input
                className="flex-grow lg:flex-grow-0 rounded-md text-black p-2 mb-4 sm:mb-0 sm:mr-4"
                type="email"
                placeholder="Enter Email"
                value={newsletterEmail}
                onChange={(e) => setNewsletterEmail(e.target.value)}
                required
              />
                <button 
                 type="submit" 
                className="rounded-md font-medium w-auto lg:w-[200px] px-6 py-3 text-white bg-black hover:bg-gray-900"
                  
                    disabled={newsletterLoading}>
                       {newsletterLoading ? "Subscribing..." : "Notify Me"}
                </button>
                {newsletterError && <p className="text-red-500 mt-2">{newsletterError}</p>}
                {newsletterSuccess && <p className="text-green-500 mt-2">Thank you for subscribing!</p>}
            </form>
           
        </div>
    </div>
</div> */}
      </>
  );
};

export default ContactPage;



// import React from "react";
// import { AiFillInstagram } from "react-icons/ai";
// import {
//   BsFacebook,
//   BsFillSendFill,
//   BsTwitter,
//   BsWhatsapp,
// } from "react-icons/bs";
// import {
//   MdAttachEmail,
//   MdContactPhone,
//   MdOutlineLocationCity,
// } from "react-icons/md";
// import AccordionComponent from "../accordion/AccordionComponent";

// const ContactPage = () => {
//   return (
//     <div className="w-full flex flex-col items-center">
//       <div
//         className="w-full h-auto md:h-screen flex flex-col items-center bg-black text-white bg-cover bg-center"
//         style={{ backgroundImage: 'url("/bgcontact.jpg")' }}
//       >
//         <h1 className="text-4xl md:text-6xl py-10 text-white text-center">
//           CONTACT
//         </h1>

//         <div className="w-[90%] flex flex-col md:flex-row justify-between gap-5 md:gap-20 p-5">
//           <div className="w-full md:w-1/2">
//             <form className="flex flex-col gap-2">
//               <input
//                 className="w-full bg-none text-black "
//                 placeholder="NAME"
//                 type="text"
//                 required
//               />
//                <input
//                 className="w-full bg-none text-black "
//                 placeholder="PHONE NUMBER"
//                 type="number"
//                 required
//               />
//               <input
//                 className="w-full bg-none text-black "
//                 placeholder="EMAIL"
//                 type="email"
//                 required
//               />
//               <textarea
//                 className="w-full  text-black"
//                 rows="6"
//                 placeholder="MESSAGE"
//                 name="message"
//               ></textarea>
//               <button
//                 className="flex items-center justify-center gap-2 w-full md:w-auto px-8 py-4 bg-blue-600 text-white"
//                 id="submit"
//                 type="submit"
//                 value="SEND"
                
//               >
//                 <BsFillSendFill className="text-xl font-medium" />
//               </button>
//             </form>
//           </div>
//           <div className="w-full md:w-1/2">
//             <div className="flex flex-col gap-8">
//               <div className="flex items-center gap-5 text-lg md:text-xl text-gray-200">
//                 <MdOutlineLocationCity className="text-3xl text-blue-600" />
//                 <p className="">Gurugram, Sector-44</p>
//               </div>
//               <div className="flex items-center gap-5 text-lg md:text-xl text-gray-200">
//                 <MdContactPhone className="text-3xl text-blue-600" />
//                 <p className="">+ (91) 9876543210</p>
//               </div>
//               <div className="flex items-center gap-5 text-lg md:text-xl text-gray-200">
//                 <MdAttachEmail className="text-3xl text-blue-600" />
//                 <p className="">finance@FinanceEaseHub</p>
//               </div>
//             </div>

//             <div className="flex flex-col gap-5 mt-10">
//               <hr className="border-gray-500" />
//               <div className="flex items-center justify-evenly gap-5 text-lg md:text-2xl">
//                 <div className="w-12 h-12 md:w-16 md:h-16 rounded-full border flex items-center justify-center hover:text-blue-600 hover:border-blue-600">
//                   <AiFillInstagram className="text-3xl" />
//                 </div>
//                 <div className="w-12 h-12 md:w-16 md:h-16 rounded-full border flex items-center justify-center hover:text-blue-600 hover:border-blue-600">
//                   <BsTwitter className="text-3xl" />
//                 </div>
//                 <div className="w-12 h-12 md:w-16 md:h-16 rounded-full border flex items-center justify-center hover:text-blue-600 hover:border-blue-600">
//                   <BsFacebook className="text-3xl" />
//                 </div>
//                 <div className="w-12 h-12 md:w-16 md:h-16 rounded-full border flex items-center justify-center hover:text-blue-600 hover:border-blue-600">
//                   <BsWhatsapp className="text-3xl" />
//                 </div>
//               </div>
//               <hr className="border-gray-500" />
//               <p className="text-gray-200">@ALL OF THE RIGHTS RESERVED</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="w-[95%] py-10">
//         <h1 className="text-center font-bold text-5xl my-10">
//           Frequently Asked Questions
//         </h1>
//         <AccordionComponent />
//       </div>
//     </div>
//   );
// };

// export default ContactPage;
