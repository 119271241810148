import React, { useState } from 'react';
import AccordionComponent from '../accordion/AccordionComponent'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}
function HdfcBankMillennia() {
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);


    // Function to get current date
    // const getCurrentDate = () => {
    //     const date = new Date();
    //     const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //     return date.toLocaleDateString('en-US', options);
    // };
    const [activeIndex, setActiveIndex] = useState(0);

    const faqs = [
        {
            question: 'How do I check the status of my Millennia Credit Card application?',
            answer:
            'To check the status of your HDFC Millennia Credit Card application, visit the following link: [Millennia Credit Card Application Status](https://leads.hdfcbank.com/applications/webforms/apply/cc_track_revamp/index.aspx). Enter your 16-digit application reference number along with your mobile number or date of birth to view the status.',
        },
        {
            question: 'Will I get cashback benefits if I make purchases on equated monthly installments (EMIs)?',
            answer:
                'Yes, all transactions made through equated monthly installments (EMIs) using the HDFC Millennia Credit Card are eligible for cashback benefits.',
        },
        {
            question: 'What happens if I do not redeem my CashPoints?',
            answer:
                'Your CashPoints will expire if not redeemed within 1 year of accumulation. It is advisable to utilize your points before they expire.',
        },
        {
            question: 'Can I use the HDFC Bank Millennia Credit Card to withdraw cash?',
            answer:
                'Yes, you can withdraw cash from any ATM using the Millennia Credit Card. A transaction fee of 2.5% on the withdrawn amount will be applicable and will reflect in your subsequent credit card statement.',
        },
        {
            question: 'What should I do if I lose my credit card?',
            answer:
                'If your HDFC Credit Card is lost or stolen, immediately contact HDFC Credit Card Customer Care or log in to your HDFC credit card net banking account to report the incident and block the card.',
        },
        {
            question: 'When should I claim the voucher received for the quarterly Spends Milestone?',
            answer:
                'You must claim your voucher within 60 days of receiving the winner communication for the Quarterly Spends Milestone. Details regarding the claiming process will be provided in the SMS and Email sent to you.',
        },
        {
            question: 'Can I continue to access the lounge if I have already availed Lounge access using the Millennia credit card in the past?',
            answer:
                'Yes, you can still access the lounge by presenting your Millennia Credit Card, even if you have previously used it for lounge access. Additional charges may apply as per applicable rates.',
        },
        {
            question: 'How many vouchers can I claim upon achieving the quarterly Spends Milestone program or Benefit?',
            answer:
                'You can claim one voucher of your choice from the available brands upon achieving the Quarterly Spends Milestone program or Benefit.',
        },
    ];

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };
    return (
        <>
<Helmet>
      <title>HDFC Millennia Credit Card - Key Features & Benefits</title>
      <meta  name='description' content='Explore the features, benefits, cashback, travel, dining privileges & redemption process of the HDFC Bank Millennia Credit Card. Check eligibility & fees.'/>
      <meta name='keywords' content='hdfc millennia credit card apply, hdfc millennia card, hdfc millennia debit card benefits' />
      <link rel="canonical" href="https://thefinmaster.com/credit-card/hdfc-bank/millennia-credit-card" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
            <div className='w-[95%] py-10 px-10'>
                <div className="mx-auto ml-4 border border-gray-300 rounded-md mt-4  mb-6 ">
                    <div className="flex justify-between items-center px-4 py-2 mt-4 text-black">
                        {/* Left Side */}
                        <div className="flex items-center">
                            <h1 className="text-xl font-bold">HDFC Bank Millennia Credit Card</h1>
                        </div>

                        {/* Right Side */}
                        <div className="flex items-center ">
                            {/* Replace 'your_link_here' with your actual link */}

                            <a href="https://www.hdfcbank.com/personal/pay/cards/millennia-cards/millennia-cc-new" target="_blank"  rel="noopener noreferrer" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>

                        </div>


                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        {/* Right Side - Image */}
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/hdfc.webp" alt="IDFC Bank" className="w-full" />
                            {/* Rating Component */}
                            <div className="flex items-center mr-4 text-lg ml-4">
                                {/* You can replace the static stars with your actual rating logic */}
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>  
                        </div>

                        {/* Left Side - Headings */}
                        <div className="md:w-1/3 flex flex-col justify-between mb-4 md:mb-0 ">
                            <div className="mb-2 ">
                                <h2 className="text-lg font-semibold text-blue-600">Fees</h2>
                                <p className='text-gray-600'>Joining Fee: Rs. 1,000 + GST</p>
                                <p className='text-gray-600'>Annual Fee: Rs. 1,000 + GST</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg font-semibold text-blue-600">Welcome Benefits</h2>
                                <p className='text-gray-600'>1,000 CashPoints</p>
                            </div>
                        </div>



                        {/* Right Side - Content */}
                        <div className="md:w-1/3 md:ml-6">

                            <div>
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p className='text-gray-600'>Shopping</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p className='text-gray-600'>5% cashback*, 1% cashback*</p>
                            </div>
                        </div>
                    </div>
                    {/* New Div with Border Line */}
                    <div className="border-t border-gray-300 mt-4">
                        {/* Content */}
                        <div className="text-xl md:text-2xl lg:text-1xl font-bold  mt-3 ml-4">Rewards and Benefits</div>
                        <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                            {/* Left Side - Headings */}
                            <div className="md:w-1/2 mb-4 md:mb-0">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600"> Rewards Rate</h2>
                                    <p className='text-gray-600'>Get 5% cashback in CashPoints at select partner online merchants and 1% cashback in CashPoints on all other spending (excluding fuel, rental, and government-related transactions).</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Travel</h2>
                                    <p className='text-gray-600'>Enjoy one free airport lounge visit when you spend Rs. 1 lakh in a quarter (Milestone Benefit).</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Movie and Dining</h2>
                                    <p className='text-gray-600'>Get up to 20% off at partner restaurants with Swiggy Dineout.</p>
                                </div>
                            </div>
        
                            {/* Right Side - Headings */}
                            <div className="md:w-1/2 md:ml-6">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">International Lounge Access</h2>
                                    <p className='text-gray-600'>Nil</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Zero Liability Protection</h2>
                                    <p className='text-gray-600'>The cardholder bears no liability for fraudulent transactions in case of credit card loss or theft, provided it is reported to the bank's customer care within 7 days.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Reward Redemption</h2>
                                    <p className='text-gray-600'>Rewards are processed as CashPoints, where 1 CashPoint equals Re. 1 when redeeming against your credit card statement balance, and 1 CashPoint equals Rs. 0.30 when redeeming for products, vouchers, or flight/hotel bookings in the product catalogue.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-300 mt-4" />
                    <Accordion open={open === 1} className='px-3' icon={<Icon id={1} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(1)}>Fees & Charges</AccordionHeader>
                        <AccordionBody>
                            <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                                {/* Left Side - Headings */}
                                <div className="md:w-1/2 mb-4 md:mb-0">
                                    
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Interest Rates</h2>
                                        <p className='text-gray-600 text-base'>3.6% per month (or 43.2% per annum)</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Rewards Redemption Fee</h2>
                                        <p className='text-gray-600 text-base'>Nil</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Fuel Surcharge
                                        </h2>
                                        <p className='text-gray-600 text-base'>A 1% fuel surcharge is waived for fuel purchases between Rs. 400 and Rs. 5,000, with a maximum waiver of Rs. 250 per statement cycle.</p>
                                    </div>
                                </div>

                                {/* Right Side - Headings */}
                                <div className="md:w-1/2 md:ml-6">
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Foreign Currency Markup</h2>
                                        <p className='text-gray-600 text-base'>3.5% (plus taxes)</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Waiver Based on Spending</h2>
                                        <p className='text-gray-600 text-base' >The renewal fee is waived if the cardholder spends Rs. 1 lakh or more in the previous year. </p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Add-on Card Fee</h2>
                                        <p className='text-gray-600 text-base'>Nil</p>
                                    </div>
                                </div>
                                {/* Right Side - Headings */}
                                {/* <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div> */}
                            </div>

                        </AccordionBody>
                    </Accordion>
                    <Accordion className='px-3' open={open === 2} icon={<Icon id={2} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(2)}>
                            Card Details
                        </AccordionHeader>
                        <AccordionBody>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Receive 1,000 CashPoints as a welcome benefit when your card is set up (subject to realization of the joining fee).
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Get gift vouchers worth Rs. 1,000 or a free domestic airport lounge visit by spending Rs. 1 lakh or more in a quarter.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Enjoy 5% cashback at select partner online merchants, including popular e-commerce websites like Amazon and Flipkart.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Get 1% cashback on all other spending, excluding fuel, rental, and government-related transactions.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Save up to 20% at partner restaurants through Swiggy Dineout.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Have your renewal fee waived by spending Rs. 1,00,000 in the previous year.
                    </p>
                   
                                    {/* <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Left Heading 2</h2>
                            <p>Description for left heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font- text-blue-600">Left Heading 3</h2>
                            <p>Description for left heading 3</p>
                        </div> */}
                            

                                {/* Right Side - Headings */}
                                {/* <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div>
                      {/* Right Side - Headings *
                      <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div> */}
                            

                        </AccordionBody>
                    </Accordion>

                </div>
                <div className=" mx-auto ">
                <div className="text-xl md:text-2xl lg:text-4xl font-bold mt-8 mb-4 ml-4">HDFC Bank Millennia Credit Card Detail</div>
                <div className="text-sm md:text-base lg:text-lg text-gray-600 mb-8">
                    {/* <p className="float-left">{getCurrentDate()}</p> */}
                    {/* <p className="float-right font-bold mr-4 ">{getCurrentDate()}</p> */}
                </div>
                <p className='  px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg '>

                The HDFC Bank Millennia Credit Card is a cashback card designed for millennials and Gen-Y, with an annual fee of Rs. 1,000. It offers 5% cashback on popular platforms like Amazon, Flipkart, Myntra, Swiggy, and Uber, and 1% cashback on all other online and offline spending. 

                </p>
                <p className=' px-4 mt-2  text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg'>

                CashPoints, the card's reward system, can be redeemed against statement balances, for flights/hotels, or for products from HDFC's catalog. Additionally, the card provides great gift vouchers and one free airport lounge visit when you meet the quarterly milestone of Rs. 1 lakh in spending.
                </p>
                
            </div>
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of HDFC Bank Millennia Credit Card</h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Cardholders receive 1,000 bonus CashPoints as a welcome gift upon paying the joining fee. Dining benefits include up to 20% off at partner restaurants through Swiggy Dineout. The quarterly spend benefit rewards spending Rs. 1 lakh or more per quarter with gift vouchers worth Rs. 1,000, totaling Rs. 4,000 annually, and also provides four complimentary visits to domestic airport lounges. </p>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">The card offers a 1% fuel surcharge waiver for transactions over Rs. 400, capped at Rs. 250 per payment cycle. Additionally, the annual fee is waived if spending exceeds Rs. 1 lakh within an anniversary year. The credit card provides an interest-free credit period of up to 50 days, depending on when the merchant processes the charge.</p>
                </div>
                {/* <div className="container mx-auto px-4 py-2 ">
                    <h1 className="text-2xl font-bold pl-4 py-2">New UI Heading</h1>
                    <p className="text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> 
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600 ">&#8226;</span>
                        Responsive paragraph text. This text will adjust based on the screen size.
                    </p>
                    <p className="text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> 
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> 
                        Responsive paragraph text. This text will adjust based on the screen size.
                    </p>
                </div> */}
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Cashback & Reward Points</h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg pl-6 ">Cashback earned with the HDFC Millennia Credit Card is credited as CashPoints to your account, which can be redeemed for various options, including offsetting your credit card balance.
</p>

                    
                  
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-4"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        5% cashback on partner online merchants like Amazon, BookMyShow, Cult.fit, Flipkart, Myntra, Sony LIV, Swiggy, Tata CLiQ, Uber, and Zomato.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        1% cashback on all other online and offline purchases.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        The CashPoints earned from the 5% and 1% cashback categories are capped at 1,000 CashPoints per statement cycle each, with a maximum of 2,000 CashPoints per statement cycle.
                    </p>
                    
                    
                  
                    <h1 className="text-2xl font-bold pl-4 py-2 mt-2">Rewards Point Redemption :</h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg pl-6 ">As previously mentioned, the cashback earned with the HDFC Millennia Credit Card is credited to your card account as CashPoints at the end of each statement cycle. You can redeem these CashPoints in several ways, including as cashback (by applying them against your credit card statement balance), booking flights and hotels through SmartBuy, purchasing items from HDFC's product catalog, or converting them into partner airlines' AirMiles.</p>

                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-4"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        You need at least 500 CashPoints to redeem against your credit card's statement balance.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        CashPoints have a validity of 2 years and will expire afterward.
                    </p>
                    
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Each redemption request incurs a fee of Rs. 99.
                    </p>
                  
                    <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How To Apply For The HDFC Bank Millennia Credit Card? </h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
                    Follow these simple steps to apply for an HDFC Millenia Credit Card.

                    </p>
               
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Click the "Apply Now" button on this page.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        You'll be redirected to HDFC's credit card application page.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Complete the required form to submit your application.
                    </p>
                    
                    </div>
                    
                </div>
                
            </div>
            <div className="max-w-4xl mx-auto py-6">
            <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                        <button
                            onClick={() => toggleAccordion(index)}
                            className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                        >
                            {faq.question}
                            <svg
                                className={`w-6 h-6 transition-transform transform ${
                                    activeIndex === index ? 'rotate-180' : ''
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d={activeIndex === index ? 'M19 9l-7 7-7-7' : 'M9 5l7 7 7-7'}
                                />
                            </svg>
                        </button>
                        {activeIndex === index && (
                            <div className="p-4 bg-gray-100">
                                <p className="text-gray-600">{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
            {/* <div className='w-[95%] py-10 px-10'>
                <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
                <AccordionComponent />
            </div> */}

        </>



    );
}

export default HdfcBankMillennia;