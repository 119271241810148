import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const RelianceHealthLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: 'What is the cancellation process for a Reliance Health Insurance plan?',
        answer:
            'To cancel your Reliance Health Insurance plan, you must submit the policy document along with a surrender form to the nearest branch. Upon submission, the refund amount will be credited to the policyholder respective bank account.',
    },
    {
        question: 'What payment modes are available for Reliance Health Insurance plan premiums?',
        answer:
            'You can make premium payments for Reliance Health Insurance plan through various modes including credit card, debit card, and net banking.',
    },
    {
        question: 'What factors determine the premium amount for my health insurance policy?',
        answer:
            ' Key factors influencing the premium amount include the plan category, insured persons age, geographical location, number of members covered, and the insured persons medical history.',
    },
    {
      question: 'Is a medical test required before purchasing health insurance?',
      answer:
          'No, a medical test is generally not required before purchasing health insurance unless the insured person is over 45 years old. In such cases, the insurance provider may request medical test reports.x',
  },
  {
    question: 'How can I check the status of my health insurance claim?',
    answer:
        'To track the status of your health insurance claim, visit the insurance providers official website, navigate to the Lodge a Claim section, enter required details such as name, policy number, customer ID, and submit the form to track your claim status.',
},
{
  question: 'What is the recommended coverage amount for health insurance plans?',
  answer:
      'While there is no ideal coverage amount, insurance providers typically suggest coverage equivalent to double your annual income.',
},
{
  question: 'From what age can children be included under my health insurance plan?',
  answer:
      'Children can be included in your health insurance plan starting from three months of age up to when the policyholder turns 25 years old.',
},
{
  question: 'What factors determine the premium amount for my health insurance policy?',
  answer:
      ' Key factors influencing the premium amount include the plan category, insured persons age, geographical location, number of members covered, and the insured persons medical history.',
},
{
  question: 'What factors determine the premium amount for my health insurance policy?',
  answer:
      ' Key factors influencing the premium amount include the plan category, insured persons age, geographical location, number of members covered, and the insured persons medical history.',
},
{
  question: 'What factors determine the premium amount for my health insurance policy?',
  answer:
      ' Key factors influencing the premium amount include the plan category, insured persons age, geographical location, number of members covered, and the insured persons medical history.',
},
{
  question: ' How can I increase the sum insured on my existing health insurance plan?',
  answer:
      ' You can increase the sum insured on your existing health insurance plan by opting for a top-up plan or purchasing an additional health insurance policy.',
},
{
  question: 'How do I initiate a policy cancellation with Reliance Health Insurance?',
  answer:
      ' To cancel your policy with Reliance Health Insurance, write a letter addressed to Reliance General Insurance indicating your intention to cancel. Upon receipt, the premium amount will be refunded, provided the policy is less than 6 months old and no claims have been lodged.',
},
{
  question: 'Is there a deadline for submitting reimbursement claims forms?',
  answer:
      ' It is recommended to submit reimbursement claims forms promptly. These forms can be submitted at Reliance General Insurance offices or through their Rcare Team.',
},
    
  ];
   
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
        <Helmet>
         <title>Reliance Health Insurance - Check Plans, Benefits &amp; Reviews | TheFinmaster</title>
        <meta  name='description' content='Reliance General Health Insurance Plans, features and benefits, compare with other general insurance companies in India in few minutes. Get the best medical insurance plan online now. | TheFinmaster.'/>
        <meta name='keywords' content='reliance health insurance plans, reliance mediclaim policy, reliance infinity health insurance, reliance critical illness insurance' />
        <link rel="canonical" href="https://thefinmaster.com/health-insurance/reliance-health-insurance" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <FAQSchema faqs={faqs} />
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/health-insurance" className="text-blue-600 hover:text-blue-800">Health Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Reliance Health Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Reliance Health Insurance: Be prepared for life's uncertainties. 
                  </span>{" "}
                  Stay healthy
                </h1>
                <a href="https://www.reliancegeneral.co.in/insurance/health-insurance/health-insurance-plan.aspx"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Open Account!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/reliancehealth1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about Reliance Health Insurance</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> Reliance Health Insurance prioritizes accessibility and affordability, providing inclusive health insurance solutions that address the evolving needs of customers. With a vast network of hospitals facilitating cashless hospitalization, Reliance Health Insurance ensures prompt access to quality healthcare services.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Their plans offer comprehensive coverage, encompassing essential benefits such as maternity coverage, specialized OPD benefits tailored for specific conditions, and efficient ambulance services. By focusing on accessibility and affordability, Reliance Health Insurance aims to empower individuals and families to safeguard their health without financial strain. </p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/policy.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            3 crore+ policyholders

            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/hospital.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            10,000+ Cashless Hospitals

            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            100% Claims Settled



            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/crore.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            3 Lakhs - 5 Crore Sum Assured



            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/bonus.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Up to 100% Cumulative Bonus

            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/money.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Up to ₹1 Lakh Tax Benefits* under Section 80D

            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>


    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Explore Reliance's Health Insurance Plans</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Here are some top insurance plans provided by Reliance. Click the link below to discover all plans offered by the brand.</p>
            </div>
            
            <div class="container mx-auto px-4 sm:px-6 lg:px-8 mt-4">
  <div class="flex flex-wrap justify-between -mx-2">
  
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/reliance1.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Reliance Health Infinity Insurance</h2>
          <ul class="list-disc list-inside text-gray-700 mb-4">
          <p className='font-bold'>A Limitless Policy with Limitless Benefits</p>
        <li className='mt-4'>Enhanced Sum Insured</li>
        <li>Unlimited Restore Benefit</li>
        <li>No Sub-Limits on Inpatient Care
</li>
        <li>Discount Up to 35%</li>
      </ul>
   
      <Link to="https://www.reliancegeneral.co.in/insurance/health-insurance/health-infinity-insurance-plan.aspx">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>

    
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/reliance2.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Reliance Health Gain Insurance</h2>
          <ul class="list-disc list-inside text-gray-700 mb-4">
          <p className='font-bold'>A No-Compromise Health Policy</p>
        <li className='mt-4'>Double Sum Insured</li>
        <li>No Room Rent Limit</li>
        <li>Loyalty Cover</li>
        <li>Discount Up to 40%</li>
      </ul>


      <Link to="https://www.reliancegeneral.co.in/insurance/health-insurance/health-gain-insurance-plan.aspx">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>

   
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/reliance3.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Reliance Super Top-up Health Insurance</h2>
          <ul class="list-disc list-inside text-gray-700 mb-4">
          <p className='font-bold'>A Backup For Your Medical Emergencies</p> 
        <li className='mt-4'>Maternity Cover</li>
        <li>Worldwide Emergency Cover</li>
        <li>No Claim Bonus</li>
        <li>Discount Up to 35%</li>
    
      </ul>
      <Link to="https://www.reliancegeneral.co.in/insurance/health-insurance/super-top-up-health-insurance-plan.aspx">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
<Link to="https://www.reliancegeneral.co.in/insurance/health-insurance/health-insurance-plan.aspx">
<h1 className="text-2xl font-semibold text-center mb-8 text-black italic underline flex items-center justify-center">
  View all Plans
  <svg
    className="ml-2 w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5l7 7-7 7"
    ></path>
  </svg>
</h1></Link>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            

        </>
    )
}

export default RelianceHealthLearn
