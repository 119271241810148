import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const CareHealthLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: 'What advantages come with purchasing Care health insurance?',
        answer:
            'Care health insurance provides coverage for various medical expenses including nursing care, surgeries, consultation fees, cashless hospitalization for stays exceeding 24 hours, diagnostic tests, hospital accommodation, and more. The insurer covers all costs related to your entire course of treatment.',
    },
    {
        question: 'What does the Care Health Insurance plans co-payment mean?',
        answer:
            'Co-payment in Care Health Insurance refers to a portion of the claim amount that the policyholder is required to pay after the claim is settled. Co-payment can be either a fixed dollar amount or a percentage of the insured amount.',
    },
    {
        question: 'What does the Care Health Family Floater plan mean?',
        answer:
            ' The Care Family Floater plan offers health insurance coverage for the entire family including the policyholder, dependent children, spouse, and parents under a single premium. Multiple claims can be submitted as long as the total insurance coverage does not exceed the permissible limit.',
    },
    {
      question: 'Is it feasible to change the policy covered amount, either up or down?',
      answer:
          ' The sum insured under the primary policy cannot be increased or decreased during the policy term. Changes to the sum insured can be made at the time of policy renewal, subject to the terms and conditions of the policy.',
  },
  {
    question: ' Explain the Claim Processing and Reimbursement under Care Health Insurance Policy?',
    answer:
        ' For emergency hospitalization, inform the insurer within 24 hours of admission. For planned hospitalization, notify the insurer at least 48 hours in advance. To file a reimbursement claim, download the claim form from the insurers website, fill it, and submit it along with necessary documents (discharge summary, hospital bills, prescriptions) to the nearest branch office. The claim management team will review and send an approval or rejection letter. The approved claim amount will be transferred to the policyholders bank account.',
},
{
  question: 'How does one submit a claim for cashless hospitalization?',
  answer:
      ' For cashless hospitalization, inform the insurer within 24 hours of emergency admission or 48 hours before planned admission. Fill out the pre-authorization request form at the hospital desk and the network hospital will send it to the insurer. The claim management team will review and provide an approval or denial letter. If denied, you may start treatment and apply for reimbursement.',
},
{
  question: 'How can one cancel his or her Care Health Insurance Policy?',
  answer:
      ' During the free-look period, policyholders can cancel the policy and receive a refund of the premium after deductions, if they disagree with the policy terms. Cancellation requests can be made at any time during the policy period provided no claims have been made. If claims have been made and the policy is cancelled, premiums will not be refunded.',
},
    
  ];
    
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
         <title>Care Health Care Plan (Religare): Benefits, Premium, Online Renewal</title>
        <meta  name='description' content='Get Care Plan by Care Health Insurance (Religare) online for interesting benefits that closely meets your needs. It covers nursing, boarding expenses & more. Get a Quote Now | TheFinmaster.'/>
        <meta name='keywords' content='care health care plan, care health insurance, health care plans' />
        <link rel="canonical" href="https://thefinmaster.com/health-insurance/care-health-insurance" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <FAQSchema faqs={faqs} />
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/health-insurance" className="text-blue-600 hover:text-blue-800">Health Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Care Health Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Care for your health, care for your future.
                  </span>{" "}
                  Choose Care Health Insurance.
                </h1>
                <a href="https://www.careinsurance.com/health-insurance/"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Open Account!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/carehealth1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about Care Health Insurance</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> Care Health Insurance is synonymous with reliability and excellence in providing tailored health insurance solutions to meet the diverse needs of individuals and families. Their comprehensive plans offer extensive coverage for hospitalization expenses, critical illnesses, maternity benefits, and preventive health check-ups, ensuring holistic protection for policyholders.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Care Health Insurance is renowned for its exceptional customer service, with a dedicated team committed to delivering prompt and hassle-free claim settlement experiences. By prioritizing customer satisfaction and peace of mind, Care Health Insurance establishes itself as a trusted partner in safeguarding the health and financial well-being of its customers.</p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/hospital.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            24,800+ Cashless Healthcare Providers
            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/life.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            7 Crore+ Lives Covered


            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/support.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            24*7 Claims & Customer Support




            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            48 Lacs+ Claims Settled




            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/money.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Upto 75k Tax Benefits Under Section 80D


            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/crore.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Get a sum insured of as high as 6 Cr.


            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>


    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Explore CARE's Health Insurance Plans</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Here are some basic insurance plans provided by Care. Click the link below to discover all plans offered by the brand.</p>
            </div>
            
            <div class="container mx-auto px-4 sm:px-6 lg:px-8 mt-4">
  <div class="flex flex-wrap justify-between -mx-2">
  
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/care1.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Family Health Insurance</h2>
          <ul class="list-disc list-inside text-gray-700 mb-4">
          <p className='font-bold'>Health coverage for your loved ones</p>
        <li className='mt-4'>Annual Health Checkup and Save Tax</li>
        <li>Modern Treatments Coverage</li>
      
    
      </ul>
   
      <Link to="https://www.careinsurance.com/product/care-supreme">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>

    
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/care2.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Maternity Health Plan</h2>
          <ul class="list-disc list-inside text-gray-700 mb-4">
          <p className='font-bold'> Maternity Insurance with Newborn Baby Cover</p>
        <li className='mt-4'>Newborn Baby Cover up to the first 90 days</li>
        <li>Pre & Post Hospitalization Cover</li>
       
      </ul>


      <Link to="https://www.careinsurance.com/product/joy">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>

   
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/care3.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">1 Crore Health Plan</h2>
          <ul class="list-disc list-inside text-gray-700 mb-4">
          <p className='font-bold'> Health insurance with increased sum insuredp</p>
        <li className='mt-4'>Upto 50% No Claim Bonus</li>
        <li>Individual or Floater Option</li>
    
    
      </ul>
      <Link to="https://www.careinsurance.com/product/care-advantage">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
<Link to="https://www.careinsurance.com/health-insurance/">
<h1 className="text-2xl font-semibold text-center mb-8 text-black italic underline flex items-center justify-center">
  View all Plans
  <svg
    className="ml-2 w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5l7 7-7 7"
    ></path>
  </svg>
</h1></Link>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            

        </>
    )
}

export default CareHealthLearn
