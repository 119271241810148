import React, { useState } from 'react';
import AccordionComponent from '../accordion/AccordionComponent'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}
function EasyDinerBank() {
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);


    // Function to get current date
    // const getCurrentDate = () => {
    //     const date = new Date();
    //     const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //     return date.toLocaleDateString('en-US', options);
    // };
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: 'What should I do if I lose my EazyDiner credit card?',
            answer:
            'If your IndusInd EazyDiner Credit Card is lost, promptly contact the banks customer care service to report the loss and request for card blocking. A replacement card will be issued by the bank.',
        },
        {
            question: 'Is the IndusInd EazyDiner Credit Card contactless?',
            answer:
                'Yes, the IndusInd EazyDiner Credit Card features contactless technology, allowing you to make transactions securely without physical contact with the card reader.',
        },
        {
            question: 'Is it safe to make online transactions using the IndusInd EazyDiner Credit Card?',
            answer:
                'Yes, transactions conducted online using the IndusInd EazyDiner Credit Card are secure and protected against unauthorized use.',
        },
        {
            question: 'Can I use the IndusInd EazyDiner Credit Card to pay my utility bills?',
            answer:
                'Yes, you can use your IndusInd EazyDiner Credit Card to conveniently pay your utility bills.',
        },
        {
            question: 'What will happen if I do not pay my credit card bill on time?',
            answer:
                'Failure to pay your credit card bill on time will result in interest being charged on the outstanding amount. Continued non-payment may lead to cancellation of the card and negatively impact your credit score.',
        },
        {
            question: 'Can my spouse have an add-on card?',
            answer:
                'Yes, your spouse can avail of an add-on card facility linked to your IndusInd EazyDiner Credit Card account.',
        },
        {
            question: 'Will the add-on card have the same benefits as the primary credit card?',
            answer:
                'Yes, the add-on card will enjoy the same benefits and privileges offered under the IndusInd EazyDiner Credit Card.',
        },
        {
            question: 'Can I pay my credit card bill using net banking?',
            answer:
                'Yes, you can conveniently settle your IndusInd EazyDiner Credit Card bill through net banking facilities provided by the bank.',
        },
    ];

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };
    return (
        <>
<Helmet>
      <title>EazyDiner IndusInd Bank Credit Cards: Features, Benefits, Fees &amp;Charges</title>
      <meta  name='description' content='Apply for EazyDiner IndusInd Bank Credit Card or EazyDiner IndusInd Bank Platinum Credit Card &amp; avail free membership, welcome benefits, and much more.'/>
      <meta name='keywords' content='indusind bank lifetime free credit card, indusind bank platinum credit card, indusind eazydiner credit card, indusind next credit card, pinnacle credit card indusind' />
      <link rel="canonical" href="https://thefinmaster.com/credit-card/eazy-diner-indusind-bank-credit-card" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
            <div className='w-[95%] py-10 px-10'>
                <div className="mx-auto ml-4 border border-gray-300 rounded-md mt-4  mb-6 ">
                    <div className="flex justify-between items-center px-4 py-2 mt-4 text-black">
                        {/* Left Side */}
                        <div className="flex items-center">
                            <h1 className="text-xl font-bold">EazyDiner IndusInd Credit Card</h1>
                        </div>

                        {/* Right Side */}
                        <div className="flex items-center ">
                            {/* Replace 'your_link_here' with your actual link */}

                            <a href="https://www.indusind.com/in/en/personal/cards/credit-card/eazydiner-credit-card.html" target="_blank"  rel="noopener noreferrer" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>

                        </div>


                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        {/* Right Side - Image */}
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/easy.webp" alt="IDFC Bank" className="w-full" />
                            {/* Rating Component */}
                            <div className="flex items-center mr-4 text-lg ml-4">
                                {/* You can replace the static stars with your actual rating logic */}
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>  
                        </div>

                        {/* Left Side - Headings */}
                        <div className="md:w-1/3 flex flex-col justify-between mb-4 md:mb-0 ">
                            <div className="mb-2 ">
                                <h2 className="text-lg font-semibold text-blue-600">Fees</h2>
                                <p className='text-gray-600'>Joining Fee: Nil</p>
                                <p className='text-gray-600'>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg font-semibold text-blue-600">Welcome Benefits</h2>
                                <p className='text-gray-600'>3-Month EazyDiner Prime Membership and 500 Bonus EazyPoints</p>
                            </div>
                        </div>



                        {/* Right Side - Content */}
                        <div className="md:w-1/3 md:ml-6">

                            <div>
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p className='text-gray-600'>Food</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p className='text-gray-600'>Reward Points</p>
                            </div>
                        </div>
                    </div>
                    {/* New Div with Border Line */}
                    <div className="border-t border-gray-300 mt-4">
                        {/* Content */}
                        <div className="text-xl md:text-2xl lg:text-1xl font-bold  mt-3 ml-4">Rewards and Benefits</div>
                        <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                            {/* Left Side - Headings */}
                            <div className="md:w-1/2 mb-4 md:mb-0">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600"> Rewards Rate</h2>
                                    <p className='text-gray-600'>2 Reward Points/Rs. 100 Spent. 2X EazyPoints on Dining Spends</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Travel</h2>
                                    <p className='text-gray-600'>Nil</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Movie and Dining</h2>
                                    <p className='text-gray-600'>Get a 25% discount with EazyDiner and an extra 20% off your dining bill (up to Rs. 500).</p>
                                </div>
                            </div>
        
                            {/* Right Side - Headings */}
                            <div className="md:w-1/2 md:ml-6">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">International Lounge Access</h2>
                                    <p className='text-gray-600'>Nil</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Zero Liability Protection</h2>
                                    <p className='text-gray-600'>Cardholders are not liable for any fraudulent transactions if they report a lost or stolen card to the bank promptly.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Reward Redemption</h2>
                                    <p className='text-gray-600'>On EazyDiner App</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-300 mt-4" />
                    <Accordion open={open === 1} className='px-3' icon={<Icon id={1} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(1)}>Fees & Charges</AccordionHeader>
                        <AccordionBody>
                            <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                                {/* Left Side - Headings */}
                                <div className="md:w-1/2 mb-4 md:mb-0">
                                    
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Interest Rates</h2>
                                        <p className='text-gray-600 text-base'>3.83% Monthly and 46% annually.</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Rewards Redemption Fee</h2>
                                        <p className='text-gray-600 text-base'>Rs. 100</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Fuel Surcharge
                                        </h2>
                                        <p className='text-gray-600 text-base'>1% Fuel Surcharge Waiver</p>
                                    </div>
                                </div>

                                {/* Right Side - Headings */}
                                <div className="md:w-1/2 md:ml-6">
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Foreign Currency Markup</h2>
                                        <p className='text-gray-600 text-base'>3.5% of the Transaction Amount</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Waiver Based on Spending</h2>
                                        <p className='text-gray-600 text-base' >N/A</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Add-on Card Fee</h2>
                                        <p className='text-gray-600 text-base'>Nil</p>
                                    </div>
                                </div>
                                {/* Right Side - Headings */}
                                {/* <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div> */}
                            </div>

                        </AccordionBody>
                    </Accordion>
                    <Accordion className='px-3' open={open === 2} icon={<Icon id={2} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(2)}>
                            Card Details
                        </AccordionHeader>
                        <AccordionBody>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        This credit card is free for life, with no annual fees.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Get a complimentary 3-month EazyDiner Prime Membership as a welcome benefit
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Enjoy instant discounts on the EazyDiner app and website when you use this card.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Make contactless payments through EazyDiner and enjoy substantial discounts on your restaurant bills.
                    </p>
      {/* <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Left Heading 2</h2>
                            <p>Description for left heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font- text-blue-600">Left Heading 3</h2>
                            <p>Description for left heading 3</p>
                        </div> */}
                            

                                {/* Right Side - Headings */}
                                {/* <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div>
                      {/* Right Side - Headings *
                      <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div> */}
                            

                        </AccordionBody>
                    </Accordion>

                </div>
                <div className=" mx-auto ">
                <div className="text-xl md:text-2xl lg:text-4xl font-bold mt-8 mb-4 ml-4">EazyDiner IndusInd Credit Card Detail</div>
                <div className="text-sm md:text-base lg:text-lg text-gray-600 mb-8">
                    {/* <p className="float-left">{getCurrentDate()}</p> */}
                    {/* <p className="float-right font-bold mr-4 ">{getCurrentDate()}</p> */}
                </div>
                <p className='px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  '>

                The EazyDiner Platinum Credit Card from IndusInd Bank offers a guaranteed 25% discount on dining at a selection of premium restaurants. As a lifetime-free card, it has no joining or renewal fees, making it an excellent choice for young adults and frequent diners. The card is the second in collaboration with EazyDiner, a popular restaurant booking platform, following the EazyDiner Signature Credit Card.

                </p>
                <p className=' px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg '>

                With this card, enjoy substantial savings when dining at restaurants listed on the EazyDiner website and app, using PayEazy for payment. As a welcome benefit, you receive a complimentary 3-month EazyDiner Prime membership, with the option to renew by spending Rs. 90,000 each quarter.
                </p>
               
            </div>
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of EazyDiner IndusInd Credit Card</h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Get a guaranteed 25% discount on dining at top restaurants with the lifetime-free EazyDiner Platinum Credit Card from IndusInd Bank. This card offers no joining or renewal fees and provides exclusive dine-in benefits, making it ideal for those who love eating out. Enjoy a complimentary 3-month EazyDiner Prime membership as a welcome gift, with an option to renew by spending Rs. 90,000 each quarter.</p>
                    {/* <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">It provides a comprehensive range of credit card benefits and allows holders to select one or multiple features/categories simultaneously. This empowers customers to enjoy benefits from their chosen category while only paying for the features they use, rather than the entire annual card fees.</p> */}
                </div>
                {/* <div className="container mx-auto px-4 py-2 ">
                    <h1 className="text-2xl font-bold pl-4 py-2">New UI Heading</h1>
                    <p className="text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> 
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600 ">&#8226;</span>
                        Responsive paragraph text. This text will adjust based on the screen size.
                    </p>
                    <p className="text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> 
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> 
                        Responsive paragraph text. This text will adjust based on the screen size.
                    </p>
                </div> */}
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Cashback & Reward Points</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-4"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Earn 2 Reward Points for every INR 100 spent, except on fuel, insurance, rent, utilities, and government-related payments.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Earn 0.7 Reward Points for spending on insurance, rent, utilities, and government payments.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Get 2X EazyPoints when you use your EazyDiner Credit Card.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Reward Points Validity: These points are valid for one year.
                    </p>
                    <h1 className="text-2xl font-bold pl-4 py-2 mt-2">How to redeem rewards?</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Instant Restaurant Discounts: Redeem your reward points for immediate discounts on your restaurant bills.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        EazyPoints Redemption: Use your EazyPoints to enjoy free hotel stays, meals, and exclusive restaurant events across the country.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Redemption Platform: EazyPoints and reward points can only be redeemed through the EazyDiner app, not offline.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        No Cash Credit: Reward points cannot be redeemed for cash credit.
                    </p>
                    
                    
                    <h1 className="text-lg font-bold pl-4 py-2 mt-2">Unlock Exclusive Discounts with EazyDiner App.</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Use the EazyDiner app to book a table at restaurants that support EazyDiner PRIME and PayEazy.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        As an EazyDiner Platinum Credit Cardholder, you'll receive a complimentary PRIME Membership, granting you a 25% discount on your restaurant bill.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Enjoy an additional 20% discount (up to Rs. 500) when you use your EazyDiner Platinum Credit Card to pay via PayEazy on the EazyDiner app.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        This extra 20% discount does not apply if you pay directly at the restaurant.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        No promo code is needed; the discount is automatically applied within the EazyDiner app.
                    </p>
                   
                    
                    <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How To Apply For The EazyDiner IndusInd Credit Card</h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
                    Applying for the EazyDiner Platinum Credit Card is a breeze—just follow these simple steps:
                    </p>
            
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Click the "Apply Now" button on this page.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        You'll be redirected to Easy Diner credit card application page.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Complete the required form to submit your application.
                    </p>
                   
                  
                  
                    </div>
                    
                </div>
            </div>
            <div className="max-w-4xl mx-auto py-6">
            <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                        <button
                            onClick={() => toggleAccordion(index)}
                            className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                        >
                            {faq.question}
                            <svg
                                className={`w-6 h-6 transition-transform transform ${
                                    activeIndex === index ? 'rotate-180' : ''
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d={activeIndex === index ? 'M19 9l-7 7-7-7' : 'M9 5l7 7 7-7'}
                                />
                            </svg>
                        </button>
                        {activeIndex === index && (
                            <div className="p-4 bg-gray-100">
                                <p className="text-gray-600">{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
            {/* <div className='w-[95%] py-10 px-10'>
                <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
                <AccordionComponent />
            </div> */}

        </>



    );
}

export default EasyDinerBank;


