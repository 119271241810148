import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const GSTCalculator = () => {
    const [amount, setAmount] = useState(25000);
    const [taxSlab, setTaxSlab] = useState(12);
    const [isGstExclusive, setIsGstExclusive] = useState(true);

    const calculateGST = () => {
        let gstAmount;
        let postGstAmount;
        if (isGstExclusive) {
            gstAmount = (amount * taxSlab) / 100;
            postGstAmount = amount + gstAmount;
        } else {
            gstAmount = (amount * taxSlab) / (100 + taxSlab);
            postGstAmount = amount;
        }
        return {
            gstAmount,
            postGstAmount,
        };
    };

    const { gstAmount, postGstAmount } = calculateGST();

    return (
        <>
        <Helmet>
         <title>Online GST Calculator | TheFinmaster</title>
        <meta  name='description' content='GST Calculator - Calculate your GST amount using an online GST calculator. reduce errors and ensure accurate cost calculation for GST. | TheFinmaster'/>
        <meta name='keywords' content='gst calculation formula, gst tax calculator,easy gst calculator, calculate gst online, gst interest calculator, gst late fee calculator, gst rate finder' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/gst-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">GST Calculator</span>
        </li> 
      
      </ol>
    </nav>
            <div className='py-4'>
                <div className="container mx-auto p-4 max-w-8xl">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full lg:w-2/3 px-4">
                            <h1 className="text-2xl font-bold mb-4">GST Calculator</h1>
                            <div className="bg-white shadow-md rounded p-6">
                                <div className="flex items-center mb-4">
                                    <div className="flex items-center mr-4">
                                        <input
                                            type="radio"
                                            id="gstExclusive"
                                            name="gstType"
                                            checked={isGstExclusive}
                                            onChange={() => setIsGstExclusive(true)}
                                            className="mr-2"
                                        />
                                        <label htmlFor="gstExclusive" className="text-gray-700 font-semibold">
                                            GST Exclusive
                                        </label>
                                        <Tooltip id="gstExclusive" content="GST will be added to the amount" />
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type="radio"
                                            id="gstInclusive"
                                            name="gstType"
                                            checked={!isGstExclusive}
                                            onChange={() => setIsGstExclusive(false)}
                                            className="mr-2"
                                        />
                                        <label htmlFor="gstInclusive" className="text-gray-700 font-semibold">
                                            GST Inclusive
                                        </label>
                                        <Tooltip id="gstInclusive" content="GST is already included in the amount" />
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 font-semibold">Total amount</label>
                                    <div className="flex items-center">
                                        <input
                                            type="number"
                                            value={amount}
                                            onChange={(e) => setAmount(Number(e.target.value))}
                                            className="w-1/2 p-2 border rounded mr-2"
                                        />
                                        <span className="text-gray-700">₹ {amount.toLocaleString()}</span>
                                    </div>
                                    <input
                                        type="range"
                                        min="1000"
                                        max="1000000"
                                        value={amount}
                                        onChange={(e) => setAmount(Number(e.target.value))}
                                        className="w-full mt-2"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 font-semibold">Tax slab</label>
                                    <div className="flex items-center">
                                        <input
                                            type="number"
                                            min="1"
                                            max="100"
                                            value={taxSlab}
                                            onChange={(e) => setTaxSlab(Number(e.target.value))}
                                            className="w-1/2 p-2 border rounded mr-2"
                                        />
                                        <span className="text-gray-700">{taxSlab} %</span>
                                    </div>
                                    <input
                                        type="range"
                                        min="1"
                                        max="100"
                                        value={taxSlab}
                                        onChange={(e) => setTaxSlab(Number(e.target.value))}
                                        className="w-full mt-2"
                                    />
                                </div>
                                <div className="mt-4 bg-green-100 p-4 rounded">
                                    <div className="flex justify-between items-center">
                                        <span className="font-semibold text-gray-700">Total GST:</span>
                                        <span className="text-xl font-bold text-gray-900">₹ {gstAmount.toLocaleString()}</span>
                                    </div>
                                    <div className="flex justify-between items-center mt-2">
                                        <span className="font-semibold text-gray-700">Post-GST amount:</span>
                                        <span className="text-xl font-bold text-gray-900">₹ {postGstAmount.toLocaleString()}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 px-4 mt-8 lg:mt-0 text-center">
                            <div className="bg-white shadow-md rounded p-6">
                                <h2 className="text-xl font-bold mb-4">Invest the way you want</h2>
                                <div className="mb-4">
                                    <img src="https://www.shutterstock.com/image-illustration/credit-cards-online-shopping-banner-260nw-355524626.jpg" alt="Banner" className="w-full rounded" />
                                </div>
                                <p>Join millions of Indians who trust and love TheFinMasters.</p>
                                <button className="bg-green-500 text-white px-4 py-2 rounded mt-4">Explore Products</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap px-6 py-6" >
                <div className="w-full md:w-2/3 p-4">
                    <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">What is GST?
                    </h1>

                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">The Goods and Services Tax (GST) is a single indirect tax levied on the supply of goods and services in India. Implemented in 2017, it replaced a multitude of previous indirect taxes like sales tax, VAT, and excise duty.
                    </p>


                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Who Pays GST?

                        </h1>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'> Businesses: </span> Every business operating in India must register for GST and obtain a GST Identification Number (GSTIN).

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>      Consumers:  </span>Consumers pay GST when purchasing goods and services. Understanding how to calculate GST is crucial for informed financial decisions.


                        </p>

                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How Can a GST Calculator Help You?
                        </h1>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Since GST is applied to almost everything you buy, a GST calculator can be a valuable tool. Here's how it benefits you:
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>     Estimates GST on Purchases:  </span>Know exactly how much tax you're paying on any good or service.

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>    Saves Time: </span>  Skip manual calculations and get instant GST estimates.

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Empowers Informed Consumption: </span>   Being aware of the GST component in prices helps you make informed purchasing decisions.

                        </p>


                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">GST Calculation Formulas</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">A GST calculator uses a standardized method with two main functions: adding GST and removing GST from a price.
                        </p>
                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Adding GST:
                        </h1>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Formula: </span>     GST amount = (Price x GST%)

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Formula:  </span>  GST amount = (Price x GST%)

                        </p>

                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Removing GST:

                        </h1>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>Formula: </span>            GST = Original cost – [Original cost x {"{"}100/(100+ GST %){"}"}]


                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  Example: </span>  The final price of a product with 18% GST is Rs. 118. Original cost = 118 – [118 / (100 + 18%)], which is Rs. 100.


                        </p>

                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Using the TheFinMaster GST Calculator Online</h1>
                        <p className="text-lg font-bold mt-2 text-gray-800 ">TheFinMaster offers a user-friendly online GST calculator :
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">1. Visit the TheFinMaster website.</p>

                        <p className="text-lg font-normal mt-2 text-gray-700 ">2. Enter the original cost of the product and the GST percentage.</p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">3. The calculator instantly displays the GST amount.

                        </p>
                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Understanding GST Inclusive vs. Exclusive Prices:

                        </h1>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'> GST Inclusive Price:  </span>           The total price you pay includes the GST amount already incorporated. There's no separate GST charge.


                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  GST Exclusive Price: </span>   The price excludes GST. To find the final price, you'll need to add the calculated GST amount.
                        </p>

                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using a GST Calculator


                        </h1>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'> Transparency in Taxation:  </span>  Clearly understand the GST component in prices.



                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  Saves Time and Effort: </span> Quick and accurate GST calculations without manual hassles.
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  Protects Against Errors:</span>  Avoids potential discrepancies due to manual calculations.
                        </p>
                        <p className="text-lg text-gray-700 font-bold sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-3">GST has transformed India's tax system by unifying various taxes under one umbrella. Utilize a GST calculator to stay informed about the tax you pay on your everyday purchases!
</p>
                    </div>
                   




                </div>

                <div className="w-full md:w-1/3 p-4">
                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
                            <h2 className="text-lg font-semibold ">Popular Categories</h2>
                        </div>
                        <div className="p-2 ">
                        <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                                {/* {moreCategories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
};

export default GSTCalculator;
