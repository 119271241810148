import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
    return (
        <div className="shadow-xl rounded-md p-4 bg-white mt-4 md:mt-0 text-center">
            <img src="https://www.shutterstock.com/image-illustration/credit-cards-online-shopping-banner-260nw-355524626.jpg" alt="Advertisement" className="w-full rounded-md mb-4" />
            <h3 className="text-xl font-bold mb-2">Invest the way you want</h3>
            <p>Join millions of Indians who trust and love TheFinMaster</p>
            <button className="bg-green-500 text-white px-4 py-2 rounded mt-2">Explore Products</button>
        </div>
    );
};

const BrokerageCalculator = () => {
    const [segment, setSegment] = useState('equity-delivery');
    const [market, setMarket] = useState('nse');
    const [quantity, setQuantity] = useState(50);
    const [buyPrice, setBuyPrice] = useState(1000);
    const [sellPrice, setSellPrice] = useState(1500);
    const [qtyOptionType, setQtyOptionType] = useState('option');
    const [charges, setCharges] = useState({
        turnover: 0,
        pl: 0,
        brokerage: 0,
        stt: 0,
        exchange: 0,
        sebi: 0,
        gst: 0,
        stampDuty: 0,
        netPL: 0,
    });
    const [calculated, setCalculated] = useState(false);

    const calculateCharges = () => {
        const turnover = quantity * (buyPrice + sellPrice);
        const pl = (sellPrice - buyPrice) * quantity;

        const brokerage = Math.min(turnover * 0.0003, 20); // Example: 0.03% or max 20 per trade
        const stt = sellPrice * quantity * 0.001; // Example: 0.1% on sell side
        const exchange = turnover * 0.0000325; // Example: 0.00325%
        const sebi = turnover * 0.0000005; // Example: 0.00005%
        const gst = (brokerage + exchange) * 0.18; // GST
        const stampDuty = buyPrice * quantity * 0.00015; // Example: 0.015% on buy side

        const netPL = pl - (brokerage + stt + exchange + sebi + gst + stampDuty);

        setCharges({
            turnover,
            pl,
            brokerage,
            stt,
            exchange,
            sebi,
            gst,
            stampDuty,
            netPL,
        });

        setCalculated(true);
    };

    return (
        <>
           <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Brokerage Calculator</span>
        </li> 
      
      </ol>
    </nav>
            <div className='py-4'>

                <div className="container mx-auto p-4 flex flex-col md:flex-row md:space-x-4">
                    <div className="w-full md:w-2/3 p-4 shadow-xl rounded-md bg-white">
                        <h1 className="text-2xl font-bold mb-4">Brokerage Calculator</h1>
                        <div className="mb-4 flex">
                            <button
                                onClick={() => setSegment('equity-delivery')}
                                className={`px-4 py-2 rounded mr-2 ${segment === 'equity-delivery' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                            >
                                Equity Delivery
                            </button>
                            <button
                                onClick={() => setSegment('equity-intraday')}
                                className={`px-4 py-2 rounded mr-2 ${segment === 'equity-intraday' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                            >
                                Equity Intraday
                            </button>
                            <button
                                onClick={() => setSegment('fo')}
                                className={`px-4 py-2 rounded ${segment === 'fo' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                            >
                                F&O
                            </button>
                        </div>
                        {(segment === 'equity-delivery' || segment === 'equity-intraday') && (
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold">Market</label>
                                <select
                                    value={market}
                                    onChange={(e) => setMarket(e.target.value)}
                                    className="w-full p-2 border rounded"
                                >
                                    <option value="nse">NSE</option>
                                    <option value="bse">BSE</option>
                                </select>
                            </div>
                        )}
                        {segment === 'fo' && (
                            <>
                                <div className="mb-4">
                                    <label className="block text-gray-700 font-semibold">Quantity Type</label>
                                    <select
                                        value={qtyOptionType}
                                        onChange={(e) => setQtyOptionType(e.target.value)}
                                        className="w-full p-2 border rounded"
                                    >
                                        <option value="option">Option</option>
                                        <option value="future">Future</option>
                                    </select>
                                </div>
                            </>
                        )}
                        <div className="mb-4">
                            <label className="block text-gray-700 font-semibold">Quantity</label>
                            <input
                                type="number"
                                value={quantity}
                                onChange={(e) => setQuantity(parseInt(e.target.value))}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-semibold">Buy Price per Share</label>
                            <input
                                type="number"
                                value={buyPrice}
                                onChange={(e) => setBuyPrice(parseFloat(e.target.value))}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 font-semibold">Sell Price per Share</label>
                            <input
                                type="number"
                                value={sellPrice}
                                onChange={(e) => setSellPrice(parseFloat(e.target.value))}
                                className="w-full p-2 border rounded"
                            />
                        </div>
                        <button
                            onClick={calculateCharges}
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Calculate
                        </button>
                        {calculated && (
                            <div className="mt-4 p-4 bg-gray-100 rounded-md">
                                <h2 className="text-xl font-bold mb-4">Results</h2>
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-semibold">Turnover:</span>
                                        <span>₹ {charges.turnover.toLocaleString()}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="font-semibold">P&L:</span>
                                        <span>₹ {charges.pl.toLocaleString()}</span>
                                    </div>
                                </div>
                                <h3 className="text-lg font-bold mt-4 mb-2">Charges</h3>
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <span className="font-semibold">Brokerage:</span>
                                        <span>₹ {charges.brokerage.toLocaleString()}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="font-semibold">STT:</span>
                                        <span>₹ {charges.stt.toLocaleString()}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="font-semibold">Exchange Charges:</span>
                                        <span>₹ {charges.exchange.toLocaleString()}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="font-semibold">SEBI Turnover Fees:</span>
                                        <span>₹ {charges.sebi.toLocaleString()}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="font-semibold">GST:</span>
                                        <span>₹ {charges.gst.toLocaleString()}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="font-semibold">Stamp Duty:</span>
                                        <span>₹ {charges.stampDuty.toLocaleString()}</span>
                                    </div>
                                </div>
                                <h3 className="text-lg font-bold mt-4 mb-2">Net P&L</h3>
                                <div className="flex justify-between">
                                    <span className="font-semibold">Net P&L:</span>
                                    <span className="text-green-500">₹ {charges.netPL.toLocaleString()}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="w-full md:w-1/3">
                        <Banner />
                    </div>
                </div>
            </div>


            <div className="flex flex-wrap px-6 py-6" >
                <div className="w-full md:w-2/3 p-4">
                    <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Beyond the Purchase Price: Unveiling Hidden Trading Costs
                    </h1>

                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Buying a stock isn't just about the initial price. There are additional fees that can eat into your profits, making cost analysis crucial for successful trading.
                    </p>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">The Silent Expense: Brokerage Fees</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">A significant hidden cost is the brokerage fee, a commission paid to your broker for facilitating your trades. Typically, it's a percentage of the total trade value, deducted from your portfolio.

                        </p>
                    </div>


                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Why Use a Brokerage Calculator?
                        </h1>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">These seemingly small fees can add up quickly, especially for frequent traders. A brokerage calculator helps you:
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>     Uncover All Costs:  </span> Get a complete breakdown of trading expenses, including brokerage fees, taxes, and other charges.

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>     Informed Decisions: </span>  Knowing the total cost upfront allows you to make smarter trading decisions and maximize your returns.

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Compare Brokers:  </span>    See how different brokers stack up in terms of fees to find the most cost-effective option for your needs.
                        </p>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  Save Time:  </span> Skip manual calculations and get instant results, allowing you to focus on your trading strategy.
                        </p>
                    </div>

                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Invest Smarter, Not Harder</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">A brokerage calculator is your secret weapon for navigating hidden trading costs. Use it to plan your trades with confidence and keep more of your hard-earned profits.
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">Here's a rewritten version focusing on the key concept and avoiding technical details:
                        </p>
                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Understanding Brokerage Fees: A Must for Smart Trading</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">Brokerage fees are a cost associated with buying and selling securities. These fees compensate your broker for facilitating your trades.

                        </p>

                    </div>


                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">The Bottom Line:


                        </h1>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            Brokerage fees are typically a percentage of the total trade value.
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            The exact percentage can vary depending on the broker you choose.
                        </p>

                    </div>

                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Why It Matters:</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">Being aware of brokerage fees helps you make informed trading decisions. Lower fees can significantly impact your overall return, especially for frequent traders.

                        </p>

                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Focus on Your Strategy:</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">By using a brokerage calculator, you can save time on calculations and focus on developing a winning trading strategy.

                        </p>

                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How to calculate Brokerage</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 "> <span className='font-bold text-gray-700'>Example:</span> Subhash decides to purchase 20 shares of Hindustan Unilever Limited at Rs.2000 each, and within 10 days, she sells those off at Rs.2100. He does so via a Broker Z who charges 0.5% as brokerage fees.

                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">  In this example, Subhash’s total trade value stands to be:

                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 "> Rs. [(20 x 2000) + (20 x 2100)]

                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">  Or, Rs. (40,000 + 42000) = Rs.82,000

                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">  Since Broker Z charges 0.5% as brokerage, the total fee he pays to the broker is:

                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">  Rs. (82000 x 0.5%) = Rs.410
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">  Therefore, Subhash would pay Rs.410 as a brokerage for carrying out transactions worth Rs.82000. However, if he carried out a transaction on which brokerage by standard means is too low, then a broker would charge the minimum amount applicable, which is usually Rs.20 – Rs.30.:
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">  Nevertheless, to simplify this tedious process of calculating brokerage, individuals can use a stock brokerage calculator.
                        </p>
                    </div>



                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">What Affects Your Brokerage Costs?
                        </h1>
                        <h1 className="text-lg font-medium  mt-2 text-gray-800">While brokerage calculators are great tools, understanding the factors behind these fees can empower you to make informed choices. Here's a quick breakdown:
                        </h1>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  Trade Size: </span>   The higher the value of your trade (stock price x number of shares), the higher the brokerage fee will likely be (for percentage-based fees)
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>    Trading Frequency: </span>Some brokers offer lower fees for frequent traders.

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Broker Type: </span>   Full-service brokers often charge more as they provide additional services like research and advice. Discount brokers typically have lower fees but offer a more basic platform.
                        </p>
                        <p className="text-lg font-medium mt-2 text-gray-800 ">By considering these factors, you can choose a broker that aligns with your trading style and budget.

</p>
                    </div>
                    
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Why Use a Brokerage Calculator? Trade Smarter, Not Harder
                        </h1>
                        <h1 className="text-lg font-medium  mt-2 text-gray-800">Before you buy or sell, a brokerage calculator can be your secret weapon for navigating trading costs. Here's how it helps:
                        </h1>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  Save Time & Effort:</span>    Skip manual calculations and get instant results, allowing you to focus on your trading strategy.
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>    See the Full Picture: </span> Get a complete breakdown of all trading costs, including brokerage fees, taxes, and other charges.

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>    Compare Broker Options: </span>   Find the most cost-effective broker for your needs by comparing their commission structures.
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Make Informed Decisions: </span>    Knowing the total cost upfront allows you to make smarter trades and maximize your returns.
                        </p>
                        <p className="text-lg font-medium mt-2 text-gray-800 ">Brokerage calculators are free and easy to use, so there's no reason not to take advantage of this valuable tool!


</p>
                    </div>

                </div>

                <div className="w-full md:w-1/3 p-4">
                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
                            <h2 className="text-lg font-semibold ">Popular Categories</h2>
                        </div>
                        <div className="p-2 ">
                        <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                                {/* {moreCategories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
};

export default BrokerageCalculator;
