// src/PPFCalculator.js
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const PPFCalculator = () => {
  const [yearlyInvestment, setYearlyInvestment] = useState(150000);
  const [timePeriod, setTimePeriod] = useState(15);
  const [rateOfInterest, setRateOfInterest] = useState(7.1);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [maturityValue, setMaturityValue] = useState(0);

  const calculateMaturity = () => {
    let totalInvestment = yearlyInvestment * timePeriod;
    let maturityValue = 0;

    for (let i = 0; i < timePeriod; i++) {
      maturityValue = (maturityValue + yearlyInvestment) * (1 + rateOfInterest / 100);
    }

    let totalInterest = maturityValue - totalInvestment;

    setTotalInvestment(totalInvestment);
    setTotalInterest(totalInterest);
    setMaturityValue(maturityValue.toFixed(2));
  };

  const handleYearlyInvestmentChange = (e) => {
    const value = e.target.value;
    setYearlyInvestment(value === '' ? 0 : parseInt(value));
  };

  const handleTimePeriodChange = (e) => {
    const value = e.target.value;
    setTimePeriod(value === '' ? 0 : parseInt(value));
  };

  const handleRateOfInterestChange = (e) => {
    const value = e.target.value;
    setRateOfInterest(value === '' ? 0 : parseFloat(value));
  };

  const data = {
    labels: ['Total Investment', 'Total Interest', 'Maturity Value'],
    datasets: [
      {
        label: 'PPF Breakdown',
        data: [totalInvestment, totalInterest, maturityValue],
        backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56'],
      },
    ],
  };

  return (
    <>
     <Helmet>
         <title>PPF Calculator- Public Provident Fund Calculator Online | TheFinmaster</title>
        <meta  name='description' content='Use this online PPF Calculator to calculate the maturity amount and interest earned on your Public Provident Fund investments.| TheFinmaster'/>
        <meta name='keywords' content='ppf calculator post office, ppf interest rate in sbi,post office public provident fund calculator, post ppf calculator' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/ppf-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">PPF Calculator</span>
        </li> 
      
      </ol>
    </nav>
    <div className='py-4'>
    <div className="container mx-auto p-4 shadow-xl rounded-md ">
      <h1 className="text-2xl font-bold mb-4">PPF Calculator</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Yearly Investment</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                max="150000"
                value={yearlyInvestment}
                onChange={handleYearlyInvestmentChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">₹ {yearlyInvestment.toLocaleString()}</span>
            </div>
            <input
              type="range"
              min="0"
              max="150000"
              value={yearlyInvestment}
              onChange={(e) => setYearlyInvestment(parseInt(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Time Period (Years)</label>
            <div className="flex items-center">
              <input
                type="number"
                min="1"
                max="50"
                value={timePeriod}
                onChange={handleTimePeriodChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{timePeriod} Yr</span>
            </div>
            <input
              type="range"
              min="1"
              max="50"
              value={timePeriod}
              onChange={(e) => setTimePeriod(parseInt(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Rate of Interest (%)</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                max="15"
                step="0.1"
                value={rateOfInterest}
                onChange={handleRateOfInterestChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{rateOfInterest} %</span>
            </div>
            <input
              type="range"
              min="0"
              max="15"
              step="0.1"
              value={rateOfInterest}
              onChange={(e) => setRateOfInterest(parseFloat(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <button
            onClick={calculateMaturity}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Calculate
          </button>
        </div>
        <div>
          <div className="mb-4">
            <h2 className="text-xl font-bold">Results</h2>
            <p>Total Investment: ₹ {totalInvestment.toLocaleString()}</p>
            <p>Total Interest: ₹ {totalInterest.toLocaleString()}</p>
            <p>Maturity Value: ₹ {maturityValue.toLocaleString()}</p>
          </div>
          <div>
            <h2 className="text-xl font-bold">Breakdown</h2>
            <div className="flex justify-center">
              <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="flex flex-wrap px-6 py-6" >
        <div className="w-full md:w-2/3 p-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Public Provident Fund (PPF): Grow Your Savings Securely </h1>
          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Building a secure future starts with smart savings. The Public Provident Fund (PPF) is a government-backed savings scheme that offers attractive interest rates and tax benefits, making it ideal for long-term financial goals.</p>

          
          
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Who can benefit from PPF?</h1>
                 
               <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              New employees looking to build a strong financial foundation.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Parents saving for their child's future education or marriage.            </p>
              <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Anyone seeking a safe and reliable investment option.            </p>
                      </div>

                      <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Why use a PPF calculator?</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">These online tools use a formula to calculate your maturity amount based on factors like:</p>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>    Investment amount:</span>  How much you contribute annually. 
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Interest rate: </span>  The current PPF interest rate (as of May 28, 2024) tenure.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Investment tenure:</span> The duration for which you invest in PPF.
            </p>
            </div>
            <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How do PPF calculators work?</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">These online tools use a formula to calculate your maturity amount based on factors like:</p>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Investment amount:</span>  How much you contribute annually. 
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Interest rate:</span> The current PPF interest rate (as of May 28, 2024).
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Investment tenure:</span>   The duration for which you invest in PPF.
            </p>
            </div>
            <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of using a PPF calculator:</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Estimate your earnings:</span>  Get a clear idea of the potential interest you can earn on your investment. 
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Tax planning:</span> PPF contributions qualify for tax deductions, helping you save on taxes.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Choose your investment horizon:</span>   Determine the ideal investment period to reach your financial goals.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  User-friendly and convenient:</span>   Several online calculators offer quick and easy calculations.
            </p>
            </div>
            <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Start Saving Today!</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">PPF is a secure and reliable way to grow your wealth over time. Use a PPF calculator to plan your investments and achieve your financial goals with confidence</p>
</div>            
<div className="container mx-auto  py-2">
            <p className="text-lg font-normal  text-gray-600 "><span className='text-gray-800 text-xl'>Note:</span> This revised content avoids promotional mentions of specific investment platforms and focuses on the general benefits of PPF accounts and calculators.</p>
</div>    
                      
           
                      
        </div>

        <div className="w-full md:w-1/3 p-4">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
              <h2 className="text-lg font-semibold ">Popular Categories</h2>
            </div>
            <div className="p-2 ">
            <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                {/* {moreCategories.map(category => (
          <Link key={category.id} to={`/category/${category.id}`}>
            <li className="py-2 border-b border-gray-300">{category.name}</li>
          </Link>
        ))} */}
              </ul>
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default PPFCalculator;
