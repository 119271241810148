import React, { useState } from 'react'
import PersonalLoanCard from './PersonalLoanCard';
import { Link } from 'react-router-dom';
import { MdStars } from "react-icons/md";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { FaInfinity } from "react-icons/fa";
import { BsCashCoin } from "react-icons/bs";
import { MdOutlineAirplanemodeActive } from "react-icons/md";
import { Helmet } from 'react-helmet';

    function Icon({ id, open }) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        );
      }
      
      const EducationLoan = () => {
        const [activeIndex, setActiveIndex] = useState(0);
      
        const faqs = [
            {
                question: '  What is an education loan?',
                answer:
                    'An education loan is a financial arrangement in which a lender provides funds to help you pay for college or other educational expenses. This loan can cover tuition, books, supplies, and even living costs. You agree to repay the loan over a specified period, typically with interest, after you graduate or complete your studies.',
            },
            {
                question: 'What factors should I consider when choosing an education loan?',
                answer:
                    'When selecting an education loan, consider factors like the interest rate, loan term, repayment options, and total loan cost. It is also important to evaluate your financial need and whether the loan is subsidized or unsubsidized. Additionally, assess the deferment and forbearance options, as well as any available loan forgiveness programs.',
            },
            {
                question: ' Which bank offers the lowest interest rate on education loans?',
                answer:
                    'The interest rate for education loans depends on several factors, including the loan amount, your credit history, type of loan (subsidized or unsubsidized), and loan tenure. To find the best rate, compare offers from different banks and lending institutions. Personalized rates can be obtained by providing your financial information to lenders and using our comparison tools to assess your best options.',
            },
            {
                question: 'Can I get an education loan through TheFinMaster.com?',
                answer:
                    ':  TheFinMaster.com does not offer education loans directly. However, we provide educational resources, loan comparison tools, and unbiased information to help you understand education loans and choose the best one for your needs. Once you have identified a suitable loan, you will be directed to the lenders website to complete a secure application process.',
            },
            
        ];
      
        const toggleAccordion = (index) => {
          if (activeIndex === index) {
              // If the clicked question is already expanded, collapse it
              setActiveIndex(null);
          } else {
              // If the clicked question is not expanded, expand it
              setActiveIndex(index);
          };
        };
    return (
        <>
           <Helmet>
      <title>Apply For Student Education Loan Online in India | Thefinmaster</title>
      <meta  name='description' content='Education loan India, education loan for Indian student, study loan India, student loan India, educational loan.'/>
      <meta name='keywords' content='student education loan, private student loans, student loan application, education loan' />
      <link rel="canonical" href="https://thefinmaster.com/education-loan" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Education Loan</span>
        </li> 
        
      </ol>
    </nav>
            <div className="w-full flex flex-col items-center">
                <div className="w-full flex justify-center">
                    <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
                        <div className="w-full sm:w-[50%]">
                            <div className="w-[90%]">
                                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                                    <span className="text-blue-600 ">
                                        Get fast approval for your dream car today
                                    </span>{" "}
                                    Easy process, low rates, drive away with your dream car
                                </h1>
                                <a href="#insurance_plans">
                                    <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                                        See Plans
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className="w-full sm:w-[50%]">
                            <img
                                className="w-full max-w-full"
                                src="https://cdn3d.iconscout.com/3d/premium/thumb/invest-for-education-5768769-4833566.png"
                                alt="education-loan"
                            />
                        </div>
                    </div>
                </div>

            </div>

            <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 flex justify-center items-center">
    <p className="text-lg md:text-base lg:text-lg text-center">
    TheFinMaster.com bridges the gap by simplifying your education loan search. Our platform connects you with a wide range of lenders offering education loans.  Easily compare interest rates, repayment options, and eligibility criteria to find the perfect fit for financing your academic aspirations. TheFinMaster.com empowers you with knowledge to find the best education loan deal and unlock your full potential.
    </p>        </div>
            <div className="flex flex-wrap   justify-center gap-4 mt-10">
                <Link to="/car-loan" className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                    <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
                    <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                        <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                        <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span className="relative flex items-center"><BsCashCoin className="mr-4 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Car Loan</span></span>
                </Link>
                <Link to="/home-loan" className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                    <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
                    <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                        <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                        <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span className="relative flex items-center"><MdOutlineAirplanemodeActive className="mr-4 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Home Loan</span></span>
                </Link>

                {/* <Link to="/fuel_detail" className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                    <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
                    <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                        <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                        <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span className="relative flex items-center">< BsFillFuelPumpFill className="mr-4 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Education Loan</span></span>
                </Link> */}

                <Link to="/two-wheeler-loan" className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                    <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
                    <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                        <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                        <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span className="relative flex items-center"><FaInfinity className="mr-4 text-2xl text-black" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Two-wheeler Loan</span></span>
                </Link>
                <Link to="/personal-loan" className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                    <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
                    <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                        <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                        <svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span className="relative flex items-center"><MdStars className="mr-1 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Personal Loan</span></span>
                </Link>



            </div>
            {/* <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Get fast approval for your dream car today
                  </span>{" "}
                  Easy process, low rates, drive away with your dream car
                </h1>
                <a href="#insurance_plans">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    See Plans
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src="https://www.shutterstock.com/image-illustration/car-loan-concept-isolated-on-260nw-1126188713.jpg"
                alt="car-loan"
              />
            </div>
          </div>
       </div>

       </div> */}
            <div
                id="insurance_plans"
                className="relative w-[95%] flex justify-between gap-5 mt-20"
            >
                <div className="w-full flex flex-col gap-10">
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Axis_Bank_logo.svg/2560px-Axis_Bank_logo.svg.png"
                    />
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/HDFC_Life_logo.png"
                    />
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/MAX_logo.png"
                        interest={"200%"}
                        LoanAmount='Based on Income'
                        ProcessingFeeUp='to 1% or min. Rs. 10,000/-'
                    />
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/BAJAJ_logo.png"
                    />
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/PNB_Metlife_logo.png"
                    />
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/HSBC_logo.png"
                    />
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/Birla_Sun_Life_logo.png"
                    />
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/kotak_logo.png"
                    />
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/TATA_AIA_logo.png"
                    />
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/Edelweiss_Tokio_logo.png"
                    />
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/SBI_logo.png"
                    />
                    <PersonalLoanCard
                        cardName="ICICI Bank Coral"
                        cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/Aegon_Life_logo.png"
                    />
                </div>
                <div className="sticky top-10 w-[450px] border h-fit">
                    <img className="" src="https://thumbs.dreamstime.com/b/d-man-car-car-loan-concept-white-background-36834187.jpg" alt="banner" />
                </div>
            </div>

             {/* FAQ section */}
      <div className="max-w-4xl mx-auto py-6 mt-10">
                <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>

    );
};


export default EducationLoan