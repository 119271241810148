import React from "react";
import { FiArrowRight } from "react-icons/fi";
import AccordionComponent from "../accordion/AccordionComponent";

const ResearchPage = () => {
  return (
    // <div className='w-full'>
    //   <div className='w-full bg-black text-white h-screen flex items-center justify-between'>
    //     <div className='w-[40%] flex justify-center'>
    //       <div className='w-[80%]'>
    //         <h2 className='text-5xl font-semibold'>Exploring Finance Solutions</h2>
    //         <p className='text-2xl py-5'>Discover effective budgeting techniques to manage your finances, save for the future, and achieve your financial goals.</p>
    //         <button className='border rounded-full px-8 py-4 text-lg shadow-lg hover:bg-white hover:text-black mt-2'>Get Started</button>
    //       </div>
    //     </div>
    //     <div className='w-[60%]'>
    //       <img className='w-[100%] h-[100%] object-cover' src='/research-removebg-preview.png' alt='research' />
    //     </div>
    //   </div>
    <div className="w-full">
      <div className="w-full bg-black text-white md:h-screen h-full flex flex-col md:flex-row items-center justify-between">
        <div className="w-full md:w-[40%] flex justify-center">
          <div className="w-[80%] md:w-[80%]">
            <h2 className="text-2xl md:text-5xl font-semibold mt-10 md:mt-0">
              Exploring Finance Solutions
            </h2>
            <p className="text-md md:text-2xl py-3">
              Discover effective budgeting techniques to manage your finances,
              save for the future, and achieve your financial goals.
            </p>
            <button className="border rounded-full px-6 md:px-8 py-3 md:py-4 text-md md:text-lg shadow-lg hover:bg-white hover:text-black mt-2 md:mt-2">
              Get Started
            </button>
          </div>
        </div>
        <div className="w-full md:w-[60%]">
          <img
            className="w-full h-auto object-cover"
            src="/research-removebg-preview.png"
            alt="research"
          />
        </div>
      </div>

      <div className="w-full flex flex-col items-center">
        <div className="w-[95%] my-20">
          <h2 className="font-semibold text-3xl md:text-5xl text-center">
            Why Use Our Research Page
          </h2>
          <p className="text-base md:text-lg text-blue-600 py-2 md:py-3 text-center md:text-left">
            3 easy steps. Get started now!
          </p>

          <div className="w-full flex flex-col md:flex-row gap-5 md:gap-20 mt-3 md:mt-5">
            <div className="w-full md:w-[30%] pl-2 md:pl-5">
              <p className="text-4xl md:text-7xl font-extrabold text-gray-500">
                1
              </p>
              <div className="flex items-center justify-between mt-3 md:mt-5">
                <div>
                  <p className="text-lg md:text-2xl text-blue-600 font-medium flex items-center">
                    Personal & Bank Details
                  </p>
                </div>
                <div>
                  <FiArrowRight className="text-2xl md:text-3xl animate-pulse" />
                </div>
              </div>
              <p className="text-sm md:text-lg py-2 w-full md:w-[70%]">
                Choose a Depository Participant (DP): Select a DP, like a bank
                or stockbroker, to open your Demat account.
              </p>
            </div>
            <div className="w-full md:w-[30%] pl-2 md:pl-5">
              <p className="text-4xl md:text-7xl font-extrabold text-gray-500">
                2
              </p>
              <div className="flex items-center justify-between mt-3 md:mt-5">
                <div>
                  <p className="text-lg md:text-2xl text-blue-600 font-medium flex items-center">
                    Personal & Bank Details
                  </p>
                </div>
                <div>
                  <FiArrowRight className="text-2xl md:text-3xl animate-pulse" />
                </div>
              </div>
              <p className="text-sm md:text-lg py-2 w-full md:w-[70%]">
                Choose a Depository Participant (DP): Select a DP, like a bank
                or stockbroker, to open your Demat account.
              </p>
            </div>
            <div className="w-full md:w-[30%] pl-2 md:pl-5">
              <p className="text-4xl md:text-7xl font-extrabold text-gray-500">
                3
              </p>
              <div className="flex items-center justify-between mt-3 md:mt-5">
                <div>
                  <p className="text-lg md:text-2xl text-blue-600 font-medium flex items-center">
                    Personal & Bank Details
                  </p>
                </div>
                <div>{/* <FiArrowRight className='text-4xl'/> */}</div>
              </div>
              <p className="text-sm md:text-lg py-2 w-full md:w-[70%]">
                Choose a Depository Participant (DP): Select a DP, like a bank
                or stockbroker, to open your Demat account.
              </p>
            </div>
          </div>
        </div>
        {/* <p className='text-lg md:text-lg text-blue-600 py-3'>3 easy steps. Get started now!</p>

          <div className='w-full flex  flex-col md:flex-row gap-20 mt-5'>
            <div className='w-full pl-5'>
              <p className='text-7xl font-extrabold text-gray-500'>1</p>
              <div className='flex items-center justify-between mt-5'>
                <div>
                  <p className='text-2xl text-blue-600 font-medium flex items-center'>Personal & Bank Details</p>
                </div>
                <div>
                  <FiArrowRight className='text-3xl animate-pulse' />
                </div>
              </div>
              <p className='text-lg py-2 w-[70%]'>Choose a Depository Participant (DP):
                Select a DP, like a bank or stockbroker, to open your Demat account.
              </p>
            </div>
            <div className='w-full pl-5'>
              <p className='text-7xl font-extrabold text-gray-500'>2</p>
              <div className='flex items-center justify-between mt-5'>
                <div>
                  <p className='text-2xl text-blue-600 font-medium flex items-center'>Personal & Bank Details</p>
                </div>
                <div>
                  <FiArrowRight className='text-3xl animate-pulse' />
                </div>
              </div>
              <p className='text-lg py-2 w-[70%]'>Choose a Depository Participant (DP):
                Select a DP, like a bank or stockbroker, to open your Demat account.
              </p>
            </div>
            <div className='w-full pl-5'>
              <p className='text-7xl font-extrabold text-gray-500'>3</p>
              <div className='flex items-center justify-between mt-5'>
                <div>
                  <p className='text-2xl text-blue-600 font-medium flex items-center'>Personal & Bank Details</p>
                </div>
                <div>
                  {/* <FiArrowRight className='text-4xl'/> */}
        {/* </div>
              </div>
              <p className='text-lg py-2 w-[70%]'>Choose a Depository Participant (DP):
                Select a DP, like a bank or stockbroker, to open your Demat account.
              </p>
            </div>
          </div>
        </div> */}

        <div className="w-[95%] flex flex-col items-center">
          <div className="w-full flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-[50%]">
              <img
                className="w-full h-auto object-cover"
                src="/research1.jpg"
                alt="img1"
              />
            </div>
            <div className="w-full md:w-[40%] flex items-center mt-5 md:mt-0 ml-0 md:ml-5">
              <div className="w-[80%]">
                <h2 className="font-bold text-2xl md:text-4xl">
                  Comprehensive Information
                </h2>
                <p className="text-md md:text-xl font-medium py-3 md:py-5">
                  Our research materials cover a wide spectrum of topics,
                  ensuring that you have access to all the necessary information
                  in one place.
                </p>
                <button className="border px-6 md:px-8 py-3 md:py-4 rounded-full bg-blue-600 text-white font-medium text-md md:text-lg mt-2">
                  Get Your Card
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[95%] flex flex-col items-center">
          <div className="w-full flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-[40%] flex items-center">
              <div>
                <h2 className="font-bold text-2xl md:text-4xl md:mt-0 mt-10">
                  Practical Insights
                </h2>
                <p className="text-md md:text-xl font-medium py-3 md:py-5">
                  Our research materials cover a wide spectrum of topics,
                  ensuring that you have access to all the necessary information
                  in one place.
                </p>
                <button className="border px-6 md:px-8 py-3 md:py-4 rounded-full bg-blue-600 text-white font-medium text-md md:text-lg mt-2">
                  Open Account
                </button>
              </div>
            </div>
            <div className="w-full md:w-[60%] mt-5 md:mt-0">
              <img
                className="w-full h-auto object-cover"
                src="/research5.png"
                alt="img1"
              />
            </div>
          </div>
        </div>

        <div className="w-[95%] my-10">
          <h2 className="text-2xl md:text-5xl font-bold pb-3">
            How to Use Our Research Page
          </h2>
          <p className="text-md md:text-xl text-blue-600">
            Discover effective budgeting, saving, and debt management strategies
            to improve your financial well-being.
          </p>

          <div className="w-full flex flex-col md:flex-row gap-4 md:gap-16 py-10">
            <div className="w-full md:w-[30%] border border-b-4 border-b-blue-600 hover:border-b-black shadow-lg hover:shadow-2xl">
              <div className="w-full h-[250px] overflow-hidden">
                <img
                  className="w-[100%] h-[100%] object-cover hover:scale-110 duration-150"
                  src="/blog1.jpg"
                  alt="blog1"
                />
              </div>
              <div className="p-5">
                <h1 className="text-lg font-medium">Investment Guidance</h1>
                <p className="text-sm text-gray-500 pt-2">
                  Learn about various investment options, from stocks and bonds
                  to real estate and cryptocurrencies, and find out how to grow
                  your wealth.
                </p>
              </div>
            </div>

            {/* Repeat similar blocks for other items */}

            <div className="w-full md:w-[30%] border border-b-4 border-b-blue-600 hover:border-b-black shadow-lg hover:shadow-2xl">
              <div className="w-full h-[250px] overflow-hidden">
                <img
                  className="w-[100%] h-[100%] object-cover hover:scale-110 duration-150"
                  src="/blog1.jpg"
                  alt="blog1"
                />
              </div>
              <div className="p-5">
                <h1 className="text-lg font-medium">Investment Guidance</h1>
                <p className="text-sm text-gray-500 pt-2">
                  Learn about various investment options, from stocks and bonds
                  to real estate and cryptocurrencies, and find out how to grow
                  your wealth.
                </p>
              </div>
            </div>

            <div className="w-full md:w-[30%] border border-b-4 border-b-blue-600 hover:border-b-black shadow-lg hover:shadow-2xl">
              <div className="w-full h-[250px] overflow-hidden">
                <img
                  className="w-[100%] h-[100%] object-cover hover:scale-110 duration-150"
                  src="/blog1.jpg"
                  alt="blog1"
                />
              </div>
              <div className="p-5">
                <h1 className="text-lg font-medium">Investment Guidance</h1>
                <p className="text-sm text-gray-500 pt-2">
                  Learn about various investment options, from stocks and bonds
                  to real estate and cryptocurrencies, and find out how to grow
                  your wealth.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[95%] py-10">
          <h1 className="text-center font-bold text-5xl my-10">
            Frequently Asked Questions
          </h1>
          <AccordionComponent />
        </div>
      </div>
    </div>
  );
};

export default ResearchPage;
