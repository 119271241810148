import React from 'react'
import Box from './Box';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function ToolsPages() {
  const boxesData = [
    { imgSrc: '../assets/sip.jpeg', title: 'SIP Calculator        ', description: 'Plan your investments with our SIP calculator! See how much your savings can grow over time.   ', link: '/financial-tools-calculators/sip-calculator' },
    { imgSrc: '../assets/lumpsum.jpeg', title: 'Lumpsum Calculator        ', description: ' Plan your lump sum investment! See how much you can achieve with a one-time investment.   ', link: '/financial-tools-calculators/lumpsum-calculator' },
    { imgSrc: '../assets/swp.jpeg', title: 'SWP Calculator        ', description: 'Plan your income stream! Use our SWP calculator to see how much you will have left after withdrawals.        ', link: '/financial-tools-calculators/swp-calculator' },
    { imgSrc: '../assets/mutual.jpeg', title: 'Mutual Fund Calculator        ', description: 'Mutual Fund Returns Made Easy! Calculate your gains with our investment calculator.', link: '/financial-tools-calculators/mutual-fund-calculator' },
    { imgSrc: '../assets/sukanya.jpeg', title: 'Sukanya Samriddhi Yojana Calculator        ', description: 'Estimate your Sukanya Samriddhi Yojana returns with our calculator based on your contributions.        ', link: '/financial-tools-calculators/sukanya-samriddhi-yojana-calculator        ' },
    { imgSrc: '../assets/ppf.jpeg', title: 'PPF Calculator        ', description: 'Estimate your PPF maturity amount! Use PPF calculator to see how much your PPF account could grow.   ', link: '/financial-tools-calculators/ppf-calculator ' },
    { imgSrc: '../assets/epf.jpeg', title: 'EPF Calculator        ', description: 'Estimate your EPF corpus! Use the EPF calculator to get a sense of how much your EPF will accumulate.        ', link: '/financial-tools-calculators/pf-epf-calculator ' },
    { imgSrc: '../assets/fd.jpeg', title: 'FD Calculator', description: 'Stress-free FD returns! Our calculator helps you discover your fixed deposit earnings with ease.        ', link: '/financial-tools-calculators/fd-calculator ' },
    { imgSrc: '../assets/rd.jpeg', title: 'RD Calculator', description: 'RD growth at your fingertips! Calculate your recurring deposit returns with just a few clicks.        ', link: '/financial-tools-calculators/rd-calculator    ' },
    { imgSrc: '../assets/nps.jpeg', title: 'NPS Calculator        ', description: 'Monitor your NPS performance! Use our tool to gain insights into your NPS returns.        ', link: '/financial-tools-calculators/nps-calculator        ' },
    { imgSrc: '../assets/hra.jpeg', title: 'HRA Calculator        ', description: 'Plan your housing expenses! Our HRA helps you understand how much tax benefit you might receive on your rent.        ', link: '/financial-tools-calculators/hra-exemption-calculator ' },
    { imgSrc: '../assets/pention.jpeg', title: 'Retirement Calculator       ', description: 'Plan your dream retirement! See how much you might need to save for a comfortable lifestyle after you work.        ', link: '/financial-tools-calculators/retirement-planning-calculator' },
    { imgSrc: '../assets/emi.jpeg', title: 'EMI Calculator       ', description: 'Plan your loan payments! See your estimated monthly EMI for car, personal, and education loans etc.        ', link: '/financial-tools-calculators/emi-calculator' },
    { imgSrc: '../assets/carcalculator.jpeg', title: 'Car Loan EMI Calculator', description: 'Estimate your monthly car loan payment! Use our calculator to see what your EMI might be.        ', link: '/financial-tools-calculators/car-loan-emi-calculator' },
    { imgSrc: '../assets/homecalculator.jpeg', title: 'Home Loan EMI Calculator ', description: 'Estimate your monthly home loan payment! See how much your EMIs could be.        ', link: '/financial-tools-calculators/home-loan-emi-calculator' },
    { imgSrc: '../assets/interest.jpeg', title: '  Simple Interest Calculator       ', description: 'Track your earnings and interest! See how much your investments grow and how much you pay on loans.        ', link: '/financial-tools-calculators/simple-interest-calculator' },
    { imgSrc: '../assets/compound.jpeg', title: '  Compound Interest Calculator       ', description: 'Grow your money faster! See the power of compound interest with our easy-to-use calculator.        ', link: '/financial-tools-calculators/compound-interest-calculator        ' },
    { imgSrc: '../assets/nsc.jpeg', title: '  NSC Calculator       ', description: 'Plan your savings growth! See how much your National Savings Certificate investment can accumulate over time.        ', link: '/financial-tools-calculators/nsc-calculator' },
    { imgSrc: '../assets/setsip.jpeg', title: ' Step Up SIP Calculator      ', description: 'Boost your SIP returns! See how increasing your investment amount annually can grow your savings significantly.        ', link: '/financial-tools-calculators/step-up-sip-calculator' },
    { imgSrc: '../assets/tax.jpeg', title: 'Income Tax Calculator', description: 'Estimate your tax burden in a flash! Use our calculator to simplify your tax calculations.  ', link: '/financial-tools-calculators/income-tax-calculator' },
    { imgSrc: '../assets/gratuity.jpeg', title: '    Gratuity Calculator      ', description: 'Estimate your retirement gratuity! Learn how much you might receive based on your salary and service.        ', link: '/financial-tools-calculators/gratuity-calculator' },
    { imgSrc: '../assets/pention.jpeg', title: 'APY Calculator', description: ' Use our calculator to estimate your monthly contribution for your target retirement pension.        ', link: '/financial-tools-calculators/apy-calculator' },
    { imgSrc: '../assets/cagr.jpeg', title: ' CAGR Calculator      ', description: 'See how your money grows over time! Use our easy-to-use CAGR calculator.        ', link: '/financial-tools-calculators/cagr-calculator' },
    { imgSrc: '../assets/tax2.jpeg', title: 'GST Calculator      ', description: 'GST made easy! Calculate your payable GST amount quickly and effortlessly. ', link: '/financial-tools-calculators/gst-calculator' },
    { imgSrc: '../assets/flat.jpeg', title: ' Flat Reducing Rate Calculator     ', description: 'Plan your loan payments wisely! Our calculator helps you compare EMIs under flat and reducing interest rates.        ', link: '/financial-tools-calculators/flat-vs-reducing-rate-calculator' },
    // { imgSrc: 'https://cdn3d.iconscout.com/3d/premium/thumb/calculator-4086297-3379639.png?f=webp', title: ' Margin     ', description: 'Plan your trades effectively! See how margin can impact your buying power for delivery and intraday stock orders.        ', link: '/financial-tools-calculators/equity-margin-calculator' },
    { imgSrc: '../assets/inflation.jpeg', title: ' Inflation Calculator    ', description: 'Factor in inflation! Our calculator helps you understand the purchasing power of money across different years.   ', link: '/financial-tools-calculators/inflation-calculator' },
    { imgSrc: '../assets/salary.jpeg', title: 'Salary Calculator', description: 'Know what hits your bank! Estimate your take-home pay after taxes and deductions.        ', link: '/financial-tools-calculators/salary-calculator' },
    { imgSrc: '../assets/brokerage.jpeg', title: ' Brokerage Calculator    ', description: 'Trade informed! Use our calculator to understand the fees associated with your stock orders.        ', link: '/financial-tools-calculators/brokerage-calculator' },
    { imgSrc: '../assets/tds.jpeg', title: '      TDS Calculator    ', description: 'Know your TDS liability! Use our tool to get an idea of your potential TDS deductions.        ', link: '/financial-tools-calculators/tds-calculator' },
    

  ];

  return (
    <>
      <Helmet>
        <title>Financial Tools & Calculators – Online Best Financial Calculator | TheFinmaster</title>
        <meta name="description" content="Financial tools and calculators can help you for financial planning and investment at TheFinmaster." />
        <meta name='keywords' content='financial calculator online, financial planning calculator, financial calculator online free' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
      
          <li className="flex items-center">
          <span className="text-gray-500 ">Financial Tools Calculators</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav> 
      <div className=" p-4 md:p-8 lg:p-10 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 flex justify-center items-center">
        <div>
          <h2 className="text-2xl md:text-xl lg:text-6xl text-center mb-4 text-gray-800">Calculators</h2>
          <p className="text-lg md:text-base lg:text-2xl text-center">
            Use our financial calculators to make informed decisions about FDs, PPFs, EMIs, and more!
          </p>
          <p className="text-lg md:text-base lg:text-2xl text-center">
            Plan your savings with ease.
          </p>
        </div>
      </div>

      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-4">
          {boxesData.map((box, index) => (
            <Box key={index} imgSrc={box.imgSrc} title={box.title} description={box.description} link={box.link} />
          ))}
        </div>
      </div>
    </>
  );
}

export default ToolsPages
