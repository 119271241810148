import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const BajajTravelLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: ' Why should I choose Bajaj Allianz Travel Insurance?',
        answer:
            'Bajaj Allianz provides comprehensive travel insurance for both domestic and international trips, covering various risks and inconveniences to ensure a worry-free journey. Our plans, including Travel Companion, Travel Elite, and Student Travel, offer customizable options to suit different needs and provide peace of mind.',
    },
    {
        question: ' How does Bajaj Allianz assist with urgent cash needs abroad?',
        answer:
            'Our Emergency Cash Advance service offers immediate financial support in situations such as theft or loss of cash. We coordinate with your relatives in India to provide emergency cash assistance, up to a specified limit detailed in your policy.',
    },
    {
        question: ' What additional benefits does the Bajaj Allianz Family Travel Insurance policy offer?',
        answer:
            'The Family Travel Insurance policy includes the Golfers Hole-in-One benefit, reimbursing expenses for celebrating a hole-in-one at recognized golf courses worldwide (excluding India). This feature is also available in the Travel Companion and Travel Elite packages.',
    },
    {
      question: 'How can I cancel my Bajaj Allianz Travel Insurance policy before it starts?',
      answer:
          'To cancel your policy before its start date, email Bajaj Allianz with your policy or schedule number. A cancellation fee of Rs. 250 will apply.',
  },
  {
    question: 'What medical conditions are excluded from Bajaj Allianz Travel Insurance coverage?',
    answer:
        'The policy does not cover pre-existing medical conditions or ailments for which treatment or medical advice was received prior to the policy period.',
},



    
  ];
    const benefits = [
      {
        title: 'No Claim Bonus Protection',
      },
      {
        title: 'Roadside Assistance',
      },
      {
        title: 'Repair of Glass Fiber and Plastic Parts',
      },
      {
        title: 'Daily Allowance',
      },
      {
        title: 'Emergency Transport and Hotel Expenses',
      },
      {
        title: 'Key Replacement        ',
      },
      {
        title: 'Engine & Tyre Secure',
      },
      {
        title: 'Loss of Personal Belongings',
      },
      {
        title: 'Return to Invoice',
      },
      // {
      //   title: 'Tyre Secure Cover',
      // },
    ]
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
         <title>Get Bajaj Allianz Travel Insurance | TheFinmaster</title>
        <meta  name='description' content='Get Bajaj Allianz travel insurance with TheFinmaster. Enjoy reliable coverage and peace of mind for your travels. Buy online easily and securely.'/>
        <meta name='keywords' content='bajaj allianz travel insurance, bajaj travel insurance' />
        <link rel="canonical" href="https://thefinmaster.com/travel-insurance/bajaj-allianz-travel-insurance" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <FAQSchema faqs={faqs} />
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/travel-insurance" className="text-blue-600 hover:text-blue-800">Travel Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Bajaj Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Protect Every Aspect of Your Trip with 
                  </span>{" "}
                  Our Robust Insurance Plan!
                </h1>
                <a href="https://www.bajajallianz.com/travel-insurance-online.html"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Get Plan!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/adityahealth1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about Bajaj Travel Insurance</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Bajaj Allianz is committed to delivering transparency, integrity, and simplicity in insurance. We offer a range of products, including travel, motor, health, and home insurance. Our comprehensive travel insurance plan is designed to ensure a worry-free vacation, allowing you to travel globally with complete peace of mind.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Traveling offers a welcome break from routine and can be essential for business or education. However, unexpected issues can disrupt plans and lead to significant financial losses, especially on international trips. Additionally, many countries require travel insurance for visa applications.
                </p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/hospital.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Prices starting at ₹40.82 Per Day


            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Instant Policy-No Medical Test


            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/onspot.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Pay In ₹   Get Covered In $



            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
       
      </motion.div>
    </div>
  </div>
</div>


    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Explore Aditya Birla's Health Insurance Plans</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Here are some top insurance plans provided by Aditya Birla. Click the link below to discover all plans offered by the brand.</p>
            </div>
            <div class="flex justify-center items-center p-4">
  <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[64rem] flex-col sm:flex-row">
    <div class="relative w-full sm:w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-t-xl sm:rounded-r-none bg-clip-border rounded-xl shrink-0">
      <img
        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
        alt="card-image" class="object-cover w-full h-full" />
    </div>
    <div class="p-6">
      <h6 class="block mb-2  text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      Individual Travel Insurance
      </h6>
      {/* <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Lyft launching cross-platform service this week
      </h4> */}
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
       <span className='text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>Coverage :</span> Tailored for solo travelers, this policy offers protection against medical emergencies, trip cancellations, baggage loss, and more.
      </p>
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
       <span className='text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>Benefits :</span> Provides financial security with customized coverage for various travel needs.
      </p>
      <p class="block mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
       <span className='text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>Benefits :</span> Provides financial security with customized coverage for various travel needs.
      <p className='text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'> Plans :</p>
      <span className='text-base antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>1. Travel Care :</span> Affordable medical and emergency coverage abroad. 
      <p><span className='text-base antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>2. Travel Secure :</span> Comprehensive protection for emergencies, lost baggage, trip delays, and unique perks.      </p>
      <p><span className='text-base antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>3. Travel Value :</span> Extensive coverage with $500,000 for medical expenses and $1,500 for emergency cash.      </p>
      <p><span className='text-base antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>4. Travel Asia :</span>  Specialized coverage for travel in Asia, with options for stays from 1 to 30 days.      </p>

      </p>
      <a href="#" class="inline-block"><button
          class="flex items-center gap-2 px-6 py-3  text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none  disabled:shadow-none disabled:pointer-events-none hover:bg-blue-600 bg-blue-500"
          type="button">
          Learn More<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
    </div>
  </div>
</div>
<div class="flex justify-center items-center p-4">
  <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[64rem] flex-col sm:flex-row">
    <div class="relative w-full sm:w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-t-xl sm:rounded-r-none bg-clip-border rounded-xl shrink-0">
      <img
        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
        alt="card-image" class="object-cover w-full h-full" />
    </div>
    <div class="p-6">
      <h6 class="block mb-2  text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      Family Travel Insurance
      </h6>
      {/* <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Lyft launching cross-platform service this week
      </h4> */}
      <p class="block mb-4 text-base antialiased font-normal leading-relaxed text-gray-700">
       <span className='text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>Coverage :</span> This policy is designed for families traveling together, offering comprehensive protection for all family members under a single plan.
      </p>
      <p class="block mb-4 text-base antialiased font-normal leading-relaxed text-gray-700">
       <span className='text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>Benefits :</span> Provides cost-effective and convenient coverage for the entire family, addressing various travel-related risks.
      </p>
      <p class="block mb-4 text-base antialiased font-normal leading-relaxed text-gray-700">
       <span className='text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>Travel Family :</span> Ideal for family vacations abroad, this package covers the entire family (self, spouse up to 60 years, and two children under 21 years). It includes medical coverage and floater benefits for individual family members.
      </p>
      <a href="#" class="inline-block"><button
          class="flex items-center gap-2 px-6 py-3  text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none  disabled:shadow-none disabled:pointer-events-none hover:bg-blue-600 bg-blue-500"
          type="button">
          Learn More<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
    </div>
  </div>
</div>
<div class="flex justify-center items-center p-4">
  <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[64rem] flex-col sm:flex-row">
    <div class="relative w-full sm:w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-t-xl sm:rounded-r-none bg-clip-border rounded-xl shrink-0">
      <img
        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
        alt="card-image" class="object-cover w-full h-full" />
    </div>
    <div class="p-6">
      <h6 class="block mb-2  text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      Senior Citizen Travel Insurance
      </h6>
      {/* <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Lyft launching cross-platform service this week
      </h4> */}
      <p class="block mb-4 text-base antialiased font-normal leading-relaxed text-gray-700">
       <span className='text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>Coverage :</span> Designed for elderly travelers aged 61-70, this policy addresses age-specific health concerns, including medical emergencies and trip interruptions.
      </p>
      <p class="block mb-4 text-base antialiased font-normal leading-relaxed text-gray-700">
       <span className='text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>Benefits :</span> Provides peace of mind for senior citizens by covering potential health issues and ensuring a worry-free travel experience.
      </p>
     
      <a href="#" class="inline-block"><button
          class="flex items-center gap-2 px-6 py-3  text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none  disabled:shadow-none disabled:pointer-events-none hover:bg-blue-600 bg-blue-500"
          type="button">
          Learn More<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
    </div>
  </div>
</div>
<div class="flex justify-center items-center p-4">
  <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[64rem] flex-col sm:flex-row">
    <div class="relative w-full sm:w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-t-xl sm:rounded-r-none bg-clip-border rounded-xl shrink-0">
      <img
        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
        alt="card-image" class="object-cover w-full h-full" />
    </div>
    <div class="p-6">
      <h6 class="block mb-2  text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      Student Travel Insurance
      </h6>
      {/* <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Lyft launching cross-platform service this week
      </h4> */}
      <p class="block mb-4 text-base antialiased font-normal leading-relaxed text-gray-700">
       <span className='text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>Coverage :</span> Designed for students studying abroad, this policy includes protection for medical emergencies, tuition fees, and other education-related expenses.
      </p>
      <p class="block mb-4 text-base antialiased font-normal leading-relaxed text-gray-700">
       <span className='text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>Benefits :</span> Tailored to meet the unique needs of students, offering support for both academic and non-academic challenges.
      </p>
    
      <a href="#" class="inline-block"><button
          class="flex items-center gap-2 px-6 py-3  text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none  disabled:shadow-none disabled:pointer-events-none hover:bg-blue-600 bg-blue-500"
          type="button">
          Learn More<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
    </div>
  </div>
</div>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>


        </>
    )
}

export default BajajTravelLearn
