import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const HdfcErgoTravelLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: 'What are the cancellation policies for HDFC Ergo travel insurance?',
        answer:
            'To cancel your policy, you must submit a cancellation request via email or fax to HDFC Ergo within 14 days of policy issuance. If travel has already commenced, a photocopy of 40 pages of the insureds passport is required as proof of non-journey. A cancellation fee of Rs.250 will be deducted from the refunded amount.',
    },
    {
        question: 'Can I extend my HDFC Ergo travel insurance policy?',
        answer:
            'Yes, you can extend your policy by sending an extension request email to HDFC Ergo customer care along with a health declaration. HDFC Ergo will provide instructions and inform you of any additional premium required.',
    },
    {
        question: 'Is it possible to reduce the coverage period of my plan?',
        answer:
            'No, HDFC Ergo does not offer the option to reduce the coverage period of the plan, as this policy aims to mitigate risks.',
    },
    {
        question: 'Will my claim be settled upon my return to India?',
        answer:
            'Claims are settled based on the receipt of original documents. Ensure you submit all required documents to facilitate the claim process.',
    },
    {
        question: 'What is a deductible, and can the sum insured be increased?',
        answer:
            'A deductible is the portion of the claim that the insured is responsible for paying. HDFC Ergo does not offer an option to increase the sum insured under its travel insurance policy.',
    },
    {
        question: 'How do I extend the policy period of Student Suraksha travel insurance?',
        answer:
            'Extension of the Student Suraksha policy is subject to several conditions: the original policy must remain valid, and a health declaration certified by a physician is required. Additionally, no claims should have been made during the initial policy period, and the extension premium must be paid before the extension becomes effective. The total duration of the policy, including the extension, cannot exceed 2 years, and extensions are permitted only once during the policy term.',
    },
    {
        question: 'What support does HDFC Ergo provide if my luggage is lost or delayed while traveling abroad?',
        answer:
            'HDFC Ergo travel insurance covers expenses for replacing lost documents and luggage, and provides compensation for permanently lost checked-in baggage. For delayed checked-in baggage exceeding 12 hours, reasonable compensation is available for expenses incurred for toiletries, medicines, and clothing.',
    },


    
  ];
    const benefits = [
      {
        title: 'No Claim Bonus Protection',
      },
      {
        title: 'Roadside Assistance',
      },
      {
        title: 'Repair of Glass Fiber and Plastic Parts',
      },
      {
        title: 'Daily Allowance',
      },
      {
        title: 'Emergency Transport and Hotel Expenses',
      },
      {
        title: 'Key Replacement        ',
      },
      {
        title: 'Engine & Tyre Secure',
      },
      {
        title: 'Loss of Personal Belongings',
      },
      {
        title: 'Return to Invoice',
      },
      // {
      //   title: 'Tyre Secure Cover',
      // },
    ]
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
         <title>AGet HDFC EGRO Travel Insurance | TheFinmaster</title>
        <meta  name='description' content='Get HDFC ERGO travel insurance with TheFinmaster. Enjoy comprehensive coverage for your travels. Buy online quickly and securely.'/>
        <meta name='keywords' content='hdfc ergo travel insurance, hdfc travel insurance, hdfc ergo international travel insurance' />
        <link rel="canonical" href="https://thefinmaster.com/travel-insurance/hdfc-ergo-travel-insurance" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <FAQSchema faqs={faqs} />
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/travel-insurance" className="text-blue-600 hover:text-blue-800">Travel Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">HDFC ERGO Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  From vacations to business trips, HDFC ERGO has you
                  </span>{" "}
                  covered for international travel!
                </h1>
                <a href="https://www.hdfcergo.com/campaigns/buy-travel-insurance-new?utm_source=google_search_1&utm_medium=cpc&utm_campaign=Travel_Search_Brand_Neev-Broad-1&utm_adgroup=Generic&adid=607777551484&utm_term=hdfc%20ergo%20travel%20insurance&utm_network=g&utm_matchtype=e&utm_device=c&utm_location=9061698&utm_sitelink={sitelink}&utm_placement=&ci=googlesearch&SEM=1&gad_source=1&gclid=Cj0KCQjwh7K1BhCZARIsAKOrVqFQuwpU3gUV8YqDQBziCEIFPHjkCselaobrFqDwvuRLExUDEG9UtUwaAvjrEALw_wcB"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Get Plan!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/adityahealth1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about HDFC ERGO Travel Insurance</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Traveling is universally celebrated as a premier way to escape the routine of daily life, relieve stress, and create lasting memories. However, venturing to new destinations can also present unforeseen risks and challenges. </p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">HDFC Ergo provides comprehensive travel insurance policies designed to protect you from various travel-related risks. Our coverage ensures that you can enjoy your vacation with peace of mind, fully safeguarded against potential threats and unexpected situations.
                </p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/hospital.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
        
            1.5 Crore+ Happy Customers

            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            1 Lac+ Cashless Hospitals


            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/onspot.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            24x7 In-house Claim Assistance  



            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
       
      </motion.div>
    </div>
  </div>
</div>

    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Explore Aditya Birla's Health Insurance Plans</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Here are some top insurance plans provided by Aditya Birla. Click the link below to discover all plans offered by the brand.</p>
            </div>
            
            <div class="flex justify-center items-center p-4">
            <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[64rem] flex-col sm:flex-row">
    <div class="relative w-full sm:w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-t-xl sm:rounded-r-none bg-clip-border rounded-xl shrink-0">
      <img
        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
        alt="card-image" class="object-cover w-full h-full" />
    </div>
    <div class="p-6">
      <h6 class="block mb-2  text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      Travel Insurance for Families: Ensuring Safety and Peace of Mind      </h6>
      {/* <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Lyft launching cross-platform service this week
      </h4> */}
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      Family vacations create cherished memories that endure for years. With HDFC ERGO Family Travel Insurance, you can ensure that your loved ones are protected throughout your journey.            </p>
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      This policy provides comprehensive coverage, offering peace of mind as you and your family embark on the vacation of your dreams.
      </p>
      <a href="#" class="inline-block"><button
          class="flex items-center gap-2 px-6 py-3  text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none  disabled:shadow-none disabled:pointer-events-none hover:bg-blue-600 bg-blue-500"
          type="button">
          Learn More<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
    </div>
  </div>
  </div>
  <div class="flex justify-center items-center p-4">
            <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[64rem] flex-col sm:flex-row">
    <div class="relative w-full sm:w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-t-xl sm:rounded-r-none bg-clip-border rounded-xl shrink-0">
      <img
        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
        alt="card-image" class="object-cover w-full h-full" />
    </div>
    <div class="p-6">
      <h6 class="block mb-2  text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">

Travel Insurance for Students: Secure Your Study Abroad Experience
      </h6>
      {/* <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Lyft launching cross-platform service this week
      </h4> */}
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      Embarking on higher education abroad requires more than just academic preparation. Ensure a smooth and secure stay with travel insurance designed for students.             </p>
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      HDFC ERGO Student Travel Insurance safeguards your extended stay, allowing you to focus solely on your studies and academic success.
      </p>
      <a href="#" class="inline-block"><button
          class="flex items-center gap-2 px-6 py-3  text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none  disabled:shadow-none disabled:pointer-events-none hover:bg-blue-600 bg-blue-500"
          type="button">
          Learn More<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
    </div>
  </div>
  </div>
  <div class="flex justify-center items-center p-4">
            <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[64rem] flex-col sm:flex-row">
    <div class="relative w-full sm:w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-t-xl sm:rounded-r-none bg-clip-border rounded-xl shrink-0">
      <img
        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
        alt="card-image" class="object-cover w-full h-full" />
    </div>
    <div class="p-6">
      <h6 class="block mb-2  text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      Individual Travel Insurance 
      </h6>
      {/* <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Lyft launching cross-platform service this week
      </h4> */}
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      For solo travelers seeking new adventures, HDFC ERGO Individual Travel Insurance provides comprehensive coverage designed to enhance your journey.             </p>
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      With a range of built-in benefits, this policy ensures a smooth and seamless travel experience, making it the ideal companion for your explorations. 
      </p>
      <a href="#" class="inline-block"><button
          class="flex items-center gap-2 px-6 py-3  text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none  disabled:shadow-none disabled:pointer-events-none hover:bg-blue-600 bg-blue-500"
          type="button">
          Learn More<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
    </div>
  </div>
  </div>
  <div class="flex justify-center items-center p-4">
            <div class="relative flex bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full max-w-[64rem] flex-col sm:flex-row">
    <div class="relative w-full sm:w-2/5 m-0 overflow-hidden text-gray-700 bg-white rounded-t-xl sm:rounded-r-none bg-clip-border rounded-xl shrink-0">
      <img
        src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
        alt="card-image" class="object-cover w-full h-full" />
    </div>
    <div class="p-6">
      <h6 class="block mb-2  text-base antialiased font-semibold leading-relaxed tracking-normal text-gray-700 uppercase">
      
Travel Insurance for Frequent Flyers

      </h6>
      {/* <h4 class="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Lyft launching cross-platform service this week
      </h4> */}
      <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
      For those who travel frequently, the HDFC ERGO Annual Multi-Trip Insurance offers a tailored solution. 
      </p>
            <p class="block mb-1 text-base antialiased font-normal leading-relaxed text-gray-700">
            This plan provides coverage for multiple trips within a year under a single, comprehensive policy. Enjoy the convenience of easy renewals, streamlined in-house claim settlements, and extensive coverage for all your journeys.
            </p>
      <a href="#" class="inline-block"><button
          class="flex items-center gap-2 px-6 py-3  text-xs font-bold text-center text-white uppercase align-middle transition-all rounded-lg select-none  disabled:shadow-none disabled:pointer-events-none hover:bg-blue-600 bg-blue-500"
          type="button">
          Learn More<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
        </svg></button></a>
    </div>
  </div>
  </div>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>


        </>
    )
}

export default HdfcErgoTravelLearn
