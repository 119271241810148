// src/StepUpSIPCalculator.js
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(
  ArcElement,
  ChartTooltip,
  Legend
);

const StepUpSIPCalculator = () => {
  const [monthlyInvestment, setMonthlyInvestment] = useState(5000);
  const [annualStepUp, setAnnualStepUp] = useState(10); // Step-up percentage
  const [expectedReturnRate, setExpectedReturnRate] = useState(12); // Expected return rate (per annum)
  const [timePeriod, setTimePeriod] = useState(10); // Time period in years
  const [investedAmount, setInvestedAmount] = useState(0);
  const [estimatedReturns, setEstimatedReturns] = useState(0);
  const [totalValue, setTotalValue] = useState(0);

  const calculateSIP = () => {
    let totalInvested = 0;
    let futureValue = 0;
    const monthlyRate = expectedReturnRate / 12 / 100;
    let currentMonthlyInvestment = monthlyInvestment;

    for (let year = 1; year <= timePeriod; year++) {
      for (let month = 1; month <= 12; month++) {
        totalInvested += currentMonthlyInvestment;
        futureValue += currentMonthlyInvestment * Math.pow((1 + monthlyRate), (timePeriod * 12 - ((year - 1) * 12 + month)));
      }
      currentMonthlyInvestment += (currentMonthlyInvestment * annualStepUp / 100);
    }

    const totalEstReturns = futureValue - totalInvested;

    setInvestedAmount(totalInvested.toFixed(2));
    setEstimatedReturns(totalEstReturns.toFixed(2));
    setTotalValue(futureValue.toFixed(2));
  };

  const handleMonthlyInvestmentChange = (e) => {
    setMonthlyInvestment(parseInt(e.target.value));
  };

  const handleAnnualStepUpChange = (e) => {
    setAnnualStepUp(parseFloat(e.target.value));
  };

  const handleExpectedReturnRateChange = (e) => {
    setExpectedReturnRate(parseFloat(e.target.value));
  };

  const handleTimePeriodChange = (e) => {
    setTimePeriod(parseInt(e.target.value));
  };

  const data = {
    labels: ['Invested Amount', 'Estimated Returns'],
    datasets: [
      {
        label: 'SIP Breakdown',
        data: [investedAmount, estimatedReturns],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <>
    <Helmet>
         <title>SIP Top-up Calculator: Step Up SIP Calculator, Calculate Your SIP Online | TheFinmaster</title>
        <meta  name='description' content='Top-up SIP Calculator is also known as step up SIP calculator. It helps you calculate the returns of your SIP investments if you topping up your SIPs periodically. | TheFinmaster'/>
        <meta name='keywords' content='mutual fund calculator, lumpsum calculator, swp calculator, systematic withdrawal plan calculator, state bank of india sip calculator, systematic investment plan sbi calculator' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/step-up-sip-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Set Up SIP Calculator</span>
        </li> 
      
      </ol>
    </nav>
    <div className='py-4'>
        
    <div className="container mx-auto p-4 shadow-xl rounded-md">
      <h1 className="text-2xl font-bold mb-4">Step Up SIP Calculator</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Monthly Investment</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                value={monthlyInvestment}
                onChange={handleMonthlyInvestmentChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">₹ {monthlyInvestment.toLocaleString()}</span>
            </div>
            <input
              type="range"
              min="0"
              max="100000"
              value={monthlyInvestment}
              onChange={(e) => setMonthlyInvestment(parseInt(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Annual Step-Up (%)</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                max="100"
                step="0.1"
                value={annualStepUp}
                onChange={handleAnnualStepUpChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{annualStepUp} %</span>
            </div>
            <input
              type="range"
              min="0"
              max="100"
              step="0.1"
              value={annualStepUp}
              onChange={(e) => setAnnualStepUp(parseFloat(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Expected Return Rate (P.A.)</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                max="100"
                step="0.1"
                value={expectedReturnRate}
                onChange={handleExpectedReturnRateChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{expectedReturnRate} %</span>
            </div>
            <input
              type="range"
              min="0"
              max="100"
              step="0.1"
              value={expectedReturnRate}
              onChange={(e) => setExpectedReturnRate(parseFloat(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Time Period (Years)</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                max="30"
                value={timePeriod}
                onChange={handleTimePeriodChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{timePeriod} Yr</span>
            </div>
            <input
              type="range"
              min="0"
              max="30"
              value={timePeriod}
              onChange={(e) => setTimePeriod(parseInt(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <button
            onClick={calculateSIP}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Calculate
          </button>
        </div>
        <div>
          <div className="mb-4">
            <h2 className="text-xl font-bold">Results</h2>
            <p>Invested Amount: ₹ {parseFloat(investedAmount).toLocaleString()}</p>
            <p>Estimated Returns: ₹ {parseFloat(estimatedReturns).toLocaleString()}</p>
            <p>Total Value (Invested Amount + Returns): ₹ {parseFloat(totalValue).toLocaleString()}</p>
          </div>
          <div>
            <h2 className="text-xl font-bold">Breakdown</h2>
            <div className="flex justify-center">
              <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    
    <div className="flex flex-wrap px-6 py-6" >
     <div className="w-full md:w-2/3 p-4">
       <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Grow Your Wealth Gradually with TheFinMaster's Step-Up SIP Calculator
 </h1>
 {/* <p className="text-lg font-normal mt-2 text-gray-700 ">Invest Smarter, Grow Bigger:</p> */}

       <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">A Systematic Investment Plan (SIP) allows you to invest small amounts regularly in mutual funds. TheFinMaster's free Step-Up SIP calculator helps you see how gradually increasing your SIP amount can significantly boost your long-term returns.
</p>


       <div className="container mx-auto  py-4">
         <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using a Step-Up SIP Calculator:

</h1>

         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>    Plan for Growth:</span> Visualize how increasing your SIP amount along with your income can accelerate your investment growth.
         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'> Achieve Your Goals:</span>  See how a Step-Up SIP strategy can help you reach your financial goals faster.
           </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>  Make Informed Decisions:</span>      Estimate your potential returns with different SIP increase percentages before you invest.

         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>   Easy to Use:</span>     Get instant results without complex calculations.


         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>     Free and Accessible: </span>  Use the calculator anytime, anywhere to explore different investment scenarios.
         </p>
       </div>
       <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How the Calculator Works:
</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Simply enter your initial SIP amount, investment timeframe, and desired annual SIP increase percentage. Our calculator will project your future returns, including the impact of gradually increasing your investment.
            </p>
          </div>
      
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Grow Beyond the Basics:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">TheFinMaster offers a variety of investment tools and resources to help you create a personalized investment strategy.
            </p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Ready to Get Started?</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Use TheFinMaster's free Step-Up SIP calculator today! See the power of gradual investing and take control of your financial future.


            </p>
          </div>
     

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Additional Notes:</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Removed unnecessary information about SIPs in general.         </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Simplified the explanation of how a Step-Up SIP works.
    </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Focused on the user benefits and a clear call to action.
      </p>
             
          </div>

       


     </div>

     <div className="w-full md:w-1/3 p-4">
       <div className="bg-white shadow-md rounded-lg overflow-hidden">
         <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
           <h2 className="text-lg font-semibold ">Popular Categories</h2>
         </div>
         <div className="p-2 ">
         <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
             {/* {moreCategories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
           </ul>
         </div>
       </div>

     </div>

   </div>
        </>
  );
};

export default StepUpSIPCalculator;
