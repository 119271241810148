// src/Sitemap.js
import React from 'react';

const SiteMapUi = () => {
  return (
    <>
    <div className="p-6 ">
        <div className='bg-white shadow-md rounded-md px-6'>
      <h1 className="text-2xl font-bold text-red-600 mb-4">Sitemap</h1>
      <div className="space-y-8">
        <div>
          <h2 className="text-xl font-semibold">Credit Card</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/" className="text-blue-500 hover:underline">Insurance - Compare Insurance Plans and Policies Online</a></li>
            <li><a href="https://thefinmaster.com/credit-card" className="text-blue-500 hover:underline">Compare & Apply Credit Cards Online for Instant Approval</a></li>
            <li><a href="https://thefinmaster.com/credit-card/best-cashback-credit-card" className="text-blue-500 hover:underline">Best Cashback Credit Cards in India 2024 - Features & Benefits</a></li>
            <li><a href="https://thefinmaster.com/credit-card/travel-credit-card" className="text-blue-500 hover:underline">Best Travel Credit Cards in India 2024 | Compare &amp; Apply</a></li>
            <li><a href="https://thefinmaster.com/credit-card/fuel-credit-card" className="text-blue-500 hover:underline">Best Fuel Credit Cards in India 2024 | Apply Now &amp; Earn Free Fuel!</a></li>
            <li><a href="https://thefinmaster-com/credit-card/best-lifetime-free-credit-cards" className="text-blue-500 hover:underline">Lifetime Free credit cards come with nil annual and joining fees | Finmaster</a></li>
            <li><a href="https://thefinmaster.com/credit-card/best-premium-credit-card" className="text-blue-500 hover:underline">Best Premium Credit Cards in India 2024 - Exclusive Benefits</a></li>

          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Credit Card Bank Details</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/credit-card/aubank-credit-card" className="text-blue-500 hover:underline">AU Bank Credit Card - Features &amp; Apply Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/credit-card/eazy-diner-indusind-bank-credit-card" className="text-blue-500 hover:underline">EazyDiner IndusInd Bank Credit Cards: Features, Benefits, Fees &amp;Charges</a></li>
            <li><a href="https://thefinmaster.com/credit-card/hdfc-bank/millennia-credit-card" className="text-blue-500 hover:underline">HDFC Millennia Credit Card - Key Features & Benefits</a></li>
            <li><a href="https://thefinmaster.com/credit-card/sbi-simplyclick-credit-card" className="text-blue-500 hover:underline">SBI SimplyClick Card - Check Benefits & Apply Online</a></li>
            <li><a href="https://thefinmaster.com/credit-card/idfcfirst-classic-credit-card" className="text-blue-500 hover:underline">IDFC FIRST Classic Credit Card - Benefits &amp; Apply Online</a></li>
            <li><a href="https://thefinmaster.com/credit-card/axis-bank-flipkart-credit-card" className="text-blue-500 hover:underline">Flipkart Axis Bank Credit Card - Apply Online for Cashback</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> More Bank Details</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/au-bank/credit-card" className="text-blue-500 hover:underline">Get Best AU Bank Credit Card 2024 - Compare &amp; Apply Now at TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/bank-of-baroda/credit-card" className="text-blue-500 hover:underline">Get Bank of Baroda (BOB) Credit Card 2024 - Compare &amp; Apply Online at TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/american-bank/credit-card" className="text-blue-500 hover:underline">Get American Express Credit Card: Check Eligibility &amp; Apply Online at TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/hsbc-bank/credit-card" className="text-blue-500 hover:underline">Get HSBC Credit Card - Apply Online for Best Credit Cards 2024 at TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/standard-bank/credit-card" className="text-blue-500 hover:underline">Get Standard Chartered Bank Credit Card Offers - Deals &amp; Vouchers | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/yes-bank/credit-card" className="text-blue-500 hover:underline">Get YES Bank Credit Cards - Rewards, Cashback, Benefits & Apply Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/induslnd-bank/credit-card" className="text-blue-500 hover:underline">Get IndusInd bank Credit Card Online | Get Rewards, Benefits & More | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/sbi-bank/credit-card" className="text-blue-500 hover:underline">Get SBI Credit Card - Features, Eligibility & Apply Online - 2024 | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/idfc-bank/credit-card" className="text-blue-500 hover:underline">Get IDFC FIRST Credit Card - Key Features and Benefits | Apply Online 2024 | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/icici-bank/credit-card" className="text-blue-500 hover:underline">Get ICICI Credit Card - Check Features &amp; Apply Online  2024 | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/axix-bank/credit-card" className="text-blue-500 hover:underline">Get Axis Bank Credit Card 2024 - Eligibility, Features &amp; Apply | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/hdfc-bank/credit-card" className="text-blue-500 hover:underline">Get HDFC Credit Cards in  2024: Apply Online | Check Offers | TheFinmaster</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Demat & Demat Account Details</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/demat" className="text-blue-500 hover:underline">What is Demat Account - Meaning, Features, Types, Benefits</a></li>
            <li><a href="https://thefinmaster.com/demat/upstox-demat-account" className="text-blue-500 hover:underline">Upstox Demat Account: Opening Proces, Charges &amp; Documents</a></li>
            <li><a href="https://thefinmaster.com/demat/angel-one-demat-account" className="text-blue-500 hover:underline">Angel One Demat Account Review, Opening Charges, Details</a></li>
            <li><a href="https://thefinmaster.com/demat/groww-demat-account" className="text-blue-500 hover:underline">Groww Demat Account Review, Opening Charges, Details</a></li>
            <li><a href="https://thefinmaster.com/demat/fyers-demat-account" className="text-blue-500 hover:underline">Fyers Demat Account Review, Opening Charges, Details</a></li>
            <li><a href="https://thefinmaster.com/demat/zeroda-demat-account" className="text-blue-500 hover:underline">Zerodha Demat Account Review, Opening Charges, Details</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Car Insurance</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/car-insurance" className="text-blue-500 hover:underline">Car Insurance Online - Buy/Renew Car Insurance Policy & Save Upto 60%</a></li>
            <li><a href="https://thefinmaster.com/car-insurance/bajaj-allianz-car-insurance" className="text-blue-500 hover:underline">Bajaj Allianz Car Insurance - Renew Bajaj Allianz Motor Insurance Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/car-Insurance/icici-lombard-car-insurance" className="text-blue-500 hover:underline">ICICI Lombard Car Insurance - ICICI Car Insurance | Renewal</a></li>
            <li><a href="https://thefinmaster.com/car-insurance/acko-car-insurance" className="text-blue-500 hover:underline">Acko Car Insurance - Plans, Features and Benefits</a></li>
            <li><a href="https://thefinmaster.com/car-insurance/hdfc-car-insurance" className="text-blue-500 hover:underline">HDFC ERGO Car Insurance - Plans, Features and Benefits | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/car-insurance/tata-aij-car-insurance" className="text-blue-500 hover:underline">Tata Aig Car Insurance - Renew Tata AIG Motor Insurance Online at TheFinmaster</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Life Insurance</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/life-Insurance" className="text-blue-500 hover:underline">Life Insurance: Best Life Insurance Policy in India 2024</a></li>
            <li><a href="https://thefinmaster.com/life-insurance/icici-life-insurance" className="text-blue-500 hover:underline">ICICI Prudential Life Insurance: Plan Details, Premium &amp; Benefits | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/life-insurance/hdfc-life-insurance" className="text-blue-500 hover:underline">HDFC Life Insurance Online: Policy Details, Premium &amp; Benefits | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/life-insurance/maxlife-insurance" className="text-blue-500 hover:underline">Max Life Insurance: Policy Details, Premium &amp; Benefits | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/life-insurance/adityalife-insurance" className="text-blue-500 hover:underline">Aditya Birla Sun Life Insurance: Policy Details, Premium &amp; Benefits | TheFinmaster</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Bike Insurance</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/bike-insurance" className="text-blue-500 hover:underline">Bike Insurance Online: Buy/Renew Two Wheeler Insurance at ₹547*</a></li>
            <li><a href="https://thefinmaster.com/bike-insurance/icici-bike-insurance" className="text-blue-500 hover:underline">ICICI Lombard Two Wheeler Insurance | Reviews, Renewal | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/bike-insurance/bajaj-bike-insurance" className="text-blue-500 hover:underline">Bajaj Allianz Bike Insurance: Buy/Renew Bajaj Allianz Two-Wheeler | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/bike-insurance/Acko-bike-insurance" className="text-blue-500 hover:underline">ACKO Two Wheeler Insurance - Complete Information | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/bike-insurance/hdfc-bike-insurance" className="text-blue-500 hover:underline">HDFC ERGO Two Wheeler Insurance: Reviews and Online Renewal | Buy Now | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/bike-insurance/future-bike-insurance" className="text-blue-500 hover:underline">Future Generali Two Wheeler Insurance – Renewal, Review Online at TheFinmaster</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Health Insurance</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/health-insurance" className="text-blue-500 hover:underline">Health Insurance: Buy Best Health Insurance Plan Online in India May, 2024</a></li>
            <li><a href="https://thefinmaster.com/health-insurance/digit-health-insurance" className="text-blue-500 hover:underline">Digit Health Insurance: Buy Online, Reviews &amp; Claim Benefits | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/health-insurance/care-health-insurance" className="text-blue-500 hover:underline">Care Health Care Plan (Religare): Benefits, Premium, Online Renewal</a></li>
            <li><a href="https://thefinmaster.com/health-insurance/aditya-health-insurance" className="text-blue-500 hover:underline">Aditya Birla Health Insurance Plan: Features, Riders & Support | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/health-insurance/hdfc-ergo-health-insurance" className="text-blue-500 hover:underline">HDFC ERGO Health Insurance Plan: Features, Riders & Support | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/health-insurance/reliance-health-insurance" className="text-blue-500 hover:underline">Reliance Health Insurance - Check Plans, Benefits &amp; Reviews | TheFinmaster</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Travel Insurance</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/travel-insurance" className="text-blue-500 hover:underline">Get Travel Insurance- Buy Best Travel Insurance Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/travel-insurance/bajaj-allianz-travel-insurance" className="text-blue-500 hover:underline">Get Bajaj Allianz Travel Insurance | TheFinmaster &amp; Claim Benefits | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/travel-insurance/tata-aij-travel-insurance" className="text-blue-500 hover:underline">Get TATA AIG Travel Insurance | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/travel-insurance/care-health-travel-insurance" className="text-blue-500 hover:underline">Get Care Travel Insurance | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/travel-insurance/hdfc-ergo-travel-insurance" className="text-blue-500 hover:underline">Get HDFC EGRO Travel Insurance | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/travel-insurance/icici-lombard-travel-insurance" className="text-blue-500 hover:underline">Get ICICI Lambard Travel Insurance | TheFinmaster</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Car Loan</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/car-loan" className="text-blue-500 hover:underline">Car Loan EMI, Best Interest Rates for Auto/Vehicle Loans in India May 2024 starting 9% - Thefinmaster</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Home Loan</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/home-loan" className="text-blue-500 hover:underline">Home Loan - Compare & Apply Lowest Interest Rate @8.50%</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Personal Loan</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/personal-loan" className="text-blue-500 hover:underline">Personal Loan - Compare & Apply to Get the Best Interest Rates</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Education Loan</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/education-loan" className="text-blue-500 hover:underline">Apply For Student Education Loan Online in India | Thefinmaster</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Two Wheeler Loan </h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/two-wheeler-loan" className="text-blue-500 hover:underline">Bike Loan – Attractive Interest Rates, EMI for Two-Wheeler Loans in India 2024 - Thefinmaster</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Blog </h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/blog_page" className="text-blue-500 hover:underline">TheFinmaster Blog - Articles on Life Insurance, Term Insurance, Health Insurance, Moter Insurance, Bike Insurance,Tax, Investment, Retirement Planning</a></li>
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-semibold"> Financial Tools Calculators </h2>
          <ul className="list-disc pl-5 space-y-2">
            <li><a href="https://thefinmaster.com/financial-tools-calculators" className="text-blue-500 hover:underline">Financial Tools & Calculators – Online Best Financial Calculator | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/tds-calculator" className="text-blue-500 hover:underline">TDS Tax Calculator- Calculation of Interest on TDS I TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/inflation-calculato" className="text-blue-500 hover:underline">Inflation Calculator - Calculate Future Value Online | Nippon India Mutual Fund</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/salary-calculator" className="text-blue-500 hover:underline">In Hand Salary Calculator - Calculate Your Take Home Salary | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/flat-vs-reducing-rate-calculator" className="text-blue-500 hover:underline">Flat Interest Rate vs Reducing Balance Interest Rate Free Calculator | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/gst-calculator" className="text-blue-500 hover:underline">Online GST Calculator | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/cagr-calculator" className="text-blue-500 hover:underline">CAGR Calculator: Calculate Compound Annual Growth Rate Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/apy-calculator" className="text-blue-500 hover:underline">Atal Pension Yojana (APY) Calculator Online - TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/gratuity-calculator" className="text-blue-500 hover:underline">Gratuity Calculator: Gratuity Calculator Online In India | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/income-tax-calculator" className="text-blue-500 hover:underline">Income Tax Calculator - Calculate Income Tax Online for FY 2024-25 &amp; | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/step-up-sip-calculator" className="text-blue-500 hover:underline">SIP Top-up Calculator: Step Up SIP Calculator, Calculate Your SIP Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/nsc-calculator" className="text-blue-500 hover:underline">NSC Calculator: Calculate National Savings Certificate returns | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/compound-interest-calculator" className="text-blue-500 hover:underline">Compound Interest Calculator Online- Daily, Monthly & Yearly | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/simple-interest-calculator" className="text-blue-500 hover:underline">simple interest loan payment calculator- Calculate Simple Interest Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/home-loan-emi-calculator" className="text-blue-500 hover:underline">EMI Calculator for Home Loan, Car Loan &amp; Personal Loan in India | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/car-loan-emi-calculator" className="text-blue-500 hover:underline">Car Loan EMI Calculator: Online Car EMI Calculator For New Cars - TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/emi-calculator" className="text-blue-500 hover:underline">Calculate EMI on Car, Home & Personal Loan | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/retirement-planning-calculator" className="text-blue-500 hover:underline">Retirement Planner Calculator India | Retirement Savings Calculator Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/hra-exemption-calculator" className="text-blue-500 hover:underline">HRA Exemption Calculator - Calculate House Rent Allowance Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/nps-calculator" className="text-blue-500 hover:underline">NPS Calculator - National Pension Scheme Calculator | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/rd-calculator" className="text-blue-500 hover:underline">RD Calculator- Reccuring Deposit Interest Rates Calculator Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/fd-calculator" className="text-blue-500 hover:underline">FD Calculator- Fixed Deposit Intrest calculator Online At TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/pf-epf-calculator" className="text-blue-500 hover:underline">EPF or PF Calculator: Calculate Employee Provident and Monthly PF Fund Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/ppf-calculator" className="text-blue-500 hover:underline">PPF Calculator- Public Provident Fund Calculator Online | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/sukanya-samriddhi-yojana-calculator" className="text-blue-500 hover:underline">Sukanya Samriddhi Yojana Calculator-SSY Calculator Online 2024 | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/mutual-fund-calculator" className="text-blue-500 hover:underline">Mutual Fund Investment Return Calculator 2024 | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/swp-calculator" className="text-blue-500 hover:underline">Systematic Withdrawal Plan Calculator- SWP Calculator Online 2024 | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/lumpsum-calculator" className="text-blue-500 hover:underline">Lumpsum Calculator Online- Best SIP and Lumpsum Calculator | TheFinmaster</a></li>
            <li><a href="https://thefinmaster.com/financial-tools-calculators/sip-calculator" className="text-blue-500 hover:underline">SIP Calculator- Calculate Returns on SIP Investments</a></li>

          </ul>
        </div>
        
      </div>
    </div>
    </div>
    </>
  );
};

export default SiteMapUi;
