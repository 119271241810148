import React, { useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(
  ArcElement,
  ChartTooltip,
  Legend
);

const HomeLoanEMICalculator = () => {
  const [loanAmount, setLoanAmount] = useState(5000000);
  const [interestRate, setInterestRate] = useState(7.5);
  const [loanTenure, setLoanTenure] = useState(20);
  const [monthlyEMI, setMonthlyEMI] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [amortizationSchedule, setAmortizationSchedule] = useState([]);
  const [activeYear, setActiveYear] = useState(null);

  const calculateEMI = () => {
    const principal = loanAmount;
    const annualInterest = interestRate / 100;
    const monthlyInterest = annualInterest / 12;
    const tenureInMonths = loanTenure * 12;

    const emi =
      (principal * monthlyInterest * Math.pow(1 + monthlyInterest, tenureInMonths)) /
      (Math.pow(1 + monthlyInterest, tenureInMonths) - 1);

    const totalAmount = emi * tenureInMonths;
    const totalInterest = totalAmount - principal;

    setMonthlyEMI(emi.toFixed(2));
    setTotalInterest(totalInterest.toFixed(2));
    setTotalAmount(totalAmount.toFixed(2));

    const schedule = [];
    let balance = principal;
    // let year = new Date().getFullYear();
    let currentYear = new Date().getFullYear();

    let currentMonth = 6;

    for (let i = 0; i < tenureInMonths; i++) {
      const interestForMonth = balance * monthlyInterest;
      const principalForMonth = emi - interestForMonth;
      balance -= principalForMonth;

      schedule.push({
        // month: i + 1,
        // year: year,
        month: (currentMonth + i) % 12 + 1, // Ensure month is in range 1-12
        year: currentYear + Math.floor((currentMonth + i) / 12),
        principalPaid: principalForMonth,
        interestPaid: interestForMonth,
        totalPayment: emi,
        balance: balance,
      });

      // if ((i + 1) % 12 === 0) {
      //   year++;
      // }
    }

    setAmortizationSchedule(schedule);
  };

  const handleLoanAmountChange = (e) => {
    const value = e.target.value;
    setLoanAmount(value === '' ? 0 : parseInt(value));
  };

  const handleInterestRateChange = (e) => {
    const value = e.target.value;
    setInterestRate(value === '' ? 0 : parseFloat(value));
  };

  const handleLoanTenureChange = (e) => {
    const value = e.target.value;
    setLoanTenure(value === '' ? 0 : parseInt(value));
  };

  const handleYearToggle = (year) => {
    setActiveYear(activeYear === year ? null : year);
  };

  const data = {
    labels: ['Principal Amount', 'Total Interest'],
    datasets: [
      {
        label: 'EMI Breakdown',
        data: [loanAmount, totalInterest],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };
  const categories = [
    { id: 1, name: 'Category 1', link: '/' },
    { id: 2, name: 'Category 2', link: '/' },
    { id: 3, name: 'Category 3', link: '/' },
    { id: 4, name: 'Category 4', link: '/' },
    // Add more categories as needed
  ];

  return (
    <>

<Helmet>
         <title>EMI Calculator for Home Loan, Car Loan &amp; Personal Loan in India | TheFinmaster</title>
        <meta  name='description' content='Home Loan EMI Calculator helps you calculate EMI of your housing loan required to pay for your dream home. Know about Online Home Loan EMI Calculator here! | TheFinmaster'/>
        <meta name='keywords' content='hdfc home loan calculator, home loan emi calculator, sbi home loan interest rate calculator, axis bank home loan calculator, lic home loan emi calculator, axis bank home loan emi calculator, icici bank housing loan calculator' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/home-loan-emi-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Home Loan EMI Calculator</span>
        </li> 
      
      </ol>
    </nav>
    <div className='py-4 '>

   
    <div className="container mx-auto p-4 shadow-xl rounded-md ">
      <h1 className="text-2xl font-bold mb-4">Home Loan EMI Calculator</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Loan Amount</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                value={loanAmount}
                onChange={handleLoanAmountChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">₹ {loanAmount.toLocaleString()}</span>
            </div>
            <input
              type="range"
              min="0"
              max="100000000"
              value={loanAmount}
              onChange={(e) => setLoanAmount(parseInt(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Rate of Interest (P.A.)</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                max="100"
                step="0.1"
                value={interestRate}
                onChange={handleInterestRateChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{interestRate} %</span>
            </div>
            <input
              type="range"
              min="0"
              max="15"
              step="0.1"
              value={interestRate}
              onChange={(e) => setInterestRate(parseFloat(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Loan Tenure (Years)</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                max="30"
                value={loanTenure}
                onChange={handleLoanTenureChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{loanTenure} Yr</span>
            </div>
            <input
              type="range"
              min="0"
              max="30"
              value={loanTenure}
              onChange={(e) => setLoanTenure(parseInt(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <button
            onClick={calculateEMI}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Calculate
          </button>
        </div>
        <div>
          <div className="mb-4">
            <h2 className="text-xl font-bold">Results</h2>
            <p>Monthly EMI: ₹ {parseFloat(monthlyEMI).toLocaleString()}</p>
            <p>Total Principal Amount: ₹ {loanAmount.toLocaleString()}</p>
            <p>Total Interest: ₹ {parseFloat(totalInterest).toLocaleString()}</p>
            <p>Total Amount (Principal + Interest): ₹ {parseFloat(totalAmount).toLocaleString()}</p>
          </div>
          <div>
            <h2 className="text-xl font-bold">Breakdown</h2>
            <div className="flex justify-center">
              <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <h2 className="text-xl font-bold mb-4">Amortization Schedule</h2>
        {amortizationSchedule.length > 0 && (
          <div className="flex flex-col space-y-4">
            {Array.from(new Set(amortizationSchedule.map(item => item.year))).map((year, idx) => (
              <div key={idx} className="mb-4 border rounded-md shadow-md">
                <div
                  className={`cursor-pointer text-lg font-semibold bg-gray-100 p-4 flex justify-between items-center ${activeYear === year ? 'bg-blue-100' : ''}`}
                  onClick={() => handleYearToggle(year)}
                >
                  <span>{year}</span>
                  <span>{activeYear === year ? '-' : '+'}</span>
                </div>
                {activeYear === year && (
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-white rounded-md shadow-md text-center">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border-b">Month</th>
                          <th className="py-2 px-4 border-b">Principal Paid</th>
                          <th className="py-2 px-4 border-b">Interest Charged</th>
                          <th className="py-2 px-4 border-b">Total Payment</th>
                          <th className="py-2 px-4 border-b">Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {amortizationSchedule.filter(item => item.year === year).map((item, idx) => (
                          <tr key={idx}>
                            <td className="py-2 px-4 border-b">{new Date(0, item.month - 1).toLocaleString('default', { month: 'short' })}</td>
                            <td className="py-2 px-4 border-b">₹ {item.principalPaid.toFixed(2).toLocaleString()}</td>
                            <td className="py-2 px-4 border-b">₹ {item.interestPaid.toFixed(2).toLocaleString()}</td>
                            <td className="py-2 px-4 border-b">₹ {item.totalPayment.toFixed(2).toLocaleString()}</td>
                            <td className="py-2 px-4 border-b">₹ {item.balance.toFixed(2).toLocaleString()}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
     </div>
    
     <div className="flex flex-wrap px-6 py-6" >
     <div className="w-full md:w-2/3 p-4">
       <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Plan Your Dream Home with TheFinMaster's Home Loan EMI Calculator
 </h1>
 {/* <p className="text-lg font-normal mt-2 text-gray-700 ">Financing Your Future:</p> */}

       <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Real estate prices can be daunting, but a home loan can make your dream home a reality. TheFinMaster's free home loan EMI calculator helps you plan your finances with confidence.</p>


       <div className="container mx-auto  py-4">
         <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Why Use a Home Loan EMI Calculator?</h1>

         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'> Effortless Calculations:</span>  Skip the complex formulas and get instant EMI estimates.
         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'> Informed Decisions:    </span>Knowing your EMI amount upfront helps you budget effectively and choose the right loan amount.
         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>  Save Time:</span>    Get quick results without the hassle of manual calculations.

         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>   Accurate Estimates:</span> Gain peace of mind with reliable results every time.

         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>    Flexibility:</span>  Experiment with different loan amounts and interest rates to find the perfect fit for your needs.

         </p>
       </div>
       <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How TheFinMaster's Calculator Works:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Simply enter your desired loan amount, interest rate, and loan tenure. Our user-friendly calculator instantly calculates your EMI, giving you a clear picture of your monthly payments.
            </p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Example</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Considering a home loan of ₹1 Crore for 15 years at 12% interest? TheFinMaster's calculator will tell you your EMI would be approximately ₹1,10,108.
            </p>
          </div>
       
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Beyond EMI:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">TheFinMaster understands that home loan payments are complex. While the EMI stays the same, the breakdown between principal and interest changes over time. We offer resources to help you visualize this process.
            </p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Ready to Get Started?</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Use TheFinMaster's free home loan EMI calculator today! Plan for your dream home with confidence.
            </p>
          </div>
     

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Additional Notes:</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Removed unnecessary information about the Indian housing market and loan growth rate.         </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Simplified the explanation of the EMI formula.     </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Removed the example of the changing principal and interest components (can be included as a separate resource).
      </p>
              <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Focused on the benefits for the user and a clear call to action.          </p>
          </div>

       


     </div>

     <div className="w-full md:w-1/3 p-4">
       <div className="bg-white shadow-md rounded-lg overflow-hidden">
         <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
           <h2 className="text-lg font-semibold ">Popular Categories</h2>
         </div>
         <div className="p-2 ">
         <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
             {/* {moreCategories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
           </ul>
         </div>
       </div>

     </div>

   </div>
 
     </>
  );
};

export default HomeLoanEMICalculator;

// import React, { useState } from 'react';
// import {
//   Chart as ChartJS,
//   ArcElement,
//   Tooltip as ChartTooltip,
//   Legend,
// } from 'chart.js';
// import { Pie } from 'react-chartjs-2';

// // Register necessary components
// ChartJS.register(
//   ArcElement,
//   ChartTooltip,
//   Legend
// );

// const HomeLoanEMICalculator = () => {
//   const [loanAmount, setLoanAmount] = useState(5000000);
//   const [rateOfInterest, setRateOfInterest] = useState(8.5);
//   const [loanTenure, setLoanTenure] = useState(20);
//   const [monthlyEMI, setMonthlyEMI] = useState(0);
//   const [totalInterest, setTotalInterest] = useState(0);
//   const [totalAmount, setTotalAmount] = useState(0);
//   const [activeYear, setActiveYear] = useState(null);

//   const calculateEMI = () => {
//     const monthlyInterestRate = rateOfInterest / 12 / 100;
//     const numberOfPayments = loanTenure * 12;
//     const emi = (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) / (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
//     const totalPayment = emi * numberOfPayments;
//     const totalInterestPaid = totalPayment - loanAmount;

//     setMonthlyEMI(emi.toFixed(2));
//     setTotalInterest(totalInterestPaid.toFixed(2));
//     setTotalAmount(totalPayment.toFixed(2));
//   };

//   const handleLoanAmountChange = (e) => {
//     const value = e.target.value;
//     setLoanAmount(value === '' ? 0 : parseInt(value));
//   };

//   const handleRateOfInterestChange = (e) => {
//     const value = e.target.value;
//     setRateOfInterest(value === '' ? 0 : parseFloat(value));
//   };

//   const handleLoanTenureChange = (e) => {
//     const value = e.target.value;
//     setLoanTenure(value === '' ? 0 : parseInt(value));
//   };

//   const toggleYear = (year) => {
//     setActiveYear(activeYear === year ? null : year);
//   };

//   const data = {
//     labels: ['Principal Amount', 'Total Interest', 'Total Amount'],
//     datasets: [
//       {
//         label: 'Loan Breakdown',
//         data: [loanAmount, totalInterest, totalAmount],
//         backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56'],
//       },
//     ],
//   };

//   return (
//     <div className="container mx-auto p-4">
//       <h1 className="text-2xl font-bold mb-4">Home Loan EMI Calculator</h1>
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//         <div>
//           <div className="mb-4">
//             <label className="block text-gray-700 font-semibold">Loan Amount</label>
//             <div className="flex items-center">
//               <input
//                 type="number"
//                 min="0"
//                 value={loanAmount}
//                 onChange={handleLoanAmountChange}
//                 className="w-1/2 p-2 border rounded mr-2"
//               />
//               <span className="text-gray-700">₹ {loanAmount.toLocaleString()}</span>
//             </div>
//             <input
//               type="range"
//               min="0"
//               max="100000000"
//               value={loanAmount}
//               onChange={(e) => setLoanAmount(parseInt(e.target.value))}
//               className="w-full mt-2"
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-700 font-semibold">Rate of Interest (p.a.)</label>
//             <div className="flex items-center">
//               <input
//                 type="number"
//                 min="0"
//                 max="15"
//                 step="0.1"
//                 value={rateOfInterest}
//                 onChange={handleRateOfInterestChange}
//                 className="w-1/2 p-2 border rounded mr-2"
//               />
//               <span className="text-gray-700">{rateOfInterest} %</span>
//             </div>
//             <input
//               type="range"
//               min="0"
//               max="15"
//               step="0.1"
//               value={rateOfInterest}
//               onChange={(e) => setRateOfInterest(parseFloat(e.target.value))}
//               className="w-full mt-2"
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-700 font-semibold">Loan Tenure (years)</label>
//             <div className="flex items-center">
//               <input
//                 type="number"
//                 min="1"
//                 max="30"
//                 value={loanTenure}
//                 onChange={handleLoanTenureChange}
//                 className="w-1/2 p-2 border rounded mr-2"
//               />
//               <span className="text-gray-700">{loanTenure} Yr</span>
//             </div>
//             <input
//               type="range"
//               min="1"
//               max="30"
//               value={loanTenure}
//               onChange={(e) => setLoanTenure(parseInt(e.target.value))}
//               className="w-full mt-2"
//             />
//           </div>
//           <button
//             onClick={calculateEMI}
//             className="bg-blue-500 text-white px-4 py-2 rounded"
//           >
//             Calculate
//           </button>
//         </div>
//         <div>
//           <div className="mb-4">
//             <h2 className="text-xl font-bold">Results</h2>
//             <p>Monthly EMI: ₹ {monthlyEMI}</p>
//             <p>Total Interest: ₹ {totalInterest}</p>
//             <p>Total Amount: ₹ {totalAmount}</p>
//           </div>
//           <div>
//             <h2 className="text-xl font-bold">Breakdown</h2>
//             <div className="flex justify-center">
//               <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="mt-6">
//         <h2 className="text-xl font-bold">Amortization Schedule</h2>
//         <div>
//           {Array.from({ length: loanTenure }, (_, i) => i + 1).map((year) => (
//             <div key={year}>
//               <div
//                 className="cursor-pointer text-gray-700 font-semibold my-2"
//                 onClick={() => toggleYear(year)}
//               >
//                 {activeYear === year ? '-' : '+'} {2023 + year}
//               </div>
//               {activeYear === year && (
//                 <div className="pl-4">
//                   <table className="w-full text-left border-collapse">
//                     <thead>
//                       <tr>
//                         <th className="p-2 border">Month</th>
//                         <th className="p-2 border">Principal Paid</th>
//                         <th className="p-2 border">Interest Charged</th>
//                         <th className="p-2 border">Total Payment</th>
//                         <th className="p-2 border">Balance</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {/* Example amortization data */}
//                       <tr>
//                         <td className="p-2 border">Jan</td>
//                         <td className="p-2 border">₹ 14,149</td>
//                         <td className="p-2 border">₹ 5,416</td>
//                         <td className="p-2 border">₹ 19,566</td>
//                         <td className="p-2 border">₹ 9,85,851</td>
//                       </tr>
//                       {/* Add more rows as needed */}
//                     </tbody>
//                   </table>
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeLoanEMICalculator;

