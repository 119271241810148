import React, { useState } from 'react'
import AccordionComponent from '../accordion/AccordionComponent'
import { FiArrowRight } from 'react-icons/fi';
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';

  function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
  }
  
  const GrowwDematLearnMore = () => {
    const [activeIndex, setActiveIndex] = useState(0);
  
    const faqs = [
        {
            question: 'Can I transfer funds using the Groww mobile app?',
            answer:
                'Yes, you can transfer funds instantly using the Groww mobile app. You can add funds to your Groww balance or initiate separate transactions using UPI, Net banking, or NEFT.',
        },
        {
            question: 'Does Groww facilitate IPO applications?',
            answer:
                'Yes, Groww allows its customers to apply for IPOs online through the Groww website. Investors can use the UPI gateway to submit their IPO applications. They can place up to three bids and modify or cancel their applications anytime before the IPO window closes.',
        },
        {
            question: 'Does Groww support BTST (Buy Today Sell Tomorrow) orders?',
            answer:
                'Yes, Groww supports BTST orders, which allow you to sell stocks before receiving their delivery in your Demat account.',
        },
        {
            question: 'What are the charges associated with Groww?',
            answer:
                'Groww charges Rs. 20 or 0.05% of the trade value (whichever is lower) for Equity transactions and Rs. 20 per order for F&O trades. Additionally, regulatory charges such as STT, Exchange transaction charges, GST, SEBI fees, Stamp Duty, and DP Charges are applicable. Account opening at Groww is free, and there are no annual maintenance charges for Demat accounts. Mutual fund investments through Groww are brokerage-free.',
        },
        {
          question: 'How can I delete my Groww mutual fund account?',
          answer:
              'Deleting a Groww mutual fund account requires an offline process. You need to submit a signed closure form to the Groww office for account closure.',
      },
      {
        question: 'Does Groww provide trading tips or investment strategies?',
        answer:
            ' No, Groww does not offer trading tips or investment strategies for stock and commodity trading to its customers.',
    },
        
    ];
  
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
  return (
    <>
     <Helmet>
      <title>Groww Demat Account Review, Opening Charges, Details</title>
      <meta  name='description' content='Groww Demat account review. Groww open Demat account or closure. Find account opening charges, closing forms, customer care and brokerage charges. | TheFinmaster.'/>
      <meta name='keywords' content='groww account opening, groww demat account charges, groww app charges, groww trading charges' />
      <link rel="canonical" href="https://thefinmaster.com/demat/groww-demat-account" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/demat" className="text-blue-600 hover:text-blue-800">Demat</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Groww Demat Account</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
            <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Discover Groww's User-Friendly Platform Tailored for 
                  </span>{" "}
                  Efficient Investment Management.
                </h1>
                <a 
          href="https://groww.in/open-demat-account"
          target="_blank" 
          rel="noopener noreferrer"
        >
                
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Open Account!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src="../assets/growdemat1.webp"
                alt="car-loan"
              />
            </div>
          </div>
      

       </div>
      {/* <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-8xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Groww</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>Groww Your Wealth with Ease</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='/growth.jpg' alt='demat growth' />
          </div>
        </div>
      </div> */}

      <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 justify-left items-left">
    <p className="text-lg md:text-base lg:text-lg text-left"> 
    Established in 2016, Groww has become a popular choice for beginners in the Indian stock market. They focus on a user-friendly platform with a clean interface and simple navigation. One of Groww's biggest attractions is their zero brokerage for equity delivery investments, allowing you to buy and hold stocks for the long term without incurring commission fees. 
    </p>
    <p className="text-lg md:text-base lg:text-lg text-left"> 

    Another unique feature is fractional share investing, which enables you to invest in expensive stocks or build a diversified portfolio even with a limited amount of capital. Groww's focus on low costs and a beginner-friendly approach makes it a great starting point for your investment journey.


</p>
   
</div>
      {/* <div className="relative px-6 py-6">
        <img 
        src= "https://marketplace.canva.com/EAENvp21inc/1/0/1600w/canva-simple-work-linkedin-banner-qt_TMRJF4m0.jpg" // Replace "banner-image.jpg" with your actual image URL
          alt= "Banner Image"
          className= "object-cover w-full h-64 md:h-80 lg:h-96"
        />
      </div> */}
      {/* <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
      <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
          Key Highlights
        </h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
        
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 1</h3>
            <p className="text-gray-700">
              Description of Highlight 1 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 2</h3>
            <p className="text-gray-700">
              Description of Highlight 2 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </div> */}
    {/* <div class="container mx-auto px-3">
  <h2 class="font-bold text-4xl md:pl-0 pl-3 md:mt-0 mt-5 text-center">Why open a <span class="text-blue-600">Demat</span> account with Us</h2>
  <div class="w-full text-white flex justify-center mt-10">

    <div class="w-full md:w-1/2 bg-neutral-800 flex flex-col justify-center items-center md:h-[450px] md:mr-2 mb-4 md:mb-0">
      <h2 class="text-3xl font-medium border-b-2 pb-5">Zero maintenance fee</h2>
      <p class="text-center w-[80%] md:w-full pt-5 text-lg">A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
    </div>
    <div class="w-full md:w-1/2 bg-blue-600 flex flex-col justify-center items-center md:h-[450px] md:ml-2">
      <h2 class="text-3xl font-medium border-b-2 pb-5">Zero maintenance fee</h2>
      <p class="text-center w-[80%] md:w-full pt-5 text-lg">A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
    </div>
  </div>
</div> */}


<div class="container mx-auto px-4">
<h2 class="font-bold text-3xl md:text-4xl lg:text-4xl text-center mt-10 md:mt-20">Why open a <span class="text-blue-600">Demat</span> account with <span className='text-blue-600'>GROWW </span>?</h2>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10 md:mt-20">
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Zero brokerage for equity delivery</h3>
      <p class="text-base md:text-lg text-center">Groww stands out for offering zero brokerage on equity delivery investments. This means you can buy stocks and hold them for the long term without any commission fees eating into your returns.</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Simple and User-friendly platform</h3>
      <p class="text-base md:text-lg text-center">Groww's app and website are recognized for their user-friendly design. This makes it a breeze to navigate, even for beginners who are unfamiliar with the world of investing.

</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Zero account maintenance fees</h3>
      <p class="text-base md:text-lg text-center">You won't be charged any fees for simply having a Groww account. This can be a significant advantage compared to other brokers who may charge annual or monthly maintenance fees.

</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Invest in fractional shares</h3>
      <p class="text-base md:text-lg text-center">Grow allows you to invest in fractional shares of expensive stocks. This is a great feature for those who want to invest in companies with a high share price but have limited capital.
</p>
    </div>
  </div>
</div>



      {/* <h2 className='font-semibold text-5xl'>Why open a <span className='text-blue-600'>Demat</span> account with Us</h2>
        
        <div className='w-full text-white flex justify-center mt-10'>
          <div className='w-full bg-black flex flex-col justify-center items-center h-[450px]'>
              <h2 className='text-4xl font-medium border-b-2 pb-5'>Zero maintenance fee</h2>
              <p className='text-center w-[80%] pt-5 text-lg'>A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
          </div>
          <div className='w-full bg-blue-600 flex flex-col justify-center items-center h-[450px]'>
              <h2 className='text-4xl font-medium border-b-2 pb-5'>Zero maintenance fee</h2>
              <p className='text-center w-[80%] pt-5 text-lg'>A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
          </div>
        </div> */}
      <div className="flex flex-col w-[95%] h-auto mt-6">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-center text-sm font-light">
                <thead
                  className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
                  <tr>
                    <th scope="col" className=" px-6 py-4">#</th>
                    <th scope="col" className=" px-6 py-4">Fee Type</th>

                    <th scope="col" className=" px-6 py-4">Brokerage Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">1</td>
                    <td className="whitespace-nowrap  px-6 py-4">Equity Delivery Charges</td>

                    <td className="whitespace-nowrap  px-6 py-4"> ₹20 per executed order or 0.05% (whichever is lower)


</td>
                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">2</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Equity Intraday Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4"> ₹20 per executed order or 0.05% (whichever is lower)

</td>

                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">3</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Future Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4"> ₹20 per executed order
</td>

                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">4</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Option Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4"> ₹20 per executed order
</td>

                  </tr>
                  {/* <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap  px-6 py-4 font-medium">3</td>
                  <td colSpan={2} className="whitespace-nowrap  px-6 py-4">
                    Larry the Bird
                  </td>
                  <td className="whitespace-nowrap  px-6 py-4">@twitter</td>
                </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full md:w-[95%] mt-5 md:mt-20 py-4 px-4 md:px-16'>
  <h2 className='font-bold text-3xl md:text-4xl text-center'>How to open a <span className='text-blue-600'>Demat</span> account with <span className='text-blue-600'>GROWW </span>?</h2>
  <div className='text-center mt-4'>
    <p className='text-base md:text-2xl text-blue-600 '>3 easy steps. Get started now!</p>
  </div>
 

  <div className='w-full flex flex-col md:flex-row gap-5 md:gap-20 mt-3 md:mt-5 ml-0 md:ml-8'>
    <div className='w-full md:w-[30%] pl-2 md:pl-5'>
      <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>1</p>
      <div className='flex items-center justify-between mt-3 md:mt-5'>
        <div>
          <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Provide Personal Information</p>
        </div>
        <div>
          <FiArrowRight className='text-2xl md:text-3xl animate-pulse' />
        </div>
      </div>
      <p className='text-sm md:text-lg py-2 md:py-0 md:w-[70%]'> Submit Essential Personal Information and Link a Bank Account.</p>
    </div>
    <div className='w-full md:w-[30%] pl-2 md:pl-5'>
      <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>2</p>
      <div className='flex items-center justify-between mt-3 md:mt-5'>
        <div>
          <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Online KYC Submission</p>
        </div>
        <div>
          <FiArrowRight className='text-2xl md:text-3xl animate-pulse' />
        </div>
      </div>
      <p className='text-sm md:text-lg py-2 md:py-0 md:w-[70%]'> Submit your KYC details online, and Groww will notify you upon completion of verification.</p>
    </div>
    <div className='w-full md:w-[30%] pl-2 md:pl-5'>
      <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>3</p>
      <div className='flex items-center justify-between mt-3 md:mt-5'>
        <div>
          <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Online Form Signing</p>
        </div>
        <div>
          {/* <FiArrowRight className='text-4xl'/> */}
        </div>
      </div>
      <p className='text-sm md:text-lg py-2 md:py-0 md:w-[70%]'>Quickly Review and Sign Your Account Opening Form Online.</p>
    </div>
  </div>
 
</div>

     


      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-4 sm:mb-6 lg:mb-8">
        Is it Right for  <span className='text-blue-600 text-6xl'>You</span> <span className='text-6xl'>?</span>
        </h2>
        <p className="text-base sm:text-lg lg:text-xl text-gray-700 leading-relaxed text-center">
          Groww is a perfect starting point for new investors with their beginner-friendly platform, zero brokerage for equity delivery, and fractional share investing. Their focus on low cost and simplicity makes it easy to begin your investment journey.

        </p>
      </div>
      
     
  
     {/* FAQ section */}
     
    </div>
    <div className="max-w-4xl mx-auto py-6 mt-10">
                <h1 className="text-center text-2xl md:text-2xl lg:text-5xl font-bold mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
    </>
  )
}

export default GrowwDematLearnMore
