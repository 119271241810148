import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavLinks from "./NavLinks";
import Button from "./Button";
import { RxHamburgerMenu,  } from "react-icons/rx";
import { RxCross1 } from "react-icons/rx";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const handleLinkClick = () => {
    setOpen(false);
  };
  return (
    <nav className="bg-white sticky top-0 z-50 shadow-md">
      <div className="flex items-center font-medium justify-around">
        <div className="z-50 pr-5 md:w-auto w-full flex justify-between">
          <img src="/1logo.png" alt="logo" className="md:cursor-pointer  h-12 w-[14rem] object-cover overflow-hidden" />
          <div className="text-3xl  md:hidden mt-2" onClick={() => setOpen(!open)}>
          {open ? <RxCross1 /> : <RxHamburgerMenu />}
          </div>
        </div>
        <ul className="md:flex hidden uppercase items-center gap-6 font-[Poppins] ">
          <li>
            <Link to="/" className="py-7 px-3 inline-block">
              Home
            </Link>
          </li>
          <NavLinks handleLinkClick={handleLinkClick}/>
          <li>
            <Link to="/blog_page" className="py-7 px-3 inline-block">
              Blog
            </Link>
          </li>
          <li>
            <Link to="/financial-tools-calculators" className="py-7 px-3 inline-block">
              Tools
            </Link>
          </li>
        </ul>
        <div className="md:block hidden">
          <Button />
        </div>
        {/* Mobile nav */}
        <ul
          className={`
        md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <li>
            <Link to="/" className="py-4 px-3 inline-block" onClick={handleLinkClick}>
              Home
            </Link>
          </li>
          <NavLinks handleLinkClick={handleLinkClick}/>
          <li>
            <Link to="/blog_page" className="py-4 px-3 inline-block" onClick={handleLinkClick}>
              Blog
            </Link>
          </li>
          <li>
            <Link to="/financial-tools-calculators" className="py-4 px-3 inline-block" onClick={handleLinkClick}>
              Tools
            </Link>
          </li>
          <li>
            <Link to="/about" className="py-4 px-3 inline-block" onClick={handleLinkClick}>
              About Us
            </Link>
          </li>
          <div className="py-5" onClick={handleLinkClick}>
            <Button />
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
