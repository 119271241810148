import React, { useEffect } from 'react'

// import TagManager from 'react-gtm-module'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import Home from './components/Home';
import PersonalLoan from './components/personalLoan/PersonalLoan';
import CreditCardPage from './components/creditCard/CreditCardPage';
import NavBar from './components/NavBar';
import Navbar from './components/Navbar/Navbar';
import FooterComponent from './components/FooterComponent';
import BlogPage from './components/blog/BlogPage';
import ResearchPage from './components/research/ResearchPage';
import AboutPage from './components/about/AboutPage';
import ContactPage from './components/contact/ContactPage';
// import InsurancePage from './components/insurance/InsurancePage';
import LifeInsurance from './components/insurance/LifeInsurance';
import HealthInsurance from './components/insurance/HealthInsurance';
import CarInsurance from './components/insurance/CarInsurance';
import BikeInsurance from './components/insurance/BikeInsurance';
import TermInsurance from './components/insurance/TermInsurance';
import TravelInsurance from './components/insurance/TravelInsurance';
import BlogPost from './components/blog/BlogPost';
// import BankDetail from './components/creditCard/BankDetail';
import AMEX from './components/BankDetails/AMEX'
import HDFC from './components/BankDetails/HDFC';
import Au from './components/BankDetails/Au';
import AXIX from './components/BankDetails/AXIX';
import ICICI from './components/BankDetails/ICICI';
import IDFC from './components/BankDetails/IDFC';
import Indulnd from './components/BankDetails/Indulnd';
import SBI from './components/BankDetails/SBI';
import HdfcLearnMore from './components/LearnMore/HdfcLearnMore';
import BankOfBaroda from './components/BankDetails/BankOfBaroda';
import Citi from './components/BankDetails/Citi';
import Federal from './components/BankDetails/Federal';
import Hsbc from './components/BankDetails/Hsbc';
import Kotak from './components/BankDetails/Kotak';
import American from './components/BankDetails/American';
import Standard from './components/BankDetails/Standard';
import YesBank from './components/BankDetails/YesBank';
import CashBack from './components/topButtons/CashBack';
import Fuel from './components/topButtons/Fuel';
import LifeTimeFee from './components/topButtons/LifeTimeFee';
import Premium from './components/topButtons/Premium';
import Travel from './components/topButtons/Travel';
import Demat from './components/demat/Demat';
import DematLearnMore from './components/dematLearnMore/DematLearnMore';
import TermsAndConditions from './components/privacy/TermsAndConditions';
import PrivacyPolicy from './components/privacy/PrivacyPolicy';
import CarLoan from './components/personalLoan/CarLoan';
import CarLoanLearnMore from './components/LearnmorePageLoan/CarLoanLearnMore';

import HomeLoan from './components/personalLoan/HomeLoan';
import TwoWheelerLoan from './components/personalLoan/TwoWheelerLoan';
import EducationLoan from './components/personalLoan/EducationLoan';
import PersonalLoanU from './components/personalLoan/PersonalLoanU';
import AuBankLit from './components/featuredCards/AuBankLit';
import EasyDinerBank from './components/featuredCards/EasyDinerBank';
import HdfcBankMillennia from './components/featuredCards/HdfcBankMillennia';
import SbiSimplyClick from './components/featuredCards/SbiSimplyClick';
import IdfcFirstClassic from './components/featuredCards/IdfcFirstClassic';
import FlipcartAxixBank from './components/featuredCards/FlipcartAxixBank';
import UpStoxLearnMore from './components/dematLearnMore/UpStoxLearnMore';
import AngelOneLearnMore from './components/dematLearnMore/AngelOneLearnMore';
import HdfcDematLearnMore from './components/dematLearnMore/HdfcDematLearnMore';
import GrowwDematLearnMore from './components/dematLearnMore/GrowwDematLearnMore';
import FyersDematLearnMore from './components/dematLearnMore/FyersDematLearnMore';
import ZerodaDematLearnMore from './components/dematLearnMore/ZerodaDematLearnMore';
import LearnMoreAllCards from './components/cardComponents/LearnMoreAllCards';
import IciciLombardLearn from './components/LearnMoreCarInsurance/IciciLombardLearn';
import ToolOfSipLipPages from './components/ToolsOfAllBanks/ToolOfSipLipPages';
import BajajLearnMore from './components/LearnMoreCarInsurance/BajajLearnMore';
import AckoLearnMore from './components/LearnMoreCarInsurance/AckoLearnMore';
import HdfcCarLearnMore from './components/LearnMoreCarInsurance/HdfcCarLearnMore';
import TataAijLearnMore from './components/LearnMoreCarInsurance/TataAijLearnMore';
import IciciBikeInsurance from './components/LearnMoreBikeInsurance/IciciBikeInsurance';
import AckoBikeInsurance from './components/LearnMoreBikeInsurance/AckoBikeInsurance';
import BajajBikeInsurance from './components/LearnMoreBikeInsurance/BjajajBikeInsurance';
import FutureBikeInsurance from './components/LearnMoreBikeInsurance/FutureBikeInsurance';
import HdfcBikeInsurance from './components/LearnMoreBikeInsurance/HdfcBikeInsurance';
import AdityaLifeLearn from './components/LearnMoreLifeInsurance/AdityaLifeLearn';
import FutureLifeLearn from './components/LearnMoreLifeInsurance/FutureLifeLearn';
import HdfcLifeLearn from './components/LearnMoreLifeInsurance/HdfcLifeLearn';
import IciciLifeLearn from './components/LearnMoreLifeInsurance/IciciLifeLearn';
import MaxLifeLearn from './components/LearnMoreLifeInsurance/MaxLifeLearn';
import AdityaHealthLearn from './components/learnmoreHealthInsurance/AdityaHealthLearn';
import CareHealthLearn from './components/learnmoreHealthInsurance/CareHealthLearn';
import HdfcargoHealthLearn from './components/learnmoreHealthInsurance/HdfcargoHealthLearn';
import DigitHealthLearn from './components/learnmoreHealthInsurance/DigitHealthLearn';
import RelianceHealthLearn from './components/learnmoreHealthInsurance/RelianceHealthLearn';
import ToolsPages from './components/ToolsOfAllBanks/ToolsPages';
import SWPCalculator from './components/ToolsOfAllBanks/SWPCalculator';
import MutualFundCalculator from './components/ToolsOfAllBanks/MutualFundCalculator';
import SSYCalculator from './components/ToolsOfAllBanks/SSYCalculator';
import PPFCalculator from './components/ToolsOfAllBanks/PPFCalculator';
import EPFCalculator from './components/ToolsOfAllBanks/EPFCalculator';
import FDCalculator from './components/ToolsOfAllBanks/FDCalculator';
import RDCalculator from './components/ToolsOfAllBanks/RDCalculator';
import NPSCalculator from './components/ToolsOfAllBanks/NPSCalculator';
import HRACalculator from './components/ToolsOfAllBanks/HRACalculator';
import RetirementCalculator from './components/ToolsOfAllBanks/RetirementCalculator';
import EMICalculator from './components/ToolsOfAllBanks/EMICalculator';
import Layout from './Layout';
import SimpleInterestCalculator from './components/ToolsOfAllBanks/SimpleInterestCalculator';
import CompoundInterestCalculator from './components/ToolsOfAllBanks/CompoundInterestCalculator';
import NSCCalculator from './components/ToolsOfAllBanks/NSCCalculator';
import StepUpSIPCalculator from './components/ToolsOfAllBanks/StepUpSIPCalculator';
import IncomeTaxCalculator from './components/ToolsOfAllBanks/IncomeTaxCalculator';
import CarLoanEMICalculator from './components/ToolsOfAllBanks/CarLoanEMICalculator';
import HomeLoanEMICalculator from './components/ToolsOfAllBanks/HomeLoanEMICalculator';
import GratuityCalculator from './components/ToolsOfAllBanks/GratuityCalculator';
import APYCalculator from './components/ToolsOfAllBanks/APYCalculator';
import CAGRCalculator from './components/ToolsOfAllBanks/CAGRCalculator';
import GSTCalculator from './components/ToolsOfAllBanks/GSTCalculator';
import FlatReducingRateCalculator from './components/ToolsOfAllBanks/FlatReducingRateCalculator';
import EquityMarginCalculator from './components/ToolsOfAllBanks/EquityMarginCalculator';
import InflationCalculator from './components/ToolsOfAllBanks/InflationCalculator';
import SalaryCalculator from './components/ToolsOfAllBanks/SalaryCalculator';
import BrokerageCalculator from './components/ToolsOfAllBanks/BrokerageCalculator';
import TDSCalculator from './components/ToolsOfAllBanks/TDSCalculator';
import LumpsumCalculator from './components/ToolsOfAllBanks/LumpsumCalculator';
import SiteMapUi from './components/sitemap/SiteMapUi';
import LayoutGTM from './LayoutGTM';
import LayoutScroll from './LayoutScroll';
import Blogs from './components/blog/Blogs';
import SinglepageBlog from './components/blog/SinglepageBlog';
import BajajTravelLearn from './components/LearnMoreTravelInsurance.js/BajajTravelLearn';
import IciciLombardTravelLearn from './components/LearnMoreTravelInsurance.js/IciciLombardTravelLearn';
import TataaijTravelLearn from './components/LearnMoreTravelInsurance.js/TataaijTavelLearn';
import CareHealthTravelLearn from './components/LearnMoreTravelInsurance.js/CareHealthTravelLearn';
import HdfcErgoTravelLearn from './components/LearnMoreTravelInsurance.js/HdfcErgoTravelLearn';
function App() {

  // const usePageTracking = () => {
  //   const location = useLocation();
  
  //   useEffect(() => {
  //     if (window.gtag) {
  //       window.gtag('config', 'G-DCYD12PDV7', {
  //         page_path: location.pathname,
  //       });
  //     }
  //   }, [location]);
  // };
  
 
  //   usePageTracking();

const location = useLocation()
  useEffect(() => {
  
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <>
    
      {/* <Box sx={{ background: '#000' }}> */}
      <Navbar />
      {/* <NavBar /> */}
      <Layout>
        <LayoutScroll>
      <Routes>
      
        <Route path='/' exact element={<Home />} />
        <Route path='/blog_page/:id' element={<SinglepageBlog />} />
        <Route path='/blog_page' element={<Blogs />} />
        {/* <Route path='/blog_page' element={<Navigate to="/blogpage" replace /> }/> */}

        <Route path='/sitemap' element={<SiteMapUi />} />
        <Route path='/financial-tools-calculators/tds-calculator' element={<TDSCalculator />} />
        <Route path='/financial-tools-calculators/brokerage-calculator' element={<BrokerageCalculator />} />
        <Route path='/financial-tools-calculators/salary-calculator' element={<SalaryCalculator />} />
        <Route path='/financial-tools-calculators/inflation-calculator' element={<InflationCalculator />} />
        <Route path='/financial-tools-calculators/equity-margin-calculator' element={<EquityMarginCalculator />} />
        <Route path='/financial-tools-calculators/flat-vs-reducing-rate-calculator' element={<FlatReducingRateCalculator />} />
        <Route path='/financial-tools-calculators/gst-calculator' element={<GSTCalculator />} />
        <Route path='/financial-tools-calculators/cagr-calculator' element={<CAGRCalculator />} />
        <Route path='/financial-tools-calculators/apy-calculator' element={<APYCalculator />} />
        <Route path='/financial-tools-calculators/gratuity-calculator' element={<GratuityCalculator />} />
        <Route path='/financial-tools-calculators/car-loan-emi-calculator' element={<CarLoanEMICalculator />} />
        <Route path='/financial-tools-calculators/home-loan-emi-calculator' element={<HomeLoanEMICalculator />} />
        <Route path='/financial-tools-calculators/income-tax-calculator' element={<IncomeTaxCalculator />} />
        <Route path='/financial-tools-calculators/step-up-sip-calculator' element={<StepUpSIPCalculator />} />
        <Route path='/financial-tools-calculators/nsc-calculator' element={<NSCCalculator />} />
        <Route path='/financial-tools-calculators/compound-interest-calculator' element={<CompoundInterestCalculator />} />
<Route path='/financial-tools-calculators/simple-interest-calculator' element={<SimpleInterestCalculator />} />
        <Route path='/financial-tools-calculators/emi-calculator' element={<EMICalculator />} />
        <Route path='/financial-tools-calculators/retirement-planning-calculator' element={<RetirementCalculator />} />
        <Route path='/financial-tools-calculators/hra-exemption-calculator' element={<HRACalculator />} />
        <Route path='/financial-tools-calculators/nps-calculator' element={<NPSCalculator />} />
        <Route path='/financial-tools-calculators/rd-calculator' element={<RDCalculator />} />
        <Route path='/financial-tools-calculators/fd-calculator' element={<FDCalculator />} />
        <Route path='/financial-tools-calculators/pf-epf-calculator' element={<EPFCalculator />} />
        <Route path='/financial-tools-calculators/ppf-calculator' element={<PPFCalculator />} />
        <Route path='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator' element={<SSYCalculator />} />
        <Route path='/financial-tools-calculators/mutual-fund-calculator' element={<MutualFundCalculator />} />
        <Route path='/financial-tools-calculators/swp-calculator' element={<SWPCalculator />} />
        <Route path='/financial-tools-calculators' element={<ToolsPages />} />
        <Route path='/financial-tools-calculators/sip-calculator' element={<ToolOfSipLipPages />} />
        <Route path='/financial-tools-calculators/lumpsum-calculator' element={<ToolOfSipLipPages />} />
        <Route path='/travel-insurance/hdfc-ergo-travel-insurance' element={<HdfcErgoTravelLearn />} />
        <Route path='/travel-insurance/care-health-travel-insurance' element={<CareHealthTravelLearn />} />
        <Route path='/travel-insurance/tata-aij-travel-insurance' element={<TataaijTravelLearn />} />
        <Route path='/travel-insurance/icici-lombard-travel-insurance' element={<IciciLombardTravelLearn />} />
        <Route path='/travel-insurance/bajaj-allianz-travel-insurance' element={<BajajTravelLearn />} />
        <Route path='/health-insurance/aditya-health-insurance' element={<AdityaHealthLearn />} />
        <Route path='/health-insurance/care-health-insurance' element={<CareHealthLearn />} />
        <Route path='/health-insurance/digit-health-insurance' element={<DigitHealthLearn />} />
        <Route path='/health-insurance/hdfc-ergo-health-insurance' element={<HdfcargoHealthLearn />} />
        <Route path='/health-insurance/reliance-health-insurance' element={<RelianceHealthLearn />} />
        <Route path='/life-insurance/adityalife-insurance' element={<AdityaLifeLearn />} />
        <Route path='/life-insurance/hdfc-life-insurance' element={<HdfcLifeLearn/>} />
        <Route path='/life-insurance/icici-life-insurance' element={<IciciLifeLearn />} />
        <Route path='/life-insurance/maxlife-insurance' element={<MaxLifeLearn />} />
        <Route path='/futurelife_insurance_learn_more' element={<FutureLifeLearn />} />
        <Route path='/bike-insurance/hdfc-bike-insurance' element={<HdfcBikeInsurance />} />
        <Route path='/bike-insurance/bajaj-bike-insurance' element={<BajajBikeInsurance />} />
        <Route path='/bike-insurance/acko-bike-insurance' element={<AckoBikeInsurance />} />
        <Route path='/bike-insurance/future-bike-insurance' element={<FutureBikeInsurance />} />
        <Route path='/bike-insurance/icici-bike-insurance' element={<IciciBikeInsurance />} />
        <Route path='/car-insurance/tata-aij-car-insurance' element={<TataAijLearnMore />} />
        <Route path='/car-insurance/hdfc-car-insurance' element={<HdfcCarLearnMore />} />
        <Route path='/car-insurance/acko-car-insurance' element={<AckoLearnMore />} />
        <Route path='/car-Insurance/icici-lombard-car-insurance' element={<IciciLombardLearn />} />
        <Route path='/car-insurance/bajaj-allianz-car-insurance' element={<BajajLearnMore />} />
         <Route path='/learn-more-page/card' element={<LearnMoreAllCards />} />
        <Route path='/demat/zeroda-demat-account' element={<ZerodaDematLearnMore />} />
        <Route path='/demat/fyers-demat-account' element={<FyersDematLearnMore />} />
        <Route path='/demat/groww-demat-account' element={<GrowwDematLearnMore />} />
        <Route path='/hdfcdemat_detail' element ={<HdfcDematLearnMore />} />
        <Route path='/demat/angel-one-demat-account' element={<AngelOneLearnMore />} />
        <Route path='/demat/upstox-demat-account' element={<UpStoxLearnMore />} />
        <Route path='/credit-card/aubank-credit-card' element={<AuBankLit />} />
        <Route path='/credit-card/eazy-diner-indusind-bank-credit-card' element={<EasyDinerBank />} />
        <Route path='/credit-card/hdfc-bank/millennia-credit-card' element={<HdfcBankMillennia />} />
        <Route path='/credit-card/sbi-simplyclick-credit-card' element={<SbiSimplyClick />} />
        <Route path='/credit-card/idfcfirst-classic-credit-card' element={<IdfcFirstClassic />} />
        <Route path='/credit-card/axis-bank-flipkart-credit-card' element={<FlipcartAxixBank />} />
        <Route path='/car-loan' element={<CarLoan />} />
        <Route path='/home-loan' element={<HomeLoan />} />
        <Route path='/two-wheeler-loan' element={<TwoWheelerLoan />} />
        <Route path='/education-loan' element={<EducationLoan />} />
        <Route path='/personal-loan' element={<PersonalLoanU />} />
        <Route path='/learnmore_car_detail' element={<CarLoanLearnMore />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/term-and-conditions' element={<TermsAndConditions />} />
        <Route path='/amex_detail' element={<AMEX />} />
        <Route path='/au-bank/credit-card' element={<Au />} />
        <Route path='/axix-bank/credit-card' element={<AXIX />} />
        <Route path='/icici-bank/credit-card' element={<ICICI />} />
        <Route path='/idfc-bank/credit-card' element={<IDFC />} />
        <Route path='/induslnd-bank/credit-card' element={<Indulnd />} />
        <Route path='/sbi-bank/credit-card' element={<SBI />} />
        <Route path='/hdfc-bank/credit-card' element={<HDFC />} />
        <Route path='/bank-of-baroda/credit-card' element={<BankOfBaroda />} />
        <Route path='/citi_detail' element={<Citi />} />
        <Route path='/fedral_detail' element={<Federal />} />
        <Route path='/hsbc-bank/credit-card' element={<Hsbc />} />
        <Route path='/kotak_detail' element={<Kotak />} />
        <Route path='/american-bank/credit-card' element={<American />} />
        <Route path='/standard-bank/credit-card' element={<Standard />} />
        <Route path='/yes-bank/credit-card' element={<YesBank />} />
        <Route path='/hdfc_learn_more' element={<HdfcLearnMore />} />
        <Route path='/credit-card/travel-credit-card' element={<Travel />} />
        <Route path='/credit-card/best-cashback-credit-card' element={<CashBack />} />
        <Route path='/credit-card/best-premium-credit-card' element={<Premium />} />
        <Route path='/credit-card/best-lifetime-free-credit-cards' element={<LifeTimeFee />} />
        <Route path='/credit-card/fuel-credit-card' element={<Fuel />} />
        <Route path='/demat_learn_more' element={<DematLearnMore />} />
        {/* <Route path='/detailofbank' element={<BankDetail />} /> */}
        <Route path='/personal_loan' element={<PersonalLoan />} />
        <Route path='/credit-card' element={<CreditCardPage />} />
        <Route path='/demat' element={<Demat />} />
        <Route path='/research' element={<ResearchPage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/contact-us' element={<ContactPage />} />
        {/* <Route path='/insurance' element={<InsurancePage />} /> */}
        <Route path='/life-Insurance' element={<LifeInsurance />} />
        <Route path='/health-insurance' element={<HealthInsurance />} />
        <Route path='/car-insurance' element={<CarInsurance />} />
        <Route path='/bike-insurance' element={<BikeInsurance />} />
        <Route path='/term_insurance' element={<TermInsurance />} />
        <Route path='/travel-insurance' element={<TravelInsurance />} />
        {/* <Route path='/blog' element={<BlogPage />} /> */}
        {/* <Route path='/blog_page/:id' element={<BlogPost />} /> */}
        {/* <Route path='/video/:id' element={<VideoDetail />} />
          <Route path='/channel/:id' element={<ChannelDetail />} />
          <Route path='/search/:searchTerm' element={<SearchFeed />} /> */}
      </Routes>
      <LayoutGTM>
      <FooterComponent />
      </LayoutGTM>
      </LayoutScroll>
      </Layout>
      
       {/* </Box> */ }
    </>
     
    
  );
}

export default App;



// import React from 'react'
// import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import Home from './components/Home';
// import PersonalLoan from './components/personalLoan/PersonalLoan';

// import CreditCardPage from './components/creditCard/CreditCardPage';
// import NavBar from './components/NavBar';
// import FooterComponent from './components/FooterComponent';
// import BlogPage from './components/blog/BlogPage';
// import ResearchPage from './components/research/ResearchPage';
// import AboutPage from './components/about/AboutPage';
// import ContactPage from './components/contact/ContactPage';
// import InsurancePage from './components/insurance/InsurancePage';
// import LifeInsurance from './components/insurance/LifeInsurance';
// import HealthInsurance from './components/insurance/HealthInsurance';
// import CarInsurance from './components/insurance/CarInsurance';
// import BikeInsurance from './components/insurance/BikeInsurance';
// import TermInsurance from './components/insurance/TermInsurance';
// import TravelInsurance from './components/insurance/TravelInsurance';
// import BlogPost from './components/blog/BlogPost';
// // import BankDetail from './components/creditCard/BankDetail';
// import AMEX from './components/BankDetails/AMEX'
// import HDFC from './components/BankDetails/HDFC';
// import Au from './components/BankDetails/Au';
// import AXIX from './components/BankDetails/AXIX';
// import ICICI from './components/BankDetails/ICICI';
// import IDFC from './components/BankDetails/IDFC';
// import Indulnd from './components/BankDetails/Indulnd';
// import SBI from './components/BankDetails/SBI';
// import HdfcLearnMore from './components/LearnMore/HdfcLearnMore';
// import BankOfBaroda from './components/BankDetails/BankOfBaroda';
// import Citi from './components/BankDetails/Citi';
// import Federal from './components/BankDetails/Federal';
// import Hsbc from './components/BankDetails/Hsbc';
// import Kotak from './components/BankDetails/Kotak';
// import American from './components/BankDetails/American';
// import Standard from './components/BankDetails/Standard';
// import YesBank from './components/BankDetails/YesBank';
// import CashBack from './components/topButtons/CashBack';
// import Fuel from './components/topButtons/Fuel';
// import LifeTimeFee from './components/topButtons/LifeTimeFee';
// import Premium from './components/topButtons/Premium';
// import Travel from './components/topButtons/Travel';
// import Demat from './components/demat/Demat';
// import DematLearnMore from './components/dematLearnMore/DematLearnMore';
// import TermsAndConditions from './components/privacy/TermsAndConditions';
// import PrivacyPolicy from './components/privacy/PrivacyPolicy';
// import CarLoan from './components/personalLoan/CarLoan';
// import CarLoanLearnMore from './components/LearnmorePageLoan/CarLoanLearnMore';
// import CarInsuranceLearn from './components/LearnMoreInsurance/CarInsuranceLearn';
// function App() {

//   return (
//     <BrowserRouter>
//       {/* <Box sx={{ background: '#000' }}> */}
//         <NavBar />
//         <Routes>
        
//           <Route path='/' exact element={<Home />} />
//           <Route path='/car_insurance_detail' element={<CarInsuranceLearn />} />
//           <Route path='car_loan' element={<CarLoan />} />
//           <Route path='learnmore_car_detail' element={<CarLoanLearnMore />} />

// <Route path='/privacy_policy' element={<PrivacyPolicy />} />
//           <Route path='/term_and_conditions' element={<TermsAndConditions />} />
//           <Route path='/amex_detail' element={<AMEX />} />
//           <Route path='/au_detail' element={<Au />} />
//           <Route path='/axix_detail' element={<AXIX />} />
//           <Route path='/icici_detail' element={<ICICI/>} />
//           <Route path='/idfc_detail' element={<IDFC />} />
//           <Route path='/indulnd_detail' element={<Indulnd />} />
//           <Route path='/sbi_detail' element={<SBI />} />
//           <Route path='/hdfc_detail' element={<HDFC />} />
//           <Route path='/bank_of_baroda_detail' element={<BankOfBaroda />} />
//           <Route path='/citi_detail' element={<Citi />} />
//           <Route path='/fedral_detail' element={<Federal />} />
//           <Route path='/hsbc_detail' element={<Hsbc />} />
//           <Route path='/kotak_detail' element={<Kotak/>} />
//           <Route path='/american_detail' element={<American/>} />
//           <Route path='/standard_detail' element={<Standard />} />
//           <Route path='/yes_bank_detail' element={<YesBank />} />
//           <Route path='/hdfc_learn_more' element={<HdfcLearnMore />} />
//           <Route path='/travel_detail' element={<Travel />} />
//           <Route path='/cashback_detail' element={<CashBack />} />
//           <Route path='/premium_detail' element={<Premium />} />
//           <Route path='/life_time_fee_detail' element={<LifeTimeFee />} />
//           <Route path='/fuel_detail' element={<Fuel />} />
//           <Route path='demat_learn_more' element={<DematLearnMore />} />
//           {/* <Route path='/detailofbank' element={<BankDetail />} /> */}
//           <Route path='/personal_loan' element={<PersonalLoan />} />
//           <Route path='/credit_card' element={<CreditCardPage />} />
//           <Route path='/demat' element={<Demat />} />
//           <Route path='/blog' element={<BlogPage />} />
//           <Route path='/research' element={<ResearchPage />} />
//           <Route path='/about' element={<AboutPage />} />
//           <Route path='/contact' element={<ContactPage />} />
//           <Route path='/insurance' element={<InsurancePage />} />
//           <Route path='/life_insurance' element={<LifeInsurance />} />
//           <Route path='/health_insurance' element={<HealthInsurance />} />
//           <Route path='/car_insurance' element={<CarInsurance />} />
//           <Route path='/bike_insurance' element={<BikeInsurance />} />
//           <Route path='/term_insurance' element={<TermInsurance />} />
//           <Route path='/travel_insurance' element={<TravelInsurance />} />
//           <Route path='/blog_page/:id' element={<BlogPost/>} />
//           {/* <Route path='/video/:id' element={<VideoDetail />} />
//           <Route path='/channel/:id' element={<ChannelDetail />} />
//           <Route path='/search/:searchTerm' element={<SearchFeed />} /> */}
//         </Routes>
//         <FooterComponent/>
//       {/* </Box> */}
//     </BrowserRouter>
//   );
// }

// export default App;
