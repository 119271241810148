import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Newsletter from '../contact/Newsletter';
function Icon({ id, open }) {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      >
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
  );
}

function AboutPage() {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
      {
          question: '  What is TheFinMaster.com?',
          answer:
              'TheFinMaster.com is your one-stop shop for all things personal finance! We provide clear and unbiased information on a wide range of financial products and services, like credit cards, mutual funds, and insurance. Think of us as your friendly neighborhood financial guide, helping you navigate the complexities of the financial world and make informed decisions.  ',
      },
      {
          question: ' Can I compare financial products side-by-side on TheFinMaster.com?',
          answer:
              'Absolutely! We understand the importance of comparing different options before making a financial decision. TheFinMaster.com offers user-friendly comparison tools for credit cards, mutual funds, and insurance policies.  These tools allow you to analyze key features, fees, and benefits to choose the product that best aligns with your needs.',
      },
      {
          question: '   Does TheFinMaster.com offer any educational resources?',
          answer:
              ' TheFinMaster.com offers a comprehensive library of educational resources, including informative blog posts. Our engaging blog articles cover a wide range of financial topics, from the basics of budgeting and saving to more complex concepts like investing and retirement planning.',
      },
      {
          question: 'Is TheFinMaster.com affiliated with any financial institutions?',
          answer:
              ' TheFinMaster.com maintains a strict policy of editorial independence. We are not affiliated with any banks, investment companies, or insurance providers. This ensures that the information and recommendations we provide are objective and unbiased.  Our goal is to empower you with the knowledge to make informed financial decisions, not to promote specific products or services.',
      },
      
  ];

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
        // If the clicked question is already expanded, collapse it
        setActiveIndex(null);
    } else {
        // If the clicked question is not expanded, expand it
        setActiveIndex(index);
    };
  };

  const backgroundImageUrl = "../assets/bgabout.webp"; 
  const data = [
    {
      iconbutton:  'https://www.iconpacks.net/icons/1/free-red-card-icon-460-thumb.png' ,
      price: "$19.99",
      ownedfrom: "Owned from January 2022"
    },
    {
      iconbutton:  'https://www.iconpacks.net/icons/1/free-red-card-icon-460-thumb.png' ,
      price: "$19.99",
      ownedfrom: "Owned from January 2022"
    },
    {
      iconbutton:  'https://www.iconpacks.net/icons/1/free-red-card-icon-460-thumb.png' ,
      price: "$19.99",
      ownedfrom: "Owned from January 2022"
    },
    {
      iconbutton:  'https://www.iconpacks.net/icons/1/free-red-card-icon-460-thumb.png' ,
      price: "$19.99",
      ownedfrom: "Owned from January 2022"
    },
    // Add more objects as needed
  ];
  return (
    <>
      <Helmet>  
            <title>Thefinmaster About </title>
            <link rel="canonical" href="https://thefinmaster.com/about" />
     </Helmet>
      {/* <div className="flex w-full flex-col gap-[100px] bg-white-A700 md:gap-[75px] sm:gap-[50px]">
        <div className="flex flex-col items-center gap-[100px] md:gap-[75px] sm:gap-[50px]">
          <div className="container-xs flex flex-col gap-[38px] md:p-5">
            <div className="flex flex-col gap-4">
              <h1 className="text-center text-4xl md:text-7xl leading-[140%] tracking-[-1.08px]">
                <>
                  Reimagining real  to <br />
                  make it easier to unlock.
                </>
              </h1>
              <p className="text-center text-lg leading-[180%]">
                <>
                  On the other hand, we denounce with righteous indignation and dislike men who <br />
                  are so beguiled and demoralized by the charms of pleasure of the moment, <br />
                  so blinded by desire, that they cannot foresee the pain and trouble.
                </>
              </p>
            </div>
            <div className="flex flex-col gap-6 md:flex-row">
              <img src="https://cdn3d.iconscout.com/3d/premium/thumb/people-discussion-about-business-8659916-6909666.png"
                alt="image"
                className="h-[300px] md:h-[400px] w-full md:w-[50%] rounded-[10px] object-cover"
              />
              <img
                src="https://img.freepik.com/free-psd/icon-characters-representing-community-3d-illustration_1419-2990.jpg?size=338&ext=jpg&ga=GA1.1.553209589.1713657600&semt=ais"
                alt="image"
                className="h-[300px] md:h-[400px] w-full md:w-[50%] rounded-[10px] object-cover"
              />
            </div>
            <div className="flex flex-col gap-6 md:flex-row">
              <img
                src="https://img.freepik.com/free-photo/3d-rendering-people-avatars-zoom-call_23-2149576734.jpg"
                alt="image"
                className="h-[300px] md:h-[400px] w-full md:w-[50%] rounded-[10px] object-cover"
              />
              <img
                src="https://pingbix.com/wp-content/uploads/2024/04/4568171.webp"
                alt="image"
                className="h-[300px] md:h-[400px] w-full md:w-[50%] rounded-[10px] object-cover"
              />
            </div>
          </div>
        </div>
      </div> */}

<div className="mx-auto max-w-screen-xl mt-4 mb-4">
        <div className="relative bg-cover bg-center h-80 md:h-96 flex items-center overflow-hidden">
          <div className="absolute inset-0 bg-black opacity-20"></div>
          <div className="absolute inset-0 z-10 flex flex-col items-center justify-center text-white text-center">
            <h1 className="text-3xl md:text-4xl lg:text-7xl font-bold mb-4">Welcome to TheFinMaster !</h1>
            <p className="text-sm md:text-base lg:text-lg mb-6">
            Empowering you to make informed financial decisions.
            </p>
            
          </div>
          <img className="absolute inset-0 object-cover w-full h-full" src={backgroundImageUrl} alt="Background" />
        </div>
      </div>
      <div className='w-full flex flex-col items-center '>
                        <div className='w-[90%] flex flex-wrap md:flex-nowrap justify-between mt-8'>
                            <div className='w-full'>
                                <img src='../assets/about3.webp' alt='img1' />
                            </div>
                            <div className='w-full flex items-center'>
                                <div>
                                    <h2 className='font-bold text-4xl'>Who We Are: Your Partner in Financial Empowerment?</h2>
                                    {/* <ul className='py-5 text-xl font-medium'> */}
                                    {/* <li className='flex items-center gap-2'><TbTransfer className='text-blue-600' /> Physical card in Standard and Premium options</li>
                                        <li className='flex items-center gap-2'><PiGlobeHemisphereWestLight className='text-blue-600' /> Instantly issued Virtual card</li>
                                        <li className='flex items-center gap-2'><MdOutlinePermContactCalendar className='text-blue-600' /> Make purchases or withdraw cash from ATMs</li> */}
                                    <p className='py-5 text-xl font-medium text-gray-600'>TheFinMaster.com is your one-stop shop for navigating the exciting yet often complex world of personal finance. We are a team of passionate financial experts dedicated to empowering individuals to make informed financial decisions and achieve their long-term goals.</p>
                                    <p className='mt-0.5 text-xl font-medium text-gray-600'> We believe that financial literacy is key to a secure and fulfilling future.  Through our user-friendly platform, we provide comprehensive educational resources, insightful financial tools, and access to a wide range of financial products from reputable lenders.</p>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex flex-col items-center mt-10 md:mt-0 mb-4'>
                        <div className='w-[90%] flex flex-wrap md:flex-nowrap justify-between '>

                            <div className='w-full md:hidden'>
                                <img src='../assets/about2.webp' alt='img1' />
                            </div>

                            <div className='md:w-full flex items-center'>
                                <div>
                                    <h2 className='font-bold text-4xl'>Why Choose TheFinMaster.com?</h2>
                                    {/* <ul className='py-5  text-xl font-medium'> */}
                                    {/* <li className='flex items-center gap-2'><TbTransfer className='text-blue-600' /> Physical card in Standard and Premium options</li>
                                        <li className='flex items-center gap-2'><PiGlobeHemisphereWestLight className='text-blue-600' /> Instantly issued Virtual card</li>
                                        <li className='flex items-center gap-2'><MdOutlinePermContactCalendar className='text-blue-600' /> Make purchases or withdraw cash from ATMs</li> */}
                                    <p className='py-5  text-xl font-medium text-gray-600'>TheFinMaster.com is more than just a financial information portal.  We are your trusted partner, providing personalized guidance and support on your financial journey.  Our team of experts is here to answer your questions, address your concerns, and help you develop a financial plan that aligns with your unique goals.
                                    </p>
                                    <p className=' mt-0.5 text-xl font-medium text-gray-600'> We are committed to building a community of financially empowered individuals.  Join us on TheFinMaster.com and unlock your full financial potential!</p>
                                    {/* </ul> */}
                                    {/* <ul className='py-5 text-xl font-medium'>
                                        <li className='text-base'>
                                            <span className='text-lg font-semibold text-blue-600'>Credit Cards:</span> Discover a world of possibilities with our detailed credit card comparisons. From cashback offers to travel rewards, we break down the features, fees, and benefits, helping you find the card that aligns perfectly with your lifestyle and spending habits.
                                        </li>
                                        <li className='py-2 text-base'>
                                            <span className='text-lg font-semibold text-blue-600'>Insurance:</span> Protecting what matters most is crucial. Explore our extensive range of insurance comparisons, including life, health, auto, and home insurance. Make informed decisions based on coverage, premiums, and policy terms.
                                        </li>
                                        <li className='text-base'>
                                            <span className='text-lg font-semibold text-blue-600'>Loans:</span> Whether it's a personal loan, home loan, or car loan, we simplify the loan comparison process. Find the best interest rates, repayment terms, and loan amounts to suit your financial needs.
                                        </li>
                                        <li className='py-2 text-base'>
                                            <span className='text-lg font-semibold text-blue-600'>Demat Accounts:</span> For savvy investors, our demat account comparisons are designed to help you choose the right platform for your stock trading and investment needs. Compare brokerage charges, user interface, and additional features effortlessly.
                                        </li>
                                    </ul> */}
                                   
                                </div>
                            </div>
                            <div className='md:w-full hidden md:block'>
                                <img src='../assets/about2.webp' alt='img1' />
                            </div>

                        </div>
                    </div>



      {/* <div className="container mx-auto py-20">
        <p className="text-center text-lg leading-8">
          On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
          {data.map((d, index) => (
            <div key={"listprice" + index} className="flex flex-col items-center gap-4">
              <div className="w-34 h-24 rounded-full flex items-center justify-center">
                <img src={d.iconbutton} alt="Icon" className="w-20 h-20" />
              </div>
              <div className="flex flex-col items-center gap-3">
                <p className="text-4xl tracking-tighter">{d.price}</p>
                <p className="text-lg leading-normal text-blue-gray-600">{d.ownedfrom}</p>
              </div>
            </div>
          ))}
        </div>
      </div> */}
<Newsletter />
{/* <div class="px-4 sm:px-8 lg:px-10 mb-8">
    <div class="w-full py-8 text-white bg-blue-600 rounded-lg shadow-lg">
        <div class="max-w-[1240px] mx-auto flex flex-col lg:flex-row items-center lg:justify-between">
            <div class="flex flex-col lg:flex-row items-center mb-4 lg:mb-0 lg:mr-4">
                <img src="../assets/newsletter.jpeg" alt="icon" class="w-48 h-40 mb-4 lg:mb-0 lg:mr-4" />
                <div class="text-center lg:text-left max-w-full lg:max-w-[60%]">
                    <h1 class="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 whitespace-normal lg:whitespace-nowrap">
                        Never miss out on exclusive offers and updates!
                    </h1>
                    <p class="text-lg sm:text-xl">Sign up now to receive personalized deals and stay in the loop.</p>
                </div>
            </div>
            <div class="flex flex-col sm:flex-row items-center w-full lg:w-auto mt-4 lg:mt-20 lg:mr-4">
                <input
                    class="flex-grow lg:flex-grow-0 rounded-md text-black p-2 mb-4 sm:mb-0 sm:mr-4"
                    type="email"
                    placeholder="Enter Email"
                />
                <button class="rounded-md font-medium w-auto lg:w-[200px] px-6 py-3 text-white bg-black hover:bg-gray-900">
                    Notify Me
                </button>
            </div>
        </div>
    </div>
</div> */}

      <div className="container mx-auto px-4 py-10 md:py-20">
  <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
    <div className="flex flex-col justify-center gap-10">
      <div>
        <h1 className="text-4xl md:text-4xl font-bold tracking-wide mb-4">What We Aim For?</h1>
        <p className="text-lg md:text-xl leading-relaxed">
        Our Mission: Simplifying Finance for Everyone
        </p>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex gap-4 items-start">
          <div className="flex items-center justify-center h-10 w-10 rounded-full border-2 border-gray-900 font-bold text-xl">
            1
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-lg md:text-xl font-semibold">  Demystifying Financial Complexity: </h5>
            <p className="text-xl font-medium leading-relaxed text-gray-600">
            Financial information can feel like a foreign language. We breaks down complex concepts into clear, easy-to-understand explanations.            </p>
          </div>
        </div>
        
        
        <div className="flex gap-4 items-start">
          <div className="flex items-center justify-center h-10 w-10 rounded-full border-2 border-gray-900 font-bold text-xl">
            3
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-lg md:text-xl font-semibold">  Taking Control of Your Finances:</h5>
            <p className="text-xl font-medium leading-relaxed text-gray-600">
            Knowledge is power, especially when it comes to your money. TheFinMaster.com equips you with the tools to make right decisions.            </p>          </div>
        </div>
        <div className="flex gap-4 items-start">
          <div className="flex items-center justify-center h-10 w-10 rounded-full border-2 border-gray-900 font-bold text-xl">
            3
          </div>
          <div className="flex flex-col gap-2">
            <h5 className="text-lg md:text-xl font-semibold">  Transparency is Key:</h5>
            <p className="text-xl font-mediumleading-relaxed text-gray-600">
            We believe in clear communication. Our platform offers a transparent view, allowing you to compare and choose with confidence.
            </p>          </div>
        </div>
        
        {/* Add more static content as needed */}
      </div>
    </div>
    <div className="flex justify-center items-center">
      <img src="../assets/about11.webp" alt="image" className="rounded-lg object-cover w-full md:w-auto md:h-auto" />
    </div>
  </div>
</div>
{/* <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-10">
  <div className="flex justify-center items-center">
    <img src="https://cdn3d.iconscout.com/3d/premium/thumb/about-us-10487023-8529328.png" alt="image" className="rounded-lg object-cover w-full md:w-auto md:h-auto" />
  </div>
  <div className="flex flex-col justify-center gap-10  ">
    <div>
      <h1 className="text-4xl md:text-5xl font-bold tracking-wide mb-4 ml-3  ">Our vision is simple.</h1>
      <p className="text-lg md:text-xl leading-relaxed ml-3">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. In a free hour,
        On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and
        demoralized by the charms of pleasure of the moment.
        <br />
        In a free hour, On the other hand, we denounce with righteous indignation and dislike men .
      </p>
    </div>
  </div>
</div> */}
  
 {/* FAQ section */}
 <div className="max-w-4xl mx-auto py-6 mt-10">
                <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
    </>
  );
}

export default AboutPage;




// import React from 'react'
// import AccordionComponent from '../accordion/AccordionComponent'

// const AboutPage = () => {
//     return (
//         <div className='w-full flex flex-col items-center'>
//         {/* <div className='w-full h-screen bg-black'> */}
//         <div className='w-full h-screen bg-cover bg-center' style={{ backgroundImage: 'url("/bg1.avif")' }}>
//           <div className='flex flex-col items-center gap-5 p-5 md:p-10'>
//             <h1 className='about text-white text-center pt-10 mb-10 text-4xl md:text-8xl border-b border-blue-600'>About Us</h1>
//             <p className='text-gray-200 text-center w-full md:w-[80%] text-lg md:text-2xl'>
//               We&apos;re your one-stop resource for personal loans, credit cards, and demat accounts. Our experts simplify the world of finance, helping you make informed choices for a brighter financial future. Explore our guides and comparisons to find the best options for your needs. Start your journey to financial success with FinancialEase Hub!
//             </p>
//             <p className='text-gray-200 text-center w-full md:w-[80%] text-lg md:text-2xl'>
//               FinancialEase Hub is your go-to destination for all things finance. We're here to support you on your financial journey, so you can make the best decisions for a brighter financial future. Welcome to the hub of financial possibilities!
//             </p>
//           </div>
//         </div>

//             <div className='w-[95%] py-10'>
//                 <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
//                 <AccordionComponent />
//             </div>
//         </div>
//     )
// }

// export default AboutPage
