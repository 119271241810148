import React, { useState } from 'react';
import InsuranceCard from "./InsuranceCard";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const BikeInsurance = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const faqs = [
        {
            question: 'What is two-wheeler insurance?',
            answer:
                'Two-wheeler insurance is a type of insurance policy that provides financial coverage for motorbikes and scooters. It covers costs related to accidents, theft, damage, and liability for injuries or property damage caused by your two-wheeler. This insurance is designed to protect you from financial losses associated with owning and operating a two-wheeler.',
        },
        {
            question: 'What factors should I consider when choosing a two-wheeler insurance policy?',
            answer:
                'When selecting two-wheeler insurance, consider factors such as the type of coverage (third-party liability vs. comprehensive), premium cost, deductible amount, policy limits, and additional features like roadside assistance. It is also important to check if the insurance covers accessories, modifications, or protective gear and whether there are discounts available for things like safe riding or multi-year policies.',
        },
        {
            question: 'Why is two-wheeler insurance important?',
            answer:
                'Two-wheeler insurance is important because it provides financial protection in case of accidents, theft, or damage to your motorbike or scooter. It also offers liability coverage, which is essential if you cause injury to someone or damage to their property while riding. Without two-wheeler insurance, you could face significant financial or legal consequences in the event of an incident.',
        },
        {
            question: 'Can I buy two-wheeler insurance through TheFinMaster.com?',
            answer:
                ' TheFinMaster.com is not a two-wheeler insurance provider. We offer educational resources, policy comparison tools, and impartial information to help you understand different types of two-wheeler insurance and find the right policy for your needs. Once you have identified the best coverage, you will be directed to the insurance providers website to complete a secure application process.',
        },
    ];

    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };

    return (
        <>
        <Helmet>
         <title>Bike Insurance Online: Buy/Renew Two Wheeler Insurance at ₹547*</title>
        <meta  name='description' content='Bike Insurance: Buy Two Wheeler Insurance in just 60 Seconds starting at ₹1.3* per day. Renew bike insurance online and Save upto 60%*.'/>
        <meta name='keywords' content='bike insurance companies, 1 year bike ins, motorcycle insurance companiesurance price, hdfc 2 wheeler insurance, icici lombard two wheeler insurance' />
        <link rel="canonical" href="https://thefinmaster.com/bike-insurance" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Bike Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
            {/* Your existing content */} 
            <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Explore Bike Insurance 
                  </span>{" "}
                  Options Now!
                </h1>
                <a href="">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    See Plans
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src="../assets/frontbike.webp"
                alt="car-loan"
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full h-full md:h-screen flex justify-center'>
            <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-5xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Explore Bike Insurance
Options Now!
</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>Zero maintenance charge, trusted by 40M+ Indians.</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='https://mypolicyexpress.com/wp-content/uploads/2024/04/own-bike-insurance-vs-third-party-insurance.webp' alt='demat growth' />
          </div>
        </div>
      </div>
            </div> */}

            <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 flex justify-center items-center">
    <p className="text-lg md:text-base lg:text-lg text-center">
    Don't get lost in the complexities of bike insurance policies. TheFinMaster.com is here to help you navigate the road to the right coverage. We've partnered with leading insurance providers to offer you a wide range of bike insurance options.  Compare plans, understand coverage details, and get free quotes – all conveniently located online.  Let us help you find the perfect bike insurance plan to keep you financially secure and ensure every ride is an adventure, not a worry.
    </p>
    </div>


            {/* <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of AU Bank LIT Credit Card</h1>
                    <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">The AU Small Finance Bank LIT Credit Card is India's pioneering customizable credit card, offering the unique advantage of selecting and paying for desired features exclusively. With a wide array of benefits spanning lifestyle, groceries, travel, and beyond, this card provides unparalleled flexibility and convenience.</p>
                    <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">It provides a comprehensive range of credit card benefits and allows holders to select one or multiple features/categories simultaneously. This empowers customers to enjoy benefits from their chosen category while only paying for the features they use, rather than the entire annual card fees.</p>
                </div> */}
                <div className=' lg:px-48'>
               <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/iciciinsurance.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
Premium Starting </p>
                <h4 className='text-2xl py-0.5 font-bold'> @ ₹538</h4>
                {/* <p className='text-gray-400 text-xs'>Max Limit 99 yrs</p> */}
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settlement </p>
                <h4 className='text-2xl py-0.5 font-bold'>87.84%</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Network Garages</p>
                <h4 className='text-2xl py-0.5 font-bold'>13000+</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
              
               <a href="https://www.icicilombard.com/motor-insurance/two-wheeler-insurance"
                  target="_blank" 
                  rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
        Get Quote!
        </button>
      </a>
                <Link to="/bike-insurance/icici-bike-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
               
            </div>
        </div>
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4 '>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/bajaj.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
Premium Starting </p>
                <h4 className='text-2xl py-0.5 font-bold'> @ ₹538</h4>
                {/* <p className='text-gray-400 text-xs'>Max Limit 99 yrs</p> */}
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settlement </p>
                <h4 className='text-2xl py-0.5 font-bold'>100%</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Network Garages</p>
                <h4 className='text-2xl py-0.5 font-bold'>  1500+</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
               
                <a href="https://www.bajajallianz.com/motor-insurance/two-wheeler-insurance-online.html"
                  target="_blank" 
                  rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
        Get Quote!
        </button>
      </a>
                <Link to="/bike-insurance/bajaj-bike-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
            </div>
        </div>
               
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/ockoinsurance.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
Premium Starting </p>
                <h4 className='text-2xl py-0.5 font-bold'> @ ₹555</h4>
                {/* <p className='text-gray-400 text-xs'>Max Limit 99 yrs</p> */}
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settlement </p>
                <h4 className='text-2xl py-0.5 font-bold'>92.71%</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Network Garages</p>
                <h4 className='text-2xl py-0.5 font-bold'> NA</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
            
                <a href="https://www.acko.com/two-wheeler-insurance/"
                  target="_blank" 
                  rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </a>
                <Link to="/bike-insurance/acko-bike-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
            </div>
        </div>
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/hdfcargo.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
Premium Starting </p>
                <h4 className='text-2xl py-0.5 font-bold'> @ ₹538</h4>
                {/* <p className='text-gray-400 text-xs'>Max Limit 99 yrs</p> */}
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settlement </p>
                <h4 className='text-2xl py-0.5 font-bold'>100%</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Network Garages</p>
                <h4 className='text-2xl py-0.5 font-bold'>2000+</h4>
            </div>
            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
           
                <a href="https://www.hdfcergo.com/two-wheeler-insurance"
                  target="_blank" 
                  rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </a>
                <Link to="/bike-insurance/hdfc-bike-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
            </div>
        </div>
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/futuregenral.webp'} />
                    </div>
                </Link>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
Premium Starting </p>
                <h4 className='text-2xl py-0.5 font-bold'> @ ₹538</h4>
                {/* <p className='text-gray-400 text-xs'>Max Limit 99 yrs</p> */}
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settlement </p>
                <h4 className='text-2xl py-0.5 font-bold'> 94%</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Network Garages</p>
                <h4 className='text-2xl py-0.5 font-bold'> 3500+</h4>
            </div>
            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
              
                <a href="https://general.futuregenerali.in/motor-insurance/bike-insurance"
                  target="_blank" 
                  rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </a>
                <Link to="/bike-insurance/future-bike-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
            </div>
        </div>
        </div>
            
            {/* FAQ section */}
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default BikeInsurance

