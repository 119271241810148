import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const  MaxLifeLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: 'What are the various types of Max Life Term Plans?',
        answer:
            'Max Life offers three types of term plans: Online Term Plan, Premium Return Protection Plan, and Super Term Plan.',
    },
    {
        question: 'What are the various benefits available under the plans from Max Life?',
        answer:
            'Each Max Life term plan offers different death benefits and optional add-on benefits through riders. Basic benefits include a lump-sum or staggered payout of the sum assured. Policyholders also have the flexibility to adjust the cover amount.',
    },
    {
        question: 'What is the minimum sum assured for Max Life Term Plans?',
        answer:
            'The minimum sum assured for Max Life Term Plans starts at INR 500,000, available under the Premium Return Protection Plan.',
    },
    {
        question: 'What is the maximum and minimum policy term available under these plans?',
        answer:
            'Max Life term plans have a minimum policy term of 10 years and a maximum of 35 years. The specific term duration depends on the age of entry and the maturity age of the insured individual.',
    },
  ];
    const benefits = [
      {
        title: 'Ensuring financial security for your family in case of untimely demise, with customizable sum assured options.',
      },
      {
        title: ' Life cover and investment in market-linked instruments, providing long-term market returns, tax savings, and fund-switching flexibility for financial security and investment goals.        ',
      },
      {
        title: 'Retirement plans provide financial security by investing during working years to create a corpus for retirement, offering a disciplined approach for golden years.        ',
      },
      {
        title: 'Child Plans ',
      },
      {
        title: 'Group Insurance Plans ',
      },
      {
        title: 'Savings and Income Plans ',
      },
    //   {
    //     title: 'Provides regular retirement income by investing a lump sum, with payouts from returns generated by the life insurance company.',
    //   },
      
      // {
      //   title: 'Tyre Secure Cover',
      // },
    ]
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
      <title>Max Life Insurance: Policy Details, Premium &amp; Benefits | TheFinmaster</title>
      <meta  name='description' content='Max Life Insurance - Max Life Insurance Company Limited is a leading life insurance company in India, offers premium, benefits and features of max life insurance plans. | TheFinmaster.'/>
      <meta name='keywords' content='max term insurance, max life insurance policy details, max life health insurance, max life smart wealth plan' />
      <link rel="canonical" href="https://thefinmaster.com/life-insurance/maxlife-insurance" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/life-insurance" className="text-blue-600 hover:text-blue-800">Life Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Max Life Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Maximize your life's security and your loved ones' 
                  </span>{" "}
                  future with Max Life. 
                </h1>
                <a href="https://www.maxlifeinsurance.com/"
                  target="_blank" 
                  rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                  Get Quote! 
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/maxlife1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about IMax Life Life Insurance </h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">  Max Life epitomizes customer-centricity and innovation, offering a diverse range of term and investment plans tailored to individual needs. Their term plans provide essential protection, while investment plans offer unique features such as premium waivers in critical illness or disability cases.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Max Life excels in child plans, ensuring financial security for children's education and marriage expenses with guaranteed payouts. With a commitment to innovation and customer satisfaction, Max Life continues to set new benchmarks in the insurance industry, earning the trust of millions of customers nationwide. </p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            99.51% Claim Settlement Ratio



            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/date.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            1 Day Claim Settlement


            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/life.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            1.01 Cr Live Insured so far

            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/policy.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            91.9% Policy Renewal Rate




            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/support.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Terminal Illness Coverage

            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/protect.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Coverage Against 64 Critical Illnesses



            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>

{/* <div className="w-full flex flex-col items-center  mb-10">
      <div className="w-[90%] text-center mb-10">
        <h2 className="font-bold text-4xl mb-4">Max Life Insurance  plan add-on benefits</h2>
        <p className="text-gray-700 mb-4">
          Choose from an assorted range of add-ons, for an additional premium, when buying Max Life Life Insurance  online.
        </p>
      </div>
      <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex flex-col items-center text-center"> */}
            {/* <img src='../assets/${benefit.icon}' alt={benefit.title} className="mb-4" /> */}
            {/* <h3 className=" text-gray-600 text-2xl mb-2">{benefit.title}</h3> */}
            {/* <p className="text-gray-700">{benefit.description}</p> */}
          {/* </div>
        ))}
      </div>
    </div> */}

    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Different Types of Life Insurance Offered by MAX Life:</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Term Life Insurance Plan: </span>Term insurance provides a fixed sum assured to beneficiaries in exchange for regular premiums, offering financial protection in case of the insured's demise during the policy term. It typically offers a higher coverage amount at lower premiums, making it an affordable option for securing your family's future. Additionally, some term plans may come with optional riders such as accidental death benefit or critical illness cover, providing enhanced coverage based on individual needs.
</p>
<p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Child insurance Plan:</span> Child insurance plans offer a vital means for parents to secure their children's future. These plans serve as essential financial tools, helping save substantially for their education and marriage expenses. Upon reaching 18, the plan provides maturity benefits, ensuring financial support for the child's needs. With built-in insurance coverage, these plans offer protection, while investment flexibility allows for tailored financial growth to combat inflation.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Unit Linked Insurance Plan (ULIP):</span> Unit-Linked Insurance Plans (ULIPs) combine insurance and investment, providing financial security for loved ones while offering opportunities for wealth creation through market-linked returns. With ULIPs, investors can choose from various fund options based on risk tolerance, including bonds for safety or equities for higher returns. ULIPs also offer flexibility for adjusting investment strategies and making partial withdrawals as needs change over time.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Retirement or Pension  Plan:</span> Retirement or pension plans aim to build a significant fund for your post-work years, fostering financial independence during retirement. By investing in these plans, you secure your future while potentially accumulating substantial wealth. These plans offer both investment and insurance benefits, ensuring financial security for your loved ones. Through a mix of debt and equity investments, retirement plans offer the potential for higher returns, empowering you to achieve your long-term financial goals.
</p>
<p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Savings Plan:</span> Savings plans are invaluable life insurance products designed to build a secure financial corpus gradually. They serve dual purposes: wealth creation and life insurance coverage, providing a safety net for financial obligations and future aspirations. With options like money-back policies, these plans offer flexibility and additional income streams, empowering individuals to plan for the long term while ensuring financial security and growth.</p>
<p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Group insurance Plan:</span> Group insurance plans provide comprehensive insurance benefits to a collective group efficiently. Ideal for banks, employers, associations, and more, these plans simplify large-scale coverage for members or employees. They offer hassle-free solutions, making them a preferred choice for organizations aiming to secure the financial well-being of their members or employees.</p>

</div>
<div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default MaxLifeLearn
