import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
     <Helmet>  
            <title>Thefinmaster Privacy Policy</title>
            <link rel="canonical" href="https://thefinmaster.com/privacy-policy" />
     </Helmet>
    <div className="container mx-auto px-4 lg:px-8 py-8 mt-8">
      <h1 className="text-3xl lg:text-4xl font-bold mb-4">Privacy Policy</h1>

      <p className="mb-4 lg:mb-8">
      This Privacy Policy describes how we collect, use, and disclose your personal information when you use the Website. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner.


      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">1. Information We Collect</h2>
      <p className="mb-4 lg:mb-3">
         We may collect the following information from you:
      </p>
      <h3 className="text-xl lg:text-1xl font-bold mb-2">1.1. Information You Provide:</h3>
      <p className="mb-4 lg:mb-3">
        1.2. Personal information you provide when you create an account (if applicable), such as your name, email address, and phone number.
      </p>
      <p className="mb-4 lg:mb-3">
        1.3. Information you provide when you use our comparison tools or request. 
      </p>
      <p className="mb-4 lg:mb-3">
        1.4. Information from us, such as your financial goals and risk tolerance.
      </p>
      <p className="mb-4 lg:mb-3">
      1.5. Information you submit through contact forms or surveys.
      </p>
      <p className="mb-4 lg:mb-3">
     1.6. User-generated content you submit, such as reviews or comments (subject to our moderation policies).</p>
     <h3 className="text-xl lg:text-1xl font-bold mb-2">1.2.1. Information Collected Automatically:</h3>
     <p className="mb-4 lg:mb-3">
      1.2.2. Information about your use of the Website, such as the pages you visit, the searches you perform, and the links you click..
      </p>
      <p className="mb-4 lg:mb-3">
      1.2.3. Information collected through cookies and other tracking technologies, such as your IP address, browser type, operating system, and device information.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">2. Use of Information</h2>
      <p className=" text-xl lg:text-1xl font-bold mb-4 lg:mb-3">
        2.1. We may use your information for the following purposes:
      </p>
      <p className="mb-4 lg:mb-3">
      2.2. To provide and maintain the Website.
      </p>
      <p className="mb-4 lg:mb-3">
      2.3. To personalize your experience on the Website, such as by tailoring content and advertisements to your interests.
      </p>
      <p className="mb-4 lg:mb-3">
      2.4. To send you information about our products and services, including newsletters and special offers (with your consent).</p>
      <p className="mb-4 lg:mb-3">
      2.5. To respond to your inquiries and requests.
      </p>
      <p className="mb-4 lg:mb-3">
      2.6. To improve the Website and our services.
      </p>
      <p className="mb-4 lg:mb-3">
      2.7. To comply with legal and regulatory requirements.
      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">3. Sharing of Information</h2>
      <p className="mb-4 lg:mb-3">
      3.1. We may share your information with third-party service providers who help us operate the Website. These service providers may be located in countries outside of your own. We will only share your information with service providers who have agreed to comply with strict data security standards.
      </p>
      <p className="mb-4 lg:mb-3">
      3.2. We may also share your information with third-party advertisers, but only in an aggregated and anonymized form that does not identify you personally. This information is used to understand user trends and to improve the effectiveness of our advertising campaigns.
      </p>
      <p className="mb-4 lg:mb-3">
     3.3. We will not share your personal information with any other third party without your consent, except as required by law. This may include situations where we are required to disclose your information to comply with a court order, subpoena, or other legal process.
</p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">4. Your Choices</h2>
      <h3 className="text-xl lg:text-1xl font-bold mb-2">4.1. You have choices regarding your personal information:</h3>
      <p className="mb-4 lg:mb-3">
      4.2. Accessing and Updating Your Information: You can access and update your personal information through your account settings (if applicable).
Opting Out of Marketing Communications: You can unsubscribe from our marketing communications by clicking on the "unsubscribe" link in any email you receive from us.
      </p>
      <h3 className="text-xl lg:text-1xl font-bold mb-2">4.2.1 Cookies and Tracking Technologies:</h3>
      <p className="mb-4 lg:mb-3">
      4.2.2 You can control cookies and tracking technologies through your browser settings. However, please note that disabling cookies may limit your ability to use certain features of the Website.
      </p>
      <p className="mb-4 lg:mb-3">
      4.2.3 Advertiser Dealings: Your dealings with advertisers found on the Website are solely between you and the advertiser. We are not responsible for any claims or damages arising from your dealings with advertisers.
      </p>
      

      <h2 className="text-xl lg:text-2xl font-bold mb-2">5. Data Security</h2>
      <p className="mb-4 lg:mb-2">
      5.1.  We take steps to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure. We cannot guarantee the security of your personal information.
      </p>
      
      <h2 className="text-xl lg:text-2xl font-bold mb-2">6. Children's Privacy</h2>
      <p className="mb-4 lg:mb-6">
      6.1.  The Website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe that your child has provided us with personal information, please contact us. We will take steps to remove the information from our systems.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">7. International Transfers</h2>
      <p className="mb-4 lg:mb-6">
      7.1. Your information may be transferred to and processed in countries outside of your own country. These countries may have different data protection laws than your own country. By using the Website, you consent to the transfer of your information to these countries.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">8. Changes to this Privacy Policy</h2>
      <p className="mb-4 lg:mb-6">
      8.1. We may update this Privacy Policy from time to time. We will post any changes on this page.  We encourage you to review this Privacy Policy periodically for the latest information on our privacy practices.
      </p>
      
      <h2 className="text-xl lg:text-2xl font-bold mb-2">9. Contact Us</h2>
      <p className="mb-4 lg:mb-6">
      9.1.  If you have any questions about this Privacy Policy, please contact us at [Your Email Address].
      </p>
      
    

      {/* <h2 className="text-xl lg:text-2xl font-bold mb-2">Contact Us</h2>
      <p className="mb-4 lg:mb-6">
        If you have any questions or concerns about these Terms and Conditions, please contact us at : <a href="mailto:Team@zonemedia.co" className="text-blue-800 underline">Team@zonemedia.co</a>.
      </p> */}
      
    </div>
    </>
  );
};

export default PrivacyPolicy;
