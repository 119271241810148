import React, { useEffect, useState } from 'react'
import CardComponent from '../cardComponents/CardComponent'
import AccordionComponent from '../accordion/AccordionComponent'
// import { CreditCardData } from './CardData'
import Http from '../../Http'
import { CARD_API } from '../../Helper'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { BsCashCoin } from "react-icons/bs";
import { MdOutlineAirplanemodeActive } from "react-icons/md";
import { IoBriefcaseSharp } from "react-icons/io5";
import { MdStars } from "react-icons/md";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { FaInfinity } from "react-icons/fa";
import { GiTavernSign } from "react-icons/gi";
import { Helmet } from 'react-helmet'
function Icon({ id, open }) {
  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      >
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
  );
}
const CreditCardPage = () => {

  useEffect(() => {
    const img = new Image();
    img.src = 'https://analytics.bidbox.ai/tracking/segment?key=izi2udb';
  }, []);
  const [activeIndex, setActiveIndex] = useState(0);
  const [CreditCardData,setCreditCardData] = useState([])


    const faqs = [
        {
            question: 'What are the different types of credit cards?',
            answer:
                'Absolutely! Credit cards come in various categories to suit your spending. Cashback cards give you a percentage of your spending back in cash, while travel cards focus on perks for frequent flyers. Premium cards offer top-tier benefits but come with annual fees. There are even cards with gas station benefits! Explore these options to find the perfect card for you.',
        },
        {
            question: 'Can we apply for a credit card through TheFinMaster.com?',
            answer:
                ' TheFinMaster.com does not directly process credit card applications. We provide information and comparison tools to help you choose the right card. Once you have selected a card, you will be directed to the issuers website to complete a secure application process.',
        },
        {
            question: 'What are the eligibility requirements for these credit cards?',
            answer:
                'Eligibility requirements vary depending on the credit card issuer and specific card.  Look for details on the issuers website or by clicking on the card for more information.  Generally, eligibility factors include credit score, income, and employment history.',
        },
        {
          question: 'What are the charges for credit cards?. ',
          answer:
              'Not always! While some cards have no annual fees, others do. There may also be fees for things like balance transfers, foreign transactions, or late payments. Explore the details of each card before applying.',
      },
    ];

    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    // useEffect(()=>{
    //     Http.post(CARD_API).then((res)=>{
    //       console.log({res})
    //         setCreditCardData(res)
    //     }).catch((err)=>{
    //         console.log(err)
    //     })
    // },[])
    
    // CreditCardData.length >0 &&CreditCardData.cards.filter((details)=>{ return details.category === 'Credit card'})
    // console.log("CreditCardData CreditCardData", CreditCardData)
    const cardDataS = [
        {
          imageUrl: '/Au1.webp',
          title: 'AU Bank LIT Credit Card',
          joiningFee: 'Nil',
          rewardsRate: 'Earn 1 Reward Point for every Rs. 100 spent on retail purchases using the card, and enjoy 10 times the points on...',
          learnMoreLink: '/credit-card/aubank-credit-card',
          applyNowLink: 'https://www.aubank.in/personal-banking/credit-cards/lit-credit-card'
        },
        {
            imageUrl: '/easy.webp',
            title: 'EazyDiner IndusInd  Credit Card',
            joiningFee: 'Nil',
            rewardsRate: 'Get rewarded with 2 Reward Points for every Rs. 100 spent, and earn double EazyPoints on all dining expenditures.',
            learnMoreLink: '/credit-card/eazy-diner-indusind-bank-credit-card',
            applyNowLink: 'https://www.indusind.com/in/en/personal/cards/credit-card/eazydiner-credit-card.html'
          },
          {
            imageUrl: '/hdfc.webp',
            title: 'HDFC Bank Millennia Credit Card',
            joiningFee: 'Rs. 1,000 + GST',
            rewardsRate: 'Enjoy a 5% cashback in the form of CashPoints, when you shop at select online merchants, and receive a 1% cashback on all other purchases.',
            learnMoreLink: '/credit-card/hdfc-bank/millennia-credit-card',
            applyNowLink: 'https://www.hdfcbank.com/personal/pay/cards/millennia-cards/millennia-cc-new'
          },
          {
            imageUrl: '/SBI1.webp',
            title: 'SBI SimplyCLICK Credit Card',
            joiningFee: 'Rs. 499 + GST',
            rewardsRate: 'Earn 10 times the Reward Points when you shop at partner brands such as BookMyShow and Apollo 24/7, and receive 5 times the Reward Points on other eligible purchases.',
            learnMoreLink: '/credit-card/sbi-simplyclick-credit-card',
            applyNowLink: 'https://www.sbicard.com/en/personal/credit-cards/shopping/simplyclick-sbi-card.page'
          },
          {
            imageUrl: '/Idfc1.webp',
            title: 'IDFC FIRST Classic Credit Card',
            joiningFee: 'Nil',
            rewardsRate: 'With this card, earn 3 Reward Points for every Rs. 150 spent offline, 6 for every Rs. 150 spent online, and up to 10 Reward Points on monthly spends exceeding Rs. 20,000.',
            learnMoreLink: '/credit-card/idfcfirst-classic-credit-card',
            applyNowLink: 'https://www.idfcfirstbank.com/credit-card/classic'
          },
          {
            imageUrl: '/axis1.webp',
            title: ' Flipkart Axis Bank Credit Card',
            joiningFee: 'Rs. 500 + GST',
            rewardsRate: 'Get a 5% cashback on purchases made on Flipkart, and enjoy a 4% cashback on transactions with preferred partners including Swiggy, Uber, PVR, and cult fit.',
            learnMoreLink: '/credit-card/axis-bank-flipkart-credit-card',
            applyNowLink: 'https://www.axisbank.com/retail/cards/credit-card/flipkart-axisbank-credit-card'
          },
        // Add more card data objects here for additional cards
      ];
    
    return (
      <>
        <Helmet>
      <title>Compare & Apply Credit Cards Online for Instant Approval</title>
      <meta  name='description' content='Credit Card - Get a Credit Card Approved Instantly from 50+ Top Banks in India. Compare Benefits, Offers, Features, Cashback & Rewards. Apply Card Now.'/>
      <meta name='keywords' content='best cash reward credit card, apply for gold card, amex credit card eligibility, hdfc credit card eligibility, aubank credit card, indusind eazydiner credit card, flipkart axis bank credit card apply' />
      <link rel="canonical" href="https://thefinmaster.com/credit-card" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Credit Card</span>
        </li> 
      </ol>
    </nav>
        <div className='w-full flex flex-col items-center overflow-hidden'>
            
            {/* <div className='w-full flex flex-col items-center justify-center bg-black h-[200px] md:h-[300px] shadow-lg mb-5 border-b-8 border-blue-600'>
                <h1 className='text-white md:text-7xl text-centerz-20 font-medium'>
                    Credit Card
                </h1>
                <p className='text-gray-200 text-2xl pt-2'>Experience financial freedom and flexibility with our credit card options.</p> */}
                {/* <img className='w-[100%] h-[100%] object-cover' src='https://www.kotak.com/content/dam/Kotak/guidance/hero-slider-banner/hacks-for-being-regular-with-personal-loan-repayments-hero-desktop.jpg' alt='personalLoan' /> */}
            {/* </div> */}

            {/* <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-5xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Compare Credit Cards & Apply Now</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>explore credit cards now!</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='../assets/credit.jpeg' alt='demat growth' />
          </div>
        </div>
      </div> */}
    
      <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600  ">
                  Compare Credit Cards 
                  </span>{" "}
                <p> & Apply Now!</p> 
                  
                </h1>
              
                <p className="mt-4 text-gray-600 text-lg pl-6 lg:pl-0 md:pl-0  ">
  Explore credit cards now!
</p>

                {/* <a href="#insurance_plans">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    See Plans
                  </button>
                </a> */}
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
               <motion.img
                className="w-full max-w-full"
                src='../assets/credit.webp'
                alt="car-loan"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeInOut' }}/>
            </div>
          </div>
       </div>

       </div>
        {/* <div className=" shadow-md rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-500 ho  border  ">
    <p className="text-lg md:text-xl lg:text-lg">
    In today's digital world, credit cards offer a powerful combination of convenience and financial rewards.  They allow you to make purchases without carrying cash, often extending a grace period before repayment is due. On top of that, many cards offer enticing rewards programs, returning a portion of your spending back to you in the form of cash back, travel points, or other benefits. However, it's crucial to use credit cards responsibly and pay your balance in full each month to avoid accumulating debt and high-interest charges.  
    </p>
    <p className="text-lg md:text-xl lg:text-lg">
    Here at TheFinMaster.com, we've compiled comprehensive lists to simplify your search and help you find the perfect credit card to unlock your financial potential.
    </p>
  </div> */}
    <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 flex justify-center items-center">
    <p className="text-lg md:text-base lg:text-lg text-center">
        In today's fast-paced world, convenience reigns supreme. Credit cards offer a powerful solution, combining ease of use with the potential for valuable rewards. Forget carrying cash – swipe, tap, or pay online with just a few clicks. But the benefits go beyond convenience. Here at TheFinMaster.com, we'll guide you through the exciting world of credit cards
    </p>
</div>

            <div className="flex flex-wrap   justify-center gap-4 lg:gap-10 mt-4">
          <Link to="/credit-card/best-cashback-credit-card" className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span className="relative flex items-center"><BsCashCoin className="mr-2 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Cash Back</span></span>
</Link>
          
    <Link to="/credit-card/travel-credit-card" className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span className="relative flex items-center"><MdOutlineAirplanemodeActive className="mr-4 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Travel</span></span>
</Link>
<Link to="/credit-card/fuel-credit-card"  className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span className="relative flex items-center">< BsFillFuelPumpFill className="mr-4 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Fuel</span></span>
</Link>
<Link to="/credit-card/best-lifetime-free-credit-cards"  className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span className="relative flex items-center"><FaInfinity className="mr-4 text-2xl text-black" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Lifetime Free</span></span>
</Link>
<Link to="/credit-card/best-premium-credit-card"  className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span className="relative flex items-center"><MdStars className="mr-1 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Premium</span></span>
</Link>
{/* <Link to="/premium_detail" state={CreditCardData ? CreditCardData?.cards : []} className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span className="relative flex items-center"><MdStars className="mr-1 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Premium</span></span>
</Link> */}
   
   
    
    
</div>

            <h1 className='text-center text-2xl md:text-2xl lg:text-5xl font-bold my-10'>Featured Credit Cards</h1>
            <div className=" flex items-center justify-center">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 ">
        {cardDataS.map((card, index) => (
          <motion.div 
            key={index} 
            className="rounded-lg overflow-hidden shadow-lg border border-gray-200 max-w-xs"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <img
              className="w-full"
              src={card.imageUrl}
              alt={card.title} 
            />
            <div className="px-3 py-2">
              <div className="font-bold text-lg mb-1 text-center text-blue-500">{card.title}</div>
              <p className="text-gray-600 text-base text-center mt-2"><strong>Joining Fee:</strong> {card.joiningFee}</p>
              <p className="text-gray-600 text-base text-center mt-2"><strong>Rewards Rate:</strong> {card.rewardsRate}</p>
            </div>
            <div className="flex justify-center py-2">  
            <a      
                className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-1 px-3 rounded-full mr-2 text-sm transition duration-300 ease-in-out"
                href={card.learnMoreLink}
                target= "blank"
                rel="nofollow"
              > 
                View Card
              </a>
              <a
                className="bg-green-500 hover:bg-green-700 text-white font-medium py-1 px-3 rounded-full text-sm transition duration-300 ease-in-out"
                href={card.applyNowLink}
                target= "blank"
                rel="nofollow"
              >
                Apply Now
              </a>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
    <div className="flex flex-col items-center justify-center mt-24">
    <h1 className="text-center text-2xl md:text-2xl lg:text-5xl font-bold mb-4">
    Discover Various Bank Credit Cards
    </h1>
    <p className="text-center text-lg md:text-xl lg:text-1xl ml-2">
    Tap on the bank logo below to discover the credit cards provided by each bank.
    </p>
</div>
    <div className="flex flex-wrap justify-center mt-10  gap-4 md:grid md:grid-cols-2 lg:grid-cols-6">
      
    <Link to="/hdfc-bank/credit-card" >
       <div className=" w-36 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white  hover:bg-white rounded-lg shadow-2xl group">
        <img src="../assets/2.webp" alt="HDFC Bank" className=" object-contain mr-4 " />
        </div>
    </Link>
    <Link to="/axix-bank/credit-card" >
    <div className=" w-36 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white  hover:bg-white rounded-lg shadow-2xl group">
        <img src="../assets/1.webp" alt="AXIS Bank" className=" object-contain mr-4" />
    </div>
    </Link>

    <Link to="/icici-bank/credit-card" >
     <div className=" w-36  relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white  hover:bg-white rounded-lg shadow-2xl group">
        <img src="../assets/12.webp" alt="ICICI Bank" className=" object-contain mr-4" />
    </div>
    </Link>
    {/* <Link to="/amex_detail">
      <div className=" w-32 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">
              <img src="/amex_logo.png" alt="AMEX" className=" object-contain mr-4" />
    </div>
    </Link> */}
    <Link to="/idfc-bank/credit-card"> 
   <div className=" w-36 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">
        <img src="../assets/4.webp" alt="IDFC Bank" className=" object-contain mr-4" />
    </div>
    </Link>
    <Link to="/sbi-bank/credit-card" >
<div className="w-36 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">      
  <img src="../assets/3.webp" alt="SBI Card" className=" object-contain mr-4" />
    </div>
    </Link>
    <Link to="/induslnd-bank/credit-card" >
<div className="w-36 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">      
  
<img src="../assets/5.webp" alt="Indulnd Bank" className=" object-contain mr-4" />
    </div>
    </Link>
    <Link to="/au-bank/credit-card" >
    <div className="w-36 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">        
        <img src="../assets/9.webp" alt="AU Bank" className=" object-contain mr-4" />
    </div>
    </Link>
    <Link to="/bank-of-baroda/credit-card"> 
    <div className="w-36 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">       
        <img src="../assets/8.webp" alt="Baroda bank" className=" object-contain mr-4" />
    </div>
    </Link>
    {/* <Link to="/citi_detail" >
    <div className="w-32 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">        
        <img src="/au_logo.png" alt="Citi Bank" className=" object-contain mr-4" />
    </div>
    </Link> */}
    {/* <Link to="/fedral_detail"> 
    <div className="w-32 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">        
        <img src="/au_logo.png" alt="Fedral Bank" className=" object-contain mr-4" />
    </div>
    </Link> */}
    {/* <Link to="/kotak_detail" >
    <div className="w-32 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">        
        <img src="/au_logo.png" alt="Kotak Bank" className=" object-contain mr-4" />
    </div>
    </Link> */}
    <Link to="/american-bank/credit-card">
    <div className="w-36 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">       
        <img src="../assets/ae.webp" alt="Rbl Bank" className="object-contain mr-4" />
    </div>
    </Link>
    <Link to="/hsbc-bank/credit-card">
    <div className="w-36 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">        
        <img src="../assets/6.webp" alt="Sbi Bank" className=" object-contain mr-4" />
   </div>
    </Link>
    <Link to="/standard-bank/credit-card">
    <div className="w-36 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">        

        <img src="../assets/7.webp" alt="Standard Bank" className=" object-contain mr-4" />
    </div>
    </Link>
    <Link to="/yes-bank/credit-card" >
    <div className="w-36 relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium z-0 transition-all duration-300 hover:scale-110 bg-white rounded-lg shadow-2xl hover:bg-white group">       
        <img src="../assets/11.webp" alt="Yesbank Bank" className=" object-contain mr-4" />
    </div>
    </Link>
    
</div>

{/* 
            <div className='w-[95%] py-10'>
                <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
                <AccordionComponent />
            </div> */}
           
        </div>
        <div className="max-w-4xl mx-auto py-6 mt-10">
                <h1 className="text-center text-2xl md:text-2xl lg:text-5xl font-bold mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default CreditCardPage





// import React, { useEffect, useState } from 'react'
// import CardComponent from '../cardComponents/CardComponent'
// import AccordionComponent from '../accordion/AccordionComponent'
// // import { CreditCardData } from './CardData'
// import Http from '../../Http'
// import { CARD_API } from '../../Helper'
// import { Link } from 'react-router-dom'
// const CreditCardPage = () => {
//     const [CreditCardData,setCreditCardData] = useState([])
//     const [currentPage, setCurrentPage] = useState(1);
//     const [cardsPerPage] = useState(10);

//     useEffect(()=>{
//         Http.post(CARD_API).then((res)=>{
//             setCreditCardData(res)
//         }).catch((err)=>{
//             console.log(err)
//         })
//     },[])
//     const indexOfLastCard = currentPage * cardsPerPage;
//     const indexOfFirstCard = indexOfLastCard - cardsPerPage;
//     console.log({indexOfLastCard,indexOfFirstCard})
//     const currentCards = CreditCardData.cards?.filter((card)=>card.category==="Credit card").slice(indexOfFirstCard, indexOfLastCard);
//     console.log({currentCards})
   
//     const paginate = (pageNumber) => setCurrentPage(pageNumber);
    
//     return (
//         <div className='w-full flex flex-col items-center '>
            
//             <div className='w-full flex flex-col items-center justify-center bg-black h-[200px] md:h-[300px] shadow-lg mb-5 border-b-8 border-blue-600'>
//                 <h1 className='text-white md:text-7xl text-centerz-20 font-medium'>
//                     Credit Card
//                 </h1>
//                 <p className='text-gray-200 text-2xl pt-2'>Experience financial freedom and flexibility with our credit card options.</p>
//                 {/* <img className='w-[100%] h-[100%] object-cover' src='https://www.kotak.com/content/dam/Kotak/guidance/hero-slider-banner/hacks-for-being-regular-with-personal-loan-repayments-hero-desktop.jpg' alt='personalLoan' /> */}
               
//             </div>

//             <h1 className='text-center font-bold text-5xl my-10'>Featured Credit Cards</h1>
//             <div className='w-[95%] mx-auto flex gap-5 flex-wrap md:flex-nowrap justify-center mb-10'>
//                 <div className='w-fit flex flex-col gap-8'>
//                     {
//                         currentCards &&
//                         currentCards.map((item, index) => {
//                             return <> <CardComponent key={index} Card_features={item?.Card_features} fees={item?.fees} cardName={item?.title} cardImage={item?.image} rating={item?.rating} category={item?.category} status={item?.status} /> </>
//                         })
//                     }
//                 </div>

//                 <div className='w-full relative '>
//                     <div className='sticky overflow-hidden top-20 shadow-lg w-full h-[230px] rounded-2xl bg-blue-500 border flex flex-col justify-between'>
//                         <div className='w-[70%] mx-auto'>
//                             <h2 className='text-center font-medium pt-5 text-lg'><span className='font-semibold text-white'>816 people</span> are on call with our advisors right now</h2>
//                         </div>
//                         <div className='w-full mx-auto'>
//                             <div className='w-[70%] mx-auto'>
//                                 <img src='https://static.pbcdn.in/health-cdn/images/insurer-logo/quotes-logos/advisors-logo.png' alt='imgg' />
//                             </div>
//                             <div className='bg-white'>
//                                 <h3 className='text-center text-blue-500 cursor-pointer font-semibold py-2'>Know your advisor</h3>
//                             </div>
//                         </div>
//                     </div>
              
//                 </div>
                
//             </div>
//                    {/* Pagination */}
//                    <ul className='flex justify-center mt-4'>
//                 {currentCards &&
//                      CreditCardData.cards?.filter((card)=>card.category==="Credit card").length > 0 &&
//                     Math.ceil( CreditCardData.cards?.filter((card)=>card.category==="Credit card").length / cardsPerPage) > 1 &&
//                     Array.from({ length: Math.ceil( CreditCardData.cards?.filter((card)=>card.category==="Credit card").length / cardsPerPage) }).map((_, index) => (
//                         <li key={index} className='mx-1'>
//                             <button
//                                 className={`px-3 py-1 rounded-lg ${
//                                     currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
//                                 }`}
//                                 onClick={() => paginate(index + 1)}
//                             >
//                                 {index + 1}
//                             </button>
//                         </li>
//                     ))}
//             </ul>
         
//             <div className='w-[95%] py-10'>
//                 <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
//                 <AccordionComponent />
//             </div>
//         </div>
//     )
// }

// export default CreditCardPage


