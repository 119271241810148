import React, { useState } from 'react'
import AccordionComponent from '../accordion/AccordionComponent'
import { FiArrowRight } from 'react-icons/fi';
import { motion } from "framer-motion";

    function Icon({ id, open }) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        );
      }
      
      const HdfcDematLearnMore = () => {
        const [activeIndex, setActiveIndex] = useState(0);
      
        const faqs = [
            {
                question: ' What is a demat account?',
                answer:
                    'A demat account (short for dematerialized account) is an electronic system used to hold and manage securities like stocks, bonds, mutual funds, and exchange-traded funds (ETFs). Instead of physical share certificates, all holdings are stored electronically, allowing for seamless transactions and safe record-keeping.',
            },
            {
                question: 'Why do I need a demat account?',
                answer:
                    'A demat account is essential for buying, selling, or holding securities in electronic form. It offers convenience, security, and ease of managing your investment portfolio. It is a mandatory requirement for trading in the stock market, as it eliminates the need for physical share certificates and reduces the risk of loss or damage.',
            },
            {
                question: '   How do I open a demat account?',
                answer:
                    ' To open a demat account, you need to choose a registered depository participant (DP), such as a bank or brokerage firm, and complete the account opening process. This typically involves submitting identity proof, address proof, a PAN card, and filling out the required forms. Once your documents are verified, you will receive your demat account details.',
            },
            {
                question: 'What are the charges associated with a demat account?',
                answer:
                    ':   The charges for a demat account can include account opening fees, annual maintenance charges (AMC), transaction fees, and additional costs for services like dematerialization or rematerialization. It is important to understand these charges and compare them across different providers to find the best fit for your needs.',
            },
            {
              question: 'Can I transfer securities between demat accounts?',
              answer:
                  ':   Yes, you can transfer securities between demat accounts, either within the same depository or between different depositories. This process requires specific forms and may incur certain fees. Ensure you understand the procedures and costs involved before initiating a transfer.',
          },
          {
            question: 'What happens to my demat account if I stop trading?',
            answer:
                ':   If you stop trading, your demat account remains active, but you might still incur annual maintenance charges (AMC) and other fees. You can choose to close your demat account if you no longer need it, but be sure to transfer or sell all your securities beforehand. Follow your DPs process for closing the account to avoid additional charges.',
        },
            
        ];
      
        const toggleAccordion = (index) => {
          if (activeIndex === index) {
              // If the clicked question is already expanded, collapse it
              setActiveIndex(null);
          } else {
              // If the clicked question is not expanded, expand it
              setActiveIndex(index);
          };
        };
  return (
    <>
    <div className='w-full flex flex-col items-center'>

      {/* <div className='w-full h-screen flex justify-center'>
        <div className='w-[95%] flex justify-center items-center'>
          <div className='w-[40%]'>
            <h1 className='text-6xl font-medium text-blue-600'>Open a freeDemat Account</h1>
            <p className='py-5 text-xl text-gray-600'>Zero maintenance charge, trusted by 40M+ Indians.</p>
            <button className='border rounded-full px-8 py-4 bg-blue-600 text-white'>Get Started</button>
          </div>
          <div className='w-[60%]'>
            <img className='w-[100%] h-[100%] object-cover' src='/growth.jpg' alt='demat growth' />
          </div>
        </div>
      </div> */}
      <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-6xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Open a free Demat Account</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>Zero maintenance charge, trusted by 40M+ Indians.</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='/growth.jpg' alt='demat growth' />
          </div>
        </div>
      </div>

      <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 justify-left items-left">
    <p className="text-lg md:text-base lg:text-lg text-left"> 
    Established in 1987, Angel One is a full-service brokerage firm that goes beyond just discount brokerage. While they offer competitive fees for trading equities, currencies, and commodities, Angel One also provides valuable services like investment advice, portfolio management, and access to a variety of financial products like mutual funds and IPOs.     </p>
   
</div>
      {/* <div className="relative px-6 py-6">
        <img 
        src= "https://marketplace.canva.com/EAENvp21inc/1/0/1600w/canva-simple-work-linkedin-banner-qt_TMRJF4m0.jpg" // Replace "banner-image.jpg" with your actual image URL
          alt= "Banner Image"
          className= "object-cover w-full h-64 md:h-80 lg:h-96"
        />
      </div> */}
      {/* <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
      <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
          Key Highlights
        </h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
        
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 1</h3>
            <p className="text-gray-700">
              Description of Highlight 1 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 2</h3>
            <p className="text-gray-700">
              Description of Highlight 2 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </div> */}
    {/* <div class="container mx-auto px-3">
  <h2 class="font-bold text-4xl md:pl-0 pl-3 md:mt-0 mt-5 text-center">Why open a <span class="text-blue-600">Demat</span> account with Us</h2>
  <div class="w-full text-white flex justify-center mt-10">

    <div class="w-full md:w-1/2 bg-neutral-800 flex flex-col justify-center items-center md:h-[450px] md:mr-2 mb-4 md:mb-0">
      <h2 class="text-3xl font-medium border-b-2 pb-5">Zero maintenance fee</h2>
      <p class="text-center w-[80%] md:w-full pt-5 text-lg">A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
    </div>
    <div class="w-full md:w-1/2 bg-blue-600 flex flex-col justify-center items-center md:h-[450px] md:ml-2">
      <h2 class="text-3xl font-medium border-b-2 pb-5">Zero maintenance fee</h2>
      <p class="text-center w-[80%] md:w-full pt-5 text-lg">A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
    </div>
  </div>
</div> */}


<div class="container mx-auto px-4">
  <h2 class="font-bold text-3xl md:text-4xl lg:text-4xl text-center mt-10 md:mt-20">Why open a <span class="text-blue-600">Demat</span> account with Us</h2>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10 md:mt-20">
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Rapid Onboarding</h3>
      <p class="text-base md:text-lg text-center">A fully digital onboarding process: simply provide your personal information, bank details, and eKYC details.

</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Brand’s Trust</h3>
      <p class="text-base md:text-lg text-center">A fully digital onboarding process: simply provide your personal information, bank details, and eKYC details.

</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Competent Brokerage</h3>
      <p class="text-base md:text-lg text-center">Benefit from zero brokerage on Equity Delivery and a flat rate of Rs. 20 on Intraday, F&O, Currencies, and Commodities.
</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Streamlined Trading Experience</h3>
      <p class="text-base md:text-lg text-center">Invest with brand’s user-friendly platforms on Android, iOS, or desktop.

</p>
    </div>
  </div>
</div>



      {/* <h2 className='font-semibold text-5xl'>Why open a <span className='text-blue-600'>Demat</span> account with Us</h2>
        
        <div className='w-full text-white flex justify-center mt-10'>
          <div className='w-full bg-black flex flex-col justify-center items-center h-[450px]'>
              <h2 className='text-4xl font-medium border-b-2 pb-5'>Zero maintenance fee</h2>
              <p className='text-center w-[80%] pt-5 text-lg'>A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
          </div>
          <div className='w-full bg-blue-600 flex flex-col justify-center items-center h-[450px]'>
              <h2 className='text-4xl font-medium border-b-2 pb-5'>Zero maintenance fee</h2>
              <p className='text-center w-[80%] pt-5 text-lg'>A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
          </div>
        </div> */}
      <div className="flex flex-col w-[95%] h-auto mt-6">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-center text-sm font-light">
                <thead
                  className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
                  <tr>
                    <th scope="col" className=" px-6 py-4">#</th>
                    <th scope="col" className=" px-6 py-4">Fee Type</th>

                    <th scope="col" className=" px-6 py-4">Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">1</td>
                    <td className="whitespace-nowrap  px-6 py-4">Delivery Charges-</td>

                    <td className="whitespace-nowrap  px-6 py-4">₹20 or 2.5% whichever is lower on Equity Delivery
</td>
                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">2</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Intraday Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4">  lower of ₹20 or 0.03% per executed order

</td>

                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">2</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Future Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4"> ₹20 per executed order
</td>

                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">2</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Option Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4"> ₹20 per executed order
</td>

                  </tr>
                  {/* <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap  px-6 py-4 font-medium">3</td>
                  <td colSpan={2} className="whitespace-nowrap  px-6 py-4">
                    Larry the Bird
                  </td>
                  <td className="whitespace-nowrap  px-6 py-4">@twitter</td>
                </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className='w-[95%] mt-5 md:mt-20'>
        <h2 className='font-bold text-3xl md:text-4xl text-center'>How to open a <span className='text-blue-600'>Demat</span> account with Us</h2>
        <p className='text-base md:text-lg text-blue-600 py-2 md:py-3 text-center md:text-left'>3 easy steps. Get started now!</p>

        <div className='w-full flex flex-col md:flex-row gap-5 md:gap-20 mt-3 md:mt-5'>
          <div className='w-full md:w-[30%] pl-2 md:pl-5'>
            <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>1</p>
            <div className='flex items-center justify-between mt-3 md:mt-5'>
              <div>
                <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Personal & Bank Details</p>
              </div>
              <div>
                <FiArrowRight className='text-2xl md:text-3xl animate-pulse' />
              </div>
            </div>
            <p className='text-sm md:text-lg py-2 w-full md:w-[70%]'>Choose a Depository Participant (DP): Select a DP, like a bank or stockbroker, to open your Demat account.</p>
          </div>
          <div className='w-full md:w-[30%] pl-2 md:pl-5'>
            <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>2</p>
            <div className='flex items-center justify-between mt-3 md:mt-5'>
              <div>
                <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Personal & Bank Details</p>
              </div>
              <div>
                <FiArrowRight className='text-2xl md:text-3xl animate-pulse' />
              </div>
            </div>
            <p className='text-sm md:text-lg py-2 w-full md:w-[70%]'>Choose a Depository Participant (DP): Select a DP, like a bank or stockbroker, to open your Demat account.</p>
          </div>
          <div className='w-full md:w-[30%] pl-2 md:pl-5'>
            <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>3</p>
            <div className='flex items-center justify-between mt-3 md:mt-5'>
              <div>
                <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Personal & Bank Details</p>
              </div>
              <div>
                {/* <FiArrowRight className='text-4xl'/> */}
              </div>
            </div>
            <p className='text-sm md:text-lg py-2 w-full md:w-[70%]'>Choose a Depository Participant (DP): Select a DP, like a bank or stockbroker, to open your Demat account.</p>
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-4 sm:mb-6 lg:mb-8">
          Heading
        </h2>
        <p className="text-base sm:text-lg lg:text-xl text-gray-700 leading-relaxed text-center">
          This is a paragraph. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat.
        </p>
      </div>
      
     
  
     

    </div>
     {/* FAQ section */}
     <div className="max-w-4xl mx-auto py-6 mt-10">
                <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
    </>
  )
}

export default HdfcDematLearnMore
