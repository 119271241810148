import React, { useState } from 'react'
import AccordionComponent from '../accordion/AccordionComponent'
import { FiArrowRight } from 'react-icons/fi';
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';

    function Icon({ id, open }) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        );
      }
      
      const AngelOneLearnMore = () => {
        const [activeIndex, setActiveIndex] = useState(0);
      
        const faqs = [
            {
                question: 'How to open an online trading account in Angel One?',
                answer:
                    'Opening an online trading account with Angel One is convenient. Visit the Angel One website and provide your Name, Mobile Number, and city to initiate the account opening process.',
            },
            {
                question: 'How to change the bank account in Angel One online?',
                answer:
                    'To update the linked bank account for your Angel One trading and demat account, you need to submit a signed paper request form. Please note that changes cannot be processed online or via email.',
            },
            {
                question: 'How to transfer money to Angel One demat account?',
                answer:
                    'Angel One provides multiple options for transferring funds from your bank account to your demat account. Unlike other brokers, fund transfers at Angel One are free of charge.',
            },
            {
                question: 'How do I find my demat account number in Angel One?',
                answer:
                    ' Your demat account number in Angel One consists of a unique 16-digit identifier assigned by the Depository Participant (DP). The first 8 digits represent your DP ID, and the last 8 digits represent your Customer ID.',
            },
            {
              question: 'How does Angel One operate?',
              answer:
                  '  Angel One is one of India largest full-service retail brokers, registered with SEBI as a broker and Investment Advisor. It offers comprehensive online and offline brokerage services, including research. Angel One is a member of both NSE and BSE and operates as a depository participant with CDSL.',
          },
          {
            question: 'What is the AMC for Angel One?',
            answer:
                'Angel One charges Rs. 0 (Free) as an Annual Maintenance Charge (AMC) for trading accounts and Rs. 240 for Demat accounts. AMC fees are levied to maintain these accounts.',
        },
        {
          question: 'What are the charges for delivery at Angel One?',
          answer:
              'Angel One does not charge any fees for equity delivery trades (CNC). However, customers are required to pay applicable taxes, demat debit transaction fees (Rs. 13.5 per trade), and exchange transaction charges.',
      }, 
      {
        question: 'Does Angel One provide trading tips?',
        answer:
            'Yes, Angel One offers trading tips for both stock and commodity trading to its customers.',
    },
        ];
      
        const toggleAccordion = (index) => {
          if (activeIndex === index) {
              // If the clicked question is already expanded, collapse it
              setActiveIndex(null);
          } else {
              // If the clicked question is not expanded, expand it
              setActiveIndex(index);
          };
        };
  return (
    <>
     <Helmet>
      <title>Angel One Demat Account Review, Opening Charges, Details</title>
      <meta  name='description' content='Angel One Demat account review. Angel One open Demat account or closure. Find account opening charges, closing forms, customer care and brokerage charges. | TheFinmaster.'/>
      <meta name='keywords' content=' angel one account opening, angel one demat account charges, angel broking free demat account' />
      <link rel="canonical" href="https://thefinmaster.com/demat/angel-one-demat-account" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/demat" className="text-blue-600 hover:text-blue-800">Demat</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Angel One Demat Account</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
    <div className='w-full flex flex-col items-center'>
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Explore Angel One's Comprehensive Demat Services for 
                  </span>{" "}
                  Seamless Investment Growth.
                </h1>
                <a 
          href="https://www.angelone.in/open-demat-account"
          target="_blank" 
          rel="noopener noreferrer"
        >
               
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Open Account!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src="../assets/angelonedemat1.webp"
                alt="car-loan"
              />
            </div>
          </div>
   

       </div>
      {/* <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-8xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Angel One</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>Elevating Your Investments.</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='/growth.jpg' alt='demat growth' />
          </div>
        </div>
      </div> */}

      <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 justify-left items-left">
    <p className="text-lg md:text-base lg:text-lg text-left"> 
    Established in 1987, Angel One is a full-service brokerage firm that goes beyond just discount brokerage. While they offer competitive fees for trading equities, currencies, and commodities, Angel One also provides valuable services like investment advice, portfolio management, and access to a variety of financial products like mutual funds and IPOs.     </p>
   
</div>
      {/* <div className="relative px-6 py-6">
        <img 
        src= "https://marketplace.canva.com/EAENvp21inc/1/0/1600w/canva-simple-work-linkedin-banner-qt_TMRJF4m0.jpg" // Replace "banner-image.jpg" with your actual image URL
          alt= "Banner Image"
          className= "object-cover w-full h-64 md:h-80 lg:h-96"
        />
      </div> */}
      {/* <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
      <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
          Key Highlights
        </h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
        
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 1</h3>
            <p className="text-gray-700">
              Description of Highlight 1 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 2</h3>
            <p className="text-gray-700">
              Description of Highlight 2 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </div> */}
    {/* <div class="container mx-auto px-3">
  <h2 class="font-bold text-4xl md:pl-0 pl-3 md:mt-0 mt-5 text-center">Why open a <span class="text-blue-600">Demat</span> account with Us</h2>
  <div class="w-full text-white flex justify-center mt-10">

    <div class="w-full md:w-1/2 bg-neutral-800 flex flex-col justify-center items-center md:h-[450px] md:mr-2 mb-4 md:mb-0">
      <h2 class="text-3xl font-medium border-b-2 pb-5">Zero maintenance fee</h2>
      <p class="text-center w-[80%] md:w-full pt-5 text-lg">A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
    </div>
    <div class="w-full md:w-1/2 bg-blue-600 flex flex-col justify-center items-center md:h-[450px] md:ml-2">
      <h2 class="text-3xl font-medium border-b-2 pb-5">Zero maintenance fee</h2>
      <p class="text-center w-[80%] md:w-full pt-5 text-lg">A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
    </div>
  </div>
</div> */}


<div class="container mx-auto px-4">
  <h2 class="font-bold text-3xl md:text-4xl lg:text-4xl text-center mt-10 md:mt-20">Why open a <span class="text-blue-600">Demat</span> account with <span className='text-blue-600'>ANGEL ONE </span>?</h2>

  <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10 md:mt-20">
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Rapid Onboarding</h3>
      <p class="text-base md:text-lg text-center">A fully digital onboarding process: simply provide your personal information, bank details, and eKYC details.

</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Brand’s Trust</h3>
      <p class="text-base md:text-lg text-center">A fully digital onboarding process: simply provide your personal information, bank details, and eKYC details.

</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Competent Brokerage</h3>
      <p class="text-base md:text-lg text-center">Benefit from zero brokerage on Equity Delivery and a flat rate of Rs. 20 on Intraday, F&O, Currencies, and Commodities.
</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Streamlined Trading Experience</h3>
      <p class="text-base md:text-lg text-center">Invest with brand’s user-friendly platforms on Android, iOS, or desktop.

</p>
    </div>
  </div>
</div>



      {/* <h2 className='font-semibold text-5xl'>Why open a <span className='text-blue-600'>Demat</span> account with Us</h2>
        
        <div className='w-full text-white flex justify-center mt-10'>
          <div className='w-full bg-black flex flex-col justify-center items-center h-[450px]'>
              <h2 className='text-4xl font-medium border-b-2 pb-5'>Zero maintenance fee</h2>
              <p className='text-center w-[80%] pt-5 text-lg'>A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
          </div>
          <div className='w-full bg-blue-600 flex flex-col justify-center items-center h-[450px]'>
              <h2 className='text-4xl font-medium border-b-2 pb-5'>Zero maintenance fee</h2>
              <p className='text-center w-[80%] pt-5 text-lg'>A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
          </div>
        </div> */}
      <div className="flex flex-col w-[95%] h-auto mt-6">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-center text-sm font-light">
                <thead
                  className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
                  <tr>
                    <th scope="col" className=" px-6 py-4">#</th>
                    <th scope="col" className=" px-6 py-4">Fee Type</th>

                    <th scope="col" className=" px-6 py-4">Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">1</td>
                    <td className="whitespace-nowrap  px-6 py-4">Delivery Charges</td>

                    <td className="whitespace-nowrap  px-6 py-4">Zero
</td>
                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">2</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Intraday Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4">  lower of ₹20 or 0.03% per executed order

</td>

                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">3</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Future Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4"> ₹20 per executed order
</td>

                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">4</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Option Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4"> ₹20 per executed order
</td>

                  </tr>
                  {/* <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap  px-6 py-4 font-medium">3</td>
                  <td colSpan={2} className="whitespace-nowrap  px-6 py-4">
                    Larry the Bird
                  </td>
                  <td className="whitespace-nowrap  px-6 py-4">@twitter</td>
                </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full md:w-[95%] mt-5 md:mt-20 py-4 px-4 md:px-16'>
  <h2 className='font-bold text-3xl md:text-4xl text-center'>How to open a <span className='text-blue-600'>Demat</span> account with <span className='text-blue-600'>ANGEL ONE </span>?</h2>
  <div className='text-center mt-4'>
    <p className='text-base md:text-2xl text-blue-600 '>3 easy steps. Get started now!</p>
  </div>
 

  <div className='w-full flex flex-col md:flex-row gap-5 md:gap-20 mt-3 md:mt-5 ml-0 md:ml-8'>
    <div className='w-full md:w-[30%] pl-2 md:pl-5'>
      <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>1</p>
      <div className='flex items-center justify-between mt-3 md:mt-5'>
        <div>
          <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Complete Sign-Up Form</p>
        </div>
        <div>
          <FiArrowRight className='text-2xl md:text-3xl animate-pulse' />
        </div>
      </div>
      <p className='text-sm md:text-lg py-2 md:py-0 md:w-[70%]'> Complete the sign-up form with your information.</p>
    </div>
    <div className='w-full md:w-[30%] pl-2 md:pl-5'>
      <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>2</p>
      <div className='flex items-center justify-between mt-3 md:mt-5'>
        <div>
          <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Mobile Verification</p>
        </div>
        <div>
          <FiArrowRight className='text-2xl md:text-3xl animate-pulse' />
        </div>
      </div>
      <p className='text-sm md:text-lg py-2 md:py-0 md:w-[70%]'> Verify your mobile number by entering the OTP received.</p>
    </div>
    <div className='w-full md:w-[30%] pl-2 md:pl-5'>
      <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>3</p>
      <div className='flex items-center justify-between mt-3 md:mt-5'>
        <div>
          <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Account Activation</p>
        </div>
        <div>
          {/* <FiArrowRight className='text-4xl'/> */}
        </div>
      </div>
      <p className='text-sm md:text-lg py-2 md:py-0 md:w-[70%]'>Provide your KYC details to finalize registration and receive your Demat Account information via email.</p>
    </div>
  </div>
 
</div>


    
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-4 sm:mb-6 lg:mb-8">
        Is it Right for  <span className='text-blue-600 text-6xl'>You</span> <span className='text-6xl'>?</span>
        </h2>
        <p className="text-base sm:text-lg lg:text-xl text-gray-700 leading-relaxed text-center">
        For those seeking a full-service experience with investment guidance and a range of products, Angel One offers a comprehensive solution. Their platform caters well to beginners or those who prefer a one-stop shop for their investment needs.
        </p>
      </div>
      
     
  
    {/* FAQ section */}
    
    </div>
    <div className="max-w-4xl mx-auto py-6 mt-10">
                <h1 className="text-center text-2xl md:text-2xl lg:text-5xl font-bold mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
    </>
  )
}

export default AngelOneLearnMore
