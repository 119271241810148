// src/SWPCalculator.js
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const SWPCalculator = () => {
  const [investment, setInvestment] = useState(1500000);
  const [interestRate, setInterestRate] = useState(12.9);
  const [duration, setDuration] = useState(10);
  const [monthlyWithdrawal, setMonthlyWithdrawal] = useState(21690);
  const [totalWithdrawal, setTotalWithdrawal] = useState(2602785);

  const calculateWithdrawal = () => {
    const monthlyRate = interestRate / 12 / 100;
    const months = duration * 12;
    const withdrawal = investment * monthlyRate / (1 - Math.pow(1 + monthlyRate, -months));
    setMonthlyWithdrawal(withdrawal.toFixed(2));
    setTotalWithdrawal((withdrawal * months).toFixed(2));
  };

  const handleInvestmentChange = (e) => {
    const value = e.target.value;
    setInvestment(value === '' ? 0 : parseInt(value));
  };

  const handleInterestRateChange = (e) => {
    const value = e.target.value;
    setInterestRate(value === '' ? 0 : parseFloat(value));
  };

  const handleDurationChange = (e) => {
    const value = e.target.value;
    setDuration(value === '' ? 0 : parseInt(value));
  };

  const data = {
    labels: ['Total Investment', 'Monthly Withdrawal (Yearly)', 'Total Withdrawal'],
    datasets: [
      {
        label: 'SWP Breakdown',
        data: [investment, monthlyWithdrawal * 12, totalWithdrawal - investment],
        backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56'],
      },
    ],
  };

  return (
    <>
     <Helmet>
         <title>Systematic Withdrawal Plan Calculator- SWP Calculator Online 2024 | TheFinmaster</title>
        <meta  name='description' content='SWP Calculator - Systematic Withdrawal Plan calculator considers the returns generated by your investment to determine the total value of your investment when your SWP tenure ends. | TheFinmaster'/>
        <meta name='keywords' content='systematic withdrawal plan calculator,swp calculator,best swp calculator,swp mutual fund calculator,sbi systematic withdrawal plan calculator,systematic withdrawal plan calculator with inflation' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/swp-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">SWP Calculator</span>
        </li> 
      
      </ol>
    </nav>
    <div className=' py-5'>
    <div className="container mx-auto p-4 shadow-lg rounded-md">
      <h1 className="text-2xl font-bold mb-4">SWP Calculator Online: Understand Systematic Withdrawal Plan</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Total Investment</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                max="10000000"
                value={investment}
                onChange={handleInvestmentChange}
                className="w-1/2 p-2 border rounded mr-2"
                
              />
              <span className="text-gray-700">₹ {investment.toLocaleString()}</span>
            </div>
            <input
              type="range"
              min="0"
              max="10000000"
              value={investment}
              onChange={(e) => setInvestment(parseInt(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Interest Rate</label>
            <div className="flex items-center">
              <input
                type="number"
                min="5"
                max="15"
                step="0.1"
                value={interestRate}
                onChange={handleInterestRateChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{interestRate} %</span>
            </div>
            <input
              type="range"
              min="5"
              max="15"
              step="0.1"
              value={interestRate}
              onChange={(e) => setInterestRate(parseFloat(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Duration</label>
            <div className="flex items-center">
              <input
                type="number"
                min="1"
                max="25"
                value={duration}
                onChange={handleDurationChange}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{duration} Yr</span>
            </div>
            <input
              type="range"
              min="1"
              max="25"
              value={duration}
              onChange={(e) => setDuration(parseInt(e.target.value))}
              className="w-full mt-2"
            />
          </div>
          <button
            onClick={calculateWithdrawal}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Calculate
          </button>
        </div>
        <div>
          <div className="mb-4 lg:ml-4 sm:mt-0">
            <h2 className="text-xl font-bold ">Results</h2>
            <p>Total Investment: ₹ {investment.toLocaleString()}</p>
            <p>Monthly Withdrawal: ₹ {monthlyWithdrawal}</p>
            <p>Total Withdrawal: ₹ {totalWithdrawal.toLocaleString()}</p>
          </div>
          <div>
            <h2 className="text-xl font-bold lg:ml-4 sm:mt-0">Breakdown</h2>
            <div className="flex justify-center">
              <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div className="flex flex-wrap px-6 py-6" >
        <div className="w-full md:w-2/3 p-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">SWP (Systematic Withdrawal Plan) </h1>
          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">SWP stands for Systematic Withdrawal Plan. Under SWP, if you invest LumpSum in a mutual fund, you can set an amount you’ll withdraw regularly and the frequency at which you’ll withdraw.</p>
          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">For example, let’s say you invested in HDFC Top 200 Fund an amount of ₹1 lakh for a year. Let’s assume that you decided to withdraw an amount of ₹10000 per month. So every month, your investment in the fund will reduce by ₹10000. The amount left every month after withdrawal will continue to remain invested.</p>
          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Use the above SWP calculator to know how much you can withdraw from your lumpsum investments.</p>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How can a SWP calculator assist you?</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            As per the Systematic Withdrawal Plan, an individual needs to invest a particular amount and withdraw a certain amount of the corpus invested each month. After withdrawal, the amount will be deducted from the investment while it continues to accumulate interest.            </p>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            It is difficult to accurately calculate the monthly withdrawals and total matured sum. TheFinMaster can help you with this complex calculations with the SWP calculator.The Systematic Withdrawal Plan calculator accurately computes your matured sum based on your monthly withdrawals. Calculators are simplifying the way people invest in mutual funds.              </p>
          
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Example of Systematic Withdrawal Plan</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            As mentioned before, the SWP allows investors to generate both monthly revenue as well as an accumulated sum at the end of the maturity period. Refer to this investment and withdrawal schedule for an in-depth idea.
            </p>          
              <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
              Here, an individual has invested Rs. 50,000 for tenure of 1 year along with a systematic withdrawal of Rs. 1,000 per month. Interest rate stands at 10%.
          </p>
          <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
          As such, total return of investments after the end of the  tenure stands at Rs. 4,565.          </p>
          <div className="mx-auto max-w-4xl mt-4">
            
    <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-300">
            <thead className="bg-gray-800 text-white">
                <tr>
                <th className="px-4 py-2 text-left">Months</th>
                <th className="px-4 py-2 text-left">Opening Balance</th>
                <th className="px-4 py-2 text-left">Withdrawals</th>
                <th className="px-4 py-2 text-left">Interest Earned</th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-300 text-gray-600">                                        
            <tr>
                <td className="px-4 py-2">1</td>
                <td className="px-4 py-2"> Rs. 50,000</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 408</td>
            </tr>
            <tr >
                <td className="px-4 py-2">2</td>
                <td className="px-4 py-2">Rs. 49,408</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 403</td>
            </tr>
            <tr>
                <td className="px-4 py-2">3</td>
                <td className="px-4 py-2">Rs. 48,812</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 398</td>
            </tr>
            <tr>
                <td className="px-4 py-2">4</td>
                <td className="px-4 py-2">Rs. 48,210</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 393</td>
            </tr>
            <tr>
                <td className="px-4 py-2">5</td>
                <td className="px-4 py-2">Rs. 47,604</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 388</td>
            </tr>
            <tr>
                <td className="px-4 py-2">6</td>
                <td className="px-4 py-2">Rs. 47,604</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 388</td>
            </tr>
            <tr>
                <td className="px-4 py-2">7</td>
                <td className="px-4 py-2">Rs. 46,922</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 383</td>
            </tr>
            <tr>
                <td className="px-4 py-2">8</td>
                <td className="px-4 py-2">Rs. 46,375</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 378</td>
            </tr>
            <tr>
                <td className="px-4 py-2">9</td>
                <td className="px-4 py-2">Rs. 45,753</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 373</td>
            </tr>
            <tr>
                <td className="px-4 py-2">10</td>
                <td className="px-4 py-2">Rs. 45,126</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 368</td>
            </tr>
            <tr>
                <td className="px-4 py-2">11</td>
                <td className="px-4 py-2">Rs. 44,494</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 362</td>
            </tr>
            <tr>
                <td className="px-4 py-2">12</td>
                <td className="px-4 py-2">Rs. 43,856</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 357</td>
            </tr>
            <tr>
                <td className="px-4 py-2">13</td>
                <td className="px-4 py-2">Rs. 43,214</td>
                <td className="px-4 py-2">Rs. 1,000</td>
                <td className="px-4 py-2">Rs. 352</td>
            </tr>
               
            </tbody>
        </table>
    </div>
</div>

          </div>
          
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How to use SWP calculator?</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            The calculator is user-friendly and easy to use first time out. However, you need to ensure that all values are properly put a.             </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              You will find three columns namely tenure, expected return and amount invested. Fill in the values as per your investment plan.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Enter values and calculate to find a similar SWP returns chart as mentioned above.
            </p>
                      </div>

          <div className="container mx-auto  py-2">

            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 mt-2">How can a SWP calculator benefit you?</h1>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg mt-2">Retirees and senior citizens are among the most common investors in this scheme. Such individuals obviously require a fixed monthly financial input. An SWP can provide the same over and above the pension or even as an alternative. They can easily use TheFinMaster simple and intuitive systematic withdrawal plan calculator for numerous benefits.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              A Systematic Withdrawal Plan ensures monthly returns while generating RoI on the total investment. This calculator can Easily help compute both accurately.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              It’s easy to set the amount which you wish to withdraw every month and calculate amount on maturity accordingly.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              It is easily accessible online.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              This SWP return calculator does not require any expertise to operate. Users need to use insert instead of this variables in proper space and output is ready in no time.
            </p>

          </div>
          <h1 className="text-xl font-medium  text-gray-700  ">“Looking to invest? Open an account with TheFinMaster and start investing in direct Mutual Funds for free”</h1>




    

         

        
       
        </div>

        <div className="w-full md:w-1/3 p-4">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
              <h2 className="text-lg font-semibold ">Popular Categories</h2>
            </div>
            <div className="p-2 ">
            <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                {/* {moreCategories.map(category => (
          <Link key={category.id} to={`/category/${category.id}`}>
            <li className="py-2 border-b border-gray-300">{category.name}</li>
          </Link>
        ))} */}
              </ul>
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default SWPCalculator;
