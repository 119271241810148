import React, { useEffect, useState } from 'react'
import CardComponent from '../cardComponents/CardComponent'
import AccordionComponent from '../accordion/AccordionComponent'
// import { CreditCardData } from './CardData'
import Http from '../../Http'
import { CARD_API } from '../../Helper'
import { Link, useLocation } from 'react-router-dom'
import { MdOutlineAirplanemodeActive } from "react-icons/md";
import { BsCashCoin } from "react-icons/bs";
import { MdStars } from "react-icons/md";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { FaInfinity } from "react-icons/fa"
import { Helmet } from 'react-helmet'
import FAQSchema from '../../FAQSchema'
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}
const Fuel = () => {
    const [CreditCardData,setCreditCardData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [cardsPerPage] = useState(10);
    // const location = useLocation()
    // console.log(location.state)
    useEffect(()=>{
        Http.post(CARD_API).then((res)=>{
            setCreditCardData(res)
            // console.log("Card API Response:", res);
        }).catch((err)=>{
            console.log(err)
        })
    },[])
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    console.log({indexOfLastCard,indexOfFirstCard})
    const currentCards = CreditCardData.cards?.filter((cards)=>cards.category_name==="Fuel").slice(indexOfFirstCard, indexOfLastCard);
    console.log({currentCards})
   
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    

    const [activeIndex, setActiveIndex] = useState(0);

    const faqs = [
      {
          question: 'Can I pay for fuel with my credit card?',
          answer:
              'Yes, you can use your credit card to pay for fuel. Just like with a debit card, you should ensure you only charge what you can afford to repay at the end of the billing cycle.',
      },
      {
          question: 'How can I obtain a fuel credit card?',
          answer:
              'To acquire a fuel credit card, you need to apply through your bank. Visit a branch of the bank where you wish to open an account to start the application process.',
      },
      {
          question: 'What is a fuel card and how is it used?',
          answer:
              'A fuel card is a payment card specifically designed for purchasing petrol and diesel at fuel stations. It simplifies managing and tracking fuel expenses.',
      },
      {
          question: 'Should I consider getting a fuel card?',
          answer:
              'A fuel card is advantageous for managing vehicle-related expenses. It helps in regulating fuel expenditures and provides insights into both personal and business-related fuel costs.',
      },
      {
        question: 'What are the eligibility requirements for a fuel credit card?',
        answer:
            'Applicants must be Indian citizens, at least 18 years old, and have a good credit score. A stable income is also a key criterion for approval.',
    },
    
    ];
  
  
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
      <title>Best Fuel Credit Cards in India 2024 | Apply Now &amp; Earn Free Fuel!</title>
      <meta  name='description' content='Find the updated list of best fuel credit cards in India 2024. Read about their fees, charges and benefits to find the best credit card for fuel.'/>
      <meta name='keywords' content='best credit card for fuel, petrol cards, indianoil axis bank credit card, bpcl sbi credit card, indian oil hdfc credit card, fuel surcharge on credit card' />
      <link rel="canonical" href="https://thefinmaster.com/credit-card/fuel-credit-card" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
     </Helmet>
     <FAQSchema faqs={faqs} />
        <div className='w-full flex flex-col items-center '>
            
            <div className='w-full flex flex-col items-center justify-center bg-black h-[200px] md:h-[300px] shadow-lg mb-5 border-b-8 border-blue-600'>
                <h1 className='text-white md:text-7xl text-centerz-20 font-medium'>
                    Fuel Card
                </h1>
                <p className='text-gray-200 text-2xl pt-2'>Experience financial freedom and flexibility with our credit card options.</p>
                {/* <img className='w-[100%] h-[100%] object-cover' src='https://www.kotak.com/content/dam/Kotak/guidance/hero-slider-banner/hacks-for-being-regular-with-personal-loan-repayments-hero-desktop.jpg' alt='personalLoan' /> */}
               
            </div>


            <div className="flex flex-wrap   justify-center gap-4 lg:gap-10 mt-4">
          <Link to="/credit-card/best-cashback-credit-card" className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span className="relative flex items-center"><BsCashCoin className="mr-2 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Cash Back</span></span>
</Link>
          
    <Link to="/credit-card/travel-credit-card" className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span className="relative flex items-center"><MdOutlineAirplanemodeActive className="mr-4 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Travel</span></span>
</Link>

<Link to="/credit-card/best-lifetime-free-credit-cards"  className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span className="relative flex items-center"><FaInfinity className="mr-4 text-2xl text-black" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Lifetime Free</span></span>
</Link>
<Link to="/credit-card/premium_detail"  className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-indigo-600 group-hover:h-full"></span>
<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
</span>
<span className="relative flex items-center"><MdStars className="mr-1 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Premium</span></span>
</Link>
</div>
            <h1 className='text-center font-bold text-5xl my-10'>Featured Credit Cards</h1>
            <div className='w-[95%] mx-auto flex gap-5 flex-wrap md:flex-nowrap justify-center mb-10'>
                <div className='w-fit flex flex-col gap-8'>
                    {
                        currentCards &&
                        currentCards.map((item, index) => {
                            return <> <CardComponent key={index} cardDetails={item} Card_features={item?.Card_features} fees={item?.fees} cardName={item?.title} cardImage={item?.image} rating={item?.rating} category={item?.category} status={item?.status} bank_link={item?.bank_link} /> </>
                        })
                    }
                </div>

                <div className='w-full relative '>
                    <div className='sticky overflow-hidden top-20 shadow-lg w-full h-[230px] rounded-2xl bg-blue-500 border flex flex-col justify-between'>
                        <div className='w-[70%] mx-auto'>
                            <h2 className='text-center font-medium pt-5 text-lg'><span className='font-semibold text-white'>816 people</span> are on call with our advisors right now</h2>
                        </div>
                        <div className='w-full mx-auto'>
                            <div className='w-[70%] mx-auto'>
                                <img src='https://static.pbcdn.in/health-cdn/images/insurer-logo/quotes-logos/advisors-logo.png' alt='imgg' />
                            </div>
                            <div className='bg-white'>
                                <h3 className='text-center text-blue-500 cursor-pointer font-semibold py-2'>Know your advisor</h3>
                            </div>
                        </div>
                    </div>
              
                </div>
                
            </div>
                   {/* Pagination */}
                   <ul className='flex justify-center mt-4'>
                {currentCards &&
                     CreditCardData.cards?.filter((card)=>card.category==="Credit card").length > 0 &&
                    Math.ceil( CreditCardData.cards?.filter((card)=>card.category==="Credit card").length / cardsPerPage) > 1 &&
                    Array.from({ length: Math.ceil( CreditCardData.cards?.filter((card)=>card.category==="Credit card").length / cardsPerPage) }).map((_, index) => (
                        <li key={index} className='mx-1'>
                            <button
                                className={`px-3 py-1 rounded-lg ${
                                    currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                                }`}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
            </ul>
        </div>
        <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Fuel



// import React, { useEffect, useState } from 'react'
// import CardComponent from '../cardComponents/CardComponent'
// import AccordionComponent from '../accordion/AccordionComponent'
// // import { CreditCardData } from './CardData'
// import Http from '../../Http'
// import { CARD_API } from '../../Helper'
// import { Link, useLocation } from 'react-router-dom'
// const Fuel = () => {
//     const [CreditCardData,setCreditCardData] = useState([])
//     const [currentPage, setCurrentPage] = useState(1);
//     const [cardsPerPage] = useState(10);
//     const location = useLocation();
//     const cardData = location.state;
//     console.log(cardData, location.state)
    
//     const indexOfLastCard = currentPage * cardsPerPage;
//     const indexOfFirstCard = indexOfLastCard - cardsPerPage;
//     //console.log({indexOfLastCard,indexOfFirstCard})
// //     console.log("cardData:", cardData);
// // const currentCards = cardData && cardData.filter((card) => card.category_name === "Fuel").slice(indexOfFirstCard, indexOfLastCard);
// // console.log("currentCards:", currentCards);
// console.log({cardData})
//     const currentCards = Array.isArray(cardData) && cardData.filter((cards)=>cards.category_name==="Fuel").slice(indexOfFirstCard, indexOfLastCard);
//     console.log({currentCards})
   
//     const paginate = (pageNumber) => setCurrentPage(pageNumber);
    
//     return (
//         <div className='w-full flex flex-col items-center '>
            
//             <div className='w-full flex flex-col items-center justify-center bg-black h-[200px] md:h-[300px] shadow-lg mb-5 border-b-8 border-blue-600'>
//                 <h1 className='text-white md:text-7xl text-centerz-20 font-medium'>
//                     Fuel Card
//                 </h1>
//                 <p className='text-gray-200 text-2xl pt-2'>Experience financial freedom and flexibility with our credit card options.</p>
//                 {/* <img className='w-[100%] h-[100%] object-cover' src='https://www.kotak.com/content/dam/Kotak/guidance/hero-slider-banner/hacks-for-being-regular-with-personal-loan-repayments-hero-desktop.jpg' alt='personalLoan' /> */}
               
//             </div>

//             <h1 className='text-center font-bold text-5xl my-10'>Featured Credit Cards</h1>
//             <div className='w-[95%] mx-auto flex gap-5 flex-wrap md:flex-nowrap justify-center mb-10'>
//                 <div className='w-fit flex flex-col gap-8'>
//                     {
//                         currentCards &&
//                         currentCards.map((item, index) => {
//                             return <> <CardComponent key={index} cardDetails={item} Card_features={item?.Card_features} fees={item?.fees} cardName={item?.title} cardImage={item?.image} rating={item?.rating} category={item?.category} status={item?.status} /> </>
//                         })
//                     }
//                 </div>

//                 <div className='w-full relative '>
//                     <div className='sticky overflow-hidden top-20 shadow-lg w-full h-[230px] rounded-2xl bg-blue-500 border flex flex-col justify-between'>
//                         <div className='w-[70%] mx-auto'>
//                             <h2 className='text-center font-medium pt-5 text-lg'><span className='font-semibold text-white'>816 people</span> are on call with our advisors right now</h2>
//                         </div>
//                         <div className='w-full mx-auto'>
//                             <div className='w-[70%] mx-auto'>
//                                 <img src='https://static.pbcdn.in/health-cdn/images/insurer-logo/quotes-logos/advisors-logo.png' alt='imgg' />
//                             </div>
//                             <div className='bg-white'>
//                                 <h3 className='text-center text-blue-500 cursor-pointer font-semibold py-2'>Know your advisor</h3>
//                             </div>
//                         </div>
//                     </div>
              
//                 </div>
                
//             </div>
//                    {/* Pagination */}
//                    <ul className='flex justify-center mt-4'>
//                 {currentCards &&
//                      CreditCardData.cards?.filter((card)=>card.category==="Credit card").length > 0 &&
//                     Math.ceil( CreditCardData.cards?.filter((card)=>card.category==="Credit card").length / cardsPerPage) > 1 &&
//                     Array.from({ length: Math.ceil( CreditCardData.cards?.filter((card)=>card.category==="Credit card").length / cardsPerPage) }).map((_, index) => (
//                         <li key={index} className='mx-1'>
//                             <button
//                                 className={`px-3 py-1 rounded-lg ${
//                                     currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
//                                 }`}
//                                 onClick={() => paginate(index + 1)}
//                             >
//                                 {index + 1}
//                             </button>
//                         </li>
//                     ))}
//             </ul>
            
//             <div className='w-[95%] py-10'>
//                 <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
//                 <AccordionComponent />
//             </div>
//         </div>
//     )
// }

// export default Fuel
