import React, { useState } from 'react';
import AccordionComponent from '../accordion/AccordionComponent'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}
function  SbiSimplyClick() {
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);


    // Function to get current date
    // const getCurrentDate = () => {
    //     const date = new Date();
    //     const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    //     return date.toLocaleDateString('en-US', options);
    // };
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: 'What are the advantages of the SBI SimplyCLICK Credit Card?',
            answer:
            'The SBI SimplyCLICK Credit Card offers additional perks for frequent online shoppers. It provides accelerated reward points for transactions on partner platforms like Cleartrip, BookMyShow, FoodPanda, and UrbanClap. Cardholders also enjoy an annual fee waiver on spending above Rs. 1 lakh.',
        },
        {
            question: 'What is the difference between SBI SimplyCLICK and SBI SimplyCLICK Advantage credit cards?',
            answer:
                'Both cards offer comparable features and benefits. The key distinction lies in the interest rates charged on outstanding balances: SBI SimplyCLICK charges 3.35% per month, while SimplyCLICK Advantage charges 2.50% per month.',
        },
        {
            question: 'Which credit card is preferable - SBI SimplySAVE or SBI SimplyCLICK?',
            answer:
                'The choice depends on spending habits. SBI SimplyCLICK is ideal for online purchases, especially on partner sites like Netmeds and Cleartrip, whereas SBI SimplySAVE offers benefits on everyday expenses like groceries and dining.',
        },
        {
            question: 'What are the modes of payment for the SBI SimplyCLICK Credit Card bill?',
            answer:
                'You can conveniently pay your SBI SimplyCLICK Credit Card bill online through various methods including debit card, UPI, NEFT, mobile app, net banking, SBI Auto Debit, and NACH. Offline options include paying at the bank counter, via manual or electronic drop boxes, and SBI ATMs.',
        },
        {
            question: 'What should I do if I lose my SBI SimplyCLICK Credit Card?',
            answer:
                'Immediately report the loss by contacting SBI Credit Card customer care or visiting the nearest SBI branch. Once reported, the card will be blocked, and you can request a replacement.',
        },
        {
            question: 'What are the Balance Transfer plans offered by the SBI SimplyCLICK Credit Card?',
            answer:
                'Two Balance Transfer plans are available:- 0.75% interest per month for three months with a processing fee of 1.5% or Rs. 199 (whichever is higher).- 1.27% interest per month for six months with a processing fee of 1.5% or Rs. 199 (whichever is higher).',

        },
        {
            question: 'How can I avail the Flexipay option?',
            answer:
                'You can opt for Flexipay by logging into your SBI Card online account, sending SMS FP to 56767, or calling 39 02 02 02 or 1860 180 1290 (for BSNL or MTNL customers).',
        },
        {
            question: 'How can I track my SBI SimplyCLICK Credit Card application status?',
            answer:
                'Monitor your application status by logging into the SBI internet banking portal or contacting SBI Credit Card customer care. Regular updates are also sent via SMS to your registered mobile number.',
        },
        {
            question: 'What is the foreign currency transaction fee charged on the SBI SimplyCLICK Credit Card?',
            answer:
                'The card applies a foreign currency transaction fee of 3.50% on the transaction amount.',
        },
        {
            question: 'How can I redeem the reward points earned on my SBI SimplyCLICK Credit Card?',
            answer:
                'Redeem your reward points by calling customer service, logging into the net banking portal, or using the mobile banking application.',
        },
    ];

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };
    return (
        <>
        <Helmet>
      <title>SBI SimplyClick Card - Check Benefits & Apply Online</title>
      <meta  name='description' content='SBI SimplyCLICK Credit Card - SimplyCLICK credit card gives exclusive benefits on online shopping. Know Features, Benefits & Offers Eligibility & Annual Fee.'/>
      <meta name='keywords' content='sbi simply click, simply click credit card, simplyclick, sbi simply click credit card benefits' />
      <link rel="canonical" href="https://thefinmaster.com/credit-card/sbi-simplyclick-credit-card" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
            <div className='w-[95%] py-10 px-10'>
                <div className="mx-auto ml-4 border border-gray-300 rounded-md mt-4  mb-6 ">
                    <div className="flex justify-between items-center px-4 py-2 mt-4 text-black">
                        {/* Left Side */}
                        <div className="flex items-center">
                            <h1 className="text-xl font-bold">SBI SimplyCLICK Credit Cards</h1>
                        </div>

                        {/* Right Side */}
                        <div className="flex items-center ">
                            {/* Replace 'your_link_here' with your actual link */}

                            <a href="https://www.sbicard.com/en/personal/credit-cards/shopping/simplyclick-sbi-card.page" target="_blank"  rel="noopener noreferrer" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>

                        </div>


                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        {/* Right Side - Image */}
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/SBI1.webp" alt="IDFC Bank" className="w-full" />
                            {/* Rating Component */}
                            <div className="flex items-center mr-4 text-lg ml-4">
                                {/* You can replace the static stars with your actual rating logic */}
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>  
                        </div>

                        {/* Left Side - Headings */}
                        <div className="md:w-1/3 flex flex-col justify-between mb-4 md:mb-0 ">
                            <div className="mb-2 ">
                                <h2 className="text-lg font-semibold text-blue-600">Fees</h2>
                                <p className='text-gray-600'>Joining Fee: Rs. 499 + GST</p>
                                <p className='text-gray-600'>Renewal Fee: Rs. 499 + GST</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg font-semibold text-blue-600">Welcome Benefits</h2>
                                <p className='text-gray-600'>Amazon Gift Card Worth Rs. 500</p>
                            </div>
                        </div>



                        {/* Right Side - Content */}
                        <div className="md:w-1/3 md:ml-6">

                            <div>
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p className='text-gray-600'>Shopping</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p className='text-gray-600'>0.25%</p>
                            </div>
                        </div>
                    </div>
                    {/* New Div with Border Line */}
                    <div className="border-t border-gray-300 mt-4">
                        {/* Content */}
                        <div className="text-xl md:text-2xl lg:text-1xl font-bold  mt-3 ml-4">Rewards and Benefits</div>
                        <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                            {/* Left Side - Headings */}
                            <div className="md:w-1/2 mb-4 md:mb-0">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600"> Rewards Rate</h2>
                                    <p className='text-gray-600'>Earn 10X Reward Points on purchases with partner brands like BookMyShow and Apollo 24/7. Enjoy 5X Reward Points on online shopping and 1 Reward Point for every Rs. 100 spent on all other transactions.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Travel</h2>
                                    <p className='text-gray-600'>N/A</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Movie and Dining</h2>
                                    <p className='text-gray-600'>N/A</p>
                                </div>
                            </div>
        
                            {/* Right Side - Headings */}
                            <div className="md:w-1/2 md:ml-6">
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">International Lounge Access</h2>
                                    <p className='text-gray-600'>Nil</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Zero Liability Protection</h2>
                                    <p className='text-gray-600'>Cardholders receive zero liability protection for a lost or stolen card, provided they report the loss to bank officials promptly.</p>
                                </div>
                                <div className="mb-2">
                                    <h2 className="text-lg font-semibold text-blue-600">Reward Redemption</h2>
                                    <p className='text-gray-600'>Reward Points can be redeemed for gift vouchers on sbicard.com or through the SBI mobile app, and also for paying off your credit card's outstanding balance, with each point valued at Rs. 0.25.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-300 mt-4" />
                    <Accordion open={open === 1} className='px-3' icon={<Icon id={1} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(1)}>Fees & Charges</AccordionHeader>
                        <AccordionBody>
                            <div className="flex flex-col md:flex-row items-center py-4 ml-4">
                                {/* Left Side - Headings */}
                                <div className="md:w-1/2 mb-4 md:mb-0">
                                    
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Interest Rates</h2>
                                        <p className='text-gray-600 text-base'>3.5% per month or 42% annualizedh</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Rewards Redemption Fee</h2>
                                        <p className='text-gray-600 text-base'>Rs. 99 (plus taxes) on each redemption request</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Fuel Surcharge
                                        </h2>
                                        <p className='text-gray-600 text-base'>A 1% fuel surcharge is waived for fuel transactions ranging from Rs. 500 to Rs. 3,000, with a maximum waiver of Rs. 100 per month.</p>
                                    </div>
                                </div>

                                {/* Right Side - Headings */}
                                <div className="md:w-1/2 md:ml-6">
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Foreign Currency Markup</h2>
                                        <p className='text-gray-600 text-base'>3.5% of the total transaction amount</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Waiver Based on Spending</h2>
                                        <p className='text-gray-600 text-base' >Get the renewal fee waived by spending Rs. 1 lakh annually.</p>
                                    </div>
                                    <div className="mb-2">
                                        <h2 className="text-lg font-semibold text-blue-600">Add-on Card Fee</h2>
                                        <p className='text-gray-600 text-base'>Nil</p>
                                    </div>
                                </div>
                                {/* Right Side - Headings */}
                                {/* <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div> */}
                            </div>

                        </AccordionBody>
                    </Accordion>
                    <Accordion className='px-3' open={open === 2} icon={<Icon id={2} open={open} />}>
                        <AccordionHeader onClick={() => handleOpen(2)}>
                            Card Details
                        </AccordionHeader>
                        <AccordionBody>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Welcome Benefit: Gift vouchers worth Rs. 500 from Amazon.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Annual Spending Rewards: E-vouchers from ClearTrip worth Rs. 2,000 for net annual spends of Rs. 1,00,000 and another Rs. 2,000 for spends of Rs. 2,00,000.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        10X Reward Points: On online spending with partner merchants, including Amazon, BookMyShow, Apollo24x7, and more.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        5X Reward Points: On all other online purchases made with your SBI SimplyClick Credit Card.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        1 Reward Point Per Rs. 100: On all offline spending, excluding fuel.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Annual Fee Waiver: Get the Rs. 499 annual fee waived by spending Rs. 1,00,000 annually.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-base relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Fuel Surcharge Waiver: 1% surcharge waived on fuel purchases between Rs. 500 and Rs. 3,000.
                    </p>
                                    {/* <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Left Heading 2</h2>
                            <p>Description for left heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font- text-blue-600">Left Heading 3</h2>
                            <p>Description for left heading 3</p>
                        </div> */}
                            

                                {/* Right Side - Headings */}
                                {/* <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div>
                      {/* Right Side - Headings *
                      <div className="md:w-1/2 md:ml-6">
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 1</h2>
                            <p>Description for right heading 1</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 2</h2>
                            <p>Description for right heading 2</p>
                        </div>
                        <div className="mb-2">
                            <h2 className="text-lg font-semibold text-blue-600">Right Heading 3</h2>
                            <p>Description for right heading 3</p>
                   </div>
                    </div> */}
                            

                        </AccordionBody>
                    </Accordion>

                </div>
                <div className=" mx-auto ">
                <div className="text-xl md:text-2xl lg:text-4xl font-bold mt-8 mb-4 ml-4">SBI SimplyCLICK Credit Card Detail</div>
                <div className="text-sm md:text-base lg:text-lg text-gray-600 mb-8">
                    {/* <p className="float-left">{getCurrentDate()}</p> */}
                    {/* <p className="float-right font-bold mr-4 ">{getCurrentDate()}</p> */}
                </div>
                <p className=' px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  '>

                The SBI SimplyCLICK Credit Card, a popular entry-level card from SBI Card, is ideal for online shopping. It offers 10x reward points at partner websites like Cleartrip, Apollo24x7, and BookMyShow, and 5x rewards on Amazon and other online purchases. 

                </p>
                <p className=' px-4 mt-2   text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg'>

                Offline spending earns one reward point per Rs. 100. The card has a low annual fee of Rs. 499 and allows contactless payments, with global acceptance. You can earn up to Rs. 4,000 in gift vouchers by meeting annual spending goals.                </p>
                
            </div>
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of SBI SimplyCLICK Credit Cards</h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">The SBI Simply Click Credit Card is a highly sought-after entry-level credit card, offering a variety of benefits. It provides a welcome bonus in the form of a Rs. 500 Amazon gift voucher upon payment of the joining fee. The card includes milestone benefits, such as ClearTrip e-vouchers worth Rs. 2,000 for annual spending of Rs. 1 lakh and an additional Rs. 2,000 for annual spending of Rs. 2 lakh.</p>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Additionally, it offers a 1% fuel surcharge waiver for fuel transactions between Rs. 500 and Rs. 3,000 (up to Rs. 100 per month). The cardholder's renewal fee is waived if annual spending exceeds Rs. 1 lakh. Furthermore, the card offers zero liability protection against unauthorized transactions if reported promptly.</p>
                </div>
                {/* <div className="container mx-auto px-4 py-2 ">
                    <h1 className="text-2xl font-bold pl-4 py-2">New UI Heading</h1>
                    <p className="text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> 
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600 ">&#8226;</span>
                        Responsive paragraph text. This text will adjust based on the screen size.
                    </p>
                    <p className="text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> 
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> 
                        Responsive paragraph text. This text will adjust based on the screen size.
                    </p>
                </div> */}
                <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Cashback & Reward Points</h1>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-4"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Offline Spends: Earn 1 Reward Point for every Rs. 100 spent on offline purchases, with a reward rate of 0.25%.                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        10X Reward Points: Get 10 Reward Points for every Rs. 100 spent at partner online merchants like Cleartrip, BookMyShow, Dominos, Lenskart, Yatra, Apollo24x7, Myntra, and Netmeds, providing a reward rate of 2.5%. Note that this 10X benefit doesn't apply to Gift Card purchases, utility bill payments, or Amazon recharges.                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        5X Reward Points: Earn 5 Reward Points for every Rs. 100 on all other online purchases with the card, yielding a reward rate of 1.25%.                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0 mt-0.5 text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Monthly Cap: A maximum of 10,000 Reward Points per month can be earned on bonus rewards (at 5X or 10X rates).                    </p>
                    <h1 className="text-2xl font-bold pl-4 py-2 mt-2">How to redeem rewards points?</h1>
                  
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        You can redeem the Reward Points earned with your SimplyCLICK SBI Credit Card by logging into sbicard.com. 
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Redeem your points for a variety of products and gift vouchers from the SBI Card reward catalog at a rate of 1 Reward Point = Re. 0.25. 
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Additionally, you can use your points to offset your credit card's statement balance. Visit the SBI Card website for more details and to apply.
                    </p>
                    
                    
                    <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How To Apply For The SBI SimplyCLICK Credit Cards? </h1>
                    <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
                    Follow these simple steps to apply for an  SBI SimplyCLICK Credit Cards

                    </p>
               
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Click the "Apply Now" button on this page.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        You'll be redirected to SBI credit card application page.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
                        <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                        Complete the required form to submit your application.
                    </p>
                    
                    </div>
                   
                    
                </div>
            </div>
            <div className="max-w-4xl mx-auto py-6">
            <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                        <button
                            onClick={() => toggleAccordion(index)}
                            className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                        >
                            {faq.question}
                            <svg
                                className={`w-6 h-6 transition-transform transform ${
                                    activeIndex === index ? 'rotate-180' : ''
                                }`}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d={activeIndex === index ? 'M19 9l-7 7-7-7' : 'M9 5l7 7 7-7'}
                                />
                            </svg>
                        </button>
                        {activeIndex === index && (
                            <div className="p-4 bg-gray-100">
                                <p className="text-gray-600">{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
            {/* <div className='w-[95%] py-10 px-10'>
                <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
                <AccordionComponent />
            </div> */}

        </>



    );
}

export default SbiSimplyClick;


