import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

const FlatReducingRateCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(100000);
  const [interestRate, setInterestRate] = useState(10);
  const [loanTenure, setLoanTenure] = useState(12);
  const [isYears, setIsYears] = useState(true);

  const calculateFlatRate = (amount, rate, tenure) => {
    const monthlyInterest = (amount * rate) / 100 / 12;
    const monthlyEMI = (amount / tenure) + monthlyInterest;
    const totalInterest = monthlyInterest * tenure;
    const totalAmount = amount + totalInterest;

    return { monthlyEMI, totalInterest, totalAmount };
  };

  const calculateReducingBalanceRate = (amount, rate, tenure) => {
    const monthlyRate = rate / 100 / 12;
    const emi = amount * monthlyRate * (Math.pow(1 + monthlyRate, tenure) / (Math.pow(1 + monthlyRate, tenure) - 1));
    const totalAmount = emi * tenure;
    const totalInterest = totalAmount - amount;

    return { monthlyEMI: emi, totalInterest, totalAmount };
  };

  const tenureInMonths = isYears ? loanTenure * 12 : loanTenure;

  const flatRate = calculateFlatRate(loanAmount, interestRate, tenureInMonths);
  const reducingBalanceRate = calculateReducingBalanceRate(loanAmount, interestRate, tenureInMonths);

  const flatData = {
    labels: ['Principal Amount', 'Total Interest'],
    datasets: [
      {
        data: [loanAmount, flatRate.totalInterest],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  const reducingData = {
    labels: ['Principal Amount', 'Total Interest'],
    datasets: [
      {
        data: [loanAmount, reducingBalanceRate.totalInterest],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: true,
    responsive: true,
  };
  
  return (
    <>


      <Helmet>
         <title>Flat Interest Rate vs Reducing Balance Interest Rate Free Calculator | TheFinmaster</title>
        <meta  name='description' content='Calculate your personal loan monthly payment with flat vs reducing rate EMI calculator in a few steps and check the total interest charges on your EMIs. | TheFinmaster'/>
        <meta name='keywords' content='flat vs reducing rate, emi calculator flat vs reducing, flat interest rate vs reducing balance calculator, flat interest rate vs reducing interest rate calculator' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/flat-vs-reducing-rate-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Flat Reducing Rate Calculator</span>
        </li> 
      
      </ol>
    </nav>
    <div className='py-4'>

   
    <div className="container mx-auto p-4 shadow-xl rounded-md border">
      <h1 className="text-2xl font-bold mb-4">Flat vs Reducing Rate Calculator</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Loan Amount</label>
            <input
              type="number"
              min="0"
              value={loanAmount}
              onChange={(e) => setLoanAmount(Number(e.target.value))}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Rate of Interest (per annum)</label>
            <input
              type="number"
              min="0"
              max="100"
              step="0.1"
              value={interestRate}
              onChange={(e) => setInterestRate(Number(e.target.value))}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Loan Tenure</label>
            <div className="flex items-center">
              <input
                type="number"
                min="0"
                value={loanTenure}
                onChange={(e) => setLoanTenure(Number(e.target.value))}
                className="w-full p-2 border rounded mr-2"
              />
              <div className='flex items-center '>
                <input
                  type="radio"
                  name="tenureType"
                  value="years"
                  checked={isYears}
                  onChange={() => setIsYears(true)}
                  className="mr-1"
                />
                <label className="mr-4">Years</label>
                <input
                  type="radio"
                  name="tenureType"
                  value="months"
                  checked={!isYears}
                  onChange={() => setIsYears(false)}
                  className="mr-1"
                />
                <label>Months</label>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={() => {
                setLoanTenure(loanTenure); // Just to trigger re-render
              }}
            >
              Calculate
            </button>
          </div>
        </div>
        <div >
<div className='flex flex-col md:flex-row justify-center gap-4'>
          <div className="mb-4">
            <h2 className="text-xl font-bold">Flat Interest Rate</h2>
            <p>Monthly EMI: ₹{flatRate.monthlyEMI.toFixed(2)}</p>
            <p>Total Interest: ₹{flatRate.totalInterest.toFixed(2)}</p>
            <p>Total Amount: ₹{flatRate.totalAmount.toFixed(2)}</p>
          </div>
          <div className="mb-4">
            <h2 className="text-xl font-bold">Reducing Balance Interest Rate</h2>
            <p>Monthly EMI: ₹{reducingBalanceRate.monthlyEMI.toFixed(2)}</p>
            <p>Total Interest: ₹{reducingBalanceRate.totalInterest.toFixed(2)}</p>
            <p>Total Amount: ₹{reducingBalanceRate.totalAmount.toFixed(2)}</p>
          </div>
          </div>
          <div className="mb-4">
            <h2 className="text-xl font-bold lg:pl-16 pl-0">Breakdown</h2>
            <div className="flex flex-col md:flex-row justify-center">
              <div className="w-full md:w-1/2 p-2">
                <h3 className="text-center font-semibold">Flat</h3>
                <div className="chart-container">
                  <Pie data={flatData} options={chartOptions} />
                </div>
              </div>
              <div className="w-full md:w-1/2 p-2">
                <h3 className="text-center font-semibold">Reducing Balance</h3>
                <div className="chart-container flex justify-center">
                  <Pie data={reducingData} options={chartOptions} width={300} height={300} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="flex flex-wrap px-6 py-6" >
        <div className="w-full md:w-2/3 p-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Understanding Loan Interest Rates: The Key to Smart Borrowing</h1>
          {/* <h1 className="text-xl font-medium   mt-2">Understanding Loan Interest Rates: The Key to Smart Borrowing</h1> */}

         
          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Loan interest rates can significantly impact your overall loan repayment costs. This guide will introduce you to the two most common methods for calculating loan interest: flat rate and reducing balance. Simplify Loan Decisions with TheFinMaster's Interest Rate Calculator.</p>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">What is Loan Interest?</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Interest is the fee charged by the lender for borrowing money. It's essentially the cost of using their funds. Loan interest rates are typically expressed as a yearly percentage (APR) of the loan amount.
            </p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Understanding EMI Payments:</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Your monthly EMI (Equated Monthly Installment) payment typically combines two parts:
             </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Principal:</span>  The portion of the payment that goes towards reducing the actual loan amount borrowed.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Interest: </span>  The portion of the payment that covers the interest expense.
            </p>
           
          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Choosing the Right Loan:</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Understanding the difference between flat and reducing balance interest rates can help you choose the loan option with the lower overall cost.            </p>
          </div>

          <div className="container mx-auto  py-2">

            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 mt-2">Flat Rate:
</h1>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg mt-2">A lumpsum calculator provides a valuable tool for estimating your potential returns:
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Simple Calculation: </span> Easier to understand as interest is calculated on the entire loan amount upfront.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Higher Overall Cost: </span>  You end up paying interest on the full loan amount, even though you're gradually paying down the principal.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Fixed Total Repayment:</span>  Provides predictable monthly payments throughout the loan term.
            </p>
           
          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Choosing the Right Option:</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            For shorter loans, the difference between flat and reducing rates might be small. But for longer loans, a reducing balance rate can save you a significant amount on interest.</p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 "> Rule of thumb:</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            If you prioritize predictable payments, flat rate might be simpler. If you want to save money on interest in the long run, reducing balance is generally better.
</p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 "> What is Flat Interest Rate Calculator and How Does It Help You </h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Interest is computed on the initial principal amount throughout the loan duration in Flat Interest Rate loans. </p>
          </div>
          <div className="container mx-auto  py-4">
              <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Calculation Formula</h1>

              <p className="text-lg font-normal mt-2 text-gray-700 ">Principal (P)

              </p>
              <p className="text-lg font-normal mt-2 text-gray-700 ">Annual Interest Rate (I) – in percentage
              </p>
              <p className="text-lg font-normal mt-2 text-gray-700 ">Tenure (T) – in years
              </p>
              <p className="text-lg font-normal mt-2 text-gray-700 ">Total Interest = (P * I * T)/100
              </p>
              <p className="text-lg font-normal mt-2 text-gray-700 ">Total amount to be repaid = P + (P * I * T) /100
              </p>
              
              <p className="text-lg font-normal mt-2 text-gray-700 ">Monthly EMI = ( P + (P *I* T)/100) / T*12    (T is in years)
              </p>
            </div>

            <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">What is Reducing Interest Rate Calculator and How Does It Help You </h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Interest is calculated on the remaining principal amount at any moment in Reducing Balance Interest Rate loans.  </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Calculation Formula:</span>   EMI = [P x Ix (1+I) ^T]/ [((1+I) ^T)-1)]
            </p>
          </div>

          <div className="container mx-auto  py-4">
         
              <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">where:</h1>

              <p className="text-lg font-normal mt-2 text-gray-700 ">P is the principal amount
              </p>
              <p className="text-lg font-normal mt-2 text-gray-700 ">I is the rate of interest / (100×12)
              </p>
              <p className="text-lg font-normal mt-2 text-gray-700 ">T is the number of years x 12
              </p>
              <p className="mt-2 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
              Total interest = monthly EMI x T – P</p>
              <p className="mt-2 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
              Total amount = monthly EMI x T</p>
            </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using Flat vs Reducing Rate Calculator:</h1>
           
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Save Money:</span>  Choose the loan option with the lower overall interest expense.

            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Informed Decisions:</span>   See a clear breakdown of interest paid under each method.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6   mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Quick and Easy: </span>  Get instant calculations without complex formulas.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6  mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Plan Your Finances:</span> Estimate your total loan cost to make informed budgeting decisions.

            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Always Available:</span>   Access the calculator anytime, anywhere to compare interest rates.
            </p>


          </div>

          <div className="container mx-auto  py-2">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 mt-2">How It Works:</h1>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg mt-2">Simply enter your loan amount, interest rate, and loan term. Our calculator will estimate the total interest paid under both flat and reducing rate methods.
            </p>
          </div>
         
          <div className="container mx-auto  py-2">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 mt-2">Grow Beyond the Basics:</h1>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg mt-2">TheFinMaster offers a variety of resources to help you understand loan terms and make informed financial choices.
            </p>
          </div>
          <div className="container mx-auto  py-2">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 mt-2">Ready to Get Started?</h1>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg mt-2">Use TheFinMaster's free Flat vs Reducing Rate Calculator today! Make smarter loan decisions and save money on your next loan.
            </p>
          </div>
          
         
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Start Investing Today!</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
              Lump sum investments can be a powerful tool for wealth creation. Consider starting with a smaller amount and gradually increasing as you gain confidence. Groww offers a user-friendly platform to begin your mutual fund journey.              </p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Open an account with Groww and explore the world of direct mutual funds! </h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
              (Consider adding a link to Groww's website here)              </p>
          </div>
        </div>

        <div className="w-full md:w-1/3 p-4">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
              <h2 className="text-lg font-semibold ">Popular Categories</h2>
            </div>
            <div className="p-2 ">
            <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                {/* {moreCategories.map(category => (
          <Link key={category.id} to={`/category/${category.id}`}>
            <li className="py-2 border-b border-gray-300">{category.name}</li>
          </Link>
        ))} */}
              </ul>
            </div>
          </div>

        </div>

      </div>




    </>
  );
};

export default FlatReducingRateCalculator;
