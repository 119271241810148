import React from 'react'

const PageCategory = ({onSelectCategory, activeCategory }) => {
  const categories = ["Loans ", "Credit Cards", "Demat", "Calculators", "Bike Insurance",  "Car Insurance", "Health Insurance", "Travel Insurance", "Life Insurance"]
    return (
        <>
    <div className='px-4 mb-8 lg:space-x-16 flex flex-wrap items-center border-b-2 py-5 text-gray-900 font-semibold shadow-md '>
      <button onClick={() => onSelectCategory(null)} className={`lg:ml-12 ${activeCategory ? "" : "active-button"}`}>
        All
      </button>
      {
        categories.map((category)=>(
            
            <button onClick={() => onSelectCategory(category)} 
            className={`mr-2 pl-2 space-x-16 py-2 ${activeCategory === category ? "active-button" : ""}`} 
            key={category}>
                {category} </button>
        ))
      }
    </div>
    </>
  )
}

export default PageCategory
