import React, { useEffect, useState } from 'react';
import SipCalculator from './SipCalculator';
import LumpsumCalculator from './LumpsumCalculator';
import { useLocation } from 'react-router-dom';

function ToolOfSipLipPages() {
  const [isSip, setIsSip] = useState(true);
  const location = useLocation()
  useEffect(()=>{
    if( location.pathname.includes('/lumpsum-calculator')){
      setIsSip(false)
    }
  },[])
  return (
    <div className="App">
      <header className="App-header">
        {isSip ? (
          <SipCalculator isSip={isSip} setIsSip={setIsSip} />
        ) : (
          <LumpsumCalculator isSip={isSip} setIsSip={setIsSip} />
        )}
      </header>
    </div>
  );
}
 
export default ToolOfSipLipPages;



