import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const HdfcCarLearnMore = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
      question: 'How much coverage does HDFC ERGO car insurance provide?',
      answer:
          'HDFC ERGO provides coverage for private cars based on the listed selling price and Insured Declared Value (IDV). Claims can be processed through cashless methods, and policies can be instantly availed via the HDFC ERGO website.',
  },
  {
      question: ' Does HDFC ERGO offer the option to renew premiums online?',
      answer:
          'Yes, HDFC ERGO car insurance allows customers to renew their premiums online using bank accounts or credit cards. Transferring from another insurer to HDFC ERGO motor insurance can also be seamlessly done online, ensuring full benefits are retained.',
  },
  {
      question: ' How is no claim bonus handled with HDFC ERGO car insurance?',
      answer:
          'Customers can avail discounts on their premiums based on their claim history. A claim-free year entitles a 20% discount on the subsequent renewal, increasing by 5% for each additional consecutive claim-free year, up to a maximum discount of 50%. No claim bonus amounts from previous policies can also be transferred to HDFC ERGO car insurance.',
  },
  {
      question: 'Should a cover note be availed with HDFC ERGO?',
      answer:
          'HDFC ERGO offers temporary insurance via cover notes during the car registration process, valid for 60 days until the comprehensive car insurance policy takes effect.',
  },
  {
    question: 'Does HDFC ERGO offer discounts based on age and occupation?',
    answer:
        'Yes, HDFC ERGO provides discounts based on age and occupation. Policyholders aged 35 to 45 receive a 5% discount on own damages, while those aged 46 to 65 can avail a 10% discount. Specific occupations such as Chartered Accountants, Defense services, Government teachers, employees, and doctors also qualify for discounts.',
  },
{
  question: 'Can HDFC ERGO Car Insurance be purchased online?',
  answer:
      'Yes, HDFC ERGO Car Insurance can be purchased online using credit cards or net banking. Policy renewals can also be conveniently conducted online.',
},
{
  question: 'Can I transfer my current third-party Motor Insurance to HDFC ERGO?',
  answer:
      'Yes, you can transfer your third-party motor insurance to HDFC ERGO, including the accrued No Claim Bonus from your previous insurer. HDFC ERGO Motor Insurance offers comprehensive coverage with the convenience of online transactions and dedicated customer support.',
},
  ];
    const benefits = [
      {
        title: 'Zero Depreciation Cover',
      },
      {
        title: 'Return to Invoice',
      },
      {
        title: 'No Claim Bonus Protection',
      },
      {
        title: 'Engine and Gear Box Protection',
      },
      {
        title: 'Emergency Assistance Cover',
      },
      {
        title: 'Downtime Protection',
      },
      {
        title: 'Cost of Consumables',
      },
      {
        title: 'Loss of Personal Belonging',
      },
      {
        title: 'Pay as your drive Cover',
      },
      // {
      //   title: 'Tyre Secure Cover',
      // },
    ]
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
      <title>HDFC ERGO Car Insurance - Plans, Features and Benefits | TheFinmaster</title>
      <meta  name='description' content='Choose HDFC ERGO car insurance on Bajaj Markets for complete protection. From cashless claims at 6700+ garages to instant policy issuance, we have  got your car covered.'/>
      <meta name='keywords' content='hdfc ergo car insurance, hdfc car insurance, renew hdfc ergo car insurance, ergo car insurance' />
      <link rel="canonical" href="https://thefinmaster.com/car-insurance/hdfc-car-insurance" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/car-insurance" className="text-blue-600 hover:text-blue-800">Car Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">HDFC Car Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Drive securely with HDFC General and enjoy 
                  </span>{" "}
                  complete peace of mind.
                </h1>
                <a href="https://www.hdfcergo.com/two-wheeler-insurance"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Open Account!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/hdfccar1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about HDFC General Car Insurance </h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">HDFC General Car Insurance offers a range of comprehensive and third-party only plans to suit varying needs and preferences of car owners. With customizable add-on covers like personal accident protection, zero depreciation cover, and consumable cover, they provide flexibility for a tailored policy.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Leveraging a wide network of garages for cashless claims, HDFC General focuses on providing a prompt and efficient claim settlement process, ensuring peace of mind for policyholders.</p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/overnight.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
         Over Night Vehicle Repairs

            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/garage.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            8000+ Cashless Garagesˇ

            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            100% Claim Settlement Ratio^



            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/bonus.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Up to 50% No Claim Bonus. 



            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/add.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Upto 8+ Add-on Covers to Protect your car.

            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/protect.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Personal accident cover upto 15 lacs*

            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>

<div className="w-full flex flex-col items-center  mb-10">
      <div className="w-[90%] text-center mb-10">
        <h2 className="font-bold text-4xl mb-4">HDFC General car insurance plan add-on benefits</h2>
        <p className="text-gray-700 mb-4">
          Choose from an assorted range of add-ons, for an additional premium, when buying HDFC General car insurance online.
        </p>
      </div>
      <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            {/* <img src='../assets/${benefit.icon}' alt={benefit.title} className="mb-4" /> */}
            <h3 className=" text-gray-600 text-2xl mb-2">{benefit.title}</h3>
            {/* <p className="text-gray-700">{benefit.description}</p> */}
          </div>
        ))}
      </div>
    </div>

    {/* <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of AU Bank LIT Credit Card</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">The AU Small Finance Bank LIT Credit Card is India's pioneering customizable credit card, offering the unique advantage of selecting and paying for desired features exclusively. With a wide array of benefits spanning lifestyle, groceries, travel, and beyond, this card provides unparalleled flexibility and convenience.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">It provides a comprehensive range of credit card benefits and allows holders to select one or multiple features/categories simultaneously. This empowers customers to enjoy benefits from their chosen category while only paying for the features they use, rather than the entire annual card fees.</p>
            </div> */}
             <div className="container mx-auto p-4">
      <div className="overflow-x-auto">
      <h2 className="font-bold text-4xl mb-6 text-center ">Comprehensive car insurance vs Third party car insurance</h2> 
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 bg-gray-200 border-b text-left">Comprehensive Car Insurance</th>
              <th className="px-4 py-2 bg-gray-200 border-b text-left">Third-Party Car Insurance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">Covers damages to your own car and third-party liabilities</td>
              <td className="border px-4 py-2">Covers third-party liabilities only</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="border px-4 py-2">Provides complete protection for your car</td>
              <td className="border px-4 py-2">Only covers legal liabilities</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">May include add-ons like zero depreciation, roadside assistance, etc.</td>
              <td className="border px-4 py-2">Only includes mandatory third-party coverage as per law</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="border px-4 py-2">Higher premium as compared to third-party insurance</td>
              <td className="border px-4 py-2">Lower premium as compared to comprehensive insurance</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">Can be purchased online or offline</td>
              <td className="border px-4 py-2">Mandatorily needs to be purchased before registering a car</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default HdfcCarLearnMore
