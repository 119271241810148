import React, { useEffect, useState } from 'react'
import CardComponent from '../cardComponents/CardComponent'
import AccordionComponent from '../accordion/AccordionComponent'
// import { CreditCardData } from './CardData'
import Http from '../../Http'
import { CARD_API } from '../../Helper'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
const HDFC = () => {
    const [CreditCardData,setCreditCardData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [cardsPerPage] = useState(10);

    useEffect(()=>{
        Http.post(CARD_API).then((res)=>{
            setCreditCardData(res)
        }).catch((err)=>{
            console.log(err)
        })
    },[])
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    console.log({indexOfLastCard,indexOfFirstCard})
    const currentCards = CreditCardData.cards?.filter((card)=>card.category==="Credit card").slice(indexOfFirstCard, indexOfLastCard);
    console.log({currentCards})
   
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    
    return (
        <>
        <Helmet>
        <title>Get HDFC Credit Cards in  2024: Apply Online | Check Offers | TheFinmaster</title>
       <meta  name='description' content='Compare and apply HDFC cards online, select the best among lifestyle, travel, shopping credit card and explore the features, benefits, eligibility, charges and offers. | TheFinmaster'/>
       <meta name='keywords' content='hdfc credit card payment, hdfc credit card offers, hdfc bank lifetime free credit card, my hdfc credit card, hdfc millennia credit card, hdfc credit card payment online, hdfc bank credit card internet banking' />
       <link rel="canonical" href="https://thefinmaster.com/hdfc-bank/credit-card" />
       <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
       <meta name="robots" content="max-video-preview:-1" />
     </Helmet>
        <div className='w-full flex flex-col items-center '>
            
            <div className='w-full flex flex-col items-center justify-center bg-black h-[200px] md:h-[300px] shadow-lg mb-5 border-b-8 border-blue-600'>
                <h1 className='text-white md:text-7xl text-centerz-20 font-medium'>
                    Credit Card
                </h1>
                <p className='text-gray-200 text-2xl pt-2'>Experience financial freedom and flexibility with our credit card options.</p>
                {/* <img className='w-[100%] h-[100%] object-cover' src='https://www.kotak.com/content/dam/Kotak/guidance/hero-slider-banner/hacks-for-being-regular-with-personal-loan-repayments-hero-desktop.jpg' alt='personalLoan' /> */}
               
            </div>
            <div className='text-center'>
        <p className='text-gray-600 text-2xl pt-2 md:text-xl md:px-10'>
        While building any interface, you are faced with a dozen decisions
    every minute. At the core of every high quality interface, is a
    set of constraints that help in creating this level of consistency
    and quality.  While building any interface, you are faced with a dozen decisions
    every minute. At the core of every high quality interface, is a
    set of constraints that help in creating this level of consistency
    and quality.  
        </p>
    </div>
            <h1 className='text-center font-bold text-5xl my-10'>Featured Credit Cards</h1>
            <div className='w-[95%] mx-auto flex gap-5 flex-wrap md:flex-nowrap justify-center mb-10'>
                <div className='w-fit flex flex-col gap-8'>
                    {
                        currentCards &&
                        currentCards.map((item, index) => {
                            return <> <CardComponent key={index} Card_features={item?.Card_features} fees={item?.fees} cardName={item?.title} cardImage={item?.image} rating={item?.rating} category={item?.category} status={item?.status} /> </>
                        })
                    }
                </div>

                <div className='w-full relative '>
                    <div className='sticky overflow-hidden top-20 shadow-lg w-full h-[230px] rounded-2xl bg-blue-500 border flex flex-col justify-between'>
                        <div className='w-[70%] mx-auto'>
                            <h2 className='text-center font-medium pt-5 text-lg'><span className='font-semibold text-white'>816 people</span> are on call with our advisors right now</h2>
                        </div>
                        <div className='w-full mx-auto'>
                            <div className='w-[70%] mx-auto'>
                                <img src='https://static.pbcdn.in/health-cdn/images/insurer-logo/quotes-logos/advisors-logo.png' alt='imgg' />
                            </div>
                            <div className='bg-white'>
                                <h3 className='text-center text-blue-500 cursor-pointer font-semibold py-2'>Know your advisor</h3>
                            </div>
                        </div>
                    </div>
              
                </div>
                
            </div>
                   {/* Pagination */}
                   <ul className='flex justify-center mt-4'>
                {currentCards &&
                     CreditCardData.cards?.filter((card)=>card.category==="Credit card").length > 0 &&
                    Math.ceil( CreditCardData.cards?.filter((card)=>card.category==="Credit card").length / cardsPerPage) > 1 &&
                    Array.from({ length: Math.ceil( CreditCardData.cards?.filter((card)=>card.category==="Credit card").length / cardsPerPage) }).map((_, index) => (
                        <li key={index} className='mx-1'>
                            <button
                                className={`px-3 py-1 rounded-lg ${
                                    currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                                }`}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
            </ul>
            
            <div className='w-[95%] py-10'>
                <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
                <AccordionComponent />
            </div>
        </div>
        </>
    )
}

export default HDFC