// src/RetirementCalculator.js
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const RetirementCalculator = () => {
  const [age, setAge] = useState(25);
  const [monthlySpend, setMonthlySpend] = useState(250);
  const [retirementStyle, setRetirementStyle] = useState("LIKE A KING");
  const [savingStyle, setSavingStyle] = useState("SAFE");
  const [amountRequired, setAmountRequired] = useState(0);
  const [monthlySavings, setMonthlySavings] = useState(0);

  const calculateRetirement = () => {
    // Assuming some basic calculations for example purposes
    const yearlySpend = monthlySpend * 12;
    const retirementFactor = retirementStyle === "LIKE A KING" ? 2 : retirementStyle === "I AM HAPPY THE WAY I AM" ? 1.5 : 1;
    const savingFactor = savingStyle === "SAFE" ? 0.05 : 0.1;
    const yearsToRetirement = 60 - age;

    const totalAmountRequired = yearlySpend * retirementFactor * yearsToRetirement / savingFactor;
    const monthlySavingsRequired = totalAmountRequired / (yearsToRetirement * 12);

    setAmountRequired(totalAmountRequired);
    setMonthlySavings(monthlySavingsRequired);
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value === '' ? 0 : parseInt(e.target.value));
  };

  const handleRetirementStyleChange = (e) => {
    setRetirementStyle(e.target.value);
  };

  const handleSavingStyleChange = (e) => {
    setSavingStyle(e.target.value);
  };

  const data = {
    labels: ['Amount Required', 'Monthly Savings'],
    datasets: [
      {
        label: 'Retirement Savings Breakdown',
        data: [amountRequired, monthlySavings * 12 * (60 - age)],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <>
    <Helmet>
         <title>Retirement Planner Calculator India | Retirement Savings Calculator Online | TheFinmaster</title>
        <meta  name='description' content='Retirement Planner - It helps you figure out the amount you need to invest regularly to meet your Financial goal. | TheFinmaster'/>
        <meta name='keywords' content='financial retirement planner, retirement income planner, best online retirement planner, early retirement planner, social security retirement planner, 401k retirement planner, military retirement planner' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/retirement-planning-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Retirement Calculator</span>
        </li> 
      
      </ol>
    </nav>
    <div className='py-4'>
    <div className="container mx-auto p-4 shadow-xl rounded-md ">
      <h1 className="text-2xl font-bold mb-4">Retirement Calculator</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">How old are you?</label>
            <div className="flex items-center">
              <input
                type="number"
                value={age}
                onChange={handleInputChange(setAge)}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">{age} years</span>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">How much do you spend per month?</label>
            <div className="flex items-center">
              <input
                type="number"
                value={monthlySpend}
                onChange={handleInputChange(setMonthlySpend)}
                className="w-1/2 p-2 border rounded mr-2"
              />
              <span className="text-gray-700">₹ {monthlySpend.toLocaleString()}</span>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">What kind of retirement you want?</label>
            <div className="flex items-center">
              <label className="mr-4">
                <input
                  type="radio"
                  value="LIKE A KING"
                  checked={retirementStyle === "LIKE A KING"}
                  onChange={handleRetirementStyleChange}
                  className="mr-2"
                />
                LIKE A KING
              </label>
              <label className="mr-4">
                <input
                  type="radio"
                  value="I AM HAPPY THE WAY I AM"
                  checked={retirementStyle === "I AM HAPPY THE WAY I AM"}
                  onChange={handleRetirementStyleChange}
                  className="mr-2"
                />
                I AM HAPPY THE WAY I AM
              </label>
              <label>
                <input
                  type="radio"
                  value="LIKE A MONK"
                  checked={retirementStyle === "LIKE A MONK"}
                  onChange={handleRetirementStyleChange}
                  className="mr-2"
                />
                LIKE A MONK
              </label>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Where are you saving for your retirement?</label>
            <div className="flex items-center">
              <label className="mr-4">
                <input
                  type="radio"
                  value="SAFE"
                  checked={savingStyle === "SAFE"}
                  onChange={handleSavingStyleChange}
                  className="mr-2"
                />
                SAFE (PF, FD, ETC)
              </label>
              <label>
                <input
                  type="radio"
                  value="AGGRESSIVE"
                  checked={savingStyle === "AGGRESSIVE"}
                  onChange={handleSavingStyleChange}
                  className="mr-2"
                />
                AGGRESSIVE (MUTUAL FUNDS, EQUITY, ETC)
              </label>
            </div>
          </div>
          <button
            onClick={calculateRetirement}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Calculate
          </button>
        </div>
        <div>
          <div className="mb-4">
            <h2 className="text-xl font-bold">Results</h2>
            <p>Amount required for retirement: ₹ {amountRequired.toLocaleString()}</p>
            <p>How much do you need to save per month to retire: ₹ {monthlySavings.toLocaleString()}</p>
          </div>
          <div>
            <h2 className="text-xl font-bold">Breakdown</h2>
            <div className="flex justify-center">
              <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className="flex flex-wrap px-6 py-6" >
        <div className="w-full md:w-2/3 p-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Secure Your Golden Years: Start Planning Now! </h1>

          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Dreaming of a comfortable retirement? It's achievable with smart saving and investing. But how much should you save? That's where a retirement calculator comes in. It helps you navigate the financial aspects of retirement planning.
</p>
<div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Why is Retirement Planning Important?
</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Planning for your golden years is crucial, especially for salaried individuals and the self-employed. TheFinMaster's retirement calculator, a top contender in the market, can guide you on this journey.</p>
          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of TheFinMaster's Retirement Calculator:</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>    Effortless Savings Target: </span>   Discover the monthly amount you need to save to reach your desired retirement corpus.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Investment Roadmap: </span>  Identify suitable investment opportunities to achieve your goals.

            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Compare Retirement Plans:</span>   Explore various retirement options offered by financial institutions.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Strategize for Extra Expenses:</span>   Factor in any post-retirement splurges into your savings plan.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Quick Decisions:</span>   Get instant calculations for your future financial needs, saving valuable time.
            </p>

          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">The Power of Compound Interest:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">TheFinMaster's calculator leverages compound interest to estimate your total retirement corpus based on your future contributions.</p>
          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Example Scenario (for illustration purposes only):</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Monthly Expenses: ₹40,000            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Current Age: 30 years           </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Retirement Age: 60 years           </p>
              <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Life Expectancy: 70 years
          </p>
              <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Inflation Rate: 3% annually       </p>
              <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Existing Retirement Savings (including EPF): ₹2,00,000          </p>
              <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Expected Rate of Return: 7%          </p>
              <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 mt-4 ">The Results:
</h1>
<p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Required Retirement Corpus:</span> ₹58.18 Lakhs

            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>Monthly Savings Target: </span>   ₹3,878.
            </p>

          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">TheFinMaster's Advantage:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Similar to other reliable online retirement calculators, TheFinMaster's tool helps you determine your ideal monthly savings amount. It also assists with long-term investment planning. Here's what sets it apart.
</p>
          </div>


          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Using the Calculator is Easy:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Simply input your details, and the calculator will instantly display your personalized results.
</p>

<p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   User-Friendly and Accurate:</span>  No more complex calculations – just straightforward results.
              

            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6  "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>    One-Click Solution: </span> Get your financial roadmap with a single click.


            </p>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6  "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Standardized Formula:</span> Ensures a realistic corpus target for a comfortable retirement.

            </p>
          </div>



          
          <div className="container mx-auto  py-2">
            <p className="text-lg font-bold  text-gray-800 "> Start planning your dream retirement today!</p>
          </div>



        </div>

        <div className="w-full md:w-1/3 p-4">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
              <h2 className="text-lg font-semibold ">Popular Categories</h2>
            </div>
            <div className="p-2 ">
            <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                {/* {moreCategories.map(category => (
          <Link key={category.id} to={`/category/${category.id}`}>
            <li className="py-2 border-b border-gray-300">{category.name}</li>
          </Link>
        ))} */}
              </ul>
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default RetirementCalculator;
