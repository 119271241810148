import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const GratuityCalculator = () => {
  const [salary, setSalary] = useState(60000);
  const [yearsOfService, setYearsOfService] = useState(20);
  const [gratuity, setGratuity] = useState(0);

  useEffect(() => {
    calculateGratuity(salary, yearsOfService);
  }, [salary, yearsOfService]);

  const calculateGratuity = (salary, yearsOfService) => {
    const gratuity = (salary * 15 * yearsOfService) / 26;
    setGratuity(gratuity.toFixed(2));
  };

  const handleSalaryChange = (e) => {
    const value = e.target.value === '' ? 0 : parseInt(e.target.value);
    setSalary(value);
  };

  const handleYearsOfServiceChange = (e) => {
    const value = e.target.value === '' ? 0 : parseInt(e.target.value);
    setYearsOfService(value);
  };

  return (
    <>
     <Helmet>
         <title>Gratuity Calculator: Gratuity Calculator Online In India | TheFinmaster</title>
        <meta  name='description' content='Gratuity Calculator: Calculate the gratuity amount you will get once you will quit your job using the gratuity amount calculator. Check your estimate gratuity amount at TheFinmaster.'/>
        <meta name='keywords' content='gratuity calculator, retirement gratuity calculation formula, calculation of gratuity for central government employees, calculation of gratuity for private employees, dcrg calculation' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/gratuity-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Gratuity Calculator</span>
        </li> 
      
      </ol>
    </nav>
<div className='py-4 '>


    <div className="container mx-auto p-4 max-w-7xl  ">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-2/3 px-4">
         
          <div className="bg-white  p-6 shadow-md rounded-md">
          <h1 className="text-2xl font-bold mb-4">Gratuity Calculator</h1>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Monthly salary (Basic + DA)</label>
              <div className="flex items-center">
                <input
                  type="number"
                  min="0"
                  value={salary}
                  onChange={handleSalaryChange}
                  className="w-1/2 p-2 border rounded mr-2"
                />
                <span className="text-gray-700">₹ {salary.toLocaleString()}</span>
              </div>
              <input
                type="range"
                min="0"
                max="1000000"
                value={salary}
                onChange={(e) => setSalary(parseInt(e.target.value))}
                className="w-full mt-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Years of service</label>
              <div className="flex items-center">
                <input
                  type="number"
                  min="0"
                  max="50"
                  value={yearsOfService}
                  onChange={handleYearsOfServiceChange}
                  className="w-1/2 p-2 border rounded mr-2"
                />
                <span className="text-gray-700">{yearsOfService} years</span>
              </div>
              <input
                type="range"
                min="0"
                max="50"
                value={yearsOfService}
                onChange={(e) => setYearsOfService(parseInt(e.target.value))}
                className="w-full mt-2"
              />
            </div>
            <div className="mt-4 text-center">
              <h2 className="text-xl font-bold">Total Gratuity payable</h2>
              <p className="text-3xl text-gray-800">₹ {gratuity.toLocaleString()}</p>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3 px-6 mt-8 lg:mt-0 text-center">
          <div className="bg-white p-6 shadow-md rounded-md">
            <h2 className="text-xl font-bold mb-4 text-center">Invest the way you want</h2>
            <img src='https://www.shutterstock.com/image-illustration/credit-cards-online-shopping-banner-260nw-355524626.jpg' alt='no image'/>
            <p>Join millions of Indians who trust and love TheFinMaster.</p>
            <div className='text-center'>
            <button className="bg-green-500 text-white px-4 py-2 rounded mt-4 ">Explore Products</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="flex flex-wrap px-6 py-6" >
     <div className="w-full md:w-2/3 p-4">
       <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Know Your Gratuity Entitlement with TheFinMaster's Free Calculator
</h1>

 {/* <p className="text-lg font-normal mt-2 text-gray-700 ">Empowering Employees:</p> */}

       <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Completed 5 years at your company? You may be eligible for gratuity! TheFinMaster's free gratuity calculator helps you estimate the potential amount your organization owes you.

</p>


       <div className="container mx-auto  py-4">
         <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using TheFinMaster's Calculator:

</h1>

         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>     Effortless Calculations: </span>Get instant gratuity estimates without complex formulas.

         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'> Informed Decisions:</span>   Knowing your potential gratuity amount can aid in your financial planning.
           </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>  Save Time:</span>     Avoid manual calculations and get results in seconds.


         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>      User-Friendly:</span>  Our calculator is easy to use, even for those unfamiliar with gratuity rules.


         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>      Always Available: </span> Access the calculator anytime, anywhere to estimate your gratuity.
         </p>
       </div>
       <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How It Works:
</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Simply enter your last drawn basic salary, Dearness Allowance (DA), and years of service. Our calculator will estimate your gratuity amount based on the Payment of Gratuity Act, 1972.

            </p>
          </div>
      
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Grow Beyond the Basics: </h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">TheFinMaster offers a variety of resources to help you understand gratuity and other employee benefits.


            </p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Ready to Get Started?</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Use TheFinMaster's free gratuity calculator today! Take control of your financial well-being and understand your gratuity rights.


            </p>
          </div>
     

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Additional Notes:</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Removed unnecessary information about gratuity eligibility and legalities (Payment of Gratuity Act).       </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Simplified the explanation of the gratuity formula.

    </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Focused on the user benefits and a clear call to action.
      </p>
             
          </div>

       


     </div>

     <div className="w-full md:w-1/3 p-4">
       <div className="bg-white shadow-md rounded-lg overflow-hidden">
         <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
           <h2 className="text-lg font-semibold ">Popular Categories</h2>
         </div>
         <div className="p-2 ">
         <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
             {/* {moreCategories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
           </ul>
         </div>
       </div>

     </div>

   </div>
   </div>
   </>
  );
};

export default GratuityCalculator;
