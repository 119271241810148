import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const AckoBikeInsurance = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const faqs = [
        {
            question: 'How to download the ACKO bike insurance policy online?',
            answer:
                'To download your ACKO bike insurance policy online, log in to your account or the ACKO app. Navigate to the policy section, click on the policy card, and select the option to download your policy document.',
        },
        {
            question: 'Can I purchase ACKO two-wheeler insurance online?',
            answer:
                'Yes, you can purchase ACKO two-wheeler insurance online by visiting the official ACKO website. Simply fill out the necessary details and choose the insurance plan that suits your requirements.',
        },
        {
            question: 'When I renew my current bike insurance policy, can I choose a different insurance plan?',
            answer:
                'Yes, during the renewal of your bike insurance policy with ACKO, you have the flexibility to choose a different insurance plan that meets your current needs and preferences.',
        },
        {
            question: 'Can I buy bike insurance and choose to have only Own Damage coverage for my bike?',
            answer:
                'No, in India, it is mandatory to have a third-party insurance policy for all vehicles. Hence, both third-party liability and Own Damage coverage must be purchased together.',
        },
        {
          question: 'Is it possible for me to purchase a bike insurance policy with my preferred IDV in mind?',
          answer:
              'Yes, ACKO two-wheeler insurance allows you to customize the Insured Declared Value (IDV) to align with your preferences. It is crucial to set the IDV accurately as it determines the total insured amount. Setting it too low may reduce the claim amount in case of a total loss, while setting it too high may increase the premium.',
      },
      {
        question: 'Does bike insurance cover engine repair?',
        answer:
            'Engine repair expenses are covered by bike insurance if you have opted for the Engine Protect add-on under Comprehensive or Own Damage bike insurance. However, availability of the engine cover add-on may vary.',
    },
    {
      question: 'What does bike insurance endorsement mean?',
      answer:
          'Bike insurance endorsement refers to the modification of the policyholders personal information or the terms and conditions of the policy. This process allows updates such as name, date of birth, and address. Endorsements can be non-premium-bearing (no additional cost) or premium-bearing (require payment of an additional premium).',
  },
    ];

    const benefits = [
      {
        icon: 'roadside-assistance.png',
        title: 'Zero Depreciation Cover',
        description: 'Helps you in case of a breakdown/accident with – towing to network garage, battery jumpstart, minor repairs, on-call support and taxi benefits.'
      },
      {
        icon: 'engine-protect.png',
        title: 'Personal Accident (PA) Cover ',
        description: 'Pays for repair or replacement of a damaged engine. A handy add-on for those who stay in a region prone to flooding during the rains, or if you own a high-end car.'
      },
      {
        icon: 'engine-protect.png',
        title: 'Consumable Expenses',
        description: 'Pays for repair or replacement of a damaged engine. A handy add-on for those who stay in a region prone to flooding during the rains, or if you own a high-end car.'
      },
      // {
      //   icon: 'consumables.png',
      //   title: 'Consumable Expenses',
      //   description: 'Pays for repair or replacement of nut and bolt, screw, washer, grease, lubricant, clips, A/C gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and related parts.'
      // },
      // {
      //   icon: 'zero-depreciation.png',
      //   title: 'Personal Baggage',
      //   description: 'As your car ages, the value of its parts reduces. This add-on ensures you get the full claim amount without any deduction for depreciation.'
      // },
      // {
      //   icon: 'garage-cash.png',
      //   title: 'Engine Protector',
      //   description: 'Gives a daily allowance for alternate travel expenses when your car is in the garage for repairs after an accident.'
      // },
      // {
      //   icon: 'personal-belongings.png',
      //   title: 'Conveyance Benefit',
      //   description: 'Covers the loss or damage of your personal belongings in the car due to theft or an accident.'
      // }
    ];
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            // If the clicked question is already expanded, collapse it
            setActiveIndex(null);
        } else {
            // If the clicked question is not expanded, expand it
            setActiveIndex(index);
        };
      };
    return (
        <>
         <Helmet>
         <title>ACKO Two Wheeler Insurance - Complete Information | TheFinmaster</title>
        <meta  name='description' content='ACKO Two Wheeler Insurance - Know the complete information about acko two-wheeler insurance check benefits, features, and different policies offered by it. | TheFinmaster.'/>
        <meta name='keywords' content='acko two wheeler insurance renewal, acko new bike insurance, two wheeler insurance renewal acko, acko insurance bike' />
        <link rel="canonical" href="https://thefinmaster.com/bike-insurance/acko-bike-insurance" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <FAQSchema faqs={faqs} />
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/bike-insurance" className="text-blue-600 hover:text-blue-800">Bike Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Acko Bike Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center px-6">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Hit the road protected! 
                  </span>{" "}
                  Get Acko's fast bike insurance.
                </h1>
                <a href="https://www.acko.com/two-wheeler-insurance/"
                  target="_blank" 
                  rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                  Get Quote!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/ackobike1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
          <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about Acko General Insurance (2 Wheeler)</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> Acko General Insurance (2 Wheeler): Acko General Insurance distinguishes itself as a digital-first insurer committed to transparency and affordability. With a focus on competitive pricing and a user-friendly online platform, they offer streamlined solutions for purchasing and managing two-wheeler insurance policies.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Providing options for comprehensive and third-party only plans with optional add-ons, Acko ensures flexibility to suit individual needs. Leveraging technology, they prioritize a quick and hassle-free claim experience, reinforcing their position as a leading choice for digitally savvy customers seeking efficient insurance solutions. </p>
            </div>
            <div className="container mx-auto px-4 py-8">
  <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/customer.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Trusted by over 8 crore Indians for insurance

            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/onspot.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Presence in 647 cities & towns across India


            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/rating.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Rated an impressive 4.8/5 on Google reviews


            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Successfully settled over 2 lakh claims



            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/3mint.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Acko offers round-the-clock 24x7x365 support


            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/money.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Pay only for insurance, no fees/commissions.


            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>
<div className="container mx-auto px-4 py-8 mb-4">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Types of Two Wheeler Insurance Policies offered by Acko
</h1>
<p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"><span className='font-bold'> Third-party Bike Insurance Policy :</span> In India, Third Party Bike Insurance (TP insurance) is a legal requirement. It offers coverage for third-party liabilities, meaning it protects you financially if your vehicle causes damage to another person's property or injures them in an accident. However, it does not cover damage to your own two-wheeler.</p>

                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"><span className='font-bold'> Comprehensive Bike Insurance Policy :</span> A Comprehensive Bike Insurance Policy includes all the coverage provided by the Third-party Two Wheeler Insurance Plan and extends coverage to damages or losses sustained by your own two-wheeler. This type of insurance covers various events such as accidental damage, fire damage, natural disasters like floods and earthquakes, man-made calamities such as riots, and theft of the insured bike.</p>
                
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"><span className='font-bold'> Standalone Own Damage Insurance Policy :</span> Own Damage Insurance (OD insurance) for bikes offers coverage for damages to your two-wheeler. It's important to note that this type of insurance does not cover third-party liabilities. It's an ideal choice if you already have a third-party policy for your bike.</p>

            </div>

<div className="w-full flex flex-col items-center  mb-10 mt-10">
      <div className="w-[90%] text-center mb-10">
        <h2 className="font-bold text-4xl mb-4">Acko General Insurance plan add-on benefits</h2>
        <p className="text-gray-700 mb-4">
          Choose from an assorted range of add-ons, for an additional premium, when buying Acko General Insurance  online.
        </p>
      </div>
      <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            {/* <img src='../assets/${benefit.icon}' alt={benefit.title} className="mb-4" /> */}
            <h3 className=" text-gray-600 text-2xl mb-2">{benefit.title}</h3>
            {/* <p className="text-gray-700">{benefit.description}</p> */}
          </div>
        ))}
      </div>
    </div>
{/* <div className='w-full flex flex-col items-center'>
                        <div className='w-[90%] flex flex-wrap md:flex-nowrap justify-between'>
                            <div className='w-full'>
                                <img src='../assets/1paragraph.jpg' alt='img1' />
                            </div>
                            <div className='w-full flex items-center'>
                                <div>
                                    <h2 className='font-bold text-4xl'>Why TheFinMaster?</h2>
                                   
                                    <p className='py-5 text-xl font-medium text-gray-600'>TheFinMaster.com simplifies your finances! Explore & compare credit cards, loans, insurance & demat accounts from various providers. As a one-stop platform, we aim to simplify your financial journey by providing detailed insights into multiple products and offerings.</p>
                                    <p className='mt-0.5 text-xl font-medium text-gray-600'> Make informed financial decisions with our insightful guides and tools.</p>

                                              </div>
                            </div>
                        </div>
                    </div> */}
            {/* <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
      <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
          Key Highlights
        </h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
        
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
        <img
          src="../assets/hospital.gif"
          alt="GIF 1"
          className="w-16 md:w-20 lg:w-16 mb-2"
        />
          <p className="text-2xl md:text-2xl lg:text-2xl text-center text-gray-600">
          Across 7,200+ Network Garages
        </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    Tie up with 18,400+ Hospitals 
    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
              <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    Get Insurance in Under 3 Minutes!

    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    24x7 Roadside Assistance

    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    On the spot Claim Settlements


    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    98% Claim Settlement Ratio

    </p>
  </div>
          </motion.div>
        </motion.div>
      </div>
    </div> 
    </div> */}



            <div className="max-w-4xl mx-auto  lg:py-32 py-10">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default AckoBikeInsurance
