'use client';
import { Button, Modal } from 'flowbite-react';
import React, { useState } from 'react'
// import { Link } from 'react-router-dom';
import CardAccordion from './CardAccordion';

const CardModal = ({ Card_features }) => {
    const [openModal, setOpenModal] = useState('');

    //console.log("Card_featuresCard_featuresCard_featuresCard_features", Card_features)

    return (
        <>
            {/* <Button onClick={() => setOpenModal('default')}>Toggle modal</Button> */}
           
                <span onClick={() => setOpenModal('default')} className='text-sm font-medium cursor-pointer text-blue-500 p-2'>View all features</span>
           

            <Modal id='all-feature' show={openModal === 'default'} onClose={() => setOpenModal('')}>
                <Modal.Header>Features</Modal.Header>
                <Modal.Body>
                    {
                        Card_features?.length > 0 && Card_features.map((card_feature, index) => {
                            return <>
                                <div  key={index} className="space-y-6">
                                    <h2 className='text-2xl font-bold mt-4'>{card_feature.Title}</h2>
                                    {(Card_features?.length > 0 && Card_features !== undefined) && <CardAccordion Card_features={card_feature.subfeatures} />}
                                    {/* <h2 className='text-2xl font-bold'>Waiting periods</h2>
                                    <CardAccordion />
                                    <h2 className='text-2xl font-bold'>Additional Features</h2>
                                    <CardAccordion />
                                    <h2 className='text-2xl font-bold'>Value Added Services</h2>
                                    <CardAccordion /> */}
                                </div></>
                        })
                    }   

                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={() => setOpenModal('')}>I accept</Button>
                    <Button color="gray" onClick={() => setOpenModal('')}>
                        Decline
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default CardModal
