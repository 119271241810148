// src/RDCalculator.js
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const RDCalculator = () => {
  const [monthlyInvestment, setMonthlyInvestment] = useState(5000);
  const [rateOfInterest, setRateOfInterest] = useState(6.5);
  const [timePeriod, setTimePeriod] = useState(5);
  const [investedAmount, setInvestedAmount] = useState(0);
  const [estimatedReturn, setEstimatedReturn] = useState(0);
  const [totalValue, setTotalValue] = useState(0);

  const calculateRD = () => {
    const n = 12; // Monthly compounding
    const interestRate = rateOfInterest / 100;
    const months = timePeriod * 12;
    let investedAmount = monthlyInvestment * months;
    let totalValue = 0;

    for (let i = 1; i <= months; i++) {
      totalValue += monthlyInvestment;
      totalValue += totalValue * (interestRate / n);
    }

    const estimatedReturn = totalValue - investedAmount;

    setInvestedAmount(investedAmount);
    setEstimatedReturn(estimatedReturn.toFixed(2));
    setTotalValue(totalValue.toFixed(2));
  };

  const handleMonthlyInvestmentChange = (e) => {
    const value = e.target.value;
    setMonthlyInvestment(value === '' ? 0 : parseInt(value));
  };

  const handleRateOfInterestChange = (e) => {
    const value = e.target.value;
    setRateOfInterest(value === '' ? 0 : parseFloat(value));
  };

  const handleTimePeriodChange = (e) => {
    const value = e.target.value;
    setTimePeriod(value === '' ? 0 : parseInt(value));
  };

  const data = {
    labels: ['Invested Amount', 'Estimated Return'],
    datasets: [
      {
        label: 'RD Breakdown',
        data: [investedAmount, estimatedReturn],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <>
     <Helmet>
         <title>RD Calculator- Reccuring Deposit Interest Rates Calculator Online | TheFinmaster</title>
        <meta  name='description' content='RD Calculator - Check Top Banks RD Interest Rates in India. And also know How to Calculate RD interest Rates, How to use &amp; Process of RD Calculator.'/>
        <meta name='keywords' content='rd calculator, rd deposit calculator, rd recurring deposit calculator, recurring deposit cal' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/rd-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">RD Calculator</span>
        </li> 
      
      </ol>
    </nav>
      <div className='py-4'>
        <div className="container mx-auto p-4 shadow-xl rounded-md ">
          <h1 className="text-2xl font-bold mb-4">Recurring Deposit Calculator</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Monthly Investment</label>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="0"
                    value={monthlyInvestment}
                    onChange={handleMonthlyInvestmentChange}
                    className="w-1/2 p-2 border rounded mr-2"
                  />
                  <span className="text-gray-700">₹ {monthlyInvestment.toLocaleString()}</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="100000"
                  value={monthlyInvestment}
                  onChange={(e) => setMonthlyInvestment(parseInt(e.target.value))}
                  className="w-full mt-2"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Rate of Interest (p.a.)</label>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.1"
                    value={rateOfInterest}
                    onChange={handleRateOfInterestChange}
                    className="w-1/2 p-2 border rounded mr-2"
                  />
                  <span className="text-gray-700">{rateOfInterest} %</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="100"
                  step="0.1"
                  value={rateOfInterest}
                  onChange={(e) => setRateOfInterest(parseFloat(e.target.value))}
                  className="w-full mt-2"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Time Period (years)</label>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="0"
                    max="50"
                    value={timePeriod}
                    onChange={handleTimePeriodChange}
                    className="w-1/2 p-2 border rounded mr-2"
                  />
                  <span className="text-gray-700">{timePeriod} Yr</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="50"
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(parseInt(e.target.value))}
                  className="w-full mt-2"
                />
              </div>
              <button
                onClick={calculateRD}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Calculate
              </button>
            </div>
            <div>
              <div className="mb-4">
                <h2 className="text-xl font-bold">Results</h2>
                <p>Invested Amount: ₹ {investedAmount.toLocaleString()}</p>
                <p>Estimated Return: ₹ {estimatedReturn.toLocaleString()}</p>
                <p>Total Value: ₹ {totalValue.toLocaleString()}</p>
              </div>
              <div>
                <h2 className="text-xl font-bold">Breakdown</h2>
                <div className="flex justify-center">
                  <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap px-6 py-6" >
        <div className="w-full md:w-2/3 p-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Recurring Deposits (RDs): TheFinMaster Savings Regularly </h1>
          {/* <p className="text-lg font-normal mt-2 text-gray-700 ">What are Recurring Deposits (RDs)?</p> */}

          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Similar to fixed deposits (FDs), RDs are a popular savings option offered by banks and financial institutions. However, unlike FDs where you invest a LumpSum, RDs encourage regular saving habits by requiring fixed monthly deposits.</p>


          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Recurring Deposits:</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>    Regular Savings: </span>   RDs cultivate financial discipline by promoting consistent deposits.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Compound Interest: </span>    Your earnings grow over time with interest compounded quarterly.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Flexibility:</span>  Choose a tenure that aligns with your financial goals, typically ranging from a few months to several years.
            </p>

          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Understanding RD Calculations:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Calculating the maturity amount of an RD can involve multiple variables like interest rate, tenure, and compounding frequency. This can be complex for manual calculations.</p>
          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How an RD Calculator Helps:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">An RD calculator is a free online tool that simplifies these calculations. It provides instant estimates of your maturity amount, considering factors like:</p>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Monthly deposit amount
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Investment tenure           </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Interest rate offered by the Bank/NBFC           </p>

          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using an RD Calculator:</h1>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2"> <span className='font-bold'>  Effortless Calculations:</span> Get accurate results without the hassle of complex formulas. </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  "><span className='font-bold'> Informed Decisions:</span>   Plan your future finances by estimating your potential returns.</p>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  "> <span className='font-bold'> Save Time:</span>    Avoid manual calculations and focus on other financial decisions.</p>


          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">TheFinMaster RD Calculator (Optional):</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">TheFinMaster offers a user-friendly RD calculator that supports both simple and compound interest calculations. Simply enter your investment amount, interest rate, and tenure to receive your estimated maturity amount.</p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Additional Tips:
            </h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Explore different RD options offered by banks and NBFCs to find the best interest rates.            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Consider factors like your investment goals and risk tolerance when choosing an RD tenure.                         </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Remember, RD calculators provide estimates. Always refer to the Bank/NBFC's terms and conditions for final details.
            </p>
          </div>


          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Using an RD Calculator:</h1>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>    Effortless Calculations: </span>    Get accurate results without the hassle of complex formulas.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Informed Decisions: </span>   Plan your future finances by estimating your potential returns.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>    Save Time: </span>   Avoid manual calculations and focus on other financial decisions.
            </p>
          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">RD Maturity Formula (Optional):
            </h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">The standard formula used for RD maturity calculations is:</p>
            <p className="text-lg font-medium mt-2 text-gray-00 ">A = P * (1 + R/N)^(N*t)</p>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Where:</p>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              A - Maturity Amount                </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Consider factors like your investment goals and risk tolerance when choosing an RD tenure.                         </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              N - Compounding Frequency (usually quarterly = 4)
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              R - Interest Rate (as a decimal)            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              t - Tenure (in years)
            </p>
          </div>




          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Using TheFinMaster RD Calculator (Optional):</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">TheFinMaster offers a user-friendly RD calculator that simplifies the process. Simply enter your planned monthly deposit, tenure, and estimated interest rate to get your projected maturity amount.
            </p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Invest Wisely with RDs:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Recurring deposits are a reliable way to build your savings habit and grow your money over time. Use an RD calculator to plan your investments and achieve your financial goals with confidence.

            </p>
          </div>
          <div className="container mx-auto  py-2">
            <p className="text-lg font-normal  text-gray-600 "><span className='text-gray-800 text-xl'>Note:</span> This revised content avoids promotional mentions and focuses on the general benefits of RDs and RD calculators.</p>
          </div>



        </div>

        <div className="w-full md:w-1/3 p-4">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
              <h2 className="text-lg font-semibold ">Popular Categories</h2>
            </div>
            <div className="p-2 ">
            <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                {/* {moreCategories.map(category => (
          <Link key={category.id} to={`/category/${category.id}`}>
            <li className="py-2 border-b border-gray-300">{category.name}</li>
          </Link>
        ))} */}
              </ul>
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default RDCalculator;
