import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const CAGRCalculator = () => {
    const [initialInvestment, setInitialInvestment] = useState(1000);
    const [finalInvestment, setFinalInvestment] = useState(2000);
    const [duration, setDuration] = useState(5);
    const [cagr, setCagr] = useState(0);

    useEffect(() => {
        calculateCAGR(initialInvestment, finalInvestment, duration);
    }, [initialInvestment, finalInvestment, duration]);

    const calculateCAGR = (initial, final, years) => {
        if (initial > 0 && final > 0 && years > 0) {
            const cagrValue = ((final / initial) ** (1 / years) - 1) * 100;
            setCagr(cagrValue.toFixed(2));
        } else {
            setCagr(0);
        }
    };

    const handleReset = () => {
        setInitialInvestment(1000);
        setFinalInvestment(2000);
        setDuration(5);
    };

    return (
        <>
         <Helmet>
         <title>CAGR Calculator: Calculate Compound Annual Growth Rate Online | TheFinmaster</title>
        <meta  name='description' content='Use online CAGR Calculator to calculate the compound annual growth rate for your investment over a certain time period. | TheFinmaster'/>
        <meta name='keywords' content='sip cagr calculator, compound annual growth rate calculator, compound average growth rate calculator, cumulative annual growth rate calculator' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/cagr-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">CAGR Calculator</span>
        </li> 
      
      </ol>
    </nav>
            <div className='py-4'>


                <div className="container mx-auto p-4 max-w-8xl">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full lg:w-2/3 px-4">
                            <h1 className="text-2xl font-bold mb-4">CAGR Calculator</h1>
                            <div className="bg-white shadow-md rounded p-6">
                                <div className="mb-4">
                                    <label className="block text-gray-700 font-semibold">Initial investment</label>
                                    <div className="flex items-center">
                                        <input
                                            type="number"
                                            value={initialInvestment}
                                            onChange={(e) => setInitialInvestment(Number(e.target.value))}
                                            className="w-1/2 p-2 border rounded mr-2"
                                        />
                                        <span className="text-gray-700">₹ {initialInvestment.toLocaleString()}</span>
                                    </div>
                                    <input
                                        type="range"
                                        min="1000"
                                        max="1000000"
                                        value={initialInvestment}
                                        onChange={(e) => setInitialInvestment(Number(e.target.value))}
                                        className="w-full mt-2"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 font-semibold">Final investment</label>
                                    <div className="flex items-center">
                                        <input
                                            type="number"
                                            value={finalInvestment}
                                            onChange={(e) => setFinalInvestment(Number(e.target.value))}
                                            className="w-1/2 p-2 border rounded mr-2"
                                        />
                                        <span className="text-gray-700">₹ {finalInvestment.toLocaleString()}</span>
                                    </div>
                                    <input
                                        type="range"
                                        min="1000"
                                        max="10000000"
                                        value={finalInvestment}
                                        onChange={(e) => setFinalInvestment(Number(e.target.value))}
                                        className="w-full mt-2"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 font-semibold">Duration of investment (years)</label>
                                    <div className="flex items-center">
                                        <input
                                            type="number"
                                            min="1"
                                            max="30"
                                            value={duration}
                                            onChange={(e) => setDuration(Number(e.target.value))}
                                            className="w-1/2 p-2 border rounded mr-2"
                                        />
                                        <span className="text-gray-700">{duration} Yr</span>
                                    </div>
                                    <input
                                        type="range"
                                        min="1"
                                        max="30"
                                        value={duration}
                                        onChange={(e) => setDuration(Number(e.target.value))}
                                        className="w-full mt-2"
                                    />
                                </div>
                                <div className="mt-4 bg-green-100 p-4 rounded">
                                    <span className="font-semibold text-gray-700">CAGR is:</span>
                                    <span className="text-xl font-bold text-gray-900"> {cagr}%</span>
                                </div>
                                <div className="mt-4">
                                    <button
                                        className="bg-green-500 text-white px-4 py-2 rounded"
                                        onClick={handleReset}
                                    >
                                        RESET
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 px-4 mt-8 lg:mt-0 text-center">
                            <div className="bg-white shadow-md rounded p-6">
                                <h2 className="text-xl font-bold mb-4">Invest the way you want</h2>
                                <div className="mb-4">
                                    <img src="https://www.shutterstock.com/image-illustration/credit-cards-online-shopping-banner-260nw-355524626.jpg" alt="Banner" className="w-full rounded" />
                                </div>
                                <p>Join millions of Indians who trust and love TheFinMaster.</p>
                                <button className="bg-green-500 text-white px-4 py-2 rounded mt-4">Explore Products</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="flex flex-wrap px-6 py-6" >
                <div className="w-full md:w-2/3 p-4">
                    <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">What is CAGR?
                    </h1>

                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">CAGR stands for Compound Annual Growth Rate. It's a key metric used to analyze a business's performance over time in a competitive market. CAGR essentially reflects an organization's growth trajectory, allowing you to identify growth trends (or lack thereof) using a CAGR calculator.

                    </p>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using a CAGR Calculator
                        </h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">While CAGR calculators are readily available online, user-friendliness is crucial for accurate calculations. TheFinMaster's online CAGR calculator offers a streamlined and reliable way to assess your business's CAGR.
                        </p>

                    </div>

                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Here's how a CAGR calculator can benefit you:


                        </h1>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'> Reveals Hidden Insights: </span>  CAGR can unveil factors that might otherwise be missed. Unlike absolute returns, CAGR growth considers the time element, providing a more accurate picture of growth over a specific period.


                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>     Standardizes Comparisons: </span> It's an indicative figure, not a guaranteed outcome. CAGR essentially reflects a hypothetical scenario where an investment grows at a steady rate with reinvested returns. This standardized approach allows for comparing growth across different investment options.
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>     Calculates True Annual Returns: </span>  A CAGR calculator helps determine your business's actual yearly return on investment. TheFinMaster's free online calculator saves you time and resources by performing these calculations efficiently.



                        </p>
                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Key Insights Revealed by CAGR:
                        </h1>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Business Growth Trajectory: </span> Is your business growing steadily? Are your profits being reinvested effectively? CAGR can provide valuable insights into these aspects.


                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>      Investment Evaluation:</span> CAGR helps you compare investment options. If Stock A's CAGR is lower than Stock B's, the calculator can guide you towards potentially better investment decisions.

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Benchmarking Against Competitors:</span>  Benchmarking Against Competitors: Analyze your organization's growth relative to industry leaders. This can help you identify areas for improvement and strategic adjustments.


                        </p>
                        <p className="text-lg text-gray-700 font-normal sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-3">   <span className='font-bold'>   Important Note : </span> CAGR calculators don't factor in investment risk.</p>

                    </div>
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full border-collapse border border-gray-300">
                            {/* <thead className="bg-gray-800 text-white">
                <tr>
                <th className="px-4 py-2 text-left">Month</th>
                <th className="px-4 py-2 text-left">Balance at Begin</th>
               
                </tr>
            </thead> */}
                            <tbody className="divide-y divide-gray-300 text-gray-600">

                                <tr>

                                    <td className="px-4 py-2 border-r-[1px] border-gray-300">FV</td>
                                    <td className="px-4 py-2"> Fuure Value</td>

                                </tr>
                                <tr >
                                    <td className="px-4 py-2 border-r-[1px] border-gray-300">PV</td>
                                    <td className="px-4 py-2">Present Value</td>

                                </tr>
                                <tr >
                                    <td className="px-4 py-2 border-r-[1px] border-gray-300">N</td>
                                    <td className="px-4 py-2">Time period in years</td>

                                </tr>





                            </tbody>
                        </table>

                        <p className="text-lg font-normal mt-4 text-gray-700 ">Understanding how to calculate CAGR (Compound Annual Growth Rate) is essential for analyzing your investment's performance over time. Let's walk through a practical example and explore the benefits of using TheFinMaster's online CAGR calculator.

                        </p>
                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Scenario:</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">Imagine you invest an initial amount of Rs. 1 Lakh (PV) in a business. After 5 years (n), the total investment value (FV) has grown to Rs. 10 Lakh.
                        </p>
                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Calculating CAGR:</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">The CAGR formula helps us determine the investment's annual growth rate:
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">CAGR = (FV / PV) ^ (1 / n) - 1
                        </p>
                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Plugging in the values:</h1>

                        <p className="text-lg font-normal mt-2 text-gray-700 ">CAGR = (10,00,000 / 1,00,000) ^ (1 / 5) - 1
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">CAGR = 10 ^ (1 / 5) - 1
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">CAGR = 0.589 (approximately)
                        </p>
                    </div>

                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Interpreting the Result:</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">To express CAGR as a percentage, multiply the result by 100:
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">CAGR percentage = 0.589 x 100
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">CAGR percentage = 58.9%
                        </p>

                        <p className="text-lg text-gray-700 font-bold sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-3">Therefore, your investment grew at a compounded annual growth rate of approximately 58.9% over 5 years.
                        </p>
                    </div>

                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Using the TheFinMaster GST Calculator Online</h1>
                        <p className="text-lg font-bold mt-2 text-gray-800 ">TheFinMaster offers a user-friendly online GST calculator :
                        </p>
                        <p className="text-lg font-bold mt-2 text-gray-800 ">Here's how to use it:
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">1. Visit the TheFinMaster website and access the CAGR calculator.</p>

                        <p className="text-lg font-normal mt-2 text-gray-700 ">2. Enter the initial investment amount (PV).</p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">3. The calculator will instantly display the CAGR percentage.
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">4. Enter the expected future value of the investment (FV) after a specific period.</p>

                        <p className="text-lg font-normal mt-2 text-gray-700 ">5. Specify the investment period in years or months.</p>

                    </div>

                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using TheFinMaster's CAGR Calculator:


                        </h1>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  Detailed ROI Analysis: </span>  Gain a comprehensive understanding of your return on investment.




                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  Mutual Fund CAGR Calculation:</span>  If you're considering mutual funds, TheFinMaster's calculator helps determine the investment amount needed to achieve your desired returns over time.
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Investment Comparison:</span> Benchmark your business's returns against industry standards to assess performance.
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Long-Term Planning:</span>  Make informed decisions about future investments for long-term capital growth.
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Globally Recognized Platform:</span> Leverage TheFinMaster's secure and reliable platform for accurate CAGR calculations.
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Efficiency and Accuracy:</span>  Save time and ensure precise results with TheFinMaster's user-friendly calculator.</p>

                        <p className="text-lg text-gray-700 font-bold sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-3">TheFinMaster's CAGR calculator empowers you to make informed investment decisions by providing valuable insights into your investment's growth potential. Explore this free tool and gain a clearer picture of your financial future!
                        </p>
                    </div>





                </div>

                <div className="w-full md:w-1/3 p-4">
                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
                            <h2 className="text-lg font-semibold ">Popular Categories</h2>
                        </div>
                        <div className="p-2 ">
                        <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                                {/* {moreCategories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
};

export default CAGRCalculator;
