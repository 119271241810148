import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const IciciLifeLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: 'How can I pay my premiums?',
        answer:
            'ICICI Prudential Life Insurance offers multiple premium payment options including online payments, cash, cheque, credit card, and debit card.',
    },
    {
        question: 'Can I change the premium payment frequency in my policy?',
        answer:
            'Yes, you can adjust the premium payment frequency of your active insurance policy provided all premiums have been paid.',
    },
    {
        question: 'How can I cancel my application?',
        answer:
            'To cancel your application before policy issuance, send an email to Buyonline@iciciprulife.com. If the policy remains in the proposal stage for 45 days after payment, it will be automatically cancelled, and you will receive confirmation via email.',
    },
    {
        question: ' How can I find out if my premium was applied to my policy?',
        answer:
            'You can access your premium payment details by logging into your account on the ICICI Prudential Life Insurance website.',
    },
    {
      question: 'What is a tax/premium paid certificate?',
      answer:
          'Tax/Premium Paid Certificate documents the total premiums paid across all your insurance policies during a fiscal year. This certificate serves as proof of investment for tax purposes.',
  },
  {
    question: ' What is the time limit for reporting a claim?',
    answer:
        'Claims should be reported as soon as possible for prompt processing. Disability claims must be filed within 120 days from the date of disability, while claims for critical illness or major surgery should be reported within 60 days of diagnosis or operation.',
},
{
  question: '  Can my nominee modify the payout option on my term insurance policy?',
  answer:
      'Yes, the nominee can modify the payout option for income and increasing income options specified at the commencement of the policy (for applicable products).',
},
{
  question: ' Is there any waiting period in a term plan?',
  answer:
      'Term life insurance provides coverage from the date of issuance, except for suicide-related deaths, which are covered after a specified waiting period.',
},
{
  question: 'Where can I invest to save tax?',
  answer:
      'Investing in a Unit Linked Insurance Plan (ULIP) can help reduce your tax liability by allowing deduction of the ULIP premium amount from your taxable income.',
},
{
  question: 'How will I receive my claim amount?',
  answer:
      'The claim amount can be disbursed via cheque or Electronic Clearance System (ECS), depending on your preference.',
},
  ];
    const benefits = [
      {
        title: 'Fixed sum assured for loved ones in case of an unfortunate incident. ',
      },
      {
        title: ' Covers medical expenses or provides predetermined payout for injuries/illnesses.',
      },
      {
        title: 'Combines investment and insurance in one policy, with premiums divided for investment and securing loved ones.',
      },
      {
        title: 'Build savings securely while safeguarding your family financial future. ',
      },
      {
        title: 'Provides regular retirement income by investing a lump sum, with payouts from returns generated by the life insurance company.',
      },
      
      // {
      //   title: 'Tyre Secure Cover',
      // },
    ]
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
      <title>ICICI Prudential Life Insurance: Plan Details, Premium &amp; Benefits | TheFinmaster</title>
      <meta  name='description' content='ICICI Prudential Life Insurance - Check, compare & buy ICICI Prudential Life Insurance online in India. Get latest details on ICICI Life Insurance & save tax under 80C. | TheFinmaster.'/>
      <meta name='keywords' content='icici prudential term plan, icici prudential health insurance, icici term life insurance' />
      <link rel="canonical" href="https://thefinmaster.com/life-insurance/icici-life-insurance" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/life-insurance" className="text-blue-600 hover:text-blue-800">Life Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">ICICI Car Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Plan for your future. Secure your loved 
                  </span>{" "}
                  ones with ICICI Prudential. 
                </h1>
                <a href="https://www.iciciprulife.com/"
                  target="_blank" 
                  rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                  Get Quote!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full "
                src='../assets/icicilife1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about ICICI Prudential Life Insurance </h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> ICICI Prudential Life Insurance is a stalwart in the industry, renowned for its versatile product range catering to diverse financial needs. Their term plans offer essential life coverage, while investment-linked plans seamlessly merge insurance with wealth accumulation, providing comprehensive financial security.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Whole life plans from ICICI Prudential guarantee benefits, ensuring long-term peace of mind. With an extensive branch network and robust digital platform, they ensure hassle-free policy management for their customers, fostering trust and confidence. </p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/date.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            1 Day Claim Settlement


            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/support.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Doorstep claim assistance


            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/bonus.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Full death benefit under Terminal illness coverage.

            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/money.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Tax Benefits on premiums.




            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/crore.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            ₹2,900Cr+ volume of claims closed

            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/premium.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Get premiums back with Smart Exit Benefit



            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>

{/* <div className="w-full flex flex-col items-center  mb-10">
      <div className="w-[90%] text-center mb-10">
        <h2 className="font-bold text-4xl mb-4">ICICI Prudential Life Insurance  plan add-on benefits</h2>
        <p className="text-gray-700 mb-4">
          Choose from an assorted range of add-ons, for an additional premium, when buying ICICI Prudential Life Insurance  online.
        </p>
      </div>
      <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex flex-col items-center text-center"> */}
            {/* <img src='../assets/${benefit.icon}' alt={benefit.title} className="mb-4" /> */}
            {/* <h3 className=" text-gray-600 text-2xl mb-2">{benefit.title}</h3> */}
            {/* <p className="text-gray-700">{benefit.description}</p> */}
          {/* </div>
        ))}
      </div>
    </div>  */}

    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Different Types of Life Insurance Offered by ICICI:</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Term Life Insurance Plan: </span>Term insurance provides a fixed sum assured to beneficiaries in exchange for regular premiums, offering financial protection in case of the insured's demise during the policy term. It typically offers a higher coverage amount at lower premiums, making it an affordable option for securing your family's future. Additionally, some term plans may come with optional riders such as accidental death benefit or critical illness cover, providing enhanced coverage based on individual needs.
</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Health Insurance Plan:</span> Health insurance reimburses medical expenses or provides a predetermined sum for treatments, surgeries, and hospitalizations due to injuries or illnesses. Beyond covering hospital bills, health insurance often includes benefits like pre and post-hospitalization expenses, ambulance charges, and even coverage for alternative treatments like Ayurveda or homeopathy. Many policies also offer additional perks such as wellness programs, health check-ups, and teleconsultations to promote overall well-being.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Unit Linked Insurance Plan (ULIP):</span> ULIP combines investment and insurance, with a portion of the premium invested in equity/debt/balanced funds and the rest securing loved ones with a sum assured. ULIPs offer flexibility in investment choices, allowing policyholders to switch between funds based on market conditions or investment goals. Moreover, they provide tax benefits under Section 80C of the Income Tax Act, making them a popular choice for long-term wealth creation while ensuring financial protection for your family.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Endowment Plan:</span> An endowment policy builds risk-free savings and safeguards your family's financial interests in your absence. Unlike term insurance, endowment plans offer both death and maturity benefits, providing a lump sum amount to the policyholder either on maturity or to the beneficiaries in case of death. These plans often come with guaranteed returns and bonuses, offering a disciplined savings avenue with the added advantage of life coverage.
</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Annuity Plan:</span> An annuity offers regular payments in exchange for a lump sum investment, with the life insurance company investing the money and returning the generated returns as payouts. Annuities provide a steady income stream during retirement, ensuring financial security and stability post-retirement. They come in various forms such as immediate annuities, where payouts start immediately after the premium payment, or deferred annuities, where payouts begin after a specified period. Additionally, annuities can offer options for lifelong income, ensuring a regular paycheck for life, or for a specific duration chosen by the policyholder.</p>

           
            </div>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default IciciLifeLearn
