import React from 'react';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>  
            <title>Thefinmaster Term And Conditions </title>
            <link rel="canonical" href="https://thefinmaster.com/term-and-conditions" />
     </Helmet>
    <div className="container mx-auto px-4 lg:px-8 py-8 mt-8">
      <h1 className="text-3xl lg:text-4xl font-bold mb-4">Terms and Conditions</h1>

      <p className="mb-4 lg:mb-8">
      These Terms and Conditions ("Terms") govern your use of this website (the "Website"), which is operated by [TheFinMaster] ("we," "us," or "our"). By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access or use the Website.


      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">1.Use of the Website</h2>
      <p className="mb-4 lg:mb-3">
        1.1. Permitted Uses: You may use the Website for your personal, non-commercial use only. This includes activities such as browsing the Website, accessing information about financial products, and using our comparison tools.
Prohibited Uses: You may not engage in any of the following activities while using the Website.
      </p>
      <p className="mb-4 lg:mb-3">
        1.2. Modifying, copying, distributing, transmitting, displaying, performing, reproducing, publishing, licensing, creating derivative works from, transferring, or selling any information, content, software, products, or services obtained from the Website.
      </p>
      <p className="mb-4 lg:mb-3">
        1.3. Using the Website for any illegal or unauthorized purpose, including but not limited to violating any intellectual property laws or transmitting any harmful or offensive content.
      </p>
      <p className="mb-4 lg:mb-3">
     1.4. Interfering with or disrupting the servers or networks connected to the Website.
Attempting to gain unauthorized access to the Website or its systems.
Using the Website to collect or harvest personal data of other users.
Engaging in any activity that could damage the reputation or goodwill of the Website or [TheFinMaster].</p>
<p className="mb-4 lg:mb-3"> 1.5. Account Security: You are responsible for maintaining the confidentiality of your account and password (if applicable). You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">2. Content</h2>
      <p className="mb-4 lg:mb-3">
        2.1. Informational Purposes Only: The Website contains information, including but not limited to, financial product comparisons, articles, data, and educational resources (the "Content"). The Content is for informational purposes only and should not be considered financial advice. You should always consult with a qualified financial professional before making any financial decisions.
      </p>
      <p className="mb-4 lg:mb-3">
      2.2. Accuracy and Completeness: We strive to provide accurate, complete, and up-to-date information on the Website. However, we make no warranties or guarantees about the accuracy, completeness, or timeliness of the Content. We are not responsible for any errors or omissions in the Content.
      </p>
      <p className="mb-4 lg:mb-3">
      2.3. Third-Party Content: The Website may contain content that is owned or licensed by third parties. We are not responsible for the accuracy, completeness, or legality of any third-party content.
      </p>
      <p className="mb-4 lg:mb-3">
      2.4. User-Generated Content: The Website may allow users to submit content, such as reviews or comments (the "User-Generated Content"). We do not endorse or approve any User-Generated Content, and we are not responsible for the content of any User-Generated Content. We reserve the right to remove any User-Generated Content that we deem to be inappropriate or offensive.</p>
      

      <h2 className="text-xl lg:text-2xl font-bold mb-2">3. Third-Party Links and Content</h2>
      <p className="mb-4 lg:mb-3">
      3.1 .Links to Third-Party Websites: The Website may contain links to third-party websites or services that are not under our control. We are not responsible for the content or privacy practices of any third-party websites or services.
Use of Third-Party Websites: You access and use third-party websites or services at your own risk. We encourage you to review the terms of service and privacy policies of any third-party website or service that you visit.
      </p>
      

      <h2 className="text-xl lg:text-2xl font-bold mb-2">4. Advertising</h2>
      <p className="mb-4 lg:mb-3">
      4.1.Third-Party Advertisers: The Website may display advertisements from third-party advertisers. These advertisements may be targeted to you based on your interests and browsing history.
      </p>
      <p className="mb-4 lg:mb-3">
      4.2.No Endorsement: We do not endorse or approve any products or services advertised on the Website.
      </p>
      <p className="mb-4 lg:mb-3">
      4.3. Advertiser Dealings: Your dealings with advertisers found on the Website are solely between you and the advertiser. We are not responsible for any claims or damages arising from your dealings with advertisers.
      </p>
      <p className="mb-4 lg:mb-3">
      4.4.  TheFinMaster may display advertisements from third-party advertisers on our website.
      </p>
      <p className="mb-4 lg:mb-3">
      4.5.   Third-party advertisers may use cookies, web beacons, and similar technologies to collect information about your interactions with their advertisements and our website.
      </p>
      <p className="mb-4 lg:mb-3">
      4.6.  The information collected by third-party advertisers may include your IP address, browser type, device identifiers, and other data to provide targeted advertising based on your interests and browsing behavior.
      </p>
      <p className="mb-4 lg:mb-3">
      4.7.  TheFinMaster does not control the data collection practices of third-party advertisers. Please review the privacy policies of these advertisers for more information about how they collect and use your data.

      </p>
      <p className="mb-4 lg:mb-3">
      4.8. You can opt out of targeted advertising from third-party advertisers by adjusting your browser settings or using opt-out tools provided by advertising networks.
      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">5. Intellectual Property</h2>
      <p className="mb-4 lg:mb-2">
      5.1.  Our Ownership: The Website and all of its content, including but not limited to trademarks, copyrights, and logos, are the property of [TheFinMaster] or its licensors.
      </p>
      <p className="mb-4 lg:mb-3">
    5.2. Restrictions on Use: You may not use any of our intellectual property without our written permission. This includes using our trademarks, copyrights, logos, or other content for any commercial purpose.
    <p className="mb-4 lg:mb-2">
    No Guarantees: We do not guarantee that the Website will be available at all times, or that it will be free of errors or interruptions.
Use at Your Own Risk: You use</p>
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">6. Disclaimer of Warranties</h2>
      <p className="mb-4 lg:mb-6">
      6.1.  Website Provided "As Is": THE WEBSITE IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND SECURITY.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">7. Changes to this Terms and Conditions</h2>
      <p className="mb-4 lg:mb-6">
      7.1. We reserve the right to update or change our Terms and Conditions at any time. We will post the updated Terms and Conditions on our website with a revised effective date. By continuing to use our website after any revisions become effective, you agree to be bound by the revised Terms and Conditions.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">8. Contact Us</h2>
      <p className="mb-4 lg:mb-6">
      8.1.  If you have any questions about our Terms and Conditions or the handling of your personal information, please contact us at [Your Contact Email].
      </p>




      {/* <p className="mb-4 lg:mb-8">
      Welcome to TheFinMaster! By accessing or using our platform (referred to as the "Platform"), you agree to be bound by the following Terms and Conditions ("Terms"). Please read these Terms carefully before using the Platform. If you do not agree with any part of these Terms, please do not use the Platform.
      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">1. General</h2>
      <p className="mb-4 lg:mb-3">
        1.1. TheFinMaster, the operator of TheFinMaster, provides access to information and comparisons of various financial products offered by third-party providers.
      </p>
      <p className="mb-4 lg:mb-3">
        1.2.  TheFinMaster is a comparison service and does not directly provide financial services. We do not endorse or recommend any specific financial product or provider.
      </p>
      <p className="mb-4 lg:mb-3">
      1.3. Users of the Platform ("Users" or "You") understand and agree that any financial products obtained through the Platform are subject to the terms and conditions of the respective providers.
      </p>



      <h2 className="text-xl lg:text-2xl font-bold mb-2">2. Services</h2>
      <p className="mb-4 lg:mb-3">
        2.1.1  Providing information and comparisons of credit cards, loans, insurance, and other financial products from multiple providers.
      </p>
      <p className="mb-4 lg:mb-3">
        2.1.2   Assisting Users in understanding the features, benefits, and terms of various financial products.
        </p>
      <p className="mb-4 lg:mb-3">
      2.1.3 Helping Users find suitable financial products based on their preferences and needs.
      </p>
      <p className="mb-4 lg:mb-3">
      2.1.4 While we strive to provide accurate and up-to-date information, Users acknowledge that the availability and terms of financial products may change without notice.
      </p>
      
      <h2 className="text-xl lg:text-2xl font-bold mb-2">3. Eligibility</h2>
      <p className="mb-4 lg:mb-3">
      3.1.  Users must be at least 18 years old and legally allowed to enter into contracts in their respective jurisdictions to use the Platform.
      </p>
      <p className="mb-4 lg:mb-6">
      3.2. By using our Platform, Users agree to abide by these Terms and Conditions. TheFinMaster reserves the right to suspend or terminate the account of any User who violates these Terms.
      </p>

      <h2 className="text-xl lg:text-2xl font-bold mb-2">4. Privacy</h2>
      <p className="mb-4 lg:mb-6">
      4.1. TheFinMaster collects and processes personal information in accordance with our Privacy Policy. By using the Platform, you consent to the collection, storage, and use of your information as described in the Privacy Policy.
</p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">5. License and Site Access</h2>
      <p className="mb-4 lg:mb-6">
      5.1.  TheFinMaster grants Users a limited license to access and use the Platform for personal, non-commercial purposes. This license does not permit any resale or commercial use of the Platform or its contents.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">6. Disclaimer of Warranties</h2>
      <p className="mb-4 lg:mb-6">
      6.1. TheFinMaster makes no representations or warranties about the accuracy, reliability, or completeness of the information provided on the Platform. Users use the Platform at their own risk.
      </p>
      <p className="mb-4 lg:mb-6">
      6.2. TheFinMaster disclaims all warranties, express or implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">7. Limitation of Liability
</h2>
      <p className="mb-4 lg:mb-6">
      7.1. TheFinMaster, its affiliates, and partners shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of the Platform.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">8. Indemnity</h2>
      <p className="mb-4 lg:mb-6">
      8.1. Users agree to indemnify and hold TheFinMaster, its affiliates, and partners harmless from any claims, losses, damages, liabilities, costs, and expenses arising out of or in connection with their use of the Platform.
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">9. Applicable Law</h2>
      <p className="mb-4 lg:mb-6">
      9.1.  These Terms and Conditions are governed by the laws of [Your Country]. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of [Your Jurisdiction].
      </p>
      <h2 className="text-xl lg:text-2xl font-bold mb-2">10. Advertising</h2>
      <p className="mb-4 lg:mb-3">
      10.1.  TheFinMaster may display advertisements from third-party advertisers on the Platform.
      </p>
      <p className="mb-4 lg:mb-3">
      10.2.  The presence of advertisements on the Platform does not imply endorsement or recommendation by TheFinMaster. Users interact with third-party advertisers at their own discretion and risk.
      </p>
      <p className="mb-4 lg:mb-3">
      10.3.  TheFinMaster is not responsible for the content of advertisements displayed on the Platform. Any transactions or interactions with advertisers are solely between the User and the advertiser.
      </p>
      <p className="mb-4 lg:mb-3">
      10.4.  TheFinMaster may receive compensation from advertisers for displaying their advertisements on the Platform. This compensation may influence the placement, content, and timing of advertisements.
      </p>
      <p className="mb-4 lg:mb-3">
      10.5.  TheFinMaster strives to ensure that advertisements comply with applicable laws and regulations. However, we cannot guarantee the accuracy, legality, or quality of advertisements displayed on the Platform.
      </p> */}
      
      
    

      {/* <h2 className="text-xl lg:text-2xl font-bold mb-2">Contact Us</h2>
      <p className="mb-4 lg:mb-6">
        If you have any questions or concerns about these Terms and Conditions, please contact us at : <a href="mailto:Team@zonemedia.co" className="text-blue-800 underline">Team@zonemedia.co</a>.
      </p> */}
      
    </div>
    </>
  );
};

export default TermsAndConditions;
