'use client';

import { Footer } from 'flowbite-react';
import { useEffect } from 'react';

import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from 'react-icons/bs';


// Define the FooterComponent
const FooterComponent = () => {
    
 
    return (
        <Footer bgDark>
            <div className="w-full">
                <div className="grid w-[95%] grid-cols-2 gap-8 mx-auto py-8 md:grid-cols-4">
                    <div>
                        <Footer.Title title="Company" />
                        <Footer.LinkGroup col>
                            <Footer.Link href="/about">
                                About
                            </Footer.Link>
                            <Footer.Link href="/blog_page">
                                Blog
                            </Footer.Link>
                            <Footer.Link href="/sitemap">
                                SiteMap
                            </Footer.Link>
                            
                            <Footer.Link href="/contact-us" >
                                Contact Us
                            </Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <div>
                        <Footer.Title title="help center" />
                        <Footer.LinkGroup col>
                            <Footer.Link href="#">
                                Linkedin
                            </Footer.Link>
                            <Footer.Link href="#">
                                Twitter
                            </Footer.Link>
                            <Footer.Link href="#">
                                Facebook
                            </Footer.Link>
                            <Footer.Link href="/contact-us">
                                Contact Us
                            </Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <div>
                        <Footer.Title title="legal" />
                        <Footer.LinkGroup col>
                            <Footer.Link href="/privacy-policy">
                                Privacy Policy
                            </Footer.Link>
                            <Footer.Link href="#">
                                Licensing
                            </Footer.Link>
                            <Footer.Link href="/term-and-conditions">
                                Terms & Conditions
                            </Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                    <div>
                        <Footer.Title title="download" />
                        <Footer.LinkGroup col>
                            <Footer.Link href="#">
                                iOS
                            </Footer.Link>
                            <Footer.Link href="#">
                                Android
                            </Footer.Link>
                            <Footer.Link href="#">
                                Windows
                            </Footer.Link>
                            <Footer.Link href="#">
                                MacOS
                            </Footer.Link>
                        </Footer.LinkGroup>
                    </div>
                </div>

                <div className="w-full bg-gray-700 px-4 py-6 sm:flex sm:items-center sm:justify-between">
                    <Footer.Copyright
                        by="TheFinmaster. All Rights Reserved"
                        href="/"
                        year={2024}
                    />
                    <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                        <Footer.Icon
                            href="#"
                            icon={BsFacebook}
                        />
                        <Footer.Icon
                            href="#"
                            icon={BsInstagram}
                        />
                        <Footer.Icon
                            href="#"
                            icon={BsTwitter}
                        />
                        <Footer.Icon
                            href="#"
                            icon={BsGithub}
                        />
                        <Footer.Icon
                            href="#"
                            icon={BsDribbble}
                        />

                    </div>
                </div>
            </div>
         
        </Footer>
    )
}

export default FooterComponent





// 'use client';

// import { Footer } from 'flowbite-react';
// import { useEffect } from 'react';
// // import TagManager from 'react-gtm-module';
// import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from 'react-icons/bs';

// const FooterComponent = () => {
//     // useEffect(() => {
//     //     // Initialize dataLayer
//     //     window.dataLayer = window.dataLayer || [];
//     //     function gtag(){dataLayer.push(arguments);}
//     //     gtag('js', new Date());
//     //     gtag('config', 'YOUR_GA_TRACKING_ID'); // Replace with your actual Google Analytics Tracking ID
        
//     //     // Example: Sending an event when the footer component mounts
//     //     window.dataLayer.push({
//     //         'event': 'footerLoaded'
//     //     });
//     // }, []);
//     // const handleButtonClick = () => {
//     //     // Example: Push a custom event when a button in the footer is clicked
//     //     TagManager.dataLayer({
//     //       dataLayer: {
//     //         event: 'contactButtonClick'
          
//     //       }
//     //     });
//     //   };
    
//     return (
//         <Footer bgDark>
//             <div className="w-full">
//                 <div className="grid w-[95%] grid-cols-2 gap-8 mx-auto py-8 md:grid-cols-4">
//                     <div>
//                         <Footer.Title title="Company" />
//                         <Footer.LinkGroup col>
//                             <Footer.Link href="/about">
//                                 About
//                             </Footer.Link>
//                             <Footer.Link href="/blog">
//                                 Blog
//                             </Footer.Link>
//                             <Footer.Link href="/about">
//                                 Careers
//                             </Footer.Link>
//                             {/* <Footer.Link href="/contact" onClick={handleButtonClick}>
//                                 Contact Us
//                             </Footer.Link> */}
//                             <Footer.Link href="/contact" >
//                                 Contact Us
//                             </Footer.Link>
//                         </Footer.LinkGroup>
//                     </div>
//                     <div>
//                         <Footer.Title title="help center" />
//                         <Footer.LinkGroup col>
//                             <Footer.Link href="#">
//                                 Linkedin
//                             </Footer.Link>
//                             <Footer.Link href="#">
//                                 Twitter
//                             </Footer.Link>
//                             <Footer.Link href="#">
//                                 Facebook
//                             </Footer.Link>
//                             <Footer.Link href="#">
//                                 Contact Us
//                             </Footer.Link>
//                         </Footer.LinkGroup>
//                     </div>
//                     <div>
//                         <Footer.Title title="legal" />
//                         <Footer.LinkGroup col>
//                             <Footer.Link href="/privacy_policy">
//                                 Privacy Policy
//                             </Footer.Link>
//                             <Footer.Link href="#">
//                                 Licensing
//                             </Footer.Link>
//                             <Footer.Link href="/term_and_conditions">
//                                 Terms & Conditions
//                             </Footer.Link>
//                         </Footer.LinkGroup>
//                     </div>
//                     <div>
//                         <Footer.Title title="download" />
//                         <Footer.LinkGroup col>
//                             <Footer.Link href="#">
//                                 iOS
//                             </Footer.Link>
//                             <Footer.Link href="#">
//                                 Android
//                             </Footer.Link>
//                             <Footer.Link href="#">
//                                 Windows
//                             </Footer.Link>
//                             <Footer.Link href="#">
//                                 MacOS
//                             </Footer.Link>
//                         </Footer.LinkGroup>
//                     </div>
//                 </div>

//                 <div className="w-full bg-gray-700 px-4 py-6 sm:flex sm:items-center sm:justify-between">
//                     <Footer.Copyright
//                         by="FinancialEase Hub"
//                         href="#"
//                         year={2023}
//                     />
//                     <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
//                         <Footer.Icon
//                             href="#"
//                             icon={BsFacebook}
//                         />
//                         <Footer.Icon
//                             href="#"
//                             icon={BsInstagram}
//                         />
//                         <Footer.Icon
//                             href="#"
//                             icon={BsTwitter}
//                         />
//                         <Footer.Icon
//                             href="#"
//                             icon={BsGithub}
//                         />
//                         <Footer.Icon
//                             href="#"
//                             icon={BsDribbble}
//                         />

//                     </div>
//                 </div>
//             </div>
         
//         </Footer>
//     )
// }

// export default FooterComponent

