import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const  HdfcLifeLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: ' Can I avail pension benefits with my life insurance plan',
        answer:
            'Yes, HDFC Life offers pension plans that provide both insurance cover and regular pension benefits.',
    },
    {
        question: ' Is there a specific plan for life-threatening diseases?',
        answer:
            'Yes, HDFC Life offers HDFC Life Cancer Care, which provides financial protection for both early and major stages of cancer. The plan includes a waiver of premium for 3 years upon diagnosis of early-stage cancer. The gold and platinum options offer an increased sum insured by 10% annually.',
    },
    {
        question: 'Can I return the policy if Iam not satisfied with it?',
        answer:
            'Yes, you have a 15-day free look period from the date of receipt of the policy within which you can return it.',
    },
    {
        question: 'What happens if I miss a premium payment?',
        answer:
            'Missing a premium payment can cause the policy to lapse. However, you can renew the policy within 2 years from the date of lapse. Some policies may also allow conversion to a paid-up policy; refer to your policy document for details.',
    },
    {
      question: 'Can I cancel my policy if I no longer want it?',
      answer:
          'Yes, you can surrender your policy to HDFC Life if you wish to discontinue it, and you will receive a proportionate return of your investment.',
  },
  {
    question: 'What are the premium payment frequencies for life insurance policies?',
    answer:
        'Premium payment frequencies vary based on the type of policy chosen. Typically, options include annual, semi-annual, quarterly, and monthly payments.',
},
{
  question: 'Does HDFC Life provide Group insurance schemes?',
  answer:
      'Yes, HDFC Life offers Group insurance schemes as well.',
},
{
  question: 'How can I check the status of my HDFC Life Insurance policy?',
  answer:
      'You can check the status of your HDFC Life Insurance policy by logging into the official HDFC Life website with your login credentials. Alternatively, you can visit the nearest HDFC Life Insurance branch for assistance.',
},
{
  question: 'Can I use auto-pay to pay my HDFC Life premiums?',
  answer:
      'Yes, you can opt for auto-pay options to ensure timely payment of your HDFC Life premiums.',
},
{
  question: 'Should I link my Aadhaar with my HDFC Life Insurance policy?',
  answer:
      'Yes, it is advisable to link your Aadhaar with your HDFC Life Insurance policy. You can do this by visiting the HDFC Life website and following the Update Aadhaar option under the My Policy tab. Enter your Aadhaar number and policy details, then click Submit to complete the linking process.',
},

  ];
    const benefits = [
      {
        title: 'Term Insurance.       ',
      },
      {
        title: ' Whole Life Insurance',
      },
      {
        title: 'Unit linked insurance plan (ULIP)        ',
      },
    //   {
    //     title: 'Retirement Plans        .',

    //   },
      {
        title: 'Child Insurance Plan         ',

      },
      {
        title: 'Savings and Investment Plans        ',

      },
      {
        title: 'Group Insurance Plans         ',

      },
    //   {
    //     title: 'Provides regular retirement income by investing a lump sum, with payouts from returns generated by the life insurance company.',
    //   },
      
      // {
      //   title: 'Tyre Secure Cover',
      // },
    ]
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
      <title>HDFC Life Insurance Online: Policy Details, Premium &amp; Benefits | TheFinmaster</title>
      <meta  name='description' content='HDFC Life Insurance - Compare HDFC life insurance company plans online in India and choose the best HDFC standard life policy to secure your family. | TheFinmaster.'/>
      <meta name='keywords' content='hdfc life insurance online payment, online insurance hdfc life, hdfc online insurance, hdfc bank life insurance' />
      <link rel="canonical" href="https://thefinmaster.com/life-insurance/hdfc-life-insurance" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/life-insurance" className="text-blue-600 hover:text-blue-800">Life Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">HDFC Life Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Live life to the fullest. Protect your family with 
                  </span>{" "}
                  HDFC Life Insurance.
                </h1>
                <a href="https://www.hdfclife.com/"
                  target="_blank" 
                  rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                  Get Quote!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/hdfclife2.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about HDFC Life Insurance </h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> HDFC Life stands as a beacon of trust and reliability in the insurance sector, offering a comprehensive suite of life insurance plans to meet various needs. Their term insurance plans provide pure protection, while child plans secure the future of loved ones.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Retirement plans offer financial security in golden years, and savings plans ensure guaranteed returns. With a focus on flexibility and rider benefits, HDFC Life empowers customers to customize coverage according to their unique requirements, cementing their position as a preferred choice among policyholders.  </p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/date.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Same Day Claims Processing


            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            99.5% Individual Claims!


            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/life.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            6.8 crore lives insured	

            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/support.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Certified Online Agents




            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/discount.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Exclusive Online Discounts

            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/crore.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            No hidden costs or details



            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>

{/* <div className="w-full flex flex-col items-center  mb-10">
      <div className="w-[90%] text-center mb-10">
        <h2 className="font-bold text-4xl mb-4">HDFC Life Insurance  plan add-on benefits</h2>
        <p className="text-gray-700 mb-4">
          Choose from an assorted range of add-ons, for an additional premium, when buying HDFC Life Insurance  online.
        </p>
      </div>
      <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex flex-col items-center text-center">
        
            <h3 className=" text-gray-600 text-2xl mb-2">{benefit.title}</h3>
          
          </div>
        ))}
      </div>
    </div>

    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of AU Bank LIT Credit Card</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">The AU Small Finance Bank LIT Credit Card is India's pioneering customizable credit card, offering the unique advantage of selecting and paying for desired features exclusively. With a wide array of benefits spanning lifestyle, groceries, travel, and beyond, this card provides unparalleled flexibility and convenience.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">It provides a comprehensive range of credit card benefits and allows holders to select one or multiple features/categories simultaneously. This empowers customers to enjoy benefits from their chosen category while only paying for the features they use, rather than the entire annual card fees.</p>
            </div> */}
             <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Different Types of Life Insurance Offered by HDFC:</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Term Life Insurance Plan: </span>Term insurance provides a fixed sum assured to beneficiaries in exchange for regular premiums, offering financial protection in case of the insured's demise during the policy term. It typically offers a higher coverage amount at lower premiums, making it an affordable option for securing your family's future. Additionally, some term plans may come with optional riders such as accidental death benefit or critical illness cover, providing enhanced coverage based on individual needs.
</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Whole Life Insurance:</span> Whole Life Insurance offers lifelong coverage, providing financial security for the policyholder's entire life. Apart from the death benefit, it also accumulates cash value over time, which can be accessed through policy loans or withdrawals for various needs such as retirement planning, education expenses, or emergencies. Additionally, some policies offer the option to receive dividends, enhancing the policy's value and flexibility.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Unit Linked Insurance Plan (ULIP):</span> Unit-Linked Insurance Plans (ULIPs) combine insurance and investment, providing financial security for loved ones while offering opportunities for wealth creation through market-linked returns. With ULIPs, investors can choose from various fund options based on risk tolerance, including bonds for safety or equities for higher returns. ULIPs also offer flexibility for adjusting investment strategies and making partial withdrawals as needs change over time.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Retirement or Pension  Plan:</span> Retirement or pension plans aim to build a significant fund for your post-work years, fostering financial independence during retirement. By investing in these plans, you secure your future while potentially accumulating substantial wealth. These plans offer both investment and insurance benefits, ensuring financial security for your loved ones. Through a mix of debt and equity investments, retirement plans offer the potential for higher returns, empowering you to achieve your long-term financial goals.
</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Savings Plan:</span> Savings plans are invaluable life insurance products designed to build a secure financial corpus gradually. They serve dual purposes: wealth creation and life insurance coverage, providing a safety net for financial obligations and future aspirations. With options like money-back policies, these plans offer flexibility and additional income streams, empowering individuals to plan for the long term while ensuring financial security and growth.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Group insurance Plan:</span> Group insurance plans provide comprehensive insurance benefits to a collective group efficiently. Ideal for banks, employers, associations, and more, these plans simplify large-scale coverage for members or employees. They offer hassle-free solutions, making them a preferred choice for organizations aiming to secure the financial well-being of their members or employees.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Child insurance Plan:</span> Child insurance plans offer a vital means for parents to secure their children's future. These plans serve as essential financial tools, helping save substantially for their education and marriage expenses. Upon reaching 18, the plan provides maturity benefits, ensuring financial support for the child's needs. With built-in insurance coverage, these plans offer protection, while investment flexibility allows for tailored financial growth to combat inflation.</p>

           
            </div>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default HdfcLifeLearn
