import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const BajajLearnMore = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const faqs = [
        {
            question: 'What are the types of car insurance available?',
            answer:
                'There are three primary types of car insurance policies: comprehensive, standalone own-damage, and third-party policies. Bajaj Allianz Car Insurance falls under the comprehensive category, providing coverage for the insured vehicle and third-party liabilities under a single policy.',
        },
        {
            question: 'What benefits are offered under Bajaj Allianz Car Insurance Policy?',
            answer:
                'The Bajaj Allianz car insurance policy includes coverage for own damage, third-party liabilities, and personal accident cover. Additionally, it offers three optional add-on covers:- Loss of accessories- Personal accident cover for occupants- Legal liability of paid driver, cleaner, or any workman',
        },
        {
            question: 'Can the car insurance premium be paid in installments?',
            answer:
                'No, Bajaj Allianz Car Insurance premiums must be paid in full; installment payment options are not available.',
        },
        {
            question: 'What is Roadside Assistance (RSA)?',
            answer:
                'Roadside Assistance (RSA) provides support in the event of car breakdowns or accidents. With Bajaj Allianz Car Insurance, you can access a 24x7 RSA facility that includes various services.',
        },
        {
          question: 'What is NCB, and how does it benefit policyholders',
          answer:
              'No Claim Bonus (NCB) is a reward for policyholders who maintain a claim-free record. It allows policyholders to accumulate up to 50% discount on the renewal premium of their motor insurance policy.',
      },
      {
        question: 'How can I apply for or renew Bajaj Allianz Car Insurance Plans?',
        answer:
            'Applying for or renewing Bajaj Allianz car insurance plans has been streamlined through technological advancements. You can easily apply for a policy by visiting the insurer’s official website. Applying for or renewing Bajaj Allianz car insurance plans has been streamlined through technological advancements. You can easily apply for a policy by visiting the insurer’s official website.',
    },
    {
      question: 'How do I file a claim with Bajaj Allianz Car Insurance?',
      answer:
          'In the unfortunate event of accidents or theft, Bajaj Allianz Car Insurance ensures a prompt and efficient claims process to assist you.',
  },
  {
    question: 'What is the Bajaj Allianz Car Insurance Customer Care contact number?',
    answer:
        'For any queries related to Bajaj Allianz Car Insurance, you can contact customer care at the following numbers:- Toll-free: 1800-22-5858, 1800-102-5858- Chargeable: 020-30305858',
},
    ];

    const benefits = [
      {
        icon: 'roadside-assistance.png',
        title: 'Zero Depreciation',
        description: 'Helps you in case of a breakdown/accident with – towing to network garage, battery jumpstart, minor repairs, on-call support and taxi benefits.'
      },
      {
        icon: 'engine-protect.png',
        title: 'Key and Lock Replacement',
        description: 'Pays for repair or replacement of a damaged engine. A handy add-on for those who stay in a region prone to flooding during the rains, or if you own a high-end car.'
      },
      {
        icon: 'consumables.png',
        title: 'Consumable Expenses',
        description: 'Pays for repair or replacement of nut and bolt, screw, washer, grease, lubricant, clips, A/C gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and related parts.'
      },
      {
        icon: 'zero-depreciation.png',
        title: 'Personal Baggage',
        description: 'As your car ages, the value of its parts reduces. This add-on ensures you get the full claim amount without any deduction for depreciation.'
      },
      {
        icon: 'garage-cash.png',
        title: 'Engine Protector',
        description: 'Gives a daily allowance for alternate travel expenses when your car is in the garage for repairs after an accident.'
      },
      {
        icon: 'personal-belongings.png',
        title: 'Conveyance Benefit',
        description: 'Covers the loss or damage of your personal belongings in the car due to theft or an accident.'
      }
    ];
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            // If the clicked question is already expanded, collapse it
            setActiveIndex(null);
        } else {
            // If the clicked question is not expanded, expand it
            setActiveIndex(index);
        };
      };
    return (
        <>
            <Helmet>
      <title>Bajaj Allianz Car Insurance - Renew Bajaj Allianz Motor Insurance Online | TheFinmaster</title>
      <meta  name='description' content='Bajaj Allianz Car Insurance - Buy/Renew Bajaj Allianz motor insurance policy online & save upto 60%*. Get accidental damages coverage for vehicle with ✓Cashless Assurance ✓24x7 Claim Assistance ✓Free Pickup & Drop ✓Self-Video Claims. | TheFinmaster.'/>
      <meta name='keywords' content='bajaj car insurance, bajaj auto insurance, bajaj allianz car insurance online, bajaj finance car insurance' />
      <link rel="canonical" href="https://thefinmaster.com/car-insurance/bajaj-allianz-car-insurance" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/car-insurance" className="text-blue-600 hover:text-blue-800">Car Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Bajaj Allianz Car Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center px-6">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Shield every drive with comprehensive!
                  </span>{" "}
                  Bajaj Allianz car insurance.
                </h1>
                <a href="https://www.bajajallianz.com/motor-insurance/car-insurance-online.html"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Open Account!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/bajajcar1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
          <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about Bajaj Allianz Car Insurance</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> Bajaj Allianz Car Insurance is renowned for its competitive premiums and diverse range of car insurance plans designed to accommodate various budgets and preferences. With comprehensive and third-party only options available, they offer flexibility through customizable add-ons like rider protection, depreciation cover, and engine protection.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Boasting a strong network of partnered workshops for cashless claims, they prioritize a customer-centric approach, reinforcing their status as a preferred choice among car owners seeking reliable coverage and service.</p>
            </div>
            <div className="container mx-auto px-4 py-8">
  <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/garage.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Across 7,200+ Garage Networks

            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/hospital.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Tie up with 18,400+ Hospitals 

            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/3mint.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Get Insurance in Under 3 Minutes!

            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/support.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            24x7 Roadside Assistance


            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/onspot.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            On the spot Claim Settlements

            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            99% Claim Settlement Ratio

            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>

<div className="w-full flex flex-col items-center  mb-10">
      <div className="w-[90%] text-center mb-10">
        <h2 className="font-bold text-4xl mb-4">Bajaj Allianz car insurance plan add-on benefits</h2>
        <p className="text-gray-700 mb-4">
          Choose from an assorted range of add-ons, for an additional premium, when buying Bajaj Allianz car insurance online.
        </p>
      </div>
      <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            {/* <img src='../assets/${benefit.icon}' alt={benefit.title} className="mb-4" /> */}
            <h3 className=" text-gray-600 text-2xl mb-2">{benefit.title}</h3>
            {/* <p className="text-gray-700">{benefit.description}</p> */}
          </div>
        ))}
      </div>
    </div>
{/* <div className='w-full flex flex-col items-center'>
                        <div className='w-[90%] flex flex-wrap md:flex-nowrap justify-between'>
                            <div className='w-full'>
                                <img src='../assets/1paragraph.jpg' alt='img1' />
                            </div>
                            <div className='w-full flex items-center'>
                                <div>
                                    <h2 className='font-bold text-4xl'>Why TheFinMaster?</h2>
                                   
                                    <p className='py-5 text-xl font-medium text-gray-600'>TheFinMaster.com simplifies your finances! Explore & compare credit cards, loans, insurance & demat accounts from various providers. As a one-stop platform, we aim to simplify your financial journey by providing detailed insights into multiple products and offerings.</p>
                                    <p className='mt-0.5 text-xl font-medium text-gray-600'> Make informed financial decisions with our insightful guides and tools.</p>

                                              </div>
                            </div>
                        </div>
                    </div> */}
            {/* <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
      <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
          Key Highlights
        </h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
        
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
        <img
          src="../assets/hospital.gif"
          alt="GIF 1"
          className="w-16 md:w-20 lg:w-16 mb-2"
        />
          <p className="text-2xl md:text-2xl lg:text-2xl text-center text-gray-600">
          Across 7,200+ Network Garages
        </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    Tie up with 18,400+ Hospitals 
    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
              <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    Get Insurance in Under 3 Minutes!

    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    24x7 Roadside Assistance

    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    On the spot Claim Settlements


    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    98% Claim Settlement Ratio

    </p>
  </div>
          </motion.div>
        </motion.div>
      </div>
    </div> 
    </div> */}


    {/* <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about Bajaj Allianz Car Insurance</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> Bajaj Allianz Car Insurance is renowned for its competitive premiums and diverse range of car insurance plans designed to accommodate various budgets and preferences. With comprehensive and third-party only options available, they offer flexibility through customizable add-ons like rider protection, depreciation cover, and engine protection.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Boasting a strong network of partnered workshops for cashless claims, they prioritize a customer-centric approach, reinforcing their status as a preferred choice among car owners seeking reliable coverage and service.</p>
            </div> */}
            <div className="container mx-auto p-4">
      <div className="overflow-x-auto">
      <h2 className="font-bold text-4xl mb-6 text-center ">Comprehensive car insurance vs Third party car insurance</h2> 
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 bg-gray-200 border-b text-left">Comprehensive Car Insurance</th>
              <th className="px-4 py-2 bg-gray-200 border-b text-left">Third-Party Car Insurance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">Covers damages to your own car and third-party liabilities</td>
              <td className="border px-4 py-2">Covers third-party liabilities only</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="border px-4 py-2">Provides complete protection for your car</td>
              <td className="border px-4 py-2">Only covers legal liabilities</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">May include add-ons like zero depreciation, roadside assistance, etc.</td>
              <td className="border px-4 py-2">Only includes mandatory third-party coverage as per law</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="border px-4 py-2">Higher premium as compared to third-party insurance</td>
              <td className="border px-4 py-2">Lower premium as compared to comprehensive insurance</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">Can be purchased online or offline</td>
              <td className="border px-4 py-2">Mandatorily needs to be purchased before registering a car</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default BajajLearnMore
