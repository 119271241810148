// Layout.js

import React from 'react';
import { Helmet } from 'react-helmet';

const LayoutGTM = ({ children }) => {
    // console.log('Layout component rendered');
  return (
    <>
      <Helmet>     
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-DCYD12PDV7"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-DCYD12PDV7');
          `}
        </script>
     
      </Helmet>
      {children}
    </>
  );
};

export default LayoutGTM;