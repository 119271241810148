import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const HdfcargoHealthLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: 'Why should I get a Health Card from HDFC ERGO Health Insurance?',
        answer:
            'A Health Card from HDFC ERGO Health Insurance provides essential contact details of your insurer’s Third Party Administrator (TPA). It facilitates quick access to emergency assistance and other services from the insurance provider. This card is mandatory during hospitalization to ensure that hospital authorities can verify your insurance coverage and initiate necessary processes.',
    },
    {
        question: 'How do I pay HDFC ERGO Health Insurance premium?',
        answer:
            'You can pay your HDFC ERGO Health Insurance premium through online and cash payment modes. For cash payments, visit the nearest HDFC ERGO branch office. Online payments can be made conveniently through the insurer’s website using net banking, debit card, or credit card.',
    },
    {
        question: 'Why choose health insurance plans (Health Suraksha) from HDFC ERGO?',
        answer:
            'HDFC ERGO’s ‘Health Suraksha’ plans offer comprehensive health coverage at competitive prices. These plans cover not only hospitalization expenses but also extend to pre and post-hospitalization care, domiciliary treatment, Ayush benefits (covering Ayurveda, Yoga & Naturopathy, Unani, Siddha, and Homeopathy treatments), day care procedures, emergency ambulance services, and organ donor charges, among other benefits.',
    },
    {
      question: 'What are the types of health insurance plans available from HDFC ERGO?',
      answer:
          'HDFC ERGO offers a range of health insurance plans tailored for individuals, families, and senior citizens. They also provide top-up insurance plans and critical illness insurance plans. The plans are categorized under Health Suraksha, my:health Medisure, and Critical Illness categories.',
  },
  {
    question: 'What do you mean by Domiciliary Hospitalization expenses?',
    answer:
        'Domiciliary hospitalization refers to medical treatment administered at home due to medical necessity or unavailability of hospital beds, as advised by a qualified medical practitioner. Expenses incurred for such treatment are termed domiciliary hospitalization expenses and are covered under the health insurance policy.',
},
{
  question: 'What tax benefits will I get?',
  answer:
      'Policyholders are eligible for attractive tax benefits on the premiums paid towards their health insurance policies under relevant sections of the Income Tax Act, 1961.',
},
{
  question: 'What do you mean by Pre and Post-Hospitalization charges?',
  answer:
      'Pre-hospitalization expenses refer to medical costs incurred before hospital admission, while post-hospitalization expenses are those incurred after discharge. The coverage duration for pre and post-hospitalization expenses may vary depending on the chosen health insurance plan.',
},
{
  question: ' What do you mean by day care procedures?',
  answer:
      'Day care procedures are medical treatments or surgeries that do not require 24-hour hospitalization. These procedures are covered under the health insurance plan as they can be completed within a day.',
},
{
  question: 'What are Ayush Benefits?',
  answer:
      'Ayush benefits encompass coverage for treatments under Ayurveda, Yoga & Naturopathy, Unani, Siddha, and Homeopathy, subject to the specific terms and conditions of the policy. Policyholders can claim reimbursement for these treatments when explicitly covered by their health insurance plan.',
},
  ];
    
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
          <Helmet>
         <title>HDFC ERGO Health Insurance Plan: Features, Riders & Support | TheFinmaster</title>
        <meta  name='description' content='HDFC ERGO Health Insurance: Check key features & metrics, Top plans, Claim process, Customer support & Grievance redressal. Compare and Buy Plan Now. | TheFinmaster.'/>
        <meta name='keywords' content='hdfc health insurance plans, hdfc ergo optima secure,  hdfc ergo mediclaim policy, buy hdfc ergo health insurance' />
        <link rel="canonical" href="https://thefinmaster.com/health-insurance/hdfc-ergo-health-insurance" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <FAQSchema faqs={faqs} />
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/health-insurance" className="text-blue-600 hover:text-blue-800">Health Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">HDFC ERGO Health Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Safeguard your health. Get reliable coverage with 
                  </span>{" "}
                  HDFC ERGO Health Insurance
                </h1>
                <a href="https://www.hdfcergo.com/health-insurance"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Open Account!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/hdfchealth1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about HDFC ERGO Health Insurance </h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> HDFC ERGO Health Insurance distinguishes itself by offering a comprehensive range of health insurance plans designed to cater to the diverse needs of individuals, families, and seniors. Boasting an extensive network comprising over 12,000 hospitals, HDFC ERGO ensures seamless cashless hospitalization experiences for policyholders.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Their plans provide coverage for various medical expenses, including hospitalization, critical illnesses, and daycare procedures. Additionally, they incentivize policyholders with renewal benefits for maintaining a claim-free record, reinforcing their commitment to long-term health security and financial well-being. </p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/hospital.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            13,000+ Cashless Healthcare Network

            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            ₹12,000+ Cr claims settled till now

            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/discount.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            99% Claim Settlement Ratio



            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/customer.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            1.5 Crore+ Happy Customers



            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/bonus.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Free preventive health-check ups

            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/crore.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Premium Starting at Just ₹ 19/Day

            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>


    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Explore HDFC ERGO's Health Insurance Plans</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">Here are some top insurance plans provided by HDFC. Click the link below to discover all plans offered by the brand.</p>
            </div>
            <div class="container mx-auto px-4 sm:px-6 lg:px-8 mt-4">
  <div class="flex flex-wrap justify-between -mx-2">
  
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/hdfc1.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">my:Optima Secure</h2>
          <p class="text-gray-700 mb-4">
          Introducing new add-ons to enhance your protection level. This plan provides 4X health coverage without extra charges, offering a significant boost to your preferred sum insured.

          </p>
          
          <Link to="https://www.hdfcergo.com/health-insurance/optima-secure">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>

    
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/hdfc2.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">my:Optima Secure Global plans</h2>
          <p class="text-gray-700 mb-4">
          In addition to 4X health coverage, this plan offers global coverage, encompassing hospitalization expenses within India and emergency medical treatments abroad.

          </p>
          <Link to="https://www.hdfcergo.com/health-insurance/optima-secure-global-family-plan">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>

   
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="../assets/hdfc3.webp"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Best Health Insurance for Family</h2>
          <p class="text-gray-700 mb-4">
          Your family is at the center of your life.  Secure them with health insurance, featuring benefits like unlimited day care treatments and sum insured restore benefits.      </p>
          <Link to="https://www.hdfcergo.com/health-insurance/family-health-insurance">
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            View Price
          </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
<Link to="https://www.hdfcergo.com/health-insurance">
<h1 className="text-2xl font-semibold text-center mb-8 text-black italic underline flex items-center justify-center">
  View all Plans
  <svg
    className="ml-2 w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5l7 7-7 7"
    ></path>
  </svg>
</h1></Link>
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

      

        </>
    )
}

export default HdfcargoHealthLearn
