import React, { useState } from 'react';
import InsuranceCard from "./InsuranceCard";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const TravelInsurance = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const faqs = [
        {
            question: 'Is a medical check-up necessary to purchase travel insurance?',
            answer:
                'For most insurers, a medical check-up is not required if the applicant is under 70 years of age. However, it is essential to disclose any pre-existing conditions in the proposal form.',
        },
        {
            question: 'How is the premium for travel insurance determined?',
            answer:
                'The premium is calculated based on several factors including the insured’s age, the destination, the duration of the trip, and the type of coverage selected.',
        },
        {
            question: 'What should I do if I lose my money during the trip?',
            answer:
                'If you lose your travel funds or they are stolen, travel insurance can provide emergency cash assistance.',
        },
        {
            question: 'Will I receive a refund if I return home earlier than planned?',
            answer:
                'Yes, you may be eligible for a partial refund of your premium if no claims have been made during the policy period.',
        },
        {
            question: 'Why is it important to have a travel insurance policy?',
            answer:
                'Travel insurance is crucial for managing unforeseen emergencies abroad. It offers protection against risks such as lost baggage, trip delays, accidents, and lost passports.',
        },
        {
            question: 'What is a deductible?',
            answer:
                'A deductible is the amount you are responsible for paying out-of-pocket before the insurance company covers the remaining claim amount.',
        },

    ];

    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };

    return (
        <>
         <Helmet>
      <title>Travel Insurance Online - Buy/Renew Car Insurance Policy & Save Upto 60%</title>
      <meta  name='description' content='Car Insurance Online - Buy/Renew Car Insurance policy from Thefinmaster at best price & save upto 60%* on four wheeler insurance policy premium.'/>
      <meta name='keywords' content='auto insurance coverages, automobile insurance companies, commercial auto insurance, icici lombard motor insurance, maruti insurance online' />
      <link rel="canonical" href="https://thefinmaster.com/car-insurance" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
            {/* Your existing content */}
            <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
      
          <li className="flex items-center">
          <span className="text-gray-500 ">Travel Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav> 
            <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Explore Travel Insurance
                  </span>{" "}
                  Options Now!
                </h1>
                <a href="#insurance_plans">
                    
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    See Plans
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/car1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>

            {/* <div className='w-full h-full md:h-screen flex justify-center'>
            <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-5xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Explore Car Insurance
Options Now!
</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>Zero maintenance charge, trusted by 40M+ Indians.</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='../assets/car1.jpeg' alt='demat growth' />
          </div>
        </div>
      </div>
            </div> */}

            <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 flex justify-center items-center">
    <p className="text-lg md:text-base lg:text-lg text-center">
    Select from a range of coverage options to customize your travel insurance plan according to your needs. Protect yourself from medical emergencies, trip cancellations, or lost luggage. Compare plans, understand coverage details, and receive free quotes—all in one place. We’ll help you find the ideal travel insurance plan to ensure a worry-free journey.   </p>
    </div>

<div className=' lg:px-48'>
    <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4 '>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/bajaj.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>
                Medical Expenses </p>
                <h4 className='text-2xl py-0.5 font-bold'> $250,000</h4>
                {/* <p className='text-gray-400 text-xs'>Max Limit 99 yrs</p> */}
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Baggage Loss </p>
                <h4 className='text-2xl py-0.5 font-bold'>$750</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Premium Starting </p>
                <h4 className='text-2xl py-0.5 font-bold'> ₹3,610</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
            
            
            <a 
          href="https://www.bajajallianz.com/travel-insurance-online.html"
          target="_blank" 
          rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
        Get Quote!
        </button>
      </a>
      <Link to="/travel-insurance/bajaj-allianz-travel-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        See Details
                    </button>
                </Link>
             
               
               
            </div>
        </div>
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4 '>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/iciciinsurance.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6 '>
            <p className='text-gray-500 font-medium'>
            Medical Expenses </p>
                <h4 className='text-2xl py-0.5 font-bold'>$250,000</h4>
                {/* <p className='text-gray-400 text-xs'>Max Limit 99 yrs</p> */}
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Baggage Loss </p>
                <h4 className='text-2xl py-0.5 font-bold'> $500</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Premium Starting</p>
                <h4 className='text-2xl py-0.5 font-bold'>₹3,086</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
                
          
                <a 
          href="https://www.icicilombard.com/travel-insurance?utm_source=google_search&utm_medium=CPC&utm_campaign=Travel_Search_Brand_Jun24&utm_adgroup=&utm_keyword=icici%20lombard%20travel%20insurance&utm_device=c&utm_ad=701849593413&utm_placement=&utm_network=g&utm_matchtype=e&gad_source=1&gclid=Cj0KCQjwh7K1BhCZARIsAKOrVqFf1kV5L_t7eBmzevAzsN30ahdoqTKaLxktyRvuBeP2XgpFJRpifIoaAotKEALw_wcB"
          target="_blank" 
          rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
        Get Quote!
        </button>
      </a>
                <Link to="/travel-insurance/icici-lombard-travel-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        See Details
                    </button>
                </Link>
               
            </div>
        </div>
               
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/carehealth.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
            Medical Expenses </p>
                <h4 className='text-2xl py-0.5 font-bold'> $200,000</h4>
                {/* <p className='text-gray-400 text-xs'>Max Limit 99 yrs</p> */}
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Baggage Loss </p>
                <h4 className='text-2xl py-0.5 font-bold'>$750</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Premium Starting </p>
                <h4 className='text-2xl py-0.5 font-bold'> ₹3,263</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
               
                <a 
          href="https://www.hdfcergo.com/campaigns/buy-travel-insurance-new?utm_source=google_search_1&utm_medium=cpc&utm_campaign=Travel_Search_Brand_Neev-Broad-1&utm_adgroup=Generic&adid=607777551484&utm_term=hdfc%20ergo%20travel%20insurance&utm_network=g&utm_matchtype=e&utm_device=c&utm_location=9061698&utm_sitelink={sitelink}&utm_placement=&ci=googlesearch&SEM=1&gad_source=1&gclid=Cj0KCQjwh7K1BhCZARIsAKOrVqFQuwpU3gUV8YqDQBziCEIFPHjkCselaobrFqDwvuRLExUDEG9UtUwaAvjrEALw_wcB"
          target="_blank" 
          rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
        Get Quote!
        </button>
      </a>
                <Link to="/travel-insurance/care-health-travel-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        See Details
                    </button>
                </Link>
               
            </div>
        </div>

        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4 '>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/genral.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
            Medical Expenses </p>
                <h4 className='text-2xl py-0.5 font-bold'> $250,000</h4>
                {/* <p className='text-gray-400 text-xs'>Max Limit 99 yrs</p> */}
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Baggage Loss</p>
                <h4 className='text-2xl py-0.5 font-bold'> $250</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Premium Starting</p>
                <h4 className='text-2xl py-0.5 font-bold'> ₹4150</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
               
                <a 
          href="https://www.hdfcergo.com/campaigns/buy-travel-insurance-new?utm_source=google_search_1&utm_medium=cpc&utm_campaign=Travel_Search_Brand_Neev-Broad-1&utm_adgroup=Generic&adid=607777551484&utm_term=hdfc%20ergo%20travel%20insurance&utm_network=g&utm_matchtype=e&utm_device=c&utm_location=9061698&utm_sitelink={sitelink}&utm_placement=&ci=googlesearch&SEM=1&gad_source=1&gclid=Cj0KCQjwh7K1BhCZARIsAKOrVqFQuwpU3gUV8YqDQBziCEIFPHjkCselaobrFqDwvuRLExUDEG9UtUwaAvjrEALw_wcB"
          target="_blank" 
          rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
        Get Quote!
        </button>
      </a>
                <Link to="/travel-insurance/hdfc-ergo-travel-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        See Details
                    </button>
                </Link>
               
            </div>
        </div>
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/tatainsurance.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
            Medical Expenses </p>
                <h4 className='text-2xl py-0.5 font-bold'> $250,000</h4>
                {/* <p className='text-gray-400 text-xs'>Max Limit 99 yrs</p> */}
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Baggage Loss </p>
                <h4 className='text-2xl py-0.5 font-bold'> $300</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Premium Starting</p>
                <h4 className='text-2xl py-0.5 font-bold'> ₹3,762</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
             
                     
                <a 
          href="https://www.tataaig.com/travel-insurance?ef_id=Cj0KCQjw-5y1BhC-ARIsAAM_oKnyZreaxZjj7gUSVQJs-67Scxcxoz8O9BVFEptWaHbeVXghyibTOGEaApaZEALw_wcB:G:s&s_kwcid=AL!371!3!!!!x!!&gclsrc=aw.ds&&utm_source=Display&utm_medium=PerformanceMax&utm_campaign={Travel_Performancemax_Nov%2723}-20730824851-&utm_content=&utm_placement=&utm_Adposition=&utm_location=1007765&utm_Sitelink=&gad_source=1&gclid=Cj0KCQjw-5y1BhC-ARIsAAM_oKnyZreaxZjj7gUSVQJs-67Scxcxoz8O9BVFEptWaHbeVXghyibTOGEaApaZEALw_wcB"
          target="_blank" 
          rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
        Get Quote!
        </button>
      </a>
                <Link to="/travel-insurance/tata-aij-travel-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        See Details
                    </button>
                </Link>
               
            </div>
        </div>
        </div>
       
            {/* FAQ section */}
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default TravelInsurance;

