const BASE_URL = "https://test.thefinmaster.com/console/"
const Contact_BASE_URL= "https://test.thefinmaster.com/console/"
const Newsletter_BASE_URL="https://test.thefinmaster.com/console/"

const Contact_API = Contact_BASE_URL + "react-app-directory/fincontact.php"

const Newsletter_API= Newsletter_BASE_URL + "react-app-directory/newsletter-api.php"

const CARD_API =  BASE_URL + "react-app-directory/card-api.php"

const Blog_Api = BASE_URL + "react-app-directory/test-blog-api.php"


export {CARD_API, Blog_Api, Contact_API, Newsletter_API}