import React from 'react';
import ScrollToTopButton from './components/ScrolldownButton/ScrollToTopButton';


const LayoutScroll = ({ children }) => {
  return (
    <div>
      {children}
      <ScrollToTopButton />
    </div>
  );
};
export default LayoutScroll;