import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ blogs, currentPage, selectedCategory, pageSize }) => {
  const filteredBlogs = Array.isArray(blogs) ? blogs : [];

  const paginatedBlogs = filteredBlogs
    .filter((blog) => !selectedCategory || blog.content_category === selectedCategory)
    .slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-8'>
      {paginatedBlogs.map((blog,index) => (
        // <Link to={`/blogpage/${blog.id}`} key={blog.id} className="p-6 shadow-lg rounded cursor-pointer w-full">
        <Link to={`/blog_page/${blog.content_url}`} key={`${blog.content_url}-${index}`}className="p-6 shadow-lg rounded cursor-pointer w-full">

          <div className='overflow-hidden rounded'>
            <img 
              src={blog.content_image} 
              alt={blog.content_title} 
              className="w-full h-64 object-cover hover:scale-110 transition-transform duration-150" // Changed the height to a larger size
            />
          </div>
          <h3 className='mt-4 mb-2 font-bold text-lg hover:text-blue-600 cursor-pointer'>{blog.content_title}</h3>
          <p className='text-sm text-gray-600 mb-2'>Published: {blog.content_date}</p>
        </Link>
      ))}
    </div>
  );
};

export default BlogCard;


// import React from 'react';
// import { Link } from 'react-router-dom';

// const BlogCard = ({ blogs, currentPage, selectedCategory, pageSize }) => {
//   const filteredBlogs = Array.isArray(blogs) ? blogs : [];

//   const paginatedBlogs = filteredBlogs
//     .filter((blog) => !selectedCategory || blog.content_category === selectedCategory)
//     .slice((currentPage - 1) * pageSize, currentPage * pageSize);

//   return (
//     <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8'>
//       {paginatedBlogs.map((blog) => (
//         <Link to={`/blogs1/${blog.id}`} key={blog.id} className="p-5 shadow-lg rounded cursor-pointer">
//           <div>
//             <img src={blog.content_image} alt={blog.content_title} className="w-full h-full object-cover hover:scale-110 duration-150'" />
//           </div>
//           <h3 className='mt-4 mb-2 font-bold hover:text-blue-600 cursor-pointer'>{blog.content_title}</h3>
//           <p className='text-sm text-gray-600 mb-2'>Published: {blog.content_date}</p>
//         </Link>
//       ))}
//     </div>
//   );
// };

// export default BlogCard;
