import React, { useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components for Chart.js
ChartJS.register(ArcElement, ChartTooltip, Legend);

const IncomeTaxCalculator = () => {
  const [assessmentYear, setAssessmentYear] = useState('2021-2022');
  const [ageCategory, setAgeCategory] = useState('below 60');
  const [activeSection, setActiveSection] = useState(null);

  const [income, setIncome] = useState({
    salary: 0,
    otherSources: 0,
    interest: 0,
    rentalIncome: 0,
    homeLoanInterestSelf: 0,
    homeLoanInterestLetOut: 0,
  });

  const [deductions, setDeductions] = useState({
    basic80C: 0,
    nps80CCD: 0,
    medical80D: 0,
    charity80G: 0,
    eduLoan80E: 0,
    savingsInterest80TTA: 0,
  });

  const [hraExemption, setHraExemption] = useState({
    basicSalary: 0,
    daReceived: 0,
    hraReceived: 0,
    rentPaid: 0,
  });

  const [results, setResults] = useState({
    oldRegimeTax: 0,
    newRegimeTax: 0,
  });

  const handleIncomeChange = (e) => {
    const { name, value } = e.target;
    setIncome({
      ...income,
      [name]: parseFloat(value) || 0,
    });
  };

  const handleDeductionsChange = (e) => {
    const { name, value } = e.target;
    setDeductions({
      ...deductions,
      [name]: parseFloat(value) || 0,
    });
  };

  const handleHRAExemptionChange = (e) => {
    const { name, value } = e.target;
    setHraExemption({
      ...hraExemption,
      [name]: parseFloat(value) || 0,
    });
  };

  const calculateTax = () => {
    // Placeholder logic for calculating tax
    const totalIncome = Object.values(income).reduce((a, b) => a + b, 0);
    const totalDeductions = Object.values(deductions).reduce((a, b) => a + b, 0);
    const hraExemptionTotal = Object.values(hraExemption).reduce((a, b) => a + b, 0);

    const taxableIncomeOld = totalIncome - totalDeductions - hraExemptionTotal;
    const taxableIncomeNew = totalIncome; // Assuming no deductions in the new regime

    const oldRegimeTax = taxableIncomeOld * 0.1; // Placeholder calculation
    const newRegimeTax = taxableIncomeNew * 0.15; // Placeholder calculation

    setResults({
      oldRegimeTax: oldRegimeTax.toFixed(2),
      newRegimeTax: newRegimeTax.toFixed(2),
    });
  };

  const toggleSection = (section) => {
    if (activeSection === section) {
      setActiveSection(null);
    } else {
      setActiveSection(section);
    }
  };

  const data = {
    labels: ['Old Regime Tax', 'New Regime Tax'],
    datasets: [
      {
        label: 'Tax Amount',
        data: [results.oldRegimeTax, results.newRegimeTax],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <>
    <Helmet>
         <title>Income Tax Calculator - Calculate Income Tax Online for FY 2024-25 &amp; | TheFinmaster</title>
        <meta  name='description' content='Income Tax Calculator: Check how to calculate income tax online using Income Tax Calculator 2024. Calculate income tax on salary, for FY 2024-25; on TheFinmaster'/>
        <meta name='keywords' content='tax calculator cleartax, tax calculator 2024, salary tax calculator, cleartax tax calculator, online income tax calculator, long term capital gain tax rate, salary income tax calculator, calculate tds on salary' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/income-tax-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Income Tax Calculator</span>
        </li> 
      
      </ol>
    </nav>
    <div className='py-4'>

    
    <div className="container mx-auto p-4 shadow-xl rounded-md ">
      <h1 className="text-2xl font-bold mb-4">Income Tax Calculator</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Assessment Year</label>
            <select
              value={assessmentYear}
              onChange={(e) => setAssessmentYear(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="2021-2022">2021-2022</option>
              <option value="2022-2023">2022-2023</option>
              <option value="2023-2024">2023-2024</option>
              <option value="2024-2025">2024-2025</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Age Category</label>
            <select
              value={ageCategory}
              onChange={(e) => setAgeCategory(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="below 60">Below 60</option>
              <option value="60 or above 60">60 or above 60</option>
              <option value="80 or above 80">80 or above 80</option>
            </select>
          </div>
          <div className="mb-4">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => toggleSection('income')}
            >
              <span className="text-xl font-bold mr-2 text-gray-700">
                {activeSection === 'income' ? '-' : '+'}
              </span>
              <h2 className=" text-gray-700 text-xl font-semibold">Income</h2>
            </div>
            {activeSection === 'income' && (
              <div>
                {Object.keys(income).map((key) => (
                  <div key={key} className="mb-2">
                    <label className="block text-gray-700 font-semibold capitalize">{key.replace(/([A-Z])/g, ' $1')}</label>
                    <input
                      type="number"
                      name={key}
                      value={income[key]}
                      onChange={handleIncomeChange}
                      className="w-full p-2 border rounded"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mb-4">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => toggleSection('deductions')}
            >
              <span className="text-xl font-bold mr-2 text-gray-700">
                {activeSection === 'deductions' ? '-' : '+'}
              </span>
              <h2 className="text-gray-700 text-xl font-semibold">Deductions</h2>
            </div>
            {activeSection === 'deductions' && (
              <div>
                {Object.keys(deductions).map((key) => (
                  <div key={key} className="mb-2">
                    <label className="block text-gray-700 font-semibold capitalize">{key.replace(/([A-Z])/g, ' $1')}</label>
                    <input
                      type="number"
                      name={key}
                      value={deductions[key]}
                      onChange={handleDeductionsChange}
                      className="w-full p-2 border rounded"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mb-4">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => toggleSection('hraExemption')}
            >
              <span className="text-xl font-bold mr-2 text-gray-700">
                {activeSection === 'hraExemption' ? '-' : '+'}
              </span>
              <h2 className="text-gray-700 text-xl font-semibold">HRA Exemption</h2>
            </div>
            {activeSection === 'hraExemption' && (
              <div>
                {Object.keys(hraExemption).map((key) => (
                  <div key={key} className="mb-2">
                    <label className="block text-gray-700 font-semibold capitalize">{key.replace(/([A-Z])/g, ' $1')}</label>
                    <input
                      type="number"
                      name={key}
                      value={hraExemption[key]}
                      onChange={handleHRAExemptionChange}
                      className="w-full p-2 border rounded"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <button
            onClick={calculateTax}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Calculate
          </button>
        </div>
        <div>
          <div className="mb-4">
            <h2 className="text-xl font-bold">Results</h2>
            <p>Total Tax (Old Regime): ₹ {results.oldRegimeTax}</p>
            <p>Total Tax (New Regime): ₹ {results.newRegimeTax}</p>
          </div>
          <div>
            <h2 className="text-xl font-bold">Breakdown</h2>
            <div className="flex justify-center">
              <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    
    <div className="flex flex-wrap px-6 py-6" >
     <div className="w-full md:w-2/3 p-4">
       <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Simplify Your Taxes with TheFinMaster's Free Income Tax Calculator</h1>

 {/* <p className="text-lg font-normal mt-2 text-gray-700 ">Know Your Tax Liability in Minutes:</p> */}

       <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Filing taxes can be overwhelming. TheFinMaster's free income tax calculator helps you estimate your tax liability for the current financial year quickly and easily.
</p>


       <div className="container mx-auto  py-4">
         <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using TheFinMaster's Income Tax Calculator:

</h1>

         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>    Effortless Calculations:</span>  Get instant results without the hassle of complex formulas or manual calculations.

         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'> Stay Compliant:</span>   Ensure you're paying the correct amount of tax, avoiding potential penalties.
           </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>  Compare Tax Regimes:</span>    See how much tax you owe under both the old and new tax regimes to make informed decisions.

         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>    Plan for the Future:</span>    Get a head start on your tax filing by estimating your liability early.


         </p>
         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
           <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
           <span className='font-bold'>     User-Friendly Interface: </span> Our calculator is easy to navigate, even for those unfamiliar with tax filing procedures.
         </p>
       </div>
       <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How the Calculator Works:
</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Simply enter your income details, deductions, and other relevant information. Our calculator will guide you through the process and provide a clear estimate of your tax liability.

            </p>
          </div>
      
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Additional Resources:</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">TheFinMaster offers a variety of resources to help you understand income tax concepts and deductions.

            </p>
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Ready to Get Started?</h1>
            <p className="text-lg font-normal mt-2 text-gray-700 ">Use TheFinMaster's free income tax calculator today! Take the stress out of tax season and gain peace of mind.


            </p>
          </div>
     

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Additional Notes:</h1>

            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Removed unnecessary information about income tax basics (taxable income, TDS, etc.) most users will be familiar with this.        </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Simplified the explanation of tax slabs and exemptions.
    </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              Focused on the user benefits and a clear call to action.
      </p>
             
          </div>

       


     </div>

     <div className="w-full md:w-1/3 p-4">
       <div className="bg-white shadow-md rounded-lg overflow-hidden">
         <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
           <h2 className="text-lg font-semibold ">Popular Categories</h2>
         </div>
         <div className="p-2 ">
         <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
             {/* {moreCategories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
           </ul>
         </div>
       </div>

     </div>

   </div>
    </>
  );
};

export default IncomeTaxCalculator;
