import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const mockFetchCompanyData = async (query) => {
  // Mock data - replace this with a real API call if available
  const companies = [
    { name: 'Apple Inc.', symbol: 'AAPL', price: 150 },
    { name: 'Microsoft Corporation', symbol: 'MSFT', price: 250 },
    { name: 'Google LLC', symbol: 'GOOGL', price: 2800 },
    { name: 'Amazon.com, Inc.', symbol: 'AMZN', price: 3300 },
    { name: 'Tesla, Inc.', symbol: 'TSLA', price: 700 },
  ];
  return companies.filter(company => company.name.toLowerCase().includes(query.toLowerCase()));
};

const EquityMarginCalculator = () => {
  const [companies, setCompanies] = useState([
    { name: '', orderPrice: 0, shares: 0, buySell: 'SELL', exchange: 'BSE' },
  ]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (searchQuery.length > 0) {
        const results = await mockFetchCompanyData(searchQuery);
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    };
    fetchData();
  }, [searchQuery]);

  const handleAddCompany = () => {
    setCompanies([...companies, { name: '', orderPrice: 0, shares: 0, buySell: 'SELL', exchange: 'BSE' }]);
  };

  const handleDeleteCompany = (index) => {
    const updatedCompanies = companies.filter((_, i) => i !== index);
    setCompanies(updatedCompanies);
  };

  const handleInputChange = (index, field, value) => {
    const updatedCompanies = [...companies];
    updatedCompanies[index][field] = value;
    setCompanies(updatedCompanies);
  };

  const handleCompanySelect = (company, index) => {
    const updatedCompanies = [...companies];
    updatedCompanies[index] = { ...updatedCompanies[index], name: company.name, orderPrice: company.price };
    setCompanies(updatedCompanies);
    setSearchQuery('');
    setSearchResults([]);
    setSelectedCompanyIndex(null);
  };

  return (
    <>
      <Helmet>
         <title>Margin Calculator - Equity Delivery &amp; Intraday Margin Calculator | TheFinmaster</title>
        <meta  name='description' content='Online Margin Calculator - Calculate and understand the margin required for delivery and intraday trading based on order details with our online margin calculator. | TheFinmaster'/>
        <meta name='keywords' content='profit margin calculator, profit percentage calculator, calculator, gross profit margin formula, product margin calculator, price margin calculator, net income margin calculator' />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
    <div className="py-4">
      <div className="container mx-auto p-4 max-w-7xl shadow-xl rounded-md border">
        <h1 className="text-2xl font-bold mb-4">Equity Margin Calculator</h1>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">COMPANY</th>
                <th className="py-2 px-4 border-b">ORDER PRICE</th>
                <th className="py-2 px-4 border-b">SHARES</th>
                <th className="py-2 px-4 border-b">BUY/SELL</th>
                <th className="py-2 px-4 border-b">EXCHANGE</th>
                <th className="py-2 px-4 border-b">MARGIN FOR DELIVERY</th>
                <th className="py-2 px-4 border-b">MARGIN FOR INTRADAY</th>
                <th className="py-2 px-4 border-b">ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b relative">
                    <input
                      type="text"
                      value={company.name}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setSelectedCompanyIndex(index);
                      }}
                      placeholder="Search for a company"
                      className="w-full p-2 border rounded"
                    />
                    {selectedCompanyIndex === index && searchResults.length > 0 && (
                      <div className="absolute z-10 w-full bg-white border rounded shadow-lg mt-1 max-h-48 overflow-y-auto">
                        {searchResults.map((result, idx) => (
                          <div
                            key={idx}
                            className="p-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => handleCompanySelect(result, index)}
                          >
                            {result.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </td>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="number"
                      value={company.orderPrice}
                      onChange={(e) => handleInputChange(index, 'orderPrice', Number(e.target.value))}
                      className="w-full p-2 border rounded"
                    />
                  </td>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="number"
                      value={company.shares}
                      onChange={(e) => handleInputChange(index, 'shares', Number(e.target.value))}
                      className="w-full p-2 border rounded"
                    />
                  </td>
                  <td className="py-2 px-4 border-b">
                    <select
                      value={company.buySell}
                      onChange={(e) => handleInputChange(index, 'buySell', e.target.value)}
                      className="w-full p-2 border rounded"
                    >
                      <option value="SELL">SELL</option>
                      <option value="BUY">BUY</option>
                    </select>
                  </td>
                  <td className="py-2 px-4 border-b">
                    <select
                      value={company.exchange}
                      onChange={(e) => handleInputChange(index, 'exchange', e.target.value)}
                      className="w-full p-2 border rounded"
                    >
                      <option value="BSE">BSE</option>
                      <option value="NSE">NSE</option>
                    </select>
                  </td>
                  <td className="py-2 px-4 border-b text-center">-</td>
                  <td className="py-2 px-4 border-b text-center">-</td>
                  <td className="py-2 px-4 border-b text-center">
                    <button onClick={() => handleDeleteCompany(index)} className="text-red-500">
                      &#x2715;
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="8" className="py-2 px-4 border-b text-center">
                  <button onClick={handleAddCompany} className="text-green-500">
                    + ADD COMPANY
                  </button>
                </td>
              </tr>
              <tr>
                <td colSpan="5" className="py-2 px-4 border-b text-right font-semibold">Total</td>
                <td className="py-2 px-4 border-b text-center">-</td>
                <td className="py-2 px-4 border-b text-center">-</td>
                <td className="py-2 px-4 border-b text-center"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div className="flex flex-wrap px-6 py-6" >
        <div className="w-full md:w-2/3 p-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Trade with Confidence: Use an Equity Margin Calculator</h1>
         
          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Not sure how much capital you'll need for your next stock purchase? An equity margin calculator can help!</p>
       
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">What It Does:
</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            This free tool estimates the margin requirement for your trade, considering factors like:             </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Delivery vs. Intraday:</span>  Calculates margins for both buying stocks for long-term holding (delivery) and short-term trading (intraday).
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Stock Volatility: </span>   Takes into account the stock's price fluctuation, with higher margins required for more volatile stocks.
            </p>
           
          </div>

           <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Choosing the Right Loan:</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Understanding the difference between flat and reducing balance interest rates can help you choose the loan option with the lower overall cost.            </p>
            </div> 

          <div className="container mx-auto  py-2">

            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 mt-2">Benefits for You:
</h1>
           
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>Plan Your Trades: </span> Knowing the margin requirement upfront helps you plan your trades and allocate your capital effectively.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Avoid Shortfalls: </span>   Ensures you have sufficient funds to cover your purchase, preventing trade rejections due to insufficient margin.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Trade Smarter:</span>  Provides valuable insights for informed trading decisions, especially for beginners.
            </p>
           
          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Ready to Get Started?</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Use a free equity margin calculator today! Trade with confidence knowing exactly how much capital you'll need. </p>          </div>
          
        </div>

        <div className="w-full md:w-1/3 p-4">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
              <h2 className="text-lg font-semibold ">Popular Categories</h2>
            </div>
            <div className="p-2 ">
            <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                {/* {moreCategories.map(category => (
          <Link key={category.id} to={`/category/${category.id}`}>
            <li className="py-2 border-b border-gray-300">{category.name}</li>
          </Link>
        ))} */}
              </ul>
            </div>
          </div>

        </div>

      </div>


    </>
  );
};

export default EquityMarginCalculator;
