import React from 'react'
import { useState } from 'react';

const CarLoanLearnMore = () => {
    const [loanAmount, setLoanAmount] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [loanTenure, setLoanTenure] = useState('');
    const [emi, setEmi] = useState(null);

    const calculateEMI = () => {
        const principal = parseFloat(loanAmount);
        const rate = parseFloat(interestRate) / 100 / 12; // Monthly interest rate
        const time = parseFloat(loanTenure) * 12; // Loan tenure in months
        const emiValue = (principal * rate * Math.pow(1 + rate, time)) / (Math.pow(1 + rate, time) - 1);
        setEmi(emiValue.toFixed(2));
    };
  return (
    <>
    <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-6xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Open a free Demat Account</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>Zero maintenance charge, trusted by 40M+ Indians.</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='/growth.jpg' alt='demat growth' />
          </div>
        </div>
      </div>
     {/* <div className="px-4 md:px-8 lg:px-12">
    <h2 className="text-4xl font-bold mb-6 text-center mt-4 text-gray-900">Car Loan Details</h2> */}
    {/* <p className="text-lg text-gray-700 text-center mb-8">Explore our flexible car loan options tailored to suit your needs.</p> */}
{/* </div> */}


<div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 md:p-8 px-4 lg:px-12">
  <h2 className="text-3xl font-bold mb-6 text-center text-gray-800 dark:text-white">Axis Bank Car Loan Details</h2>
  <div className="overflow-x-auto">
    <table className="w-full table-fixed border border-gray-200 dark:border-gray-600">
      <tbody>
        <tr className="border-b border-gray-200 dark:border-gray-600">
          <td className="w-1/2 px-6 py-3 font-semibold text-gray-800 dark:text-gray-300">Interest Rate</td>
          <td className="w-1/2 px-6 py-3 text-gray-700 dark:text-gray-400">8.70% p.a. for salaried & 9.10% p.a. for self-employed</td>
        </tr>
        <tr className="border-b border-gray-200 dark:border-gray-600">
          <td className="w-1/2 px-6 py-3 font-semibold text-gray-800 dark:text-gray-300">Loan Amount</td>
          <td className="w-1/2 px-6 py-3 text-gray-700 dark:text-gray-400">Based on income</td>
        </tr>
        <tr className="border-b border-gray-200 dark:border-gray-600">
          <td className="w-1/2 px-6 py-3 font-semibold text-gray-800 dark:text-gray-300">Processing Fees</td>
          <td className="w-1/2 px-6 py-3 text-gray-700 dark:text-gray-400">Up to 1% or min. Rs. 10,000/-</td>
        </tr>
        <tr className="border-b border-gray-200 dark:border-gray-600">
          <td className="w-1/2 px-6 py-3 font-semibold text-gray-800 dark:text-gray-300">Penal Interest Rate</td>
          <td className="w-1/2 px-6 py-3 text-gray-700 dark:text-gray-400">24% p.a. or 2% per month</td>
        </tr>
        <tr className="border-b border-gray-200 dark:border-gray-600">
          <td className="w-1/2 px-6 py-3 font-semibold text-gray-800 dark:text-gray-300">Prepayment/Foreclosure Charges</td>
          <td className="w-1/2 px-6 py-3 text-gray-700 dark:text-gray-400">0 - 2%</td>
        </tr>
        <tr className="border-b border-gray-200 dark:border-gray-600">
          <td className="w-1/2 px-6 py-3 font-semibold text-gray-800 dark:text-gray-300">Max Tenure</td>
          <td className="w-1/2 px-6 py-3 text-gray-700 dark:text-gray-400">30 Years</td>
        </tr>
        <tr>
          <td className="w-1/2 px-6 py-3 font-semibold text-gray-800 dark:text-gray-300">Rate Packages Available</td>
          <td className="w-1/2 px-6 py-3 text-gray-700 dark:text-gray-400">Floating/Fixed</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div className=" p-6 rounded-lg shadow-lg mt-4">
    <h2 className="text-2xl font-bold  mb-4">Axis Bank car Loan Interest Rates 2024</h2>
    <p className="text-lg text-gray-500">
        Interest Rate: 8.70% p.a. for salaried & 9.10% p.a. for self-employed 
        Loan Amount: Based on income 
        Processing Fees: Up to 1% or min. Rs. 10,000/-
        Penal Interest Rate: 24% p.a. or 2% per month 
        Prepayment/Foreclosure Charges: 0 - 2% 
        Max Tenure: 30 Years
        Rate Packages Available: Floating/Fixed
    </p>
</div>
<div className="mx-auto max-w-4xl mt-4">
    <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-300">
            <thead className="bg-gray-800 text-white">
                <tr>
                    <th className="px-6 py-3 text-left">Axis Car Loan Schemes</th>
                    <th className="px-6 py-3 text-left">Salarid</th>
                    <th className="px-6 py-3 text-left">Self-Employed</th>
                    {/* <th className="px-6 py-3 text-left">30 years</th> */}
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-300">                                        
                <tr>
                    <td className="px-6 py-4">Axis Car Loan</td>
                    <td className="px-6 py-4"> Rs.25,012</td>
                    <td className="px-6 py-4">Rs.25,012</td>
                  
                </tr>
                <tr>
                    <td className="px-6 py-4">Axis Shubh Aarambh Car Loan</td>
                    <td className="px-6 py-4">Rs.25,012</td>
                    <td className="px-6 py-4">Rs.17,610</td>
                  
                </tr>
                <tr>
                    <td className="px-6 py-4">Axis Power Advantage Car Loan</td>
                    <td className="px-6 py-4">Rs.50,023</td>
                    <td className="px-6 py-4">Rs.35,221</td>
                 
                </tr>
                {/* <tr>
                    <td className="px-6 py-4">Rs.50 lakh</td>
                    <td className="px-6 py-4">Rs.62,529</td>
                    <td className="px-6 py-4">Rs.44,026</td>
                    <td className="px-6 py-4">Rs.39,157</td>
                </tr> */}
            </tbody>
        </table>
    </div>
</div>






<div className=" p-6   mt-4">
    <h2 className="text-2xl font-bold  mb-2">Axis Bank Car Loan MCLR Rates Apr 2024</h2>
    <p className="text-lg text-gray-500">
    The rate of interest disbursed for fixed rate or floating rate home loans based on the MCLR, from October 2023 to December 2023, ranged from 8.20% p.a. to 11.55% p.a.
    </p>
</div>

<div className=" p-4  mt-1">
    <h2 className="text-2xl font-bold  mb-2">Axis Bank Car Loan Calculator</h2>
        <p className="text-lg text-gray-500">
        BankBazaar Axis Bank Car Loan EMI Calculator lets you calculate your EMI within a few minutes. This easy-to-use financial calculator gives you 100% accurate results every time. All you need to do is enter the loan amount, interest rate, tenure, and processing fee. Click on “Calculate” to view your EMI. You can also check a detailed breakdown of your repayment schedule through an amortisation table.
        </p>
</div>
<div className=' overflow-x-auto px-0 md:px-4 lg:px-12'>
<div className=" border-collapse border  rounded-md shadow-md overflow-hidden ">
    <table className="w-full  text-gray-800">
        <thead>
            <tr className='bg-gray-800 text-white'>
                <th className="px-4 py-2 text-left">Amount</th>
                <th className="px-4 py-2 text-left">10 years</th>
                <th className="px-4 py-2 text-left">20 years</th>
                <th className="px-4 py-2 text-left">30 years</th>
            </tr>
        </thead>
        <tbody className="divide-y text-gray-500">
            <tr>
                <td className="px-4 py-2">Rs.10 lakh</td>
                <td className="px-4 py-2">Rs.12,506</td>
                <td className="px-4 py-2">Rs.8,805</td>
                <td className="px-4 py-2">Rs.7,831</td>
            </tr>
            <tr>
                <td className="px-4 py-2">Rs.20 lakh</td>
                <td className="px-4 py-2">Rs.25,012</td>
                <td className="px-4 py-2">Rs.17,610</td>
                <td className="px-4 py-2">Rs.15,663</td>
            </tr>
            <tr>
                <td className="px-4 py-2">Rs.40 lakh</td>
                <td className="px-4 py-2">Rs.50,023</td>
                <td className="px-4 py-2">Rs.35,221</td>
                <td className="px-4 py-2">Rs.31,325</td>
            </tr>
            <tr>
                <td className="px-4 py-2">Rs.50 lakh</td>
                <td className="px-4 py-2">Rs.62,529</td>
                <td className="px-4 py-2">Rs.44,026</td>
                <td className="px-4 py-2">Rs.39,157</td>
            </tr>
        </tbody>
    </table>
</div>
</div>
<div className="mt-4 text-gray-500 p-6 gap-4">
<p className="text-lg  ">
India’s premier customizable credit card, empowering you to select your preferred features at your convenience. Enjoy a complimentary 3-month membership for Zee5, Amazon Prime, and Cult.fit with your card. 
    </p>
        <p className="text-lg">
            India’s premier customizable credit card, empowering you to select your preferred features at your convenience. Enjoy a complimentary 3-month membership for Zee5, Amazon Prime, and Cult.fit with your card. 
        </p>
        <p className="text-lg ">
        India’s premier customizable credit card,  and Cult.fit with your card. 
    </p>
    <p className="text-lg ">
    India’s premier customizable credit card, empowering you to select your preferred features at your convenience. 
    </p>
    <p className="text-lg ">
    India’s premier customizable credit card, empowering you to select your preferred features at your convenience.  
    </p>
    </div>
    <div className="flex flex-col items-center justify-center mt-6">
            <div className="bg-gray-200 p-6 rounded-lg shadow-md">
                <h2 className="text-3xl font-bold mb-4 text-center">Home Loan EMI Calculator</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="loanAmount" className="block mb-2">Loan Amount (Rs.)</label>
                        <input type="number" id="loanAmount" value={loanAmount} onChange={e => setLoanAmount(e.target.value)} className="input-field" />
                    </div>
                    <div>
                        <label htmlFor="interestRate" className="block mb-2">Interest Rate (%)</label>
                        <input type="number" id="interestRate" value={interestRate} onChange={e => setInterestRate(e.target.value)} className="input-field" />
                    </div>
                    <div>
                        <label htmlFor="loanTenure" className="block mb-2">Loan Tenure (Years)</label>
                        <input type="number" id="loanTenure" value={loanTenure} onChange={e => setLoanTenure(e.target.value)} className="input-field" />
                    </div>
                    <div className="md:col-span-2">
                      
                        <button onClick={calculateEMI} class="relative pl-6 px-5 py-3 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group">
<span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
<span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
<span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
<span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
<span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
<span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">Calculate EMI</span>
</button>
                    </div>
                </div>
                {emi && (
                    <div className="mt-4 text-center">
                        <p className="text-lg font-semibold">Your Monthly EMI:</p>
                        <p className="text-2xl text-blue-600 font-bold">{emi} Rs.</p>
                    </div>
                )}
            </div>
        </div>
    <div className="px-4 md:px-8 lg:px-6">
    <h2  className="text-2xl font-bold mt-4 mb-2">Axis Bank Home Loan Schemes</h2>
    <p className="text-lg text-gray-500 mb-4">Axis Bank offers home financing schemes for both salaried and self-employed individuals. Attractive rates and flexible tenures are some of the major highlights of Axis Bank home financing schemes.</p>
    <ul className="list-disc pl-4 text-base text-gray-500">
        <li>Minimum financing of Rs.3 lakh</li>
        <li>Option to choose fixed or floating rate of interest</li>
        <li>Loans available for both salaried and self-employed individuals</li>
        <li>Financing of up to 90% of the total property cost</li>
    </ul>
    
</div>
<div className="px-4 md:px-8 lg:px-12 mt-2">
    <ol className="list-decimal pl-4 text-gray-500 text-base">
        <li className="mb-4">
        <p className="text-lg text-blue-500">Axis Bank Shubh Aarambh Home Loan</p>
            <ul className="list-disc pl-4">
                <li>Interest subsidy under Pradhan Mantri Awas Yojana (PMAY) available</li>
                <li>EMI waived for 12 EMIs if you have a clean repayment record</li>
                <li>Loans of up to Rs.30 lakh</li>
                <li>Balance transfer facility available</li>
            </ul>
            <p className="text-base text-gray-800"><strong>* </strong>The above-mentioned interest rates will vary depending on the individual’s creditworthiness and the type of home loan.</p>
        </li>
        <li className="mb-4">
            {/* <a href="" target="_blank" className="font-semibold"><strong>Axis Bank Fast Forward Home Loan</strong></a> */}
            <p className="text-lg text-blue-500">Axis Bank Shubh Aarambh Home Loan</p>

            <ul className="list-disc pl-4">
                <li>12 EMIs waived upon regular repayments made to the loan</li>
                <li>No prepayment charges</li>
                <li>Minimum financing of Rs.30 lakh</li>
                <li>Tenure up to 30 years</li>
            </ul>
            <p className="text-base text-gray-800"><strong>* </strong>The above-mentioned interest rates will vary depending on the individual’s creditworthiness and the type of home loan.</p>
        </li>
        <li className="mb-4">
        <p className="text-lg text-blue-500">Axis Bank Shubh Aarambh Home Loan</p>
            <ul className="list-disc pl-4">
                <li>Maximum loan amount of Rs.28 lakh</li>
                <li>12 EMIs waived depending on your banking history</li>
                <li>Financing of up to 90% of the total property cost</li>
            </ul>
        </li>
        <li className="mb-4">
        <p className="text-lg text-blue-500">Axis Bank Shubh Aarambh Home Loan</p>
            <ul className="list-disc pl-4">
                <li>Minimum loan amount of Rs.50 lakh</li>
                <li>No prepayment charges</li>
                <li>Save on the total interest payable</li>
                <li>Tenure of up to 22 years</li>
            </ul>
            <p className="text-base text-gray-800"><strong>* </strong>The above-mentioned interest rates will vary depending on the individual’s creditworthiness and the type of home loan.</p>
        </li>
        <li className="mb-4">
        <p className="text-lg text-blue-500">Axis Bank Shubh Aarambh Home Loan</p>
            <ul className="list-disc pl-4">
                <li>Save more on interest</li>
                <li>Pay higher amount of principal earlier in the tenure</li>
                <li>No prepayment charges</li>
                <li>Option to avail balance transfer facility</li>
            </ul>
            <p className="text-base text-gray-800"><strong>* </strong>The above-mentioned interest rates will vary depending on the individual’s creditworthiness and the type of home loan.</p>
        </li>
        <li className="mb-4">
        <p className="text-lg text-blue-500">Axis Bank Shubh Aarambh Home Loan</p>
            <ul className="list-disc pl-4">
                <li>Fixed rate of interest for the initial two years of the loan</li>
                <li>Interest subsidy under Pradhan Mantri Aws Yojana (PMAY) available</li>
                <li>Avail balance transfer facility</li>
                <li>Hassle-free documental and quick loan disbursal</li>
            </ul>
            <p className="text-base text-gray-800"><strong>* </strong>The above-mentioned interest rates will vary depending on the individual’s creditworthiness and the type of home loan.</p>
        </li>
        <li className="mb-4">
        <p className="text-lg text-blue-500">Axis Bank Shubh Aarambh Home Loan</p>
            <p className="text-base text-gray-800">You can get the benefits of the Pradhan Mantri Awas Yojana (PMAY) scheme through Shubh Aarambh and Power Advantage schemes. The benefits of PMAY include:</p>
            <ul className="list-disc pl-4">
                <li>Interest subsidy on loans</li>
                <li>Benefits for NRIs as well</li>
                <li>Subsidy for tenures up to 20 years</li>
            </ul>
        </li>
    </ol>
</div>
<div className="px-4 md:px-8 lg:px-12">
    <h3 className="text-xl font-semibold text-gray-900 mb-4">Eligibility Criteria for Axis Bank Home Loan</h3>
    <div className="overflow-x-auto">
        <table className="mx-auto w-full table-auto border border-gray-200 border-collapse rounded-md">
            <tbody className="text-gray-800">
                <tr className="bg-gray-100">
                    <td className="px-4 py-2 font-semibold">Age</td>
                    <td className="px-4 py-2">21 years to 57 years</td>
                </tr>
                <tr className="bg-gray-200">
                    <td className="px-4 py-2 font-semibold">Nationality</td>
                    <td className="px-4 py-2">Resident Indians and NRIs</td>
                </tr>
                <tr className="bg-gray-100">
                    <td className="px-4 py-2 font-semibold">Employment type</td>
                    <td className="px-4 py-2">Salaried and self-employed</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div className="px-4 md:px-8 lg:px-12 mt-6 mb-5">
    <h3 className="text-xl font-semibold text-gray-900 mb-4">Documents Required for Axis Bank Home Loan</h3>
    <ul className="list-disc list-inside text-gray-800">
        <li>Proof of identity</li>
        <li>Address proof</li>
        <li>Birth certificate</li>
        <li>Income proof</li>
        <li>Processing fee cheques</li>
        <li>Loan agreement document</li>
        <li>Property documents</li>
        <li>Own contribution proof</li>
        <li>Sanction letter</li>
    </ul>
</div>

    </>
  )
}

export default CarLoanLearnMore
