import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const TataAijLearnMore = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
      question: ' How do I renew expired TATA AIG car insurance?',
      answer:
          'If your TATA AIG car insurance has lapsed, you can still renew it by providing your car registration number, previous policy number, details of previous claims filed, and photographs of your car.',
  },
  {
      question: 'Can I purchase TATA AIG car insurance online?',
      answer:
          'Yes, you can purchase TATA AIG car insurance online by visiting the official website of TATA AIG.',
  },
  {
      question: 'How long does TATA AIG take to settle a claim?',
      answer:
          'TATA AIG settles car insurance claims within 30 days of submission.',
  },
  {
      question: ' How does TATA AIG car insurance work?',
      answer:
          'TATA AIG car insurance covers the cost of repairs and recovery for any damage or loss to your car. Depending on your policy, you can receive full or partial reimbursement for the expenses incurred in restoring your car to its original condition.',
  },
  {
    question: ' Is an FIR required to make a claim?',
    answer:
        'If your car is stolen or you are involved in an accident, TATA AIG may require an FIR (First Information Report) to process your claim.',
},
{
  question: 'Will flood-related engine damage be covered by my car insurance?',
  answer:
      'Standard car insurance policies do not cover engine damage caused by floods. However, you can opt for specific add-on covers to claim reimbursement for such incidents.',
},
{
  question: 'Can I claim car insurance six months after the damage?',
  answer:
      'The deadline for filing a car insurance claim may vary depending on the circumstances. Generally, insurers require claims to be filed within a specific timeframe after the incident. It is crucial to adhere to this timeline to ensure your claim is processed smoothly.',
},
{
  question: 'My car has minor damage; can I claim it on my insurance?',
  answer:
      'The coverage for minor damages such as dents and scratches depends on the terms of your car insurance policy. While some policies may cover minor damages, it is important to review your policy details. Claiming for minor damages may affect your eligibility for a no-claim bonus, so it is advisable to consider this carefully.',
},
  ];
    const benefits = [
      {
        title: 'No Claim Bonus Protection',
      },
      {
        title: 'Roadside Assistance',
      },
      {
        title: 'Repair of Glass Fiber and Plastic Parts',
      },
      {
        title: 'Daily Allowance',
      },
      {
        title: 'Emergency Transport and Hotel Expenses',
      },
      {
        title: 'Key Replacement        ',
      },
      {
        title: 'Engine & Tyre Secure',
      },
      {
        title: 'Loss of Personal Belongings',
      },
      {
        title: 'Return to Invoice',
      },
      // {
      //   title: 'Tyre Secure Cover',
      // },
    ]
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
      <title>Tata Aig Car Insurance - Renew Tata AIG Motor Insurance Online at TheFinmaster</title>
      <meta  name='description' content='Tata Aig Car Insurance - Buy/Renew Tata AIG motor insurance policy online & get accidental damages coverage for vehicle. ✓Check Premium ✓Features & Benefits ✓Network Garages. | TheFinmaster.'/>
      <meta name='keywords' content='tata aig car insurance renewal, tata aig car insurance, tata aig car insurance online' />
      <link rel="canonical" href="https://thefinmaster.com/car-insurance/tata-aij-car-insurance" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/car-insurance" className="text-blue-600 hover:text-blue-800">Car Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Tata Aij Car Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Your trusted partner for complete car protection 
                  </span>{" "}
                  - TATA AIG
                </h1>
                <a href="https://www.tataaig.com/motor-insurance/car-insurance"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    Open Account!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/tatacar1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about TATA AIG Car Insurance</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> TATA AIG Car Insurance excels in providing comprehensive coverage options tailored to the diverse needs of car owners. Offering features like 24x7 roadside assistance, engine protection cover, and coverage for natural disasters, they ensure comprehensive protection for policyholders.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">With a wide network of partnered garages facilitating cashless claims, TATA AIG emphasizes superior customer service, striving to deliver prompt assistance and efficient claim settlements, thereby earning the trust and loyalty of customers nationwide.</p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/garage.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            6900+ Network Garages

            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            99% Claim Settlement Ratio

            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/customer.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            5 Cr + Customers Served since Inception



            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/policy.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            1 Cr + Policies Issued last year



            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/add.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Up to 17 add-ons to protect your four-wheeler

            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/discount.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Up to 50% discount on car insurance premiums

            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>

<div className="w-full flex flex-col items-center  mb-10">
      <div className="w-[90%] text-center mb-10">
        <h2 className="font-bold text-4xl mb-4">TATA AIG car insurance plan add-on benefits</h2>
        <p className="text-gray-700 mb-4">
          Choose from an assorted range of add-ons, for an additional premium, when buying TATA AIG car insurance online.
        </p>
      </div>
      <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            {/* <img src='../assets/${benefit.icon}' alt={benefit.title} className="mb-4" /> */}
            <h3 className=" text-gray-600 text-2xl mb-2">{benefit.title}</h3>
            {/* <p className="text-gray-700">{benefit.description}</p> */}
          </div>
        ))}
      </div>
    </div>
    <div className="container mx-auto p-4">
      <div className="overflow-x-auto">
      <h2 className="font-bold text-4xl mb-6 text-center ">Comprehensive car insurance vs Third party car insurance</h2> 
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-4 py-2 bg-gray-200 border-b text-left">Comprehensive Car Insurance</th>
              <th className="px-4 py-2 bg-gray-200 border-b text-left">Third-Party Car Insurance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">Covers damages to your own car and third-party liabilities</td>
              <td className="border px-4 py-2">Covers third-party liabilities only</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="border px-4 py-2">Provides complete protection for your car</td>
              <td className="border px-4 py-2">Only covers legal liabilities</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">May include add-ons like zero depreciation, roadside assistance, etc.</td>
              <td className="border px-4 py-2">Only includes mandatory third-party coverage as per law</td>
            </tr>
            <tr className="bg-gray-50">
              <td className="border px-4 py-2">Higher premium as compared to third-party insurance</td>
              <td className="border px-4 py-2">Lower premium as compared to comprehensive insurance</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">Can be purchased online or offline</td>
              <td className="border px-4 py-2">Mandatorily needs to be purchased before registering a car</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
            
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* <div class="container mx-auto px-4 sm:px-6 lg:px-8">
  <div class="flex flex-wrap justify-between -mx-2">
  
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="https://via.placeholder.com/300"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Heading</h2>
          <p class="text-gray-700 mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat.
          </p>
          
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Button
          </button>
        </div>
      </div>
    </div>

    
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="https://via.placeholder.com/300"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Heading</h2>
          <p class="text-gray-700 mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat.
          </p>
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Button
          </button>
        </div>
      </div>
    </div>

   
    <div class="max-w-md w-full md:w-1/3 px-2 mb-4">
      <div class="bg-white shadow-md rounded-md overflow-hidden">
        <img
          class="w-full h-56 object-cover object-center"
          src="https://via.placeholder.com/300"
          alt="Placeholder"
        />
        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">Heading</h2>
          <p class="text-gray-700 mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            commodo consequat.
          </p>
          <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Button
          </button>
        </div>
      </div>
    </div>
  </div>
</div> */}

        </>
    )
}

export default TataAijLearnMore
