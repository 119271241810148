import React, { useEffect, useState } from 'react';
import { Blog_Api } from '../../Helper';
import BlogCard from './BlogCard';
import Pagination from './Pagination';
import PageCategory from './PageCategory';
import SideBar from './SideBar';


const BlogPageNew = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12; // Number of blogs per page
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    async function fetchBlogs() {
      let url = Blog_Api;

      if (currentPage || selectedCategory) {
        url = `https://test.thefinmaster.com/console/react-app-directory/test-blog-api.php?page=${currentPage}&limit=${pageSize}`;
        if (selectedCategory) {
          url += `&category=${selectedCategory}`;
        }
      }

      try {
        const response = await fetch(url);
        const data = await response.json();
        const transformedData = data.blogs.map((blog, index) => ({
          ...blog,
          id: `${blog.content_url}-${index}`  // Modify the ID here
          // Modify the ID here
        }));
        setBlogs(transformedData);

        // setBlogs(data.blogs || data); // Adjust depending on your API response structure
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchBlogs();
  }, [currentPage, pageSize, selectedCategory]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
    setActiveCategory(category);
  };

  return (
    <div>
      {/* Category Section */}
      <div>
        <PageCategory onSelectCategory={handleCategoryChange} selectedCategory={selectedCategory} 
       activeCategory={activeCategory} />
      </div>

      {/* Blog Card Section */}
      <div className='flex flex-col lg:flex-row gap-12'>
        <BlogCard blogs={blogs} currentPage={currentPage} selectedCategory={selectedCategory} pageSize={pageSize} />
{/*  Sidebar */}
<div>
    <SideBar />
</div>

      </div>

      {/* Pagination Section */}
      <div>
        <Pagination onPageChange={handlePageChange} currentPage={currentPage} blogs={blogs} pageSize={pageSize}/>
      </div>
    </div>
  );
};

export default BlogPageNew;


// import React, { useEffect, useState } from 'react';
// import { Blog_Api } from '../../Helper';
// import BlogCard from './BlogCard';

// const BlogPageNew = () => {
//   const [blogs, setBlogs] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const pageSize = 12; // Number of blogs per page
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [activeCategory, setActiveCategory] = useState(null);

//   useEffect(() => {
//     async function fetchBlogs() {
//       // Use this block if you want to use the Blog_Api constant
//       try {
//         const response = await fetch(Blog_Api);
//         const data = await response.json();
//         setBlogs(data.blogs || data); // Adjust depending on your API response structure
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }

//       // Use this block if you want to use the constructed URL
//       // let url = `https://test.thefinmaster.com/console/react-app-directory/test-blog-api.php?page=${currentPage}&limit=${pageSize}`;
//       // if (selectedCategory) {
//       //   url += `&category=${selectedCategory}`;
//       // }
//       // try {
//       //   const response = await fetch(url);
//       //   const data = await response.json();
//       //   setBlogs(data);
//       // } catch (error) {
//       //   console.error('Error fetching data:', error);
//       // }
//     }
//     fetchBlogs();
//   }, [currentPage, pageSize, selectedCategory]);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const handleCategoryChange = (category) => {
//     setSelectedCategory(category);
//     setCurrentPage(1);
//     setActiveCategory(category);
//   };

//   return (
//     <div>
//       {/* Category Section */}
//       <div>Page Category</div>

//       {/* Blog Card Section */}
//       <div>
//         <BlogCard blogs={blogs} currentPage={currentPage} selectedCategory={selectedCategory} pageSize={pageSize} />
//       </div>

//       {/* Pagination Section */}
//       <div>Pagination</div>
//     </div>
//   );
// };

// export default BlogPageNew;
