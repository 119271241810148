import React from 'react'
import AccordionComponent from '../accordion/AccordionComponent'
import { FiArrowRight } from 'react-icons/fi';
import { motion } from "framer-motion";
const DematLearnMore = () => {
  return (
    <div className='w-full flex flex-col items-center'>

      {/* <div className='w-full h-screen flex justify-center'>
        <div className='w-[95%] flex justify-center items-center'>
          <div className='w-[40%]'>
            <h1 className='text-6xl font-medium text-blue-600'>Open a freeDemat Account</h1>
            <p className='py-5 text-xl text-gray-600'>Zero maintenance charge, trusted by 40M+ Indians.</p>
            <button className='border rounded-full px-8 py-4 bg-blue-600 text-white'>Get Started</button>
          </div>
          <div className='w-[60%]'>
            <img className='w-[100%] h-[100%] object-cover' src='/growth.jpg' alt='demat growth' />
          </div>
        </div>
      </div> */}
      <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-6xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Open a free Demat Account</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>Zero maintenance charge, trusted by 40M+ Indians.</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='/growth.jpg' alt='demat growth' />
          </div>
        </div>
      </div>

      <div className="bg-gray-200 rounded-lg p-4 md:p-8 lg:p-12 mb-6">
        <p className="text-lg md:text-xl lg:text-1xl">
          This is a responsive div with a paragraph inside.A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.
        </p>

      </div>
      <div className="relative px-6 py-6">
        <img 
        src= "https://marketplace.canva.com/EAENvp21inc/1/0/1600w/canva-simple-work-linkedin-banner-qt_TMRJF4m0.jpg" // Replace "banner-image.jpg" with your actual image URL
          alt= "Banner Image"
          className= "object-cover w-full h-64 md:h-80 lg:h-96"
        />
      </div>
      <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
      <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
          Key Highlights
        </h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {/* Key highlight items go here */}
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 1</h3>
            <p className="text-gray-700">
              Description of Highlight 1 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 2</h3>
            <p className="text-gray-700">
              Description of Highlight 2 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </div>
    {/* <div class="container mx-auto px-3">
  <h2 class="font-bold text-4xl md:pl-0 pl-3 md:mt-0 mt-5 text-center">Why open a <span class="text-blue-600">Demat</span> account with Us</h2>
  <div class="w-full text-white flex justify-center mt-10">

    <div class="w-full md:w-1/2 bg-neutral-800 flex flex-col justify-center items-center md:h-[450px] md:mr-2 mb-4 md:mb-0">
      <h2 class="text-3xl font-medium border-b-2 pb-5">Zero maintenance fee</h2>
      <p class="text-center w-[80%] md:w-full pt-5 text-lg">A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
    </div>
    <div class="w-full md:w-1/2 bg-blue-600 flex flex-col justify-center items-center md:h-[450px] md:ml-2">
      <h2 class="text-3xl font-medium border-b-2 pb-5">Zero maintenance fee</h2>
      <p class="text-center w-[80%] md:w-full pt-5 text-lg">A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
    </div>
  </div>
</div> */}


<div class="container mx-auto px-4">
  <h2 class="font-bold text-3xl md:text-4xl lg:text-4xl text-center mt-10 md:mt-20">Why open a <span class="text-blue-600">Demat</span> account with Us</h2>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10 md:mt-20">
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">User-Friendly Interface</h3>
      <p class="text-base md:text-lg text-center">Our platform provides a seamless and intuitive user experience, making it easy for you to manage your investments efficiently.</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Secure Transactions</h3>
      <p class="text-base md:text-lg text-center">Rest assured that your transactions are secure with our advanced encryption technology. Your financial data is always protected.</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Diverse Investment Options</h3>
      <p class="text-base md:text-lg text-center">Explore a wide range of investment options including stocks, mutual funds, bonds, and more. Diversify your portfolio effortlessly.</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">24/7 Customer Support</h3>
      <p class="text-base md:text-lg text-center">Need assistance? Our dedicated support team is available round the clock to address your queries and concerns. We're here to help.</p>
    </div>
  </div>
</div>



      {/* <h2 className='font-semibold text-5xl'>Why open a <span className='text-blue-600'>Demat</span> account with Us</h2>
        
        <div className='w-full text-white flex justify-center mt-10'>
          <div className='w-full bg-black flex flex-col justify-center items-center h-[450px]'>
              <h2 className='text-4xl font-medium border-b-2 pb-5'>Zero maintenance fee</h2>
              <p className='text-center w-[80%] pt-5 text-lg'>A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
          </div>
          <div className='w-full bg-blue-600 flex flex-col justify-center items-center h-[450px]'>
              <h2 className='text-4xl font-medium border-b-2 pb-5'>Zero maintenance fee</h2>
              <p className='text-center w-[80%] pt-5 text-lg'>A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
          </div>
        </div> */}
      <div className="flex flex-col w-[95%] h-auto mt-6">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-center text-sm font-light">
                <thead
                  className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
                  <tr>
                    <th scope="col" className=" px-6 py-4">#</th>
                    <th scope="col" className=" px-6 py-4">Fee Type</th>

                    <th scope="col" className=" px-6 py-4">Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">1</td>
                    <td className="whitespace-nowrap  px-6 py-4">Delivery Charges</td>

                    <td className="whitespace-nowrap  px-6 py-4">Rs 0 (no brokerage)</td>
                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">2</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Intraday Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4">Rs 20 per executed order or 0.03% (whichever is lower) on both sides.</td>

                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">2</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Option Changes</td>
                    <td className="whitespace-nowrap  px-6 py-4">Rs 20 per executed order.</td>

                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">2</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Future Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4">Rs 20 per executed order or 0.03% (whichever is lower)</td>

                  </tr>
                  {/* <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap  px-6 py-4 font-medium">3</td>
                  <td colSpan={2} className="whitespace-nowrap  px-6 py-4">
                    Larry the Bird
                  </td>
                  <td className="whitespace-nowrap  px-6 py-4">@twitter</td>
                </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className='w-[95%] mt-5 md:mt-20'>
        <h2 className='font-bold text-3xl md:text-4xl text-center'>How to open a <span className='text-blue-600'>Demat</span> account with Us</h2>
        <p className='text-base md:text-lg text-blue-600 py-2 md:py-3 text-center md:text-left'>3 easy steps. Get started now!</p>

        <div className='w-full flex flex-col md:flex-row gap-5 md:gap-20 mt-3 md:mt-5'>
          <div className='w-full md:w-[30%] pl-2 md:pl-5'>
            <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>1</p>
            <div className='flex items-center justify-between mt-3 md:mt-5'>
              <div>
                <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Personal & Bank Details</p>
              </div>
              <div>
                <FiArrowRight className='text-2xl md:text-3xl animate-pulse' />
              </div>
            </div>
            <p className='text-sm md:text-lg py-2 w-full md:w-[70%]'>Choose a Depository Participant (DP): Select a DP, like a bank or stockbroker, to open your Demat account.</p>
          </div>
          <div className='w-full md:w-[30%] pl-2 md:pl-5'>
            <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>2</p>
            <div className='flex items-center justify-between mt-3 md:mt-5'>
              <div>
                <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Personal & Bank Details</p>
              </div>
              <div>
                <FiArrowRight className='text-2xl md:text-3xl animate-pulse' />
              </div>
            </div>
            <p className='text-sm md:text-lg py-2 w-full md:w-[70%]'>Choose a Depository Participant (DP): Select a DP, like a bank or stockbroker, to open your Demat account.</p>
          </div>
          <div className='w-full md:w-[30%] pl-2 md:pl-5'>
            <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>3</p>
            <div className='flex items-center justify-between mt-3 md:mt-5'>
              <div>
                <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Personal & Bank Details</p>
              </div>
              <div>
                {/* <FiArrowRight className='text-4xl'/> */}
              </div>
            </div>
            <p className='text-sm md:text-lg py-2 w-full md:w-[70%]'>Choose a Depository Participant (DP): Select a DP, like a bank or stockbroker, to open your Demat account.</p>
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-4 sm:mb-6 lg:mb-8">
          Heading
        </h2>
        <p className="text-base sm:text-lg lg:text-xl text-gray-700 leading-relaxed text-center">
          This is a paragraph. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat.
        </p>
      </div>
      
     
  
      <div className='w-[95%] py-10'>
        <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
        <AccordionComponent />
      </div>

    </div>
  )
}

export default DematLearnMore
