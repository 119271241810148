import React, { useState } from 'react'
import AccordionComponent from '../accordion/AccordionComponent'
import { FiArrowRight } from 'react-icons/fi';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

  function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
  }
  
  const Demat = () => {
    const [activeIndex, setActiveIndex] = useState(0);
  
    const faqs = [
        {
            question: ' What is a demat account?',
            answer:
                'A demat account (short for dematerialized account) is an electronic system used to hold and manage securities like stocks, bonds, mutual funds, and exchange-traded funds (ETFs). Instead of physical share certificates, all holdings are stored electronically, allowing for seamless transactions and safe record-keeping.',
        },
        {
            question: 'Why do I need a demat account?',
            answer:
                'A demat account is essential for buying, selling, or holding securities in electronic form. It offers convenience, security, and ease of managing your investment portfolio. It is a mandatory requirement for trading in the stock market, as it eliminates the need for physical share certificates and reduces the risk of loss or damage.',
        },
        {
            question: '   How do I open a demat account?',
            answer:
                ' To open a demat account, you need to choose a registered depository participant (DP), such as a bank or brokerage firm, and complete the account opening process. This typically involves submitting identity proof, address proof, a PAN card, and filling out the required forms. Once your documents are verified, you will receive your demat account details.',
        },
        {
            question: 'What are the charges associated with a demat account?',
            answer:
                '  The charges for a demat account can include account opening fees, annual maintenance charges (AMC), transaction fees, and additional costs for services like dematerialization or rematerialization. It is important to understand these charges and compare them across different providers to find the best fit for your needs.',
        },
        {
          question: 'Can I transfer securities between demat accounts?',
          answer:
              'Yes, you can transfer securities between demat accounts, either within the same depository or between different depositories. This process requires specific forms and may incur certain fees. Ensure you understand the procedures and costs involved before initiating a transfer.',
      },
      {
        question: 'What happens to my demat account if I stop trading?',
        answer:
            ' If you stop trading, your demat account remains active, but you might still incur annual maintenance charges (AMC) and other fees. You can choose to close your demat account if you no longer need it, but be sure to transfer or sell all your securities beforehand. Follow your DPs process for closing the account to avoid additional charges.',
    },
        
    ];
  
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
  return (
    <>
    <Helmet>
      <title>What is Demat Account - Meaning, Features, Types, Benefits</title>
      <meta  name='description' content='A demat account helps investors to hold their shares and securities in an electronic format. Know its meaning,types, features, benefits, and more on thefinmaster.'/>
      <meta name='keywords' content=' what is demat and trading account, what is demat account meaning' />
      <link rel="canonical" href="https://thefinmaster.com/demat" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <nav className="text-gray-500 text-sm my-2 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Demat</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
    <div className='w-full flex flex-col items-center'>    
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Open a free Demat Account
                  </span>{" "}
                  Invest Smarter, Manage Easier.
                </h1>
                {/* <a href="#insurance_plans">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    See Plans
                  </button>
                </a> */}
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/demat1.webp'
                alt="demat"
              />
            </div>
          </div>
       </div>

       
       
      {/* <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-5xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Open a free Demat Account</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>Invest Smarter, Manage Easier.</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='../assets/demat.jpeg' alt='demat growth' />
          </div>
        </div>
      </div> */}

      <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 flex justify-center items-center">
    <p className="text-lg md:text-base lg:text-lg text-center">
    A Demat account isn't just for seasoned investors. Whether you're a beginner or a seasoned pro, a Demat account opens doors to a vast array of investment options. Explore equities, mutual funds, Exchange Traded Funds (ETFs), and more, all within a single platform. TheFinMaster.com helps you to make informed decisions. Get started today and unlock the potential of your Demat account !
    </p>
    </div>
      {/* <div className="bg-gray-100 rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10">
  <p className="text-lg md:text-xl lg:text-1xl">
    This is a responsive div with a paragraph inside.A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.
  </p>
</div> */}
      <div className="flex justify-center px-4 sm:px-6 lg:px-8 mt-6">
  <div className="relative container mx-auto rounded-lg shadow-xl bg-white mt-4 border border-gray-200 hover:border-gray-300 transition duration-300">
    {/* Buttons */}
    <div className="absolute top-0 right-0 mt-4 mr-4 flex gap-4 mb-4">
    <a 
          href="https://upstox.com/open-demat-account/"
          target="_blank" 
          rel="noopener noreferrer"
        >
      
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Apply Now
        </button>
      </a>
      <Link to="/demat/upstox-demat-account">
        <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
          Learn More
        </button>
      </Link>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-16">
      {/* Content Blocks */}
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <div className="flex items-center justify-center">
          <img src="../assets/upstox.webp" alt="Image" className="w-full max-w-xs mx-auto" />
        </div>
        <div className="flex items-center justify-center mt-4">
          {/* Rating */}
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="ml-1 text-gray-500">(3)</span>
        </div>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Account Opening Charges</h2>
        <p className="text-gray-700">Free</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">  Annual maintenance charges</h2>
        <p className="text-gray-700">Zero charges 0</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Brokerage</h2>
        <p className="text-gray-700">No brokerage for equity delivery Equity intraday......</p>
      </div>
    </div>
  </div>
</div>
<div className="flex justify-center px-4 sm:px-6 lg:px-8 mt-6">
  <div className="relative container mx-auto rounded-lg shadow-xl bg-white mt-4 border border-gray-200 hover:border-gray-300 transition duration-300">
    {/* Buttons */}
    <div className="absolute top-0 right-0 mt-4 mr-4 flex gap-4 mb-4">
    <a 
          href="https://www.angelone.in/open-demat-account"
          target="_blank" 
          rel="noopener noreferrer"
        >
    
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Apply Now
        </button>
      </a>
      <Link to="/demat/angel-one-demat-account">
        <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
          Learn More
        </button>
      </Link>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-16">
      {/* Content Blocks */}
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <div className="flex items-center justify-center">
          <img src="../assets/angel.webp" alt="Image" className="w-full max-w-xs mx-auto" />
        </div>
        <div className="flex items-center justify-center mt-4">
          {/* Rating */}
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="ml-1 text-gray-500">(3)</span>
        </div>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Account Opening Charges</h2>
        <p className="text-gray-700">Free</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2"> Annual maintenance charges</h2>
        <p className="text-gray-700">Rs 0 for first year</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Brokerage</h2>
        <p className="text-gray-700">No brokerage for equity delivery Equity intraday......</p>
      </div>
    </div>
  </div>
</div>
{/* <div className="flex justify-center px-4 sm:px-6 lg:px-8 mt-6">
  <div className="relative container mx-auto rounded-lg shadow-xl bg-white mt-4 border border-gray-200 hover:border-gray-300 transition duration-300">

    <div className="absolute top-0 right-0 mt-4 mr-4 flex gap-4 mb-4">
      <Link to="/apply_now">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Apply Now
        </button>
      </Link>
      <Link to="/hdfcdemat_detail">
        <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
          Learn More
        </button>
      </Link>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-16">
      
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <div className="flex items-center justify-center">
          <img src="../assets/hdfc.jpeg" alt="Image" className="w-full max-w-xs mx-auto" />
        </div>
        <div className="flex items-center justify-center mt-4">
        
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="ml-1 text-gray-500">(3)</span>
        </div>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Account Opening Charges</h2>
        <p className="text-gray-700">Free</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Rs 0 for first year.</h2>
        <p className="text-gray-700">INR 300</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Brokerage</h2>
        <p className="text-gray-700">No brokerage for equity delivery Equity intraday......</p>
      </div>
    </div>
  </div>
</div> */}
<div className="flex justify-center px-4 sm:px-6 lg:px-8 mt-6">
  <div className="relative container mx-auto rounded-lg shadow-xl bg-white mt-4 border border-gray-200 hover:border-gray-300 transition duration-300">
    {/* Buttons */}
    <div className="absolute top-0 right-0 mt-4 mr-4 flex gap-4 mb-4">
    <a 
          href="https://groww.in/open-demat-account"
          target="_blank" 
          rel="noopener noreferrer"
        >

     
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Apply Now
        </button>
      </a>
      <Link to="/demat/groww-demat-account">
        <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
          Learn More
        </button>
      </Link>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-16">
      {/* Content Blocks */}
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <div className="flex items-center justify-center">
          <img src="../assets/grow.webp" alt="Image" className="w-full max-w-xs mx-auto" />
        </div>
        <div className="flex items-center justify-center mt-4">
          {/* Rating */}
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="ml-1 text-gray-500">(3)</span>
        </div>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Account Opening Charges</h2>
        <p className="text-gray-700">Free</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">  Annual maintenance charges</h2>
        <p className="text-gray-700">Zero charges</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Brokerage</h2>
        <p className="text-gray-700">No brokerage for equity delivery Equity intraday......</p>
      </div>
    </div>
  </div>
</div>
<div className="flex justify-center px-4 sm:px-6 lg:px-8 mt-6">
  <div className="relative container mx-auto rounded-lg shadow-xl bg-white mt-4 border border-gray-200 hover:border-gray-300 transition duration-300">
    {/* Buttons */}
    <div className="absolute top-0 right-0 mt-4 mr-4 flex gap-4 mb-4">
    <a 
          href="https://signup.fyers.in/"
          target="_blank" 
          rel="noopener noreferrer"
        >
  
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Apply Now
        </button>
      </a>
      <Link to="/demat/fyers-demat-account">
        <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
          Learn More
        </button>
      </Link>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-16">
      {/* Content Blocks */}
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <div className="flex items-center justify-center">
          <img src="../assets/fyers.webp" alt="Image" className="w-full max-w-xs mx-auto" />
        </div>
        <div className="flex items-center justify-center mt-4">
          {/* Rating */}
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="ml-1 text-gray-500">(3)</span>
        </div>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Account Opening Charges</h2>
        <p className="text-gray-700">Free</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2"> Annual maintenance charges</h2>
        <p className="text-gray-700">Zero charges</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Brokerage</h2>
        <p className="text-gray-700">No brokerage for equity delivery Equity intraday......</p>
      </div>
    </div>
  </div>
</div>
<div className="flex justify-center px-4 sm:px-6 lg:px-8 mt-6">
  <div className="relative container mx-auto rounded-lg shadow-xl bg-white mt-4 border border-gray-200 hover:border-gray-300 transition duration-300">
    {/* Buttons */}
    <div className="absolute top-0 right-0 mt-4 mr-4 flex gap-4 mb-4">
    <a 
          href="https://zerodha.com/open-account/"
          target="_blank" 
          rel="noopener noreferrer"
        >
      
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Apply Now
        </button>
      </a>
      <Link to="/demat/zeroda-demat-account">
        <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
          Learn More
        </button>
      </Link>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-16">
      {/* Content Blocks */}
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <div className="flex items-center justify-center">
          <img src="../assets/zerodha.webp" alt="Image" className="w-full max-w-xs mx-auto" />
        </div>
        <div className="flex items-center justify-center mt-4">
          {/* Rating */}
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="ml-1 text-gray-500">(3)</span>
        </div>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Account Opening Charges</h2>
        <p className="text-gray-700">Free</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">  Annual maintenance charges</h2>
        <p className="text-gray-700">Zero charges</p>
      </div>
      <div className="p-6 bg-white hover:shadow-md transition duration-300">
        <h2 className="text-lg font-bold mb-2">Brokerage</h2>
        <p className="text-gray-700">No brokerage for equity delivery Equity intraday......</p>
      </div>
    </div>
    
  </div>
</div>

      {/* <div className="flex justify-center px-20">
  <div className="container mx-auto rounded-lg shadow-xl bg-white mt-4 ">
    
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-5">
      
    
      <div className="p-6 bg-white">
        <div className="flex items-center justify-center">
          <img src="https://iconape.com/wp-content/files/ir/345103/svg/345103.svg" alt="Image" className="w-full max-w-xs mx-auto" />
        </div>
        <div className="flex items-center justify-center mt-4">
       
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-yellow-500">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="text-gray-400">&#9733;</span>
          <span className="ml-1 text-gray-500">(3)</span>
        </div>
      </div>
      <div className="p-6 bg-white">
        <h2 className="text-lg font-bold mb-2">Account Opening Charges</h2>
        <p className="text-gray-700">INR 200</p>
      </div>
      <div className="p-6 bg-white">
        <h2 className="text-lg font-bold mb-2">Annual Maintenance Charges</h2>
        <p className="text-gray-700">INR 300</p>
      </div>
      <div className="p-6 bg-white">
        <h2 className="text-lg font-bold mb-2">Brokerage</h2>
        <p className="text-gray-700">No brokerage for equity delivery Equity intraday......</p>
      </div>
      
    </div>


    <div className="flex  mt-6 ml-6  mb-6">
      <Link to="/demat_learn_more">
        <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600">
          Learn More
        </button>
      </Link>
    </div>
  </div>
</div> */}

      {/* <div className="container mx-auto rounded-lg shadow-xl bg-white mt-4 px-10">
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-5">
   
    <div className="p-6 bg-white ">
      <div className="flex items-center justify-center">
        <img src="https://iconape.com/wp-content/files/ir/345103/svg/345103.svg" alt="Image" className="w-full max-w-xs mx-auto" />
      </div>
      <div className="flex items-center justify-center mt-4">
       
        <span className="text-yellow-500">&#9733;</span>
        <span className="text-yellow-500">&#9733;</span>
        <span className="text-yellow-500">&#9733;</span>
        <span className="text-gray-400">&#9733;</span>
        <span className="text-gray-400">&#9733;</span>
        <span className="ml-1 text-gray-500">(3)</span>
      </div>
    </div>
    
    <div className="p-6 bg-white ">
      <h2 className="text-lg font-bold mb-2">Account Opening Charges</h2>
      <p className="text-gray-700">INR 200</p>
    </div>
   
    <div className="p-6 bg-white ">
      <h2 className="text-lg font-bold mb-2">Annual Maintenance Charges</h2>
      <p className="text-gray-700">INR 300</p>
    </div>
       
    <div className="p-6 bg-white ">
      <h2 className="text-lg font-bold mb-2">Brokerage</h2>
      <p className="text-gray-700">No brokerage for equity delivery Equity intraday......</p>
    </div>
  </div>

  <div className="flex  mt-6 mb-6 pl-4">
    <Link to="/demat_learn_more">
      <button className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600">
        Learn More
      </button>
    </Link>
  </div>
</div> */}

      

    </div>
    {/* FAQ section */}
    <div className="max-w-4xl mx-auto py-6 mt-10">
                <h1 className="text-center text-2xl md:text-2xl lg:text-5xl font-bold mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
    </>
  )
}

export default Demat
