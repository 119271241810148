import React, { useState } from 'react';
import InsuranceCard from "./InsuranceCard";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const LifeInsurance = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const faqs = [
        {
            question: ' What is life insurance?',
            answer:
                'Life insurance is a financial tool that provides a death benefit to your designated beneficiaries (usually loved ones) in case of your passing. This benefit can help them cover expenses like funeral costs, outstanding debts, or maintain their financial well-being.',
        },
        {
            question: 'What factors should I consider when choosing a life insurance policy?',
            answer:
                ' Consider factors like the type of coverage (term vs. whole life), coverage amount, premium cost, beneficiary designation, and any riders (optional add-ons) that may be beneficial, such as disability income riders or waiver of premium riders.',
        },
        {
            question: 'Why is life insurance important?',
            answer:
                'Life insurance is important because it provides financial security to your loved ones in the event of your death. It can help cover funeral expenses, pay off debts, replace lost income, and ensure that your family financial needs are met. Life insurance offers peace of mind, knowing that those who depend on you will be supported even when you are no longer there to provide for them.',
        },
        {
            question: 'Can I buy insurance through TheFinMaster.com?',
            answer:
                'TheFinMaster.com is not an insurance provider.  We offer educational resources, comparison tools, and unbiased information to help you understand different insurance types and choose the right coverage for your needs.  Once you have identified your needs, you will be directed to the issuers website to complete a secure application process.',
        },
    ];

    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };

    return (
        <>
         <Helmet>
      <title>Life Insurance: Best Life Insurance Policy in India 2024</title>
      <meta  name='description' content='Life Insurance - Compare and Buy best life insurance plans online from leading life insurance companies in India. Choose affordable life insurance policy and save money on premium and save tax.'/>
      <meta name='keywords' content='best life insurance policy, cash value life insurance, life insurance plans, joint life insurance' />
      <link rel="canonical" href="https://thefinmaster.com/life-Insurance" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 "> LifeInsurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
            {/* Your existing content */} 
            <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Secure Your Family's Financial Future
                  </span>{" "}
                  Explore Life Insurance Now!
                </h1>
                <a href="#insurance_plans">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    See Plans
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/life.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full h-full md:h-screen flex justify-center'>
            <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-5xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Secure Your Family's 
Financial Future</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>Explore Life Insurance Now!</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='../assets/life.jpeg' alt='Noimage' />
          </div>
        </div>
      </div>
            </div> */}

            <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 flex justify-center items-center">
    <p className="text-lg md:text-base lg:text-lg text-center">
    Life insurance decisions are crucial, and TheFinMaster.com is here to help you navigate the process with clarity. Compare coverage details, understand policy benefits, and get free quotes – all conveniently located online.  Let us help you find the perfect life insurance plan to provide peace of mind, knowing your loved ones will be financially secure.    </p>
    </div>
            {/* <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of AU Bank LIT Credit Card</h1>
                    <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">The AU Small Finance Bank LIT Credit Card is India's pioneering customizable credit card, offering the unique advantage of selecting and paying for desired features exclusively. With a wide array of benefits spanning lifestyle, groceries, travel, and beyond, this card provides unparalleled flexibility and convenience.</p>
                    <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">It provides a comprehensive range of credit card benefits and allows holders to select one or multiple features/categories simultaneously. This empowers customers to enjoy benefits from their chosen category while only paying for the features they use, rather than the entire annual card fees.</p>
                </div> */}
                 {/* <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 flex justify-center items-center">
    <p className="text-lg md:text-base lg:text-lg text-center">
    Choose from a variety of coverage options to tailor your car insurance plan to your specific needs. Protect yourself from third-party liabilities if you cause an accident, or safeguard your own vehicle against damage from collisions, theft, or natural disasters. Compare plans, understand coverage details, and get free quotes – all in one place. We'll help you find the perfect car insurance plan to ensure a smooth ride ahead    </p>
    </div> */}


    <div>

    <div className=' lg:px-48'>
    <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/icicilife.webp'} />
                        <h3 className=' ml-7 text-gray-600 '>iProtect Smart</h3>
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
Life Cover*</p>
                <h4 className='text-2xl py-0.5 font-bold'> ₹1cr</h4>
                 <p className='text-gray-400 text-xs'>Max Limit 99 yrs*</p> 
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settled* </p>
                <h4 className='text-2xl py-0.5 font-bold'>99.17%</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Price*</p>
                <h4 className='text-2xl py-0.5 font-bold'>  ₹633/month</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
         
                     
             <a href="https://www.iciciprulife.com/"
                 target="_blank" 
                 rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </a>
                <Link to="/life-insurance/icici-life-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
               
               
            </div>
        </div>
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4 '>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/hdfclife.webp'} />
                        <h3 className=' ml-7 text-gray-600 '>Click to Protect Super</h3>
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
Life Cover*</p>
                <h4 className='text-2xl py-0.5 font-bold'> ₹1cr</h4>
                 <p className='text-gray-400 text-xs'>Max Limit 85 yrs*</p> 
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settled* </p>
                <h4 className='text-2xl py-0.5 font-bold'>99.5%</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Price*</p>
                <h4 className='text-2xl py-0.5 font-bold'>  ₹749/month</h4>
            </div>
            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
               
                <a href="https://www.hdfclife.com/"
                 target="_blank" 
                 rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </a>
                <Link to="/life-insurance/hdfc-life-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
            </div>
        </div>
               
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/maxlife.webp'} />
                        <h3 className=' ml-7 text-gray-600 '>Smart Secure Plus</h3>
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
Life Cover*</p>
                <h4 className='text-2xl py-0.5 font-bold'> ₹1cr</h4>
                 <p className='text-gray-400 text-xs'>Max Limit 85 yrs*</p> 
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settled* </p>
                <h4 className='text-2xl py-0.5 font-bold'>99.51%</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Price*</p>
                <h4 className='text-2xl py-0.5 font-bold'>  ₹662/month</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
              
                <a href="https://www.maxlifeinsurance.com/"
                 target="_blank" 
                 rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!  
        </button>
      </a>
                <Link to="/life-insurance/maxlife-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
            </div>
        </div>
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/adityalife.webp'} />
                        <h3 className=' ml-7 text-gray-600 '>DigiShield Plan</h3>
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
Life Cover*</p>
                <h4 className='text-2xl py-0.5 font-bold'> ₹1cr</h4>
                 <p className='text-gray-400 text-xs'>Max Limit 73 yrs*</p> 
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settled* </p>
                <h4 className='text-2xl py-0.5 font-bold'>98.4%</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Price*</p>
                <h4 className='text-2xl py-0.5 font-bold'>  ₹663/month</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
              
                  <a href="https://lifeinsurance.adityabirlacapital.com/"
                 target="_blank" 
                 rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </a>
                <Link to="/life-insurance/adityalife-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
            </div>
        </div>
        {/* <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="/card-details">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/futuregenral.jpeg'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>
Premium Starting </p>
                <h4 className='text-2xl py-0.5 font-bold'> @ ₹538</h4>
               
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settlement </p>
                <h4 className='text-2xl py-0.5 font-bold'>87.84%</h4>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Network Garages</p>
                <h4 className='text-2xl py-0.5 font-bold'>13000+</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                
                 <Link to="https://www.hdfclife.com/">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </Link>
                <Link to="/futurelife_insurance_learn_more">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
            </div>
        </div> */}
        </div>
                {/* <div
          id="insurance_plans"
          className="relative w-[95%] flex justify-between gap-5 mt-20"
        >
          <div className="w-full flex flex-col gap-10">
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/ICICI_logo.png"
            />
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/HDFC_Life_logo.png"
            />
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/MAX_logo.png"
            />
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/BAJAJ_logo.png"
            />
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/PNB_Metlife_logo.png"
            />
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/HSBC_logo.png"
            />
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/Birla_Sun_Life_logo.png"
            />
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/kotak_logo.png"
            />
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/TATA_AIA_logo.png"
            />
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/Edelweiss_Tokio_logo.png"
            />
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/SBI_logo.png"
            />
            <InsuranceCard
              cardName="ICICI Bank Coral"
              cardImage="https://static.pbcdn.in/term-cdn/images/images/insurer/Aegon_Life_logo.png"
            />
          </div>*/}
          {/* <div className="sticky top-10 md:w-[450px] border h-fit ml-6 mt-4">
            <img className="" src="/insurance-banner.jpg" alt="banner" />
          </div> */}
          </div>
            
            {/* FAQ section */}
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default LifeInsurance;
