// Layout.js

import React from 'react';
import { Helmet } from 'react-helmet';

const Layout = ({ children }) => {
    // console.log('Layout component rendered');
  return (
    <>
      <Helmet>
      <meta name="google-site-verification" content="YAEybaRuD-pOr9dw2scpS8Ed1NtsXCBFLHdIxVP_Ykk" />
     
      </Helmet>
      {children}
    </>
  );
};

export default Layout;
