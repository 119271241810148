import React, { useState } from 'react';
import InsuranceCard from "./InsuranceCard";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const HealthInsurance = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const faqs = [
        {
            question: 'What is health insurance?',
            answer:
                'Health insurance is a financial arrangement that helps cover medical expenses, such as doctor visits, hospital stays, prescription medications, and other healthcare costs. It provides a safety net, ensuring that you can afford necessary medical care when you need it.',
        },
        {
            question: 'What factors should I consider when choosing a health insurance policy?',
            answer:
                'When choosing a health insurance policy, consider factors like the coverage details, hospitals covered, monthly premiums and the network of healthcare providers. You should also consider the coverage for specific medical needs, such as prescriptions, specialist visits, or maternity care.',
        },
        {
            question: 'Why is health insurance important?',
            answer:
                ' Health insurance is important because it provides financial protection against high medical costs. It helps you access necessary healthcare services without worrying about the expense. Without health insurance, a serious illness or injury could lead to significant financial burdens, potentially causing debt or bankruptcy.',
        },
        
    ];

    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };

    return (
        <>
         <Helmet>
         <title>Health Insurance: Buy Best Health Insurance Plan Online in India May, 2024</title>
        <meta  name='description' content='Buy health insurance plan with Thefinmaster.com as we explore the benefits and importance, key features, inclusions as well as exclusions of a medical insurance plans.'/>
        <meta name='keywords' content='health insurance quotes, health care insurance, government health insurance, ' />
        <link rel="canonical" href="https://thefinmaster.com/health-insurance" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
      
          <li className="flex items-center">
          <span className="text-gray-500 ">Health Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav> 
            {/* Your existing content */} 
            <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Protect Your Health, 
                  </span>{" "}
                  Secure Your Future!
                </h1>
                <a href="#insurance_plans">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                    See Plans
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/health.webp'
                alt="car-loan"
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full h-full md:h-screen flex justify-center'>
            <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-5xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Protect Your Health,
Secure Your Future</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>Explore Life Insurance Now!</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='../assets/health.jpeg' alt='demat growth' />
          </div>
        </div>
      </div>
            </div> */}
            <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 flex justify-center items-center">
    <p className="text-lg md:text-base lg:text-lg text-center">
    TheFinMaster.com is here to guide you towards a secure and healthy future. We've partnered with leading health insurance providers  to offer you a wide range of plans to choose from.  Compare benefits, understand coverage details, and get free quotes – all in one convenient location. Find the perfect health insurance plan to protect your well-being, leaving the financial worries to us.
    </p>
        </div>
            {/* <div className="container mx-auto px-4 py-8">
                    <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of AU Bank LIT Credit Card</h1>
                    <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">The AU Small Finance Bank LIT Credit Card is India's pioneering customizable credit card, offering the unique advantage of selecting and paying for desired features exclusively. With a wide array of benefits spanning lifestyle, groceries, travel, and beyond, this card provides unparalleled flexibility and convenience.</p>
                    <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">It provides a comprehensive range of credit card benefits and allows holders to select one or multiple features/categories simultaneously. This empowers customers to enjoy benefits from their chosen category while only paying for the features they use, rather than the entire annual card fees.</p>
                </div> */}
                 <div className=' lg:px-48'>
               <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/reliancehealth.webp'} />
                       
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>Premium Starting* </p>
                <h4 className='text-2xl py-0.5 font-bold'>@₹355/month</h4>
                 
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settlement* </p>
                <h4 className='text-2xl py-0.5 font-bold'>98.70%</h4>
                <p className='text-gray-400 text-xs'>within 3 months</p>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Network Garages*</p>
                <h4 className='text-2xl py-0.5 font-bold'>10689</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
                <a href="https://www.reliancegeneral.co.in/insurance/health-insurance/health-insurance-plan.aspx"
                 target="_blank" 
                 rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </a>
                <Link to="/health-insurance/reliance-health-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
               
            </div>
        </div>
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4 '>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/digithealth.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>Premium Starting* </p>
                <h4 className='text-2xl py-0.5 font-bold'>@₹276/month</h4>
                 
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settlement* </p>
                <h4 className='text-2xl py-0.5 font-bold'>96.10%</h4>
                <p className='text-gray-400 text-xs'>within 3 months</p>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Network Garages*</p>
                <h4 className='text-2xl py-0.5 font-bold'>16810</h4>
            </div>


            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
               
                <a href="https://www.godigit.com/health-insurance"
                 target="_blank" 
                 rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </a>
                <Link to="/health-insurance/digit-health-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
            </div>
        </div>
               
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/carehealth.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>Premium Starting* </p>
                <h4 className='text-2xl py-0.5 font-bold'>@₹456/month</h4>
                 
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settlement* </p>
                <h4 className='text-2xl py-0.5 font-bold'>100%</h4>
                <p className='text-gray-400 text-xs'>within 3 months</p>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Network Garages*</p>
                <h4 className='text-2xl py-0.5 font-bold'>14960</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
           
               <a href="https://www.careinsurance.com/health-insurance/"
                 target="_blank" 
                 rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </a>
                <Link to="/health-insurance/care-health-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
            </div>
        </div>
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/adityalife.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>Premium Starting* </p>
                <h4 className='text-2xl py-0.5 font-bold'>@₹424/month</h4>
                 
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settlement* </p>
                <h4 className='text-2xl py-0.5 font-bold'>99.40%</h4>
                <p className='text-gray-400 text-xs'>within 3 months</p>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Network Garages*</p>
                <h4 className='text-2xl py-0.5 font-bold'>16680</h4>
            </div>


            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
                 <a href="https://www.adityabirlacapital.com/healthinsurance/homepage"
target="_blank" 
rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </a>
                <Link to="/health-insurance/aditya-health-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
            </div>
        </div>
        <div className='md:w-[900px] py-2 border shadow-lg overflow-hidden hover:shadow-xl rounded-lg flex flex-wrap md:flex-nowrap gap-5 justify-between ml-6 mt-4'>
            <div className='flex flex-wrap md:flex-nowrap w-full justify-center items-center md:justify-normal'>
                <Link to="">
                    <div className='w-[95%] md:w-[220px] overflow-hidden py-2 md:ml-2'>
                        <img className='w-[100%] h-[100%] object-contain' alt='' src={'../assets/hdfcargo.webp'} />
                    </div>
                </Link>
            </div>

            <div className='w-full md:pl-0 pl-4 mt-6'>
            <p className='text-gray-500 font-medium'>Premium Starting* </p>
                <h4 className='text-2xl py-0.5 font-bold'>@₹784/month</h4>
                 
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Claim Settlement* </p>
                <h4 className='text-2xl py-0.5 font-bold'>98.50%</h4>
                <p className='text-gray-400 text-xs'>within 3 months</p>
            </div>
            <div className='w-full md:pl-0 pl-4 mt-6'>
                <p className='text-gray-500 font-medium'>Network Garages*</p>
                <h4 className='text-2xl py-0.5 font-bold'>14747</h4>
            </div>

            <div className='flex flex-wrap justify-center md:justify-between items-center my-5 w-full gap-2'>
                {/* <Link to="/proceed-to-buy">
                    <button className='w-full md:w-auto md:hover:scale-105 md:duration-100 border rounded-lg bg-blue-500 text-white font-medium shadow-md px-4 py-2 md:py-1.5'>
                        View Plan
                    </button>
                </Link> */}
              
               <a href="https://www.hdfcergo.com/health-insurance"
target="_blank" 
rel="noopener noreferrer">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 transition duration-300">
          Get Quote!
        </button>
      </a>
                <Link to="/health-insurance/hdfc-ergo-health-insurance">
                    <button className='text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-300 bg-blue-500 '>
                        Learn More
                    </button>
                </Link>
               
            </div>
        </div>
        </div>
       

            {/* FAQ section */}
            <div className="max-w-4xl mx-auto py-6 mt-10">
                <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default HealthInsurance;
