import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const AdityaLifeLearn = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const faqs = [
    {
        question: 'What is the minimum eligibility criteria when applying for an Aditya Birla Sun Life Insurance policy?',
        answer:
            'To apply for an Aditya Birla Sun Life Insurance policy, the individual must meet the age criteria specified by the policy, be capable of making premium payments for the policy tenure, and fulfill any additional requirements outlined by the specific policy.',
    },
    {
        question: ' What is the procedure to renew my life insurance policy?',
        answer:
            'To renew your Aditya Birla Sun Life Insurance policy, you need to make the premium payment and inform the company via email or letter. Upon receipt, your policy will be renewed.',
    },
    {
        question: 'How do I cancel my Aditya Birla Sun Life Insurance policy online?',
        answer:
            'You can cancel your Aditya Birla Sun Life Insurance policy online by filling out a Surrender Form and ceasing all premium payments.',
    },
    {
        question: 'How do I generate the e-receipt for my Aditya Birla Sun Life Insurance policy?',
        answer:
            'You can generate the e-receipt by accessing the Premium Paid Certificate link on the companys homepage and providing the required details.',
    },
    {
      question: 'How do I contact Aditya Birla Sun Life Insurance customer service?',
      answer:
          'You can contact Aditya Birla Sun Life Insurance customer service via email, toll-free number (1-800-270-7000) from Monday to Friday, or by visiting a branch office.',
  },
  {
    question: 'To whom is the claim amount paid?',
    answer:
        'Claim amounts under Aditya Birla Sun Life policies are disbursed to the nominee, assignee, or appointee as specified.',
},
{
  question: 'How can a loan be taken against an Aditya Birla Sun Life policy?',
  answer:
      'Once the policy has acquired Surrender Value, you can take a loan against it. Loan amounts vary depending on the policy terms.',
},
{
  question: 'How is the NAV calculated for servicing requests?',
  answer:
      'NAV (Net Asset Value) is calculated at the time of valuation or purchase, typically on the same day as the transaction request.',
},
{
  question: 'What is a switch and redirection in terms of my ULIP, and how can I use these facilities?',
  answer:
      '- **Switch:** Moving existing units of a ULIP into a different fund without changing premium allocation. Initiate through the company website, submit a signed switch form, or call to request.- **Redirection:** Changing future premium allocation while transferring existing units to a new fund. Process similarly to a switch.',
},
{
  question: 'What documents are required to initiate a payout?',
  answer:
      'To initiate a payout, you need to submit a duly filled Payout/Cancellation form, original policy document (excluding withdrawals), ID proof, address proof, cancelled cheque, and bank passbook/statement.',
},
{
  question: 'Can riders be added to a policy that is in force?',
  answer:
      'Riders can be added after the first policy year.',
},
{
  question: 'How do I cancel my Aditya Birla Sun Life Insurance policy?',
  answer:
      'To cancel or surrender your policy, visit the nearest branch with the cancellation form, original policy documents, ID proof, updated contact details, and a cancelled cheque in the insured individuals name.',
},
{
  question: ' How do I pay my Aditya Birla Sun Life Insurance premium?',
  answer:
      'Premium payments can be made conveniently online through the official website. Select the Pay Premium Online option, follow the steps provided, validate your details, choose a payment method, and complete the transaction.',
},
  ];
    const benefits = [
      {
        title: 'Term Life Insurance ',
      },
      {
        title: ' Endowment Plans',
      },
      {
        title: 'Whole Life Insurance ',
      },
      {
        title: 'Unit Linked Insurance Plans (ULIPs)        ',
      },
        {
        title: 'Child Plans ',
      },
      {
        title: 'Money-Back Plan ',
      },
      {
        title: 'Retirement Plans ',
      },
    
   
    //   {
    //     title: 'Provides regular retirement income by investing a lump sum, with payouts from returns generated by the life insurance company.',
    //   },
      
      // {
      //   title: 'Tyre Secure Cover',
      // },
    ]
    const toggleAccordion = (index) => {
      if (activeIndex === index) {
          // If the clicked question is already expanded, collapse it
          setActiveIndex(null);
      } else {
          // If the clicked question is not expanded, expand it
          setActiveIndex(index);
      };
    };
    return (
        <>
         <Helmet>
      <title>Aditya Birla Sun Life Insurance: Policy Details, Premium &amp; Benefits | TheFinmaster</title>
      <meta  name='description' content='Aditya Birla Sun Life Insurance offers wide range of insurance products like term, life, pension, child plans etc. Check here various insurance plans and their features & benefits | TheFinmaster.'/>
      <meta name='keywords' content='aditya birla sun life insurance, aditya birla term insurance, aditya birla life insurance, birla sun life' />
      <link rel="canonical" href="https://thefinmaster.com/life-insurance/adityalife-insurance" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/life-insurance" className="text-blue-600 hover:text-blue-800">Life Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Aditya Life Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Aditya Birla Life Insurance: Secure your legacy, 
                  </span>{" "}
                  build a brighter future
                </h1>
                <a href="https://lifeinsurance.adityabirlacapital.com/"
                  target="_blank" 
                  rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                  Get Quote! 
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/aditiyalife1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about Aditya Birla Sun Life Insurance</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">  Aditya Birla Sun Life Insurance (ABSLI) is synonymous with reliability and long-term wealth creation, offering a wide range of term and investment plans designed to meet evolving financial goals. Their unit-linked insurance plans (ULIPs) provide investment opportunities in diverse fund options, reflecting their expertise in wealth management.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">ABSLI's commitment to customer-centric solutions is evident in their comprehensive product offerings, which cater to various needs and preferences. With a strong brand reputation and a focus on innovation, ABSLI continues to empower customers to achieve their financial aspirations with confidence and ease. </p>
            </div>
            <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/bonus.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Regular income for a fixed term post-maturity



            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/policy.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Flexible premium payment & policy term choices


            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/multiple.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Choice of multiple investment funds.


            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/process.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Simple online application process.





            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/multiple.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Multiple annuity options to suit individual needs.


            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            98.12% Claim Settlement Ratio





            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>

{/* <div className="w-full flex flex-col items-center  mb-10">
      <div className="w-[90%] text-center mb-10">
        <h2 className="font-bold text-4xl mb-4">Aditya Birla Sun Life Insurance  plan add-on benefits</h2>
        <p className="text-gray-700 mb-4">
          Choose from an assorted range of add-ons, for an additional premium, when buying Aditya Birla Sun Life Insurance  online.
        </p>
      </div>
      <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex flex-col items-center text-center">
           
            <h3 className=" text-gray-600 text-2xl mb-2">{benefit.title}</h3>
          
          </div>
        ))}
      </div>
    </div>

    <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Features and Benefits of AU Bank LIT Credit Card</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">The AU Small Finance Bank LIT Credit Card is India's pioneering customizable credit card, offering the unique advantage of selecting and paying for desired features exclusively. With a wide array of benefits spanning lifestyle, groceries, travel, and beyond, this card provides unparalleled flexibility and convenience.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">It provides a comprehensive range of credit card benefits and allows holders to select one or multiple features/categories simultaneously. This empowers customers to enjoy benefits from their chosen category while only paying for the features they use, rather than the entire annual card fees.</p>
            </div> */}
             <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Different Types of Life Insurance Offered by MAX Life:</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Term Life Insurance Plan: </span>Term insurance provides a fixed sum assured to beneficiaries in exchange for regular premiums, offering financial protection in case of the insured's demise during the policy term. It typically offers a higher coverage amount at lower premiums, making it an affordable option for securing your family's future. Additionally, some term plans may come with optional riders such as accidental death benefit or critical illness cover, providing enhanced coverage based on individual needs.
</p>
<p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Whole Life Insurance:</span> Whole Life Insurance offers lifelong coverage, providing financial security for the policyholder's entire life. Apart from the death benefit, it also accumulates cash value over time, which can be accessed through policy loans or withdrawals for various needs such as retirement planning, education expenses, or emergencies. Additionally, some policies offer the option to receive dividends, enhancing the policy's value and flexibility.</p>

<p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Endowment Plans:</span> Endowment plans merge life insurance and savings, offering dual benefits. Policyholders receive the assured sum in two scenarios: survival benefit upon policy term completion and death benefit to beneficiaries in case of untimely demise during the term. Aimed at building a financial corpus for future needs, these plans provide comprehensive financial security.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Unit Linked Insurance Plan (ULIP):</span> ULIPs integrate investment and insurance components, with a segment of the premium allocated to life coverage and the remainder invested across equity and debt funds. Linked to the capital market, they present the opportunity for enhanced returns, albeit with inherent investment risks. This hybrid approach provides policyholders with the prospect of wealth accumulation alongside insurance protection.</p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'> Money-back Plan:</span> Money-back plans, a variant of endowment plans, provide periodic returns of a portion of the sum assured as survival benefits throughout the policy term. Upon surviving the term, the policyholder receives the remaining sum assured as a maturity benefit. This unique feature offers both periodic payouts and a lump-sum amount, ensuring financial security and flexibility for the policyholder's needs.
</p>
<p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Child Plan:</span> Child plans are tailored insurance-investment solutions aimed at fulfilling children's future financial requirements, particularly for education. They often provide a lump sum payout upon policy maturity or in the event of the policyholder's demise, serving as a valuable resource for securing a child's financial well-being.</p>
<p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> <span className='font-bold'>Retirement or Pension Plan:</span> Retirement or pension plans aim to build a significant fund for your post-work years, fostering financial independence during retirement. By investing in these plans, you secure your future while potentially accumulating substantial wealth. These plans offer both investment and insurance benefits, ensuring financial security for your loved ones. Through a mix of debt and equity investments, retirement plans offer the potential for higher returns, empowering you to achieve your long-term financial goals.</p>

</div>
      
            <div className="max-w-4xl mx-auto py-6">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default AdityaLifeLearn
