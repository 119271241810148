// src/MutualFundCalculator.js
import React, { useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Register necessary components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const MutualFundCalculator = () => {
  const [investment, setInvestment] = useState(1500000);
  const [expectedReturnRate, setExpectedReturnRate] = useState(12.9);
  const [timePeriod, setTimePeriod] = useState(10);
  const [totalReturns, setTotalReturns] = useState(0);

  const calculateReturns = () => {
    const rate = expectedReturnRate / 100;
    const total = investment * Math.pow(1 + rate, timePeriod);
    setTotalReturns(total.toFixed(2));
  };

  const handleInvestmentChange = (e) => {
    const value = e.target.value;
    setInvestment(value === '' ? 0 : parseInt(value));
  };

  const handleExpectedReturnRateChange = (e) => {
    const value = e.target.value;
    setExpectedReturnRate(value === '' ? 0 : parseFloat(value));
  };

  const handleTimePeriodChange = (e) => {
    const value = e.target.value;
    setTimePeriod(value === '' ? 0 : parseInt(value));
  };

  const data = {
    labels: ['Total Investment', 'Total Returns'],
    datasets: [
      {
        label: 'Mutual Fund Returns Breakdown',
        data: [investment, totalReturns - investment],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>Mutual Fund Investment Return Calculator 2024 | TheFinmaster</title>
        <meta name='description' content='Mutual Fund Calculator helps to calculate wealth gained and maturity value of your mutual fund investments. Use our mutual fund calculator to plan your investment goals.| TheFinmaster' />
        <meta name='keywords' content='index fund calculator, mutual fund sip calculator, calculate sip returns, sip investment return calculator, sip mf calculator, sip return percentage calculator' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/mutual-fund-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Mutual Fund Calculator</span>
        </li> 
      
      </ol>
    </nav>
      <div className='py-5'>
        <div className="container mx-auto p-4 shadow-xl rounded-md bg-white">
          <h1 className="text-2xl font-bold mb-4">Mutual Fund Returns Calculator</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Total Investment</label>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="0"
                    max="10000000"
                    value={investment}
                    onChange={handleInvestmentChange}
                    className="w-1/2 p-2 border rounded mr-2"
                  />
                  <span className="text-gray-700">₹ {investment.toLocaleString()}</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="10000000"
                  value={investment}
                  onChange={(e) => setInvestment(parseInt(e.target.value))}
                  className="w-full mt-2"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Expected Return Rate (P.A.)</label>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.1"
                    value={expectedReturnRate}
                    onChange={handleExpectedReturnRateChange}
                    className="w-1/2 p-2 border rounded mr-2"
                  />
                  <span className="text-gray-700">{expectedReturnRate} %</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="100"
                  step="0.1"
                  value={expectedReturnRate}
                  onChange={(e) => setExpectedReturnRate(parseFloat(e.target.value))}
                  className="w-full mt-2"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Time Period (Years)</label>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="1"
                    max="50"
                    value={timePeriod}
                    onChange={handleTimePeriodChange}
                    className="w-1/2 p-2 border rounded mr-2"
                  />
                  <span className="text-gray-700">{timePeriod} Yr</span>
                </div>
                <input
                  type="range"
                  min="1"
                  max="50"
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(parseInt(e.target.value))}
                  className="w-full mt-2"
                />
              </div>
              <button
                onClick={calculateReturns}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Calculate
              </button>
            </div>
            <div>
              <div className="mb-4">
                <h2 className="text-xl font-bold">Results</h2>
                <p>Total Investment: ₹ {investment.toLocaleString()}</p>
                <p>Total Returns: ₹ {totalReturns.toLocaleString()}</p>
              </div>
              <div>
                <h2 className="text-xl font-bold">Breakdown</h2>
                <div className="flex justify-center">
                  <Pie data={data} options={{ maintainAspectRatio: false }} width={300} height={300} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div className="flex flex-wrap px-6 py-6" >
        <div className="w-full md:w-2/3 p-4">
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Grow Your Money with Mutual Funds: Understanding Returns and Calculators</h1>
          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">This revised content streamlines the information about mutual fund returns and calculators, focusing on user benefits.</p>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Mutual Funds: A Popular Investment Choice in India</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Mutual funds are a popular way to invest in India, with Assets Under Management (AUM) exceeding Rs. 24 trillion (as of June 2019). While market risks exist, mutual funds offer the potential for good returns.
         </p> 
          
          </div>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Understanding Your Returns: Different Types Explained</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Before using a calculator, let's explore the various return types for mutual funds:
              </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Absolute Return:</span>  Total profit or loss on your investment.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Total Return:</span>  Combines capital appreciation (increase in share price) and dividends received.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Point-to-Point Return:</span>  Return between two specific dates.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Rolling Return: </span>  Return for a set period that's repeatedly calculated over a longer timeframe.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>  Annualized Return:</span>   Reflects average annual growth over the investment period.

            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Trailing Return:</span>   Return over a specific period ending on the current date.

            </p>
            <h1 className="text-xl font-medium  text-gray-600 pl-4  mt-3">Knowing these return types helps you interpret the results from a mutual fund calculator.</h1>
          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Mutual Fund Return Calculators: Your Investment Ally</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            These calculators are helpful tools for estimating your potential returns:
                          </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Simple to Use:</span>  User-friendly interface, even for beginners.

            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Accurate Estimates:</span>  Provides a good approximation, considering market fluctuations.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>Informed Planning:</span>   Helps you plan future finances based on a expected returns
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>Time-Saving:</span>   Avoids complex manual calculations.
            </p>
            </div>


          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">TheFinMaster Mutual Fund Return Calculator: Easy and Efficient</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            TheFinMaster calculator simplifies the process:
            </p>          
              <p className=" text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
             1. Enter your investment amount, expected return, and investment duration.
          </p>
          <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
          2. Get your estimated return in seconds.         </p>
          
          </div>

          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">TheFinMaster Calculator Benefits:</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            These calculators are helpful tools for estimating your potential returns:
                          </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Estimate Market Fluctuations:</span>  Provides a realistic picture despite uncertainties.

            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> Financial Planning Tool:</span>   Helps plan future expenses based on potential returns.
            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>Accessibility:</span> Use it online, anytime & anywhere.
            </p>
            </div>
            <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">SIP vs. LumpSum Investments</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            There are two main ways to invest in mutual funds:
                          </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'> SIP (Systematic Investment Plan):</span>   Invest a fixed amount regularly (e.g., monthly).

            </p>
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 "> {/* Added pl-6 for left padding */}
              <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
              <span className='font-bold'>   Lump Sum:</span> Invest a significant amount at once.
            </p>
           
            </div>
          <h1 className="text-xl font-medium  text-gray-700  ">Exploring Investment Options: Equity, Debt, and Hybrid Funds</h1>
          <div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Mutual Funds: A Popular Investment Choice in India</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Mutual funds can invest in stocks (equity), bonds (debt), or a mix of both (hybrid).         </p> 
            <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Here are some examples (past performance is not indicative of future results).
            </p>      
             </div>

            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Mutual Funds: A Popular Investment Choice in India</h1>

          <div className="mx-auto max-w-4xl mt-4">
            
    <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-300">
            <thead className="bg-gray-800 text-white">
                <tr>
                    <th className="px-6 py-3 text-left">Fund Name</th>
                    <th className="px-6 py-3 text-left">Risk Level</th>
                    <th className="px-6 py-3 text-left">1-Year Return</th>
                     <th className="px-6 py-3 text-left">3-Year Return</th> 
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-300">                                        
                <tr>
                    <td className="px-6 py-4">Aditya Birla Sunlife Frontline Equity Fund</td>
                    <td className="px-6 py-4"> Moderate</td>
                    <td className="px-6 py-4">9.47%</td>
                    <td className="px-6 py-4">10.50%</td>

                  
                </tr>
                <tr>
                    <td className="px-6 py-4">HDFC Mid-cap Opportunities Fund</td>
                    <td className="px-6 py-4">High</td>
                    <td className="px-6 py-4">12.60%</td>
                    <td className="px-6 py-4">16.99%</td>

                </tr>
                <tr>
                    <td className="px-6 py-4">ICICI Pru Focused Bluechip Equity Fund</td>
                    <td className="px-6 py-4">Moderate</td>
                    <td className="px-6 py-4">13.18%</td>
                   
                    <td className="px-6 py-4">11.03%</td>

                </tr>
               
            </tbody>
        </table>
    </div>
</div>


          <div className="mx-auto max-w-4xl mt-4">  
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Debt Funds (Lower Risk, Lower Potential Return)</h1>
     
    <div className="overflow-x-auto mt-4">
        <table className="table-auto w-full border-collapse border border-gray-300">
            <thead className="bg-gray-800 text-white">
                <tr>
                    <th className="px-6 py-3 text-left">Fund Name</th>
                    <th className="px-6 py-3 text-left">Risk Level</th>
                    <th className="px-6 py-3 text-left">1-Year Return</th>
                     <th className="px-6 py-3 text-left">3-Year Return</th> 
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-300">                                        
                <tr>
                    <td className="px-6 py-4">Aditya Birla Sun Life Active Debt Multi-manager FoF</td>
                    <td className="px-6 py-4">Low</td>
                    <td className="px-6 py-4">8.30%</td>
                    <td className="px-6 py-4">6.92%</td>

                  
                </tr>
                <tr>
                    <td className="px-6 py-4">Axis Short Term –Direct Plan</td>
                    <td className="px-6 py-4">Moderate</td>
                    <td className="px-6 py-4">10.06%</td>
                    <td className="px-6 py-4">8.25%</td>

                </tr>
                <tr>
                    <td className="px-6 py-4">Canara Robeco Income- Reg</td>
                    <td className="px-6 py-4">High</td>
                    <td className="px-6 py-4">13.50%</td>
                    <td className="px-6 py-4">8.94%</td>

                </tr>
               
            </tbody>
        </table>
    </div>
</div>
<div className="mx-auto max-w-4xl mt-4">  
          <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Hybrid Funds (Balanced Risk and Return)</h1>
     
    <div className="overflow-x-auto mt-4">
        <table className="table-auto w-full border-collapse border border-gray-300">
            <thead className="bg-gray-800 text-white">
                <tr>
                    <th className="px-6 py-3 text-left">Fund Name</th>
                    <th className="px-6 py-3 text-left">Risk Level</th>
                    <th className="px-6 py-3 text-left">1-Year Return</th>
                     <th className="px-6 py-3 text-left">3-Year Return</th> 
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-300">                                        
                <tr>
                    <td className="px-6 py-4">Indiabulls Savings Income Direct-G</td>
                    <td className="px-6 py-4">Low</td>
                    <td className="px-6 py-4">9.02%</td>
                    <td className="px-6 py-4">11.42%</td>

                  
                </tr>
                <tr>
                    <td className="px-6 py-4">Mirae Asset Hybrid Equity Direct-G</td>
                    <td className="px-6 py-4">Low</td>
                    <td className="px-6 py-4">12.07%</td>
                    <td className="px-6 py-4">14.04%</td>

                </tr>
                <tr>
                    <td className="px-6 py-4">ICICI Pru Equity & Debt Direct-G</td>
                    <td className="px-6 py-4">High</td>
                    <td className="px-6 py-4">10.43%</td>
                    <td className="px-6 py-4">12.20%</td>

                </tr>
               
            </tbody>
        </table>
    </div>
</div>



<div className="container mx-auto  py-4">
            <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Invest with Confidence</h1>
            <p className="mt-4 text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            TheFinMaster mutual fund return calculator empowers you to make informed investment decisions. Consider opening an account with TheFinMaster to explore the world of direct mutual funds! (Consider adding a link to TheFinMaster here)         </p> 
          
          </div>
          </div>

        <div className="w-full md:w-1/3 p-4">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
              <h2 className="text-lg font-semibold ">Popular Categories</h2>
            </div>
            <div className="p-2 ">
            <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                {/* {moreCategories.map(category => (
          <Link key={category.id} to={`/category/${category.id}`}>
            <li className="py-2 border-b border-gray-300">{category.name}</li>
          </Link>
        ))} */}
              </ul>
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default MutualFundCalculator;
