import React, { useEffect, useState } from 'react';
import LoanCardComponent from '../loadCardComponent/LoanCardComponent';
import AccordionComponent from '../accordion/AccordionComponent';
import { LoanData } from '../loadCardComponent/LoanData';
import Http from '../../Http';
import { CARD_API } from '../../Helper';
import { Link } from 'react-router-dom';
import { MdStars } from "react-icons/md";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { FaInfinity } from "react-icons/fa";
import { BsCashCoin } from "react-icons/bs";
import { MdOutlineAirplanemodeActive } from "react-icons/md";

const PersonalLoan = () => {
    const [creditCardData, setCreditCardData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [cardsPerPage] = useState(10);

    useEffect(() => {
        Http.post(CARD_API)
            .then((res) => {
                setCreditCardData(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    console.log({indexOfLastCard,indexOfFirstCard})
    const currentCards = creditCardData.cards?.filter((card)=>card.category==="Loan").slice(indexOfFirstCard, indexOfLastCard);
    console.log({currentCards})
   
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='w-full flex flex-col items-center'>
            <div className='w-full flex flex-col items-center justify-center bg-black h-[200px] md:h-[300px] shadow-lg mb-5 border-b-8 border-blue-600'>
                <h1 className='text-white md:text-7xl text-centerz-20 font-medium'>
                    Personal Loan
                </h1>
                <p className='text-gray-200 text-2xl pt-2'>Unlock your financial potential with our personalized personal loan solutions.</p>
            </div>
            <div className="flex flex-wrap   justify-center gap-4">
            <Link to="/car_loan" className="relative inline-flex items-center justify-center px-6 py-3 text-lg font-medium tracking-tighter text-white bg-gray-800 rounded-md group md:mx-2 md:mb-2">
    <span className="absolute inset-0 w-full h-full mt-1 ml-1 transition-all duration-300 ease-in-out bg-blue-600 rounded-md group-hover:mt-0 group-hover:ml-0"></span>
    <span className="absolute inset-0 w-full h-full bg-white rounded-md "></span>
    <span className="absolute inset-0 w-full h-full transition-all duration-200 ease-in-out delay-100 bg-blue-600 rounded-md opacity-0 group-hover:opacity-100 "></span>
    <span className="relative flex items-center"><BsCashCoin className="mr-4 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Car Loan</span></span>
</Link>
    <Link to="/travel_detail" className="relative inline-flex items-center justify-center px-6 py-3 text-lg font-medium tracking-tighter text-white bg-gray-800 rounded-md group md:mx-2 md:mb-2">
        <span className="absolute inset-0 w-full h-full mt-1 ml-1 transition-all duration-300 ease-in-out bg-blue-600 rounded-md group-hover:mt-0 group-hover:ml-0"></span>
        <span className="absolute inset-0 w-full h-full bg-white rounded-md "></span>
        <span className="absolute inset-0 w-full h-full transition-all duration-200 ease-in-out delay-100 bg-blue-600 rounded-md opacity-0 group-hover:opacity-100 "></span>
        <span className="relative flex items-center"><MdOutlineAirplanemodeActive className="mr-4 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Home Loan</span></span>
    </Link>
    <Link to="/fuel_detail" className="relative  inline-flex items-center justify-center px-6 py-3 text-lg font-medium tracking-tighter text-white bg-gray-800 rounded-md group md:mx-2 md:mb-2">
        <span className="absolute inset-0 w-full h-full mt-1 ml-1 transition-all duration-300 ease-in-out bg-blue-600 rounded-md group-hover:mt-0 group-hover:ml-0"></span>
        <span className="absolute inset-0 w-full h-full bg-white rounded-md "></span>
        <span className="absolute inset-0 w-full h-full transition-all duration-200 ease-in-out delay-100 bg-blue-600 rounded-md opacity-0 group-hover:opacity-100 "></span>
        <span className="relative flex items-center">< BsFillFuelPumpFill className="mr-4 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Education Loan</span></span>
    </Link>
    <Link to="/life_time_fee_detail" className="relative inline-flex items-center justify-center px-6 py-3 text-lg font-medium tracking-tighter text-white bg-gray-800 rounded-md group md:mx-2 md:mb-2">
        <span className="absolute inset-0 w-full h-full mt-1 ml-1 transition-all duration-300 ease-in-out bg-blue-600 rounded-md group-hover:mt-0 group-hover:ml-0"></span>
        <span className="absolute inset-0 w-full h-full bg-white rounded-md "></span>
        <span className="absolute inset-0 w-full h-full transition-all duration-200 ease-in-out delay-100 bg-blue-600 rounded-md opacity-0 group-hover:opacity-100 "></span>
        <span className="relative flex items-center"><FaInfinity className="mr-4 text-2xl text-black" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Two-wheeler Loan</span></span>
    </Link>
    <Link to="/life_time_fee_detail" className="relative inline-flex items-center justify-center px-6 py-3 text-lg font-medium tracking-tighter text-white bg-gray-800 rounded-md group md:mx-2 md:mb-2">
        <span className="absolute inset-0 w-full h-full mt-1 ml-1 transition-all duration-300 ease-in-out bg-blue-600 rounded-md group-hover:mt-0 group-hover:ml-0"></span>
        <span className="absolute inset-0 w-full h-full bg-white rounded-md "></span>
        <span className="absolute inset-0 w-full h-full transition-all duration-200 ease-in-out delay-100 bg-blue-600 rounded-md opacity-0 group-hover:opacity-100 "></span>
        <span className="relative flex items-center"><MdStars className="mr-1 text-black text-2xl" /> <span className="text-black transition-colors duration-200 ease-in-out delay-100 group-hover:text-white">Personal Loan</span></span>
    </Link>
    
    
</div>
            <div className='w-[95%] mx-auto flex gap-5 flex-wrap md:flex-nowrap justify-center'>
                <div className='w-fit flex flex-col gap-8 mt-5'>
                    {currentCards &&
                        currentCards.map((item, index) => (
                            <LoanCardComponent
                                key={index}
                                Card_features={item?.Card_features}
                                fees={item?.fees}
                                cardName={item?.title}
                                cardImage={item?.image}
                                rating={item?.rating}
                                category={item?.category}
                                
                            />
                        ))}
                </div>
                <div className='w-full relative mt-5'>
                    <div className='sticky overflow-hidden top-5 shadow-lg w-full rounded-lg bg-blue-500 border flex flex-col justify-between'>

                        {/* <div className='w-full mx-auto'>
                            <div className='w-full mx-auto'>
                                <img src='https://creditloanz.com/wp-content/uploads/2022/12/HDFC-Bank-Personal-Loan.png' alt='imgg' />
                            </div>
                            <div className='bg-white'>
                                <h3 className='text-center text-blue-500 cursor-pointer font-semibold py-2'>Know your advisor</h3>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
                        
            {/* Pagination */}
            <ul className='flex justify-center mt-4'>
                {currentCards &&
                     creditCardData.cards?.filter((card)=>card.category==="Loan").length > 0 &&
                    Math.ceil( creditCardData.cards?.filter((card)=>card.category==="Loan").length / cardsPerPage) > 1 &&
                    Array.from({ length: Math.ceil( creditCardData.cards?.filter((card)=>card.category==="Loan").length / cardsPerPage) }).map((_, index) => (
                        <li key={index} className='mx-1'>
                            <button
                                className={`px-3 py-1 rounded-lg ${
                                    currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                                }`}
                                onClick={() => paginate(index + 1)}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
            </ul>

            <h1 className='mt-20 text-center text-4xl font-extrabold'>PERSONAL LOAN</h1>
            <h4 className='my-5 text-center text-3xl text-blue-900 font-bold underline'>COMPARISON</h4>
            <div className='w-[95%] mx-auto mb-10'>
                <table className="min-w-full table-auto">
                    <thead className='sticky top-0'>
                        <tr>
                            <th className="px-4 py-3 bg-blue-900 text-white"></th>
                            <th className="px-4 py-3 bg-blue-900 text-white">Bank Name</th>
                            <th className="px-4 py-3 bg-blue-900 text-white">Interest Range</th>
                            <th className="px-4 py-3 bg-blue-900 text-white">Loan Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {LoanData &&
                            LoanData.map((item, index) => (
                                <tr key={index} className='even:bg-gray-100 border hover:bg-blue-100'>
                                    <td className="font-medium text-center px-4 py-5">{index + 1}</td>
                                    <td className="font-medium text-center px-4 py-5">{item.Bank_Name}</td>
                                    <td className="text-center px-4 py-5">{item.Interest_Range}</td>
                                    <td className="text-center px-4 py-5">{item.Loan_Amount}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <div className='w-[95%] py-10'>
                <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
                <AccordionComponent />
            </div>
        </div>
    );
};

export default PersonalLoan;


// import React, { useEffect, useState } from 'react'
// import LoanCardComponent from '../loadCardComponent/LoanCardComponent'
// // import { CreditCardData } from '../creditCard/CardData' 
// import AccordionComponent from '../accordion/AccordionComponent'
// import { LoanData } from '../loadCardComponent/LoanData'
// import Http from '../../Http'
// import { CARD_API } from '../../Helper'
// const PersonalLoan = () => {
//     const [CreditCardData,setCreditCardData] = useState([])
//     useEffect(()=>{
//         Http.post(CARD_API).then((res)=>{
//             setCreditCardData(res)
//         }).catch((err)=>{
//             console.log(err)
//         })
//     },[])
//     CreditCardData.length >0 && CreditCardData.cards.filter((details)=>{ return details.category === 'Loan'})
//     return (
//         <div className='w-full flex flex-col items-center'> 
//             <div className='w-full flex flex-col items-center justify-center bg-black h-[200px] md:h-[300px] shadow-lg mb-5 border-b-8 border-blue-600'>
//                 <h1 className='text-white md:text-7xl text-centerz-20 font-medium'>
//                     Personal Loan
//                 </h1>
//                 <p className='text-gray-200 text-2xl pt-2'>Unlock your financial potential with our personalized personal loan solutions.</p>
//                 {/* <img className='w-[100%] h-[100%] object-cover' src='https://www.kotak.com/content/dam/Kotak/guidance/hero-slider-banner/hacks-for-being-regular-with-personal-loan-repayments-hero-desktop.jpg' alt='personalLoan' /> */}
//             </div>


//             <div className='w-[95%] mx-auto flex gap-5 flex-wrap md:flex-nowrap justify-center'>
//                 <div className='w-fit flex flex-col gap-8 mt-5'>
//                 {
//                             CreditCardData.cards && CreditCardData.cards.map((item, index) => {
//                                 return <> <LoanCardComponent key={index} Card_features={item?.Card_features} fees={item?.fees} cardName={item?.title} cardImage={item?.image} rating={item?.rating} category={item?.category} status={item?.status}  /> </>
//                 })
//             }
//                     </div>
        

                // <div className='w-full relative mt-5'>
                //     <div className='sticky overflow-hidden top-5 shadow-lg w-full rounded-lg bg-blue-500 border flex flex-col justify-between'>

                //         <div className='w-full mx-auto'>
                //             <div className='w-full mx-auto'>
                //                 <img src='https://creditloanz.com/wp-content/uploads/2022/12/HDFC-Bank-Personal-Loan.png' alt='imgg' />
                //             </div>
                //             <div className='bg-white'>
                //                 <h3 className='text-center text-blue-500 cursor-pointer font-semibold py-2'>Know your advisor</h3>
                //             </div>
                //         </div>
                //     </div>
                // </div>
//             </div>


//             <h1 className='mt-20 text-center text-4xl font-extrabold'>PERSONAL LOAN</h1>
//             <h4 className='my-5 text-center text-3xl text-blue-900 font-bold underline'>COMPARISON</h4>
//             <div className='w-[95%] mx-auto mb-10'>
//                 <table className="min-w-full table-auto">
//                     <thead className='sticky top-0'>
//                         <tr>
//                             <th className="px-4 py-3 bg-blue-900 text-white"></th>
//                             <th className="px-4 py-3 bg-blue-900 text-white">Bank Name</th>
//                             <th className="px-4 py-3 bg-blue-900 text-white">Interest Range</th>
//                             <th className="px-4 py-3 bg-blue-900 text-white">Loan Amount</th>
//                         </tr>
//                     </thead>
//                      <tbody>
//                          {
//                              LoanData && LoanData.map((item, index) => {
//                                  return <tr key={index} className='even:bg-gray-100 border hover:bg-blue-100'>
//                                      <td className="font-medium text-center px-4 py-5">{index + 1}</td>
//                                      <td className="font-medium text-center px-4 py-5">{item.Bank_Name}</td>
//                                      <td className="text-center px-4 py-5">{item.Interest_Range}</td>
//                                      <td className="text-center px-4 py-5">{item.Loan_Amount}</td>
//                                  </tr>
//                             })
//                          }
//                      </tbody>
//                  </table>
//            </div>

//             <div className='w-[95%] py-10'>
//                 <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
//                 <AccordionComponent />
//             </div>
//         </div>
//     )
// }

// export default PersonalLoan


// import React, { useState } from 'react'
// import LoanCardComponent from '../loadCardComponent/LoanCardComponent'
// import { CreditCardData } from '../creditCard/CardData' 
// import AccordionComponent from '../accordion/AccordionComponent'
// import { LoanData } from '../loadCardComponent/LoanData'
// const PersonalLoan = () => {
//     // console.log("LoanData", )
    
//         const [currentPage, setCurrentPage] = useState(1);
//         const itemsPerPage = 10;
    
//         // Calculate total number of pages
//         const totalPages = Math.ceil(CreditCardData.cards.length / itemsPerPage);
    
//         // Logic to calculate indexes for pagination
//         const indexOfLastItem = currentPage * itemsPerPage;
//         const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//         const currentItems = CreditCardData.cards.slice(indexOfFirstItem, indexOfLastItem);
    
//         const paginate = (pageNumber) => setCurrentPage(pageNumber);
    
    
//     return (
//         <div className='w-full flex flex-col items-center'>
//             <div className='w-full flex flex-col items-center justify-center bg-black h-[200px] md:h-[300px] shadow-lg mb-5 border-b-8 border-blue-600'>
//                 <h1 className='text-white md:text-7xl text-centerz-20 font-medium'>
//                     Personal Loan
//                 </h1>
//                 <p className='text-gray-200 text-2xl pt-2'>Unlock your financial potential with our personalized personal loan solutions.</p>
//                 {/* <img className='w-[100%] h-[100%] object-cover' src='https://www.kotak.com/content/dam/Kotak/guidance/hero-slider-banner/hacks-for-being-regular-with-personal-loan-repayments-hero-desktop.jpg' alt='personalLoan' /> */}
//             </div>


//             <div className='w-[95%] mx-auto flex gap-5 flex-wrap md:flex-nowrap justify-center'>
//                 <div className='w-fit flex flex-col gap-8 mt-5'>
//                 {currentItems.map((item, index) => (
//                     <LoanCardComponent
//                         key={index}
//                         Card_features={item?.Card_features}
//                         fees={item?.fees}
//                         cardName={item?.title}
//                         cardImage={item?.image}
//                         rating={item?.rating}
//                         category={item?.category}
//                         status={item?.status}
//                     />
//                 ))}
//             </div>
     

//                 <div className='w-full relative mt-5'>
//                     <div className='sticky overflow-hidden top-5 shadow-lg w-full rounded-lg bg-blue-500 border flex flex-col justify-between'>

//                         <div className='w-full mx-auto'>
//                             <div className='w-full mx-auto'>
//                                 <img src='https://creditloanz.com/wp-content/uploads/2022/12/HDFC-Bank-Personal-Loan.png' alt='imgg' />
//                             </div>
//                             <div className='bg-white'>
//                                 <h3 className='text-center text-blue-500 cursor-pointer font-semibold py-2'>Know your advisor</h3>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//  {/* Pagination */}
//  <div className="flex justify-center items-center mt-5">
//                 {[...Array(totalPages).keys()].map((number) => (
//                     <button
//                         key={number}
//                         onClick={() => paginate(number + 1)}
//                         className={`mx-1 px-3 py-1 rounded-lg ${
//                             currentPage === number + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
//                         }`}
//                     >
//                         {number + 1}
//                     </button>
//                 ))}
//             </div>
            
//             <h1 className='mt-20 text-center text-4xl font-extrabold'>PERSONAL LOAN</h1>
//             <h4 className='my-5 text-center text-3xl text-blue-900 font-bold underline'>COMPARISON</h4>
//             <div className='w-[95%] mx-auto mb-10'>
//                 <table className="min-w-full table-auto">
//                     <thead className='sticky top-0'>
//                         <tr>
//                             <th className="px-4 py-3 bg-blue-900 text-white"></th>
//                             <th className="px-4 py-3 bg-blue-900 text-white">Bank Name</th>
//                             <th className="px-4 py-3 bg-blue-900 text-white">Interest Range</th>
//                             <th className="px-4 py-3 bg-blue-900 text-white">Loan Amount</th>
//                         </tr>
//                     </thead>
//                      <tbody>
//                          {
//                              LoanData && LoanData.map((item, index) => {
//                                  return <tr key={index} className='even:bg-gray-100 border hover:bg-blue-100'>
//                                      <td className="font-medium text-center px-4 py-5">{index + 1}</td>
//                                      <td className="font-medium text-center px-4 py-5">{item.Bank_Name}</td>
//                                      <td className="text-center px-4 py-5">{item.Interest_Range}</td>
//                                      <td className="text-center px-4 py-5">{item.Loan_Amount}</td>
//                                  </tr>
//                             })
//                          }
//                      </tbody>
//                  </table>
//            </div>

//             <div className='w-[95%] py-10'>
//                 <h1 className='text-center font-bold text-5xl my-10'>Frequently Asked Questions</h1>
//                 <AccordionComponent />
//             </div>
//         </div>
//     )
// }

// export default PersonalLoan