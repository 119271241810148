import React, { useState } from 'react'
import AccordionComponent from '../accordion/AccordionComponent'
import { FiArrowRight } from 'react-icons/fi';
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';

    function Icon({ id, open }) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
        );
      }
      
      const UpStoxLearnMore = () => {
        const [activeIndex, setActiveIndex] = useState(0);
      
        const faqs = [
            {
                question: 'Who owns the Upstox trading platform?',
                answer:
                    'Upstox is owned by RKSV Securities, a Mumbai-based company. Incorporated in 2009, RKSV Securities is India third-largest discount stock broker, offering trading services in stocks and commodities at BSE, NSE, and MCX. They also provide online mutual fund services.',
            },
            {
                question: 'Is the Upstox Demat Account safe?',
                answer:
                    'Yes, Upstox Demat accounts are safe as they are opened with Central Depository Services Limited (CDSL). Upstox acts solely as an intermediary between the customer and the central depository, ensuring security and compliance with regulatory standards.',
            },
            {
                question: 'Is Upstox free?',
                answer:
                    'Upstox offers brokerage-free equity delivery trading. For other segments such as intraday, futures, options, and commodities, it charges a flat fee of Rs. 20 per executed order or 0.01% of the turnover, whichever is lower. Additionally, customers are required to pay government taxes, exchange turnover charges, demat debit transaction fees, and other applicable charges. For detailed information on Upstox brokerage charges, please refer to the provided link.',
            },
            {
                question: 'Does Upstox offer an installable trading terminal?',
                answer:
                    'No, Upstox does not offer an installable trading terminal.',
            },
            {
              question: 'Does Upstox offer APIs?',
              answer:
                  'Yes, Upstox provides APIs for Algo Trading.',
          },
          {
            question: 'Is Upstox offered by multiple stockbrokers?',
            answer:
                'No, Upstox is specific to RKSV Securities.',
        },
        {
          question: 'Can I trade in equity (cash, intra-day, futures, and options) using Upstox?',
          answer:
              'Yes, Upstox offers equity trading at BSE and NSE, including cash, intra-day, futures, and options.',
      },
      {
        question: 'Can I trade in currency derivatives using Upstox?',
        answer:
            'Yes, Upstox offers trading in currency futures & options at BSE and NSE.',
    },
    {
      question: 'Can I trade in commodities at MCX using Upstox?',
      answer:
          'Yes, Upstox offers commodity trading at MCX.',
  },
  {
    question: 'Does Upstox offer online IPO applications?',
    answer:
        'No, Upstox does not allow online applications for IPOs at BSE and NSE.',
},
{
  question: 'Does Upstox offer online mutual fund investment?',
  answer:
      'No, you cannot buy mutual funds online using Upstox.',
},
{
  question: 'Does Upstox have an integrated back office?',
  answer:
      'No, Upstox does not have an integrated back office. The broker offers a separate back office software.',
},
{
  question: 'Can I trade directly from charts in Upstox?',
  answer:
      'No, you cannot place buy/sell orders directly from the chart in Upstox.',
},

            
        ];
      
        const toggleAccordion = (index) => {
          if (activeIndex === index) {
              // If the clicked question is already expanded, collapse it
              setActiveIndex(null);
          } else {
              // If the clicked question is not expanded, expand it
              setActiveIndex(index);
          };
        };
  return (
    <>
     <Helmet>
      <title>Upstox Demat Account: Opening Proces, Charges &amp; Documents</title>
      <meta  name='description' content='Open a Upstox Demat Account with low minimum deposit, no monthly charges. Start your investment journey with an easy, quick, and hassle-free process | TheFinmaster.'/>
      <meta name='keywords' content=' upstox brokerage charges, upstox amc charges, upstox demat charges, upstox account opening charges' />
      <link rel="canonical" href="https://thefinmaster.com/demat/upstox-demat-account" />
      <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
      <meta name="robots" content="max-video-preview:-1" />
    </Helmet>
    <FAQSchema faqs={faqs} />
    <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/demat" className="text-blue-600 hover:text-blue-800">Demat</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Upstox Demat Account</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
    <div className="w-full flex flex-col items-center">
    <div className="w-full flex justify-center">
      <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
        <div className="w-full sm:w-[50%]">
          <div className="w-[90%]">
            <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
              <span className="text-blue-600 ">
              Elevate Your Trading Experience with Upstox's 
              </span>{" "}
              Cutting-Edge Platform
            </h1>
            <a 
          href="https://upstox.com/open-demat-account/"
          target="_blank" 
          rel="noopener noreferrer"
        >
            
              <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                Open Account!
              </button>
            </a>
          </div>
        </div>
        <div className="w-full sm:w-[50%]">
          <img
            className="w-full max-w-full"
            src='../assets/upstoxdemat1.webp' 
            alt="'demat growth'"
          />
        </div>
      </div>
 

   </div>

      {/* <div className='w-full h-full md:h-screen flex justify-center'>
        <div className='w-[95%] md:flex justify-center items-center '>
          <div className='w-full md:w-[40%] '>
            <h1 className='text-4xl md:text-8xl font-medium text-blue-600 text-center md:text-left mt-10 md:mt-0 '>Upstoxx</h1>
            <p className='py-3 md:py-5 text-lg md:text-xl text-gray-600 text-center md:text-left'>Your Investment Hub</p>
            <button className='border rounded-full px-6 md:px-8 py-3 md:py-4 bg-blue-600 text-white '>Get Started</button>
          </div>
          <div className='w-full md:w-[60%]'>
            <img className='w-full h-[300px] md:h-[100%] object-cover' src='../assets/upstoxlearn.jpeg' alt='demat growth' />
          </div>
        </div>
      </div> */}

      <div className="shadow-lg rounded-lg p-4 md:p-8 lg:p-12 mb-6 px-6 mx-4 md:mx-8 lg:mx-10 text-gray-600 justify-left items-left">
    <p className="text-lg md:text-base lg:text-lg text-left"> 
        Launched in 2010, Upstox has carved a niche for itself with its user-friendly and intuitive platform. Upstox caters to a tech-savvy investor base who appreciates a streamlined trading experience. They keep things simple with a flat fee brokerage structure for equities, derivatives, commodities, and even mutual funds.
    </p>
    <p className="text-lg md:text-base lg:text-lg text-left mt-2">
        Upstox's mobile app is highly rated for its ease of use, making it convenient to trade and manage your portfolio on the go. Upstox also offers educational resources and webinars to help investors stay informed.
    </p>
</div>

      {/* <div className="relative px-6 py-6">
        <img 
        src= "https://marketplace.canva.com/EAENvp21inc/1/0/1600w/canva-simple-work-linkedin-banner-qt_TMRJF4m0.jpg" // Replace "banner-image.jpg" with your actual image URL
          alt= "Banner Image"
          className= "object-cover w-full h-64 md:h-80 lg:h-96"
        />
      </div> */}
      {/* <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
      <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
          Key Highlights
        </h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
        
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 1</h3>
            <p className="text-gray-700">
              Description of Highlight 1 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 2</h3>
            <p className="text-gray-700">
              Description of Highlight 2 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-lg font-semibold mb-2">Highlight 3</h3>
            <p className="text-gray-700">
              Description of Highlight 3 goes here.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </div> */}
    {/* <div class="container mx-auto px-3">
  <h2 class="font-bold text-4xl md:pl-0 pl-3 md:mt-0 mt-5 text-center">Why open a <span class="text-blue-600">Demat</span> account with Us</h2>
  <div class="w-full text-white flex justify-center mt-10">

    <div class="w-full md:w-1/2 bg-neutral-800 flex flex-col justify-center items-center md:h-[450px] md:mr-2 mb-4 md:mb-0">
      <h2 class="text-3xl font-medium border-b-2 pb-5">Zero maintenance fee</h2>
      <p class="text-center w-[80%] md:w-full pt-5 text-lg">A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
    </div>
    <div class="w-full md:w-1/2 bg-blue-600 flex flex-col justify-center items-center md:h-[450px] md:ml-2">
      <h2 class="text-3xl font-medium border-b-2 pb-5">Zero maintenance fee</h2>
      <p class="text-center w-[80%] md:w-full pt-5 text-lg">A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
    </div>
  </div>
</div> */}


<div class="container mx-auto px-4">
  <h2 class="font-bold text-3xl md:text-4xl lg:text-4xl text-center mt-10 md:mt-20">Why open a <span class="text-blue-600">Demat</span> account with <span className='text-blue-600'>UPSTOX </span>?</h2>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10 md:mt-20  ">
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Top-Rated and Trusted</h3>
      <p class="text-base md:text-lg text-center">With over 10 million satisfied customers, our trading app boasts an impressive 4.4-star rating, making it one of the top-rated platforms in India.
</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Seamless Access Across Platform</h3>
      <p class="text-base md:text-lg text-center">Enjoy hassle-free access to the Upstox mobile app, web platform, and advanced TradingView web platform using just your biometrics and mobile number.
</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Dual Mode Flexibility</h3>
      <p class="text-base md:text-lg text-center">Seamlessly transition between Upstox For Investors and Upstox Pro For Traders, providing advanced trading capabilities, catering to diverse needs.</p>
    </div>
    <div class="bg-white rounded-lg shadow-md p-6 flex flex-col justify-center items-center transition duration-300 ease-in-out transform hover:scale-105">
      <h3 class="text-xl md:text-2xl font-semibold mb-4">Top-tier Trading & Investing Tools</h3>
      <p class="text-base md:text-lg text-center">Access comprehensive stock and mutual fund insights through intelligent lists and stay updated with the latest news, historical data, and fundamental analytics.
</p>
    </div>
  </div>
</div>



      {/* <h2 className='font-semibold text-5xl'>Why open a <span className='text-blue-600'>Demat</span> account with Us</h2>
        
        <div className='w-full text-white flex justify-center mt-10'>
          <div className='w-full bg-black flex flex-col justify-center items-center h-[450px]'>
              <h2 className='text-4xl font-medium border-b-2 pb-5'>Zero maintenance fee</h2>
              <p className='text-center w-[80%] pt-5 text-lg'>A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
          </div>
          <div className='w-full bg-blue-600 flex flex-col justify-center items-center h-[450px]'>
              <h2 className='text-4xl font-medium border-b-2 pb-5'>Zero maintenance fee</h2>
              <p className='text-center w-[80%] pt-5 text-lg'>A zero maintenance fee Demat account means hassle-free investing with no additional charges for holding your investments. It's a cost-effective way to manage your securities, making your investment journey more attractive and profitable.</p>
          </div>
        </div> */}
      <div className="flex flex-col w-[95%] h-auto mt-6">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-center text-sm font-light">
                <thead
                  className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
                  <tr>
                    <th scope="col" className=" px-6 py-4">#</th>
                    <th scope="col" className=" px-6 py-4">Fee Type</th>

                    <th scope="col" className=" px-6 py-4">Brokerage Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">1</td>
                    <td className="whitespace-nowrap  px-6 py-4">Equity Delivery Charges</td>

                    <td className="whitespace-nowrap  px-6 py-4">₹20 or 2.5% whichever is lower on Equity Delivery
</td>
                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">2</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Equity Intraday Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4"> ₹20 per executed order or 0.05% (whichever is lower)
</td>

                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">3</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Future Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4">₹20 per executed order or 0.05% (whichever is lower)</td>

                  </tr>
                  <tr className="border-b dark:border-neutral-500">
                    <td className="whitespace-nowrap  px-6 py-4 font-medium">4</td>
                    <td className="whitespace-nowrap  px-6 py-4 ">Option Charges</td>
                    <td className="whitespace-nowrap  px-6 py-4">₹20 per executed order
</td>

                  </tr>
                  {/* <tr className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap  px-6 py-4 font-medium">3</td>
                  <td colSpan={2} className="whitespace-nowrap  px-6 py-4">
                    Larry the Bird
                  </td>
                  <td className="whitespace-nowrap  px-6 py-4">@twitter</td>
                </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      
      <div className='w-full md:w-[95%] mt-5 md:mt-20 py-4 px-4 md:px-16'>
  <h2 className='font-bold text-3xl md:text-4xl text-center'>How to open a <span className='text-blue-600'>Demat</span> account with <span className='text-blue-600'>UPSTOX </span>?</h2>
  <div className='text-center mt-4'>
    <p className='text-base md:text-2xl text-blue-600 '>3 easy steps. Get started now!</p>
  </div>
 

  <div className='w-full flex flex-col md:flex-row gap-5 md:gap-20 mt-3 md:mt-5 ml-0 md:ml-8'>
    <div className='w-full md:w-[30%] pl-2 md:pl-5'>
      <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>1</p>
      <div className='flex items-center justify-between mt-3 md:mt-5'>
        <div>
          <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Initial Verification</p>
        </div>
        <div>
          <FiArrowRight className='text-2xl md:text-3xl animate-pulse' />
        </div>
      </div>
      <p className='text-sm md:text-lg py-2 md:py-0 md:w-[70%]'> Verify your KYC and bank details.</p>
    </div>
    <div className='w-full md:w-[30%] pl-2 md:pl-5'>
      <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>2</p>
      <div className='flex items-center justify-between mt-3 md:mt-5'>
        <div>
          <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>eSign Completion</p>
        </div>
        <div>
          <FiArrowRight className='text-2xl md:text-3xl animate-pulse' />
        </div>
      </div>
      <p className='text-sm md:text-lg py-2 md:py-0 md:w-[70%]'>Complete the eSign process.</p>
    </div>
    <div className='w-full md:w-[30%] pl-2 md:pl-5'>
      <p className='text-4xl md:text-7xl font-extrabold text-gray-500'>3</p>
      <div className='flex items-center justify-between mt-3 md:mt-5'>
        <div>
          <p className='text-lg md:text-2xl text-blue-600 font-medium flex items-center'>Start Trading</p>
        </div>
        <div>
          {/* <FiArrowRight className='text-4xl'/> */}
        </div>
      </div>
      <p className='text-sm md:text-lg py-2 md:py-0 md:w-[70%]'>Download the Upstox app and begin trading</p>
    </div>
  </div>
 
</div>



      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-4 sm:mb-6 lg:mb-8">
        Is it Right for  <span className='text-blue-600 text-6xl'>You</span> <span className='text-6xl'>?</span>
        </h2>
        <p className="text-base sm:text-lg lg:text-xl text-gray-700 leading-relaxed text-center">
        Upstox simplifies investing with their user-friendly platform and low fees. Their focus on technology makes them a great choice for tech-savvy investors who value a smooth trading experience.
        </p>
      </div>
      
     
{/* FAQ section */}
<div className="max-w-4xl mx-auto py-6 mt-10">
                <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

    </div>
    </>
  )
}

export default UpStoxLearnMore
