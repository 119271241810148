import React, { useState } from 'react'
import { motion } from "framer-motion";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import FAQSchema from '../../FAQSchema';
function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const FutureBikeInsurance = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const faqs = [
        {
            question: 'How many types of two-wheeler insurance policies does Future Generali provide?',
            answer:
                'Future Generali offers three types of two-wheeler insurance policies: Comprehensive Insurance policy, Standalone Own Damage Insurance policy, and Third Party Insurance policy. While Comprehensive and Standalone Own Damage policies are optional, Third Party Insurance is mandatory by law for all two-wheeler owners.',
        },
        {
            question: 'Does Future Generali provide add-on covers for two-wheelers?',
            answer:
                'Yes, Future Generali provides several add-on covers for their Comprehensive Insurance policy in India. These include Zero Depreciation Cover, Roadside Assistance Cover, Loss of Key Cover, Daily Cash Allowance Cover, and Engine Protection Cover, among others. These can be availed by paying an additional premium.',
        },
        {
            question: 'Why should I choose Future Generali India Insurance for my two-wheeler insurance policy?',
            answer:
                'There are several compelling reasons to opt for Future Generali India Insurance for your two-wheeler insurance needs:- Quality service commitment to customers.- 24-hour customer assistance.- Automated renewal reminders.- Claim settlement within 7 working days.- 24x7 roadside assistance facility.- Wide range of add-on covers available.',
        },
        {
            question: 'Will I receive a discount on my premium with Future Generali?',
            answer:
                ' Future Generali offers various discounts to customers, including:- No Claim Bonus (NCB) benefit.- Discounts for Automobile Association memberships.- Discounts for anti-theft devices.',
        },
        {
          question: 'Can I transfer my previous two-wheeler insurance policy to Future Generali?',
          answer:
              'Yes, you can transfer your existing two-wheeler insurance policy to Future Generali. Additionally, you can transfer your accumulated No Claim Bonus (NCB) by obtaining a retention certificate from your previous insurer.',
      },
    ];

    const benefits = [
      {
        icon: 'roadside-assistance.png',
        title: 'Towing assistance (for accidents)',
        description: 'Helps you in case of a breakdown/accident with – towing to network garage, battery jumpstart, minor repairs, on-call support and taxi benefits.'
      },
      {
        icon: 'engine-protect.png',
        title: '24*7 Roadside assistance',
        description: 'Pays for repair or replacement of a damaged engine. A handy add-on for those who stay in a region prone to flooding during the rains, or if you own a high-end car.'
      },
      {
        icon: 'engine-protect.png',
        title: 'Consumable Expenses',
        description: 'Pays for repair or replacement of a damaged engine. A handy add-on for those who stay in a region prone to flooding during the rains, or if you own a high-end car.'
      },
      // {
      //   icon: 'consumables.png',
      //   title: 'Consumable Expenses',
      //   description: 'Pays for repair or replacement of nut and bolt, screw, washer, grease, lubricant, clips, A/C gas, bearings, distilled water, engine oil, oil filter, fuel filter, break oil and related parts.'
      // },
      // {
      //   icon: 'zero-depreciation.png',
      //   title: 'Personal Baggage',
      //   description: 'As your car ages, the value of its parts reduces. This add-on ensures you get the full claim amount without any deduction for depreciation.'
      // },
      // {
      //   icon: 'garage-cash.png',
      //   title: 'Engine Protector',
      //   description: 'Gives a daily allowance for alternate travel expenses when your car is in the garage for repairs after an accident.'
      // },
      // {
      //   icon: 'personal-belongings.png',
      //   title: 'Conveyance Benefit',
      //   description: 'Covers the loss or damage of your personal belongings in the car due to theft or an accident.'
      // }
    ];
    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            // If the clicked question is already expanded, collapse it
            setActiveIndex(null);
        } else {
            // If the clicked question is not expanded, expand it
            setActiveIndex(index);
        };
      };
    return (
        <>
         <Helmet>
         <title>Future Generali Two Wheeler Insurance – Renewal, Review Online at TheFinmaster</title>
        <meta  name='description' content='Looking for reliable two-wheeler insurance? Future Generali has got you covered. Our comprehensive policies provide maximum protection for your bike. Get a quote now and secure your rides future | TheFinmaster.'/>
        <meta name='keywords' content='future general bike insurance, future generali two wheeler insurance online, future generali 2 wheeler insurance' />
        <link rel="canonical" href="https://thefinmaster.com/bike-insurance/future-bike-insurance" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <FAQSchema faqs={faqs} />
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/bike-insurance" className="text-blue-600 hover:text-blue-800">Bike Insurance</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">Future Bike Insurance</span>
        </li> 
        {/* <li className="flex items-center">
          <Link to="/credit-card" className="text-blue-600 hover:text-blue-800">Credit Card</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li> */}
        {/* <li className="flex items-center">
          <span className="text-gray-500">Car Loan EMI Calculator</span>
        </li> */}
      </ol>
    </nav>
           <div className="w-full flex flex-col items-center px-6">
        <div className="w-full flex justify-center">
          <div className="w-full sm:w-[95%] flex flex-col sm:flex-row justify-center items-center">
            <div className="w-full sm:w-[50%]">
              <div className="w-[90%]">
                <h1 className="text-4xl md:text-5xl font-bold mt-6 md:mt-0 md:pl-0 pl-6">
                  <span className="text-blue-600 ">
                  Futureproof your ride. 
                  </span>{" "}
                  Get Future Generali bike insurance.
                </h1>
                <a href="https://general.futuregenerali.in/motor-insurance/bike-insurance"
                 target="_blank" 
                 rel="noopener noreferrer">
                  <button className="py-4 px-8  border rounded-full bg-blue-600 text-white shadow-lg hover:shadow-xl text-lg mt-10 font-medium">
                  Get Quote!
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full sm:w-[50%]">
              <img
                className="w-full max-w-full"
                src='../assets/futurebike1.webp' alt='Noimage'
               
              />
            </div>
          </div>
       </div>

       </div>
            {/* <div className='w-full md:w-[95%] py-10 px-4 md:px-10'>
                <div className="mx-auto border border-gray-300 rounded-md mt-4 mb-6">
                    <div className="flex flex-col md:flex-row items-center justify-between px-4 py-2 md:py-4 md:mt-4 text-black">
                    
                        <div className="flex items-center mb-4 md:mb-0">
                            <h1 className="text-xl md:text-2xl font-bold">AU Bank LIT Credit Card</h1>
                        </div>
                    
                 
                        <div className="flex items-center">
                        
                            <a href="your_link_here" className="ml-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out">Apply now</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-6">
                        
                        <div className="md:w-1/3 mb-4 md:mb-0">
                            <img src="/aucard.jpeg" alt="AU Bank LIT Credit Card" className="w-full" />
                     
                            <div className="flex items-center mt-2 md:mt-0">
                                
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-yellow-500">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="text-gray-400">&#9733;</span>
                                <span className="ml-1 text-gray-500">(3)</span>
                            </div>
                        </div>

                      
                        <div className="md:w-1/3 flex flex-col justify-between">
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Fees</h2>
                                <p>Joining Fee: Nil</p>
                                <p>Annual Fee: Nil</p>
                            </div>
                            <div className="mb-2">
                                <h2 className="text-lg md:text-xl font-semibold text-blue-600">Welcome Benefits</h2>
                                <p>Nil</p>
                            </div>
                        </div>

                        
                        <div className="md:w-1/3 md:ml-6">
                            <div>
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Best Suited for</h2>
                                <p>Rewards & Cashback</p>
                            </div>
                            <div className="mt-4">
                                <h2 className="text-lg md:text-xl font-semibold mb-2 text-blue-600">Reward Rate</h2>
                                <p>0.25%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
          <div className="container mx-auto px-4 py-8">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">A Little about Future Generali 2 Wheeler Insurance</h1>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"> Future Generali 2 Wheeler Insurance: Future Generali addresses two-wheeler insurance requirements with a range of comprehensive and third-party only plans, offering flexibility to suit diverse preferences. Their customizable policies include add-on covers such as personal accident protection, rider cover, and consumables, allowing policyholders to tailor coverage according to their needs. </p>
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg">
            Future Generali's network of partnered workshops facilitates cashless claims, underscoring their commitment to customer convenience. With a focus on swift claim settlements and a dedication to customer satisfaction, Future Generali stands as a reliable partner for individuals seeking comprehensive and hassle-free two-wheeler insurance solutions.</p>
            </div>
            
            <div className="container mx-auto px-4 py-8">
  <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
    <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
        Key Highlights
      </h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Box 1 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/sucess.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            94% claim-settlement ratio

            </p>
          </div>
        </motion.div>
        {/* Box 2 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/support.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            24x7 Roadside Assistance 

            </p>
          </div>
        </motion.div>
        {/* Box 3 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
         <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/policy.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Instant policy issuance and renewal

            </p>
          </div>
        </motion.div>
        {/* Box 4 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/garage.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            3500+ partner workshops across India


            </p>
          </div>
        </motion.div>
        {/* Box 5 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/conflict.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Hassle-free claim process

            </p>
          </div>
        </motion.div>
        {/* Box 6 */}
        <motion.div
          className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md hover:shadow-lg transition duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center justify-center flex-col mb-2 md:mb-3 lg:mb-4">
            <img
              src="../assets/phone.gif"
              alt="GIF 1"
              className="w-12 md:w-16 lg:w-12 h-auto mb-2 hover:animate-bounce"
            />
            <p className="text-xl md:text-2xl lg:text-xl text-center text-gray-600">
            Future Xpress for personalized service
            </p>
          </div>
        </motion.div>
      </motion.div>
    </div>
  </div>
</div>
<div className="container mx-auto px-4 py-8 mb-4">
                <h1 className="text-2xl font-bold  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">Types of Two Wheeler Insurance Policies offered by Future Generali
</h1>
<p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"><span className='font-bold'> Third Party Bike Insurance :</span> As per legal requirements, this policy is designed to compensate third parties involved in accidents, ensuring financial protection for them. While it comes with lower premiums compared to comprehensive plans, the coverage is limited, primarily focusing on meeting legal obligations. It's important to note that this policy does not provide any protection for the insured rider, policyholder, or their vehicle.</p>

                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"><span className='font-bold'> Comprehensive Two Wheeler Insurance :</span>  This policy not only safeguards the rider, policyholder, and vehicle but also extends protection to third parties, all consolidated within one plan. Moreover, insurers offer a range of customizable add-ons to enhance coverage, albeit at a slightly higher premium. From roadside assistance to zero depreciation cover, these additional benefits ensure comprehensive protection for both the insured and others on the road.</p>
                
                <p className="mt-4 text-lg text-gray-700 sm:text-xl md:text-lg lg:text-xl xl:text-lg"><span className='font-bold'> Standalone Own Damage Cover :</span> This type of insurance acts as a supplement to existing policies, offering claim benefits for a range of scenarios, including accidents, theft, and damages. However, it's important to note that standalone own damage cover does not include coverage for third-party liabilities or certain risks like driving under the influence. Despite these limitations, it plays a crucial role in enhancing overall protection for both the rider and their vehicle.</p>

            </div>
<div className="w-full flex flex-col items-center  mb-10 mt-10">
      <div className="w-[90%] text-center mb-10">
        <h2 className="font-bold text-4xl mb-4"> Future Generali 2 Wheeler Insurance plan add-on benefits</h2>
        <p className="text-gray-700 mb-4">
          Choose from an assorted range of add-ons, for an additional premium, when buying  Future Generali 2 Wheeler Insurance online.
        </p>
      </div>
      <div className="w-[90%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex flex-col items-center text-center">
            {/* <img src='../assets/${benefit.icon}' alt={benefit.title} className="mb-4" /> */}
            <h3 className=" text-gray-600 text-2xl mb-2">{benefit.title}</h3>
            {/* <p className="text-gray-700">{benefit.description}</p> */}
          </div>
        ))}
      </div>
    </div>
{/* <div className='w-full flex flex-col items-center'>
                        <div className='w-[90%] flex flex-wrap md:flex-nowrap justify-between'>
                            <div className='w-full'>
                                <img src='../assets/1paragraph.jpg' alt='img1' />
                            </div>
                            <div className='w-full flex items-center'>
                                <div>
                                    <h2 className='font-bold text-4xl'>Why TheFinMaster?</h2>
                                   
                                    <p className='py-5 text-xl font-medium text-gray-600'>TheFinMaster.com simplifies your finances! Explore & compare credit cards, loans, insurance & demat accounts from various providers. As a one-stop platform, we aim to simplify your financial journey by providing detailed insights into multiple products and offerings.</p>
                                    <p className='mt-0.5 text-xl font-medium text-gray-600'> Make informed financial decisions with our insightful guides and tools.</p>

                                              </div>
                            </div>
                        </div>
                    </div> */}
            {/* <div className="container mx-auto px-4 py-8">
            <div className="bg-gray-100 py-8 md:py-12 lg:py-16 mb-6">
      <div className="max-w-5xl mx-auto px-4 md:px-8 lg:px-12">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 md:mb-6 lg:mb-8 text-center">
          Key Highlights
        </h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
        
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
            <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
        <img
          src="../assets/hospital.gif"
          alt="GIF 1"
          className="w-16 md:w-20 lg:w-16 mb-2"
        />
          <p className="text-2xl md:text-2xl lg:text-2xl text-center text-gray-600">
          Across 7,200+ Network Garages
        </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    Tie up with 18,400+ Hospitals 
    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
              <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    Get Insurance in Under 3 Minutes!

    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    24x7 Roadside Assistance

    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    On the spot Claim Settlements


    </p>
  </div>
          </motion.div>
          <motion.div
            className="bg-white rounded-lg p-4 md:p-6 lg:p-8 shadow-md"
            whileHover={{ scale: 1.05 }}
          >
             <div className="flex items-center justify-center flex-col mb-4 md:mb-6 lg:mb-8">
    <BsFillFuelPumpFill className="text-3xl md:text-4xl lg:text-5xl  mb-2" />
    <p className="text-2xl md:text-2xl lg:text-2xl  text-center text-gray-600">
    98% Claim Settlement Ratio

    </p>
  </div>
          </motion.div>
        </motion.div>
      </div>
    </div> 
    </div> */}


   
            <div className="max-w-4xl mx-auto lg:py-32 py-10">
                <h1 className="text-2xl md:text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="border rounded-lg overflow-hidden shadow-md">
                            <button
                                onClick={() => toggleAccordion(index)}
                                className="w-full p-4 text-left text-lg font-semibold focus:outline-none flex justify-between items-center"
                            >
                                {faq.question}
                                <Icon id={index} open={activeIndex} />
                            </button>
                            {activeIndex === index && (
                                <div className="p-4 bg-gray-100">
                                    <p className="text-gray-700">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default FutureBikeInsurance
