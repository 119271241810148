import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import SideBar from './SideBar';
import { Helmet } from 'react-helmet';

const SinglepageBlog = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        // console.log(`Fetching blog with ID: ${id}`);
        const response = await fetch(`https://test.thefinmaster.com/console/react-app-directory/test-blog-api.php?id=${id}`);
        
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        
        const data = await response.json();
        
        // Log data to understand the structure
        // console.log("Fetched Data:", data);

        // Extract the blogs array
        // const blogs = data.blogs;
        
        // Find the specific blog by ID
        // const foundBlog = blogs.find(blog => blog.url === id);
        const foundBlog = data.blogs.find(blog => blog.content_url === id);

        // console.log("Found Blog:", foundBlog);
        
        setBlog(foundBlog);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) {
    return<div class="spinnerContainer">
    <div class="spinner"></div>
    <div class="loader">
      <p>loading</p>
      <div class="words">
        <span class="word">posts</span>
        <span class="word">images</span>
        <span class="word">Title</span>
        <span class="word">popularBlogs</span>
        <span class="word">Latest Blogs</span>
      </div>
    </div>
  </div>
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!blog) {
    return <p>No blog found.</p>;
  }
// const {content_category, content_date, 
//     content_description, 
//     content_image, 
// content_keyword, content_meta,
// content_meta_description,
// content_title} = blogs[0];

  return (
    <>
    <Helmet>
        <title>{blog.content_meta}</title>
        <meta name='description' content={blog.content_meta_description} />
        <meta name='keywords' content={blog.content_keyword} />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-12" aria-label="Breadcrumb">
        <ol className="list-none p-0 inline-flex">
          <li className="flex items-center">
            <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
            <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link to="/blog_page" className="text-blue-600 hover:text-blue-800">Blog</Link>
            <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>

        </ol>
      </nav>
    <div className='max-w-7xl mx-auto my-12 flex flex-col md:flex-row gap-12'>
        <div className='lg:w-3/4 mx-auto px-3'>  
        <div>
            <img src={blog.content_image} alt={blog.content_title} className='w-full mx-auto rounded'/>

        </div>
        <h2 className='text-3xl mt-8 font-bold mb-4 text-blue-500 cursor-pointer'>{blog.content_title} </h2>
        <p className="text-gray-600 text-sm mb-3 ">{blog.content_date}</p>
        <p className="text-gray-700 text-base mb-6" dangerouslySetInnerHTML={{ __html: blog.content_description }}></p>
{/* <div className='text-base text-gray-600'>
<p>{blog.content_description}</p>
</div> */}




</div>

<div className='lg:w-1/2 '>
    <SideBar />
</div>
       </div> 
    </>
  
  );
};

export default SinglepageBlog;
