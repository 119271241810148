import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Http from "../../Http";
import { Newsletter_API } from "../../Helper";

const Newsletter = () => {
  const [newsletterEmail, setNewsletterEmail] = useState("");
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [newsletterError, setNewsletterError] = useState(null);
  const [newsletterSuccess, setNewsletterSuccess] = useState(false);

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();

    if (!newsletterEmail.trim()) {
      setNewsletterError("Please enter an email address.");
      return;
    }

    setNewsletterLoading(true);

    try {
      const response = await Http.post(Newsletter_API, { email: newsletterEmail });
      console.log("Newsletter Response:", response);
      setNewsletterSuccess(true);
      setNewsletterEmail("");
    } catch (error) {
      console.error("Error subscribing to newsletter:", error);
      setNewsletterError("Failed to subscribe. Please try again later.");
    } finally {
      setNewsletterLoading(false);
    }
  };

  return (
    <>
      <div className="px-4 sm:px-8 lg:px-10 mb-8">
        <div className="w-full py-8 text-white bg-blue-600 rounded-lg shadow-lg">
          <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row items-center lg:justify-between">
            <div className="flex flex-col lg:flex-row items-center mb-4 lg:mb-0 lg:mr-4">
              <img src="../assets/newsletter.webp" alt="icon" className="w-48 h-40 mb-4 lg:mb-0 lg:mr-4" />
              <div className="text-center lg:text-left max-w-full lg:max-w-[60%]">
                <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 whitespace-normal lg:whitespace-nowrap">
                  Never miss out on exclusive offers and updates!
                </h1>
                <p className="text-lg sm:text-xl">Sign up now to receive personalized deals and stay in the loop.</p>
              </div>
            </div>
            <form onSubmit={handleNewsletterSubmit} className="flex flex-col sm:flex-row items-center w-full lg:w-auto mt-4 lg:mt-20 lg:mr-4">
              <input
                className="flex-grow lg:flex-grow-0 rounded-md text-black p-2 mb-4 sm:mb-0 sm:mr-4"
                type="email"
                placeholder="Enter Email"
                value={newsletterEmail}
                onChange={(e) => setNewsletterEmail(e.target.value)}
                required
              />
              <button
                type="submit"
                className="rounded-md font-medium w-auto lg:w-[200px] px-6 py-3 text-white bg-black hover:bg-gray-900"
                disabled={newsletterLoading}>
                {newsletterLoading ? "Subscribing..." : "Notify Me"}
              </button>
            </form>
            {newsletterError && <p className="text-red-500 mt-2">{newsletterError}</p>}
          </div>
          {newsletterSuccess && <h6 className="text-green-400 mt-2 pr-6   text-lg text-right ">Thank you for subscribing!</h6>}
        </div>
      </div>
    </>
  );
};

export default Newsletter;
