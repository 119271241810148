import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Banner = () => {
    return (
        <div className="shadow-xl rounded-md p-4 bg-white mt-4 md:mt-0 text-center">
            <img src="https://www.shutterstock.com/image-illustration/credit-cards-online-shopping-banner-260nw-355524626.jpg" alt="Advertisement" className="w-full rounded-md mb-4" />
            <h3 className="text-xl font-bold mb-2">Invest the way you want</h3>
            <p>Join millions of Indians who trust and love TheFinmaster</p>
            <button className="bg-green-500 text-white px-4 py-2 rounded mt-2">Explore Products</button>
        </div>
    );
};

const TDSCalculator = () => {
    const [recipientType, setRecipientType] = useState('individual');
    const [panAvailable, setPanAvailable] = useState(true);
    const [natureOfPayment, setNatureOfPayment] = useState('192A');
    const [amount, setAmount] = useState('');
    const [tds, setTds] = useState(0);
    const [rule, setRule] = useState('');
    const [calculated, setCalculated] = useState(false);
    const [error, setError] = useState('');

    const minimumTDSValue = 1; // Set the minimum TDS value

    const natureOfPayments = [
        { value: '192', label: '192 - Salaries' },
        { value: '192A', label: '192A - Premature EPF withdrawal' },
        { value: '193', label: '193 - TDS on interest on securities' },
        { value: '194', label: '194 - Payment of dividend' },
        { value: '194A1', label: '194A - Interest issued by banks or post offices on deposits' },
        { value: '194A2', label: '194A - Interest by others apart from on securities' },
        { value: '194B', label: '194B - Amounts that someone has won through lotteries, puzzles, or games' },
        { value: '194BB', label: '194BB - Amounts that someone has won from horse races' },
        { value: '194C1', label: '194C - Payments to contractor or sub-contractor – Single Payments' },
        { value: '194C2', label: '194C - Payments to contractor/sub-contractor – Aggregate Payments' },
        { value: '194D1', label: '194D - Payment of insurance commission to domestic companies' },
        { value: '194D2', label: '194D - Payment of insurance commission to companies other than domestic ones' },
        { value: '194DA', label: '194DA - Maturity of Life Insurance Policy' },
        { value: '194EE', label: '194EE - Payment of an amount standing to the credit of an individual under NSS' },
        { value: '194F', label: '194F - Payment of repurchase of unit by UTI or any mutual fund' },
        { value: '194G', label: '194G - Payments or commission on sale of lottery tickets' },
        { value: '194H', label: '194H - Commission or brokerage' },
        { value: '194I1', label: '194I - Rent of land, building, or furniture' },
        { value: '194I2', label: '194I - Rent of plant and machinery' },
        { value: '194IA', label: '194IA - Payment for transfer of immovable property other than agricultural land' },
        { value: '194IB', label: '194IB - Rent payment by an individual or HUF not covered under payment 194I' },
        { value: '194IC', label: '194IC - Payment under Joint Development Agreement (JDA)' },
        { value: '194J1', label: '194J - Fees for professional services' },
        { value: '194J2', label: '194J - Amount paid for technical services' },
        { value: '194J3', label: '194J - Amounts paid as royalty for sale/distribution/exhibition of cinematographic films' },
        { value: '194K', label: '194K - Payment of income for units of a mutual fund' },
        { value: '194LA', label: '194LA - Payment for compensation for acquiring certain immovable property' },
        { value: '194LB', label: '194LB - Payment of interest on infrastructure bonds to Non-Resident Indians' },
        { value: '194LBA1', label: '194LBA(1) - Income distributed by a business trust among its unit holder' },
        { value: '194LD', label: '194LD - Payment of interest on rupee-denominated bonds, municipal debt security, and government securities' },
        { value: '194M', label: '194M - Amounts paid for contract, brokerage, commission or professional fee' },
        { value: '194N1', label: '194N - Cash withdrawal over a certain amount (ITR filed)' },
        { value: '194N2', label: '194N - Cash withdrawal over a certain amount (ITR not filed)' },
        { value: '194O', label: '194O - Payment for sale of products/services by e-commerce service providers' },
        { value: '194Q', label: '194Q - Payments made for the purchase of goods' },
        { value: '194S', label: '194S - TDS on payment of crypto or other virtual asset' },
        { value: '206AA', label: '206AA - TDS for non-availability of PAN' },
        { value: '206AB', label: '206AB - TDS on non-filers of Income tax return' },
        // Add more options as needed
    ];

    const tdsRates = {
        '192': { threshold: 250000, individual: 'Slab Rates', others: 'Slab Rates' },
        '192A': { threshold: 50000, individual: 0.1, others: 0.1 },
        '193': { threshold: 10000, individual: 0.1, others: 0.1 },
        '194': { threshold: 5000, individual: 0.1, others: 0.1 },
        '194A1': { threshold: 40000, individual: 0.1, others: 0.1 },
        '194A2': { threshold: 5000, individual: 0.1, others: 0.1 },
        '194B': { threshold: 10000, individual: 0.3, others: 0.3 },
        '194BB': { threshold: 10000, individual: 0.3, others: 0.3 },
        '194C1': { threshold: 30000, individual: 0.01, others: 0.02 },
        '194C2': { threshold: 100000, individual: 0.01, others: 0.02 },
        '194D1': { threshold: 15000, individual: 'NA', others: 0.1 },
        '194D2': { threshold: 15000, individual: 0.05, others: 'NA' },
        '194DA': { threshold: 100000, individual: 0.05, others: 0.05 },
        '194EE': { threshold: 2500, individual: 0.1, others: 0.1 },
        '194F': { threshold: 0, individual: 0.2, others: 0.2 },
        '194G': { threshold: 15000, individual: 0.05, others: 0.05 },
        '194H': { threshold: 15000, individual: 0.05, others: 0.05 },
        '194I1': { threshold: 240000, individual: 0.1, others: 0.1 },
        '194I2': { threshold: 240000, individual: 0.02, others: 0.02 },
        '194IA': { threshold: 5000000, individual: 0.01, others: 0.01 },
        '194IB': { threshold: 50000, individual: 0.05, others: 'NA' },
        '194IC': { threshold: 0, individual: 0.1, others: 0.1 },
        '194J1': { threshold: 30000, individual: 0.1, others: 0.1 },
        '194J2': { threshold: 30000, individual: 0.02, others: 0.02 },
        '194J3': { threshold: 30000, individual: 0.02, others: 0.02 },
        '194K': { threshold: 5000, individual: 0.1, others: 0.1 },
        '194LA': { threshold: 250000, individual: 0.1, others: 0.1 },
        '194LB': { threshold: 0, individual: 0.05, others: 0.05 },
        '194LBA1': { threshold: 0, individual: 0.1, others: 0.1 },
        '194LD': { threshold: 0, individual: 0.05, others: 0.05 },
        '194M': { threshold: 5000000, individual: 0.05, others: 0.05 },
        '194N1': { threshold: 10000000, individual: 0.02, others: 0.02 },
        '194N2': { threshold: 2000000, individual: 0.02, others: 0.02 },
        '194O': { threshold: 500000, individual: 0.01, others: 0.01 },
        '194Q': { threshold: 5000000, individual: 0.001, others: 0.001 },
        '194S': { threshold: 0, individual: 0.01, others: 0.01 },
        '206AA': { threshold: 0, individual: 0.2, others: 0.2 },
        '206AB': { threshold: 0, individual: 0.05, others: 0.05 },
    };

    const calculateTDS = () => {
        if (!amount || isNaN(amount) || amount <= 0) {
            setError('Please enter a valid amount.');
            setCalculated(false);
            return;
        }

        const paymentInfo = tdsRates[natureOfPayment];
        let rate = 0;
        let ruleDescription = '';

        if (paymentInfo) {
            if (amount < paymentInfo.threshold) {
                rate = 0;
                ruleDescription = `Amount is below the threshold limit of ₹${paymentInfo.threshold}. No TDS applicable.`;
            } else if (recipientType === 'individual') {
                rate = paymentInfo.individual;
                ruleDescription = `TDS rate for Individual/HUF is ${rate * 100}%. Threshold: ₹${paymentInfo.threshold}.`;
            } else {
                rate = paymentInfo.others;
                ruleDescription = `TDS rate for Others is ${rate * 100}%. Threshold: ₹${paymentInfo.threshold}.`;
            }

            // Handle cases where rates are provided in string format
            if (typeof rate === 'string') {
                rate = 0;
                ruleDescription = `TDS rate is specified as "${rate}". No TDS applicable.`;
            }

            // Handle PAN not available case
            if (!panAvailable && rate > 0) {
                ruleDescription = `PAN not available. Minimum TDS rate is 20%.`;
                rate = Math.max(rate, 0.2);
            }
        }

        const calculatedTds = amount * rate;

        if (calculatedTds < minimumTDSValue) {
            setError(`Calculated TDS is below the minimum value of ₹${minimumTDSValue}. Approximate TDS: ₹${calculatedTds.toFixed(2)}`);
            setCalculated(false);
            return;
        }

        setTds(calculatedTds);
        setRule(ruleDescription);
        setCalculated(true);
        setError('');
    };

    return (
        <>
         <Helmet>
        <title>TDS Tax Calculator- Calculation of Interest on TDS</title>
        <meta name="description" content="Use our free TDS tax calculator to determine the Tax Deducted at Source (TDS) applicable to your salary in India. Also, understand how to calculate interest on TDS. | TheFinmaster" />
        <meta name='keywords' content='tds tax calculator, calculate tds on salary, tax deducted at source on salary, calculation of interest on tds' />
        <link rel="canonical" href="https://thefinmaster.com/financial-tools-calculators/tds-calculator" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:standard" />
        <meta name="robots" content="max-video-preview:-1" />
      </Helmet>
      <nav className="text-gray-500 text-sm my-4 ml-10" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-600 hover:text-blue-800 ">Home</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
        <li className="flex items-center">
          <Link to="/financial-tools-calculators" className="text-blue-600 hover:text-blue-800">Financial Tools Calculators</Link>
          <svg className="w-4 h-4 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </li>
          <li className="flex items-center">
          <span className="text-gray-500 ">TDS Calculator</span>
        </li> 
      
      </ol>
    </nav>
        <div className="py-4">
            <div className="container mx-auto p-4 flex flex-col md:flex-row md:space-x-4">
                <div className="w-full md:w-2/3 p-4 shadow-xl rounded-md bg-white">
                    <h1 className="text-2xl font-bold mb-4">TDS Calculator</h1>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold">Recipient</label>
                        <div className="flex space-x-4">
                            <label>
                                <input
                                    type="radio"
                                    name="recipient"
                                    value="individual"
                                    checked={recipientType === 'individual'}
                                    onChange={() => setRecipientType('individual')}
                                    className="mr-2"
                                />
                                Individual/HUF/Sole Proprietor
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="recipient"
                                    value="others"
                                    checked={recipientType === 'others'}
                                    onChange={() => setRecipientType('others')}
                                    className="mr-2"
                                />
                                Others
                            </label>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold">PAN of recipient is available</label>
                        <select
                            value={panAvailable}
                            onChange={(e) => setPanAvailable(e.target.value === 'true')}
                            className="w-full p-2 border rounded"
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold">Nature of payment</label>
                        <select
                            value={natureOfPayment}
                            onChange={(e) => setNatureOfPayment(e.target.value)}
                            className="w-full p-2 border rounded max-h-32 overflow-auto"
                        >
                            {natureOfPayments.map((payment) => (
                                <option key={payment.value} value={payment.value}>
                                    {payment.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold">Amount of payment</label>
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(parseFloat(e.target.value))}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    <button
                        onClick={calculateTDS}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        Calculate
                    </button>
                    {error && (
                        <div className="mt-4 bg-red-100 p-4 rounded shadow-md text-red-700">
                            <p>{error}</p>
                        </div>
                    )}
                    {calculated && !error && (
                        <div className="mt-4 bg-gray-100 p-4 rounded shadow-md">
                            <h2 className="text-xl font-bold">Total TDS</h2>
                            <p className="text-2xl">₹ {tds.toFixed(2)}</p>
                            <p className="text-gray-700 mt-2">{rule}</p>
                        </div>
                    )}
                </div>
                <div className="w-full md:w-1/3">
                    <Banner />
                </div>
            </div>
        </div>
        <div className="flex flex-wrap px-6 py-6" >
                <div className="w-full md:w-2/3 p-4">
                    <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">What is TDS?
                    </h1>

                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Tax Deducted at Source (TDS) is a mechanism where a specific amount is deducted from certain payments before they reach the recipient. This applies to various income sources such as salary, rent, commission, and interest.
                    </p>
                    <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">The entity making the payment deducts the TDS, while the recipient is ultimately liable to pay the income tax. This system helps reduce tax evasion by collecting tax at the source of income.
                    </p>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How to Calculate TDS or TDS Interest:
</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">You can estimate your TDS liability or interest on TDS payments using a TDS calculator or a TDS interest calculator.

                        </p>
                    </div>


                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">What is a TDS Calculator?
                        </h1>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">A TDS calculator is a free online tool that offers several functionalities:
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>      Determines TDS Obligation:  </span> It helps you understand if you're required to deduct TDS from a particular payment

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>     Calculates TDS Amount: </span>  If TDS applies, the calculator estimates the exact amount to be deducted.

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>   Verifies TDS Accuracy:  </span>     You can use it to check if the deducted TDS on your income (like salary) is correct.
                        </p>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  Provides Basic TDS Insights:  </span> The calculator offers a general understanding of relevant TDS rules.
                        </p>
                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using a TDS Calculator:
                        </h1>
                       
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>      Simple and User-Friendly:  </span>  These online calculators are easy to navigate and require no registration or login.

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>     Quick Results: </span>   Get your TDS estimates within seconds.

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  Reduces Errors:  </span>    Eliminate the risk of mistakes from manual calculations.
                        </p>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'> Improves Financial Planning: </span>  Knowing your net pay (after TDS) helps with budgeting.

                        </p>
                    </div>
                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How to Use a TDS Calculator:
                        </h1>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Using a TDS calculator is straightforward. Here's a typical process:
                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>Recipient Type:   </span> Select your recipient category (individual, sole proprietor, etc.)

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>     Payment Amount:  </span>  Enter the payment amount.

                        </p>
                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>    Nature of Payment:  </span>    Specify the payment type (salary, fixed deposit interest, etc.)
                        </p>

                        <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
                            <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
                            <span className='font-bold'>  Results: </span>  The calculator will quickly determine if TDS applies and, if so, the amount to be deducted.
                        </p>
                    </div>



                    
                    

                    <div className="container mx-auto  py-4">
                        <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Calculating TDS Manually (Advanced)</h1>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">The actual TDS calculation formula depends on the nature of the payment. Here's a general formula for reference:

                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">Rate of Average Income Tax = Income Tax Payable (calculated using tax slabs) / Estimated Income for the Financial Year

</p>
                        <p className="text-lg font-normal mt-2 text-gray-700 "> <span className='font-bold text-gray-700'>Example:</span> Let's calculate Mr. Ramesh's monthly TDS using the above formula (assuming current tax slabs, which can change yearly).

                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">  Mr. Ramesh's annual salary is Rs. 1,00,000.o be:

                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 "> Based on current slabs, his total income tax liability would be Rs. 1,42,500.

                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">  Adding the 4% education cess, his net payable tax becomes Rs. 1,48,200.

                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 ">  Average TDS rate = Rs. 1,48,200 / Rs. 1,00,000 * 100 = 12.35%

                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-700 "> Therefore, the monthly TDS deducted from Mr. Ramesh's salary would be 12.35% of Rs. 1 lakh, which is Rs. 12,350.
                        </p>
                        <p className="text-lg font-normal mt-2 text-gray-800 "> <span className='text-gray-800 font-bold'>Remember: </span>This is a simplified example for illustrative purposes only. Actual TDS calculations may involve more complex considerations. It's always best to consult a tax professional for personalized advice.
</p>
                    </div>



                   

                </div>

                <div className="w-full md:w-1/3 p-4">
                    <div className="bg-white shadow-md rounded-lg overflow-hidden">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
                            <h2 className="text-lg font-semibold ">Popular Categories</h2>
                        </div>
                        <div className="p-2 ">
                            <ul>
                                <Link to='/financial-tools-calculators-swp-calculator'>
                                    <li className="py-2  hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/mutul-fund-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Mutual Fund Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/sukanya-samriddhi-yojana-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Samriddhi Yojana Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/fd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">FD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/rd-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">RD Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/nps-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NPS Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/hra-exemption-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">HRA Exemption Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/retirement-planning-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Retirement Planning Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">EMI Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/simple-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Simple Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/compound-interest-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Compound Interest Calculator</li>

                                </Link><Link to='/financial-tools-calculators/nsc-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">NSC Calculator</li>

                                </Link><Link to='/financial-tools-calculators/step-up-sip-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Step Up Sip Calculator</li>

                                </Link><Link to='/financial-tools-calculators/income-tax-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Income Tax Calculator</li>
                                    
                                </Link><Link to='/financial-tools-calculators/home-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Home Loan Emi Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/car-loan-emi-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Car Loan Emi Calculator</li>
                                </Link>
                                {/* {categories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
                        <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
                            <h2 className="text-lg font-semibold">More Categories</h2>
                        </div>
                        <div className="p-2">
                        <ul>
                                <Link to='/financial-tools-calculators/gratuity-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Gratuity Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/APY-calculator'>
                                    <li className="py-2 border- hover:bg-slate-100 border-gray-300">APY Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/cagr-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">CAGR Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/gst-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">GST Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/flat-vs-reducing-rate-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Flat Vs Reducing Rate Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/equity-margin-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Equity Margin Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/inflation-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Inflation Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/salary-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Salary Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/brokerage-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">Brokerage Calculator</li>
                                </Link>
                                <Link to='/financial-tools-calculators/tds-calculator'>
                                    <li className="py-2 border-b hover:bg-slate-100 border-gray-300">TDS Calculator</li>
                                </Link>
                                {/* {moreCategories.map(category => (
       <Link key={category.id} to={`/category/${category.id}`}>
         <li className="py-2 border-b border-gray-300">{category.name}</li>
       </Link>
     ))} */}
                            </ul>
                        </div>
                    </div>

                </div>

            </div>

        </>
    );
};

export default TDSCalculator;




// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
//     const Banner = () => {
//         return (
//             <div className="shadow-xl rounded-md p-4 bg-white mt-4 md:mt-0 text-center">
//                 <img src="https://www.shutterstock.com/image-illustration/credit-cards-online-shopping-banner-260nw-355524626.jpg" alt="Advertisement" className="w-full rounded-md mb-4" />
//                 <h3 className="text-xl font-bold mb-2">Invest the way you want</h3>
//                 <p>Join millions of Indians who trust and love Groww</p>
//                 <button className="bg-green-500 text-white px-4 py-2 rounded mt-2">Explore Products</button>
//             </div>
//         );
//     };

//     const TDSCalculator = () => {
//     const [recipientType, setRecipientType] = useState('individual');
//     const [panAvailable, setPanAvailable] = useState(true);
//     const [natureOfPayment, setNatureOfPayment] = useState('192A');
//     const [amount, setAmount] = useState(0);
//     const [tds, setTds] = useState(0);
//     const [calculated, setCalculated] = useState(false);

//     const natureOfPayments = [
//         { value: '192A', label: '192A - Premature withdrawal from EPF' },
//         { value: '193', label: '193 - Interest on securities' },
//         { value: '194', label: '194 - Dividend other than dividend as referred to in Section 115-O' },
//         { value: '194A', label: '194A - Interest other than banks' },
//         { value: '194A', label: '194A - Interest from banks' },
//         { value: '194B', label: '194B - Income from lottery winnings, and other games of any type' },
//         { value: '194BB', label: '194BB - Income from horse race winnings' },
//         { value: '194C', label: '194C - Single Payment to Contractor/Subcontractor' },
//         { value: '194C', label: '194C - FY Payment to Contractor/Subcontractor' },
//         { value: '194H', label: '194H - Commision or brokerage' },
//         { value: '194A(A)', label: '194A(A) - Rent on plant and machinery' },
//         { value: '194C', label: '194C - Payment to contractors' },
//         { value: '194D', label: '194D - Insurance commission' },
//         { value: '194E', label: '194E - Payment to non-resident sportsman/sports association' },
//         { value: '194EE', label: '194EE - Payment from National Saving Scheme' },
//         { value: '194F', label: '194F - Payment on account of repurchase of unit by Mutual Fund or UTI' },
//         { value: '194G', label: '194G - Commission on sale of lottery tickets' },
//         { value: '194H', label: '194H - Commission or brokerage' },
//         { value: '194I', label: '194I - Rent' },
//         { value: '194IA', label: '194IA - Transfer of certain immovable property other than agricultural land' },
//         { value: '194IB', label: '194IB - Rent on land & building or furniture & fitting' },
//         { value: '194J', label: '194J - Fees for professional service/royalty' },
//         { value: '194J1', label: '194J1 - Fees for technical service/call center' },
//         { value: '194K', label: '194K - Payment of any income for units of a mutual fund, for example, dividend' },
//         { value: '194O', label: '194O - Payment for sale of goods or provision of services by e-commerce operator' },
//         { value: '194Q', label: '194Q - Payment for purchase of goods' },
//         // Add more options as needed
//     ];

//     const calculateTDS = () => {
//         // Placeholder logic for TDS calculation
//         const calculatedTds = amount * 0.1; // Example: 10% TDS
//         setTds(calculatedTds);
//         setCalculated(true);
//     };

//     return (
//         <>
//         <div className="py-4  ">
//         <div className="container mx-auto p-4 flex flex-col md:flex-row md:space-x-4">
//             <div className="w-full md:w-2/3 p-4 shadow-xl rounded-md bg-white">
//             <h1 className="text-2xl font-bold mb-4">TDS Calculator</h1>
//             <div className="mb-4">
//                 <label className="block text-gray-700 font-semibold">Recipient</label>
//                 <div className="flex space-x-4">
//                 <label>
//                     <input
//                     type="radio"
//                     name="recipient"
//                     value="individual"
//                     checked={recipientType === 'individual'}
//                     onChange={() => setRecipientType('individual')}
//                     className="mr-2"
//                     />
//                     Individual/HUF/Sole Proprietor
//                 </label>
//                 <label>
//                     <input
//                     type="radio"
//                     name="recipient"
//                     value="others"
//                     checked={recipientType === 'others'}
//                     onChange={() => setRecipientType('others')}
//                     className="mr-2"
//                     />
//                     Others
//                 </label>
//                 </div>
//             </div>
//             <div className="mb-4">
//                 <label className="block text-gray-700 font-semibold">PAN of recipient is available</label>
//                 <select
//                 value={panAvailable}
//                 onChange={(e) => setPanAvailable(e.target.value === 'true')}
//                 className="w-full p-2 border rounded"
//                 >
//                 <option value="true">Yes</option>
//                 <option value="false">No</option>
//                 </select>
//             </div>
//             <div className="mb-4">
//                 <label className="block text-gray-700 font-semibold">Nature of payment</label>
//                 <select
//                 value={natureOfPayment}
//                 onChange={(e) => setNatureOfPayment(e.target.value)}
//                 className="w-full p-2 border rounded max-h-32 overflow-auto"
//                 >
//                 {natureOfPayments.map((payment) => (
//                     <option key={payment.value} value={payment.value}>
//                     {payment.label}
//                     </option>
//                 ))}
//                 </select>
//             </div>
//             <div className="mb-4">
//                 <label className="block text-gray-700 font-semibold">Amount of payment</label>
//                 <input
//                 type="number"
//                 value={amount}
//                 onChange={(e) => setAmount(parseFloat(e.target.value))}
//                 className="w-full p-2 border rounded"
//                 />
//             </div>
//             <button
//                 onClick={calculateTDS}
//                 className="bg-blue-500 text-white px-4 py-2 rounded"
//             >
//                 Calculate
//             </button>
//             {calculated && (
//                 <div className="mt-4 bg-gray-100 p-4 rounded shadow-md">
//                 <h2 className="text-xl font-bold">Total TDS</h2>
//                 <p className="text-2xl">₹ {tds.toFixed(2)}</p>
//                 </div>
//           )}
//         </div>
      
//             <Banner />
          
//       </div>
//     </div>


//     <div className="flex flex-wrap px-6 py-6" >
//                 <div className="w-full md:w-2/3 p-4">
//                     <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2">What is TDS?
//                     </h1>

//                     <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Tax Deducted at Source (TDS) is a mechanism where a specific amount is deducted from certain payments before they reach the recipient. This applies to various income sources such as salary, rent, commission, and interest.
//                     </p>
//                     <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">The entity making the payment deducts the TDS, while the recipient is ultimately liable to pay the income tax. This system helps reduce tax evasion by collecting tax at the source of income.
//                     </p>
//                     <div className="container mx-auto  py-4">
//                         <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How to Calculate TDS or TDS Interest:
// </h1>
//                         <p className="text-lg font-normal mt-2 text-gray-700 ">You can estimate your TDS liability or interest on TDS payments using a TDS calculator or a TDS interest calculator.

//                         </p>
//                     </div>


//                     <div className="container mx-auto  py-4">
//                         <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">What is a TDS Calculator?
//                         </h1>
//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">A TDS calculator is a free online tool that offers several functionalities:
//                         </p>
//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'>      Determines TDS Obligation:  </span> It helps you understand if you're required to deduct TDS from a particular payment

//                         </p>
//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'>     Calculates TDS Amount: </span>  If TDS applies, the calculator estimates the exact amount to be deducted.

//                         </p>
//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'>   Verifies TDS Accuracy:  </span>     You can use it to check if the deducted TDS on your income (like salary) is correct.
//                         </p>

//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'>  Provides Basic TDS Insights:  </span> The calculator offers a general understanding of relevant TDS rules.
//                         </p>
//                     </div>
//                     <div className="container mx-auto  py-4">
//                         <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Benefits of Using a TDS Calculator:
//                         </h1>
                       
//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'>      Simple and User-Friendly:  </span>  These online calculators are easy to navigate and require no registration or login.

//                         </p>
//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'>     Quick Results: </span>   Get your TDS estimates within seconds.

//                         </p>
//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'>  Reduces Errors:  </span>    Eliminate the risk of mistakes from manual calculations.
//                         </p>

//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'> Improves Financial Planning: </span>  Knowing your net pay (after TDS) helps with budgeting.

//                         </p>
//                     </div>
//                     <div className="container mx-auto  py-4">
//                         <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">How to Use a TDS Calculator:
//                         </h1>
//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg  mt-2">Using a TDS calculator is straightforward. Here's a typical process:
//                         </p>
//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'>Recipient Type:   </span> Select your recipient category (individual, sole proprietor, etc.)

//                         </p>
//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'>     Payment Amount:  </span>  Enter the payment amount.

//                         </p>
//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'>    Nature of Payment:  </span>    Specify the payment type (salary, fixed deposit interest, etc.)
//                         </p>

//                         <p className="text-lg text-gray-600 sm:text-xl md:text-lg lg:text-xl xl:text-lg relative pl-6 mt-2"> {/* Added pl-6 for left padding */}
//                             <span className="absolute left-0 top-0  text-blue-600">&#8226;</span> {/* Adjusted mt and added margin */}
//                             <span className='font-bold'>  Results: </span>  The calculator will quickly determine if TDS applies and, if so, the amount to be deducted.
//                         </p>
//                     </div>



                    
                    

//                     <div className="container mx-auto  py-4">
//                         <h1 className="text-xl font-medium  bg-blue-100 border-l-4 border-blue-600 pl-4 py-2 ">Calculating TDS Manually (Advanced)</h1>
//                         <p className="text-lg font-normal mt-2 text-gray-700 ">The actual TDS calculation formula depends on the nature of the payment. Here's a general formula for reference:

//                         </p>
//                         <p className="text-lg font-normal mt-2 text-gray-700 ">Rate of Average Income Tax = Income Tax Payable (calculated using tax slabs) / Estimated Income for the Financial Year

// </p>
//                         <p className="text-lg font-normal mt-2 text-gray-700 "> <span className='font-bold text-gray-700'>Example:</span> Let's calculate Mr. Ramesh's monthly TDS using the above formula (assuming current tax slabs, which can change yearly).

//                         </p>
//                         <p className="text-lg font-normal mt-2 text-gray-700 ">  Mr. Ramesh's annual salary is Rs. 1,00,000.o be:

//                         </p>
//                         <p className="text-lg font-normal mt-2 text-gray-700 "> Based on current slabs, his total income tax liability would be Rs. 1,42,500.

//                         </p>
//                         <p className="text-lg font-normal mt-2 text-gray-700 ">  Adding the 4% education cess, his net payable tax becomes Rs. 1,48,200.

//                         </p>
//                         <p className="text-lg font-normal mt-2 text-gray-700 ">  Average TDS rate = Rs. 1,48,200 / Rs. 1,00,000 * 100 = 12.35%

//                         </p>
//                         <p className="text-lg font-normal mt-2 text-gray-700 "> Therefore, the monthly TDS deducted from Mr. Ramesh's salary would be 12.35% of Rs. 1 lakh, which is Rs. 12,350.
//                         </p>
//                         <p className="text-lg font-normal mt-2 text-gray-800 "> <span className='text-gray-800 font-bold'>Remember: </span>This is a simplified example for illustrative purposes only. Actual TDS calculations may involve more complex considerations. It's always best to consult a tax professional for personalized advice.
// </p>
//                     </div>



                   

//                 </div>

//                 <div className="w-full md:w-1/3 p-4">
//                     <div className="bg-white shadow-md rounded-lg overflow-hidden">
//                         <div className="bg-blue-100 px-4 py-2 border-b border-blue-600 ">
//                             <h2 className="text-lg font-semibold ">Popular Categories</h2>
//                         </div>
//                         <div className="p-2 ">
//                             <ul>
//                                 <Link to='/swp-calculator'>
//                                     <li className="py-2 text-xl hover:bg-slate-100 border-b border-gray-300">SWP Calculator</li>
//                                 </Link>
//                                 <Link to='/other-link'>
//                                     <li className="py-2 border-b border-gray-300">Other Link</li>
//                                 </Link>
//                                 <Link to='/other-link'>
//                                     <li className="py-2 border-b border-gray-300">Other Link</li>
//                                 </Link>
//                                 <Link to='/other-link'>
//                                     <li className="py-2 border-b border-gray-300">Other Link</li>
//                                 </Link>
//                                 <Link to='/other-link'>
//                                     <li className="py-2 border-b border-gray-300">Other Link</li>
//                                 </Link>
//                                 {/* {categories.map(category => (
//        <Link key={category.id} to={`/category/${category.id}`}>
//          <li className="py-2 border-b border-gray-300">{category.name}</li>
//        </Link>
//      ))} */}
//                             </ul>
//                         </div>
//                     </div>
//                     <div className="bg-white shadow-md rounded-lg overflow-hidden mt-4">
//                         <div className="bg-blue-100 px-4 py-2 border-b border-blue-600">
//                             <h2 className="text-lg font-semibold">More Categories</h2>
//                         </div>
//                         <div className="p-2">
//                             <ul>
//                                 <Link to='/other-link'>
//                                     <li className="py-2 border-b border-gray-300">Other Link</li>
//                                 </Link>
//                                 <Link to='/other-link'>
//                                     <li className="py-2 border-b border-gray-300">Other Link</li>
//                                 </Link>
//                                 <Link to='/other-link'>
//                                     <li className="py-2 border-b border-gray-300">Other Link</li>
//                                 </Link>
//                                 <Link to='/other-link'>
//                                     <li className="py-2 border-b border-gray-300">Other Link</li>
//                                 </Link>
//                                 {/* {moreCategories.map(category => (
//        <Link key={category.id} to={`/category/${category.id}`}>
//          <li className="py-2 border-b border-gray-300">{category.name}</li>
//        </Link>
//      ))} */}
//                             </ul>
//                         </div>
//                     </div>

//                 </div>

//             </div>
//     </>
//   );
// };

// export default TDSCalculator;



// // import React, { useState } from 'react';

// // const TDSCalculator = () => {
// //   const [recipientType, setRecipientType] = useState('individual');
// //   const [panAvailable, setPanAvailable] = useState(true);
// //   const [natureOfPayment, setNatureOfPayment] = useState('192A');
// //   const [amount, setAmount] = useState(0);
// //   const [tds, setTds] = useState(0);
// //   const [calculated, setCalculated] = useState(false);

// //   const natureOfPayments = [
// //     { value: '192A', label: '192A - Premature withdrawal from EPF' },
// //     { value: '193', label: '193 - Interest on securities' },
// //     { value: '194', label: '194 - Dividend other than dividend as referred to in Section 115-O' },
// //     { value: '194A', label: '194A - Interest other than banks' },   
// //     { value: '194A', label: '194A - Interest from banks' },
// //     { value: '194B', label: '194B - Income from lottery winnings, and other games of any type' },
// //     { value: '194BB', label: '194BB - Income from horse race winnings' },
//     // { value: '194C', label: '194C - Single Payment to Contractor/Subcontractor' },
//     // { value: '194C', label: '194C - FY Payment to Contractor/Subcontractor' },
//     // { value: '194H', label: '194H - Commision or brokerage' },
//     // { value: '194A(A)', label: '194A(A) - Rent on plant and machinery' },




// //     // Add more options as needed
// //   ];

// //   const calculateTDS = () => {
// //     // Placeholder logic for TDS calculation
// //     const calculatedTds = amount * 0.1; // Example: 10% TDS
// //     setTds(calculatedTds);
// //     setCalculated(true);
// //   };

// //   return (
// //     <div className="container mx-auto p-4 shadow-xl rounded-md">
// //       <h1 className="text-2xl font-bold mb-4">TDS Calculator</h1>
// //       <div className="mb-4">
// //         <label className="block text-gray-700 font-semibold">Recipient</label>
// //         <div className="flex space-x-4">
// //           <label>
// //             <input
// //               type="radio"
// //               name="recipient"
// //               value="individual"
// //               checked={recipientType === 'individual'}
// //               onChange={() => setRecipientType('individual')}
// //               className="mr-2"
// //             />
// //             Individual/HUF/Sole Proprietor
// //           </label>
// //           <label>
// //             <input
// //               type="radio"
// //               name="recipient"
// //               value="others"
// //               checked={recipientType === 'others'}
// //               onChange={() => setRecipientType('others')}
// //               className="mr-2"
// //             />
// //             Others
// //           </label>
// //         </div>
// //       </div>
// //       <div className="mb-4">
// //         <label className="block text-gray-700 font-semibold">PAN of recipient is available</label>
// //         <select
// //           value={panAvailable}
// //           onChange={(e) => setPanAvailable(e.target.value === 'true')}
// //           className="w-full p-2 border rounded"
// //         >
// //           <option value="true">Yes</option>
// //           <option value="false">No</option>
// //         </select>
// //       </div>
// //       <div className="mb-4">
// //         <label className="block text-gray-700 font-semibold">Nature of payment</label>
// //         <select
// //           value={natureOfPayment}
// //           onChange={(e) => setNatureOfPayment(e.target.value)}
// //           className="w-full p-2 border rounded"
// //         >
// //           {natureOfPayments.map((payment) => (
// //             <option key={payment.value} value={payment.value}>
// //               {payment.label}
// //             </option>
// //           ))}
// //         </select>
// //       </div>
// //       <div className="mb-4">
// //         <label className="block text-gray-700 font-semibold">Amount of payment</label>
// //         <input
// //           type="number"
// //           value={amount}
// //           onChange={(e) => setAmount(parseFloat(e.target.value))}
// //           className="w-full p-2 border rounded"
// //         />
// //       </div>
// //       <button
// //         onClick={calculateTDS}
// //         className="bg-blue-500 text-white px-4 py-2 rounded"
// //       >
// //         Calculate
// //       </button>
// //       {calculated && (
// //         <div className="mt-4 bg-gray-100 p-4 rounded shadow-md">
// //           <h2 className="text-xl font-bold">Total TDS</h2>
// //           <p className="text-2xl">₹ {tds.toFixed(2)}</p>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default TDSCalculator;
